import React from "react";
import moment from "moment";
import { config, SERVICE_API } from "../libs/utils/Const";

class InvocesPrintFormat extends React.Component {
  render() {
    const { doc } = this.props;
    let totalPrecioTotal = 0;
    return (
      // PDF FACTURAS
      <div style={{ pageBreakBefore: 'always', padding: "30px 50px" }}>
        <div className="d-flex flex-column" style={{ marginTop: "20px" }}>
          {/* Cabecera información */}
          <div className="d-flex flex-row">
            <div>
              <img
                style={{ width: "80%", height: "auto", maxWidth: 200 }}
                src={config.navBar.icon}
                alt="Logo"
              />
              <br />
              <div>
                <h2 class="mt-4 text-left font-weight-bold">
                  Perez de la Mora
                </h2>
                <h5 class="mb-2 text-left">Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000, Jalisco.</h5>
                <h5 class="text-left">ventas@perezdelamora.com</h5>
                <h5 className="text-left">Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37</h5>
              </div>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ flexWrap: "wrap", alignItems: "flex-end", justifyContent: "flex-end", content: "center" }}>
              <div style={{ textAlign: "center" }}>
                <h2 style={{ fontWeight: "bold" }}>Facturas</h2>
              </div>

              {/* Tabla información de cliente */}
              <table style={{ width: "90%", borderCollapse: "collapse" }}>
                <thead>
                  <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                    <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                      Nº Cliente
                    </th>
                    <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                      Nombre
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{ border: "0px solid #ddd", textAlign: "center", fontWeight: "bold" }}
                      className="font-weight-bold"
                    >
                      {doc && doc.data && doc.data.header && doc.data.header.CardCode
                        ? doc.data.header.CardCode : "N/A"}
                    </td>
                    <td
                      style={{ border: "0px solid #ddd", textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      {doc && doc.data && doc.data.header && doc.data.header.CardName
                        ? doc.data.header.CardName : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Tabla información N° Factura, fecha de contabilización y Vendedor Asignado*/}
              <table style={{ width: "90%", borderCollapse: "collapse" }}>
                <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Nº Factura
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Fecha de contabilización
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Vendedor Asignado
                  </th>
                </tr>
                <tr>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc && doc.data && doc.data.header && doc.data.header.DocNum
                      ? doc.data.header.DocNum : "--"}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc && doc.data && doc.data.header && doc.data.header.TaxDate
                      ? moment(doc.data.header.TaxDate).utc().format('YYYY-MM-DD') : 'N/A'}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc.data.header && doc.data.header.SlpName
                      ? doc.data.header.SlpName : "N/A"}
                  </td>
                </tr>
              </table>

              {/* Tabla información domicilios */}
              <table style={{ width: "90%", borderCollapse: "collapse" }}>
                <thead>
                  <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                    <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                      Domicilio de entrega
                    </th>
                    <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                      Domicilio facturación
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{ border: "0px solid #ddd", textAlign: "center", fontWeight: "bold" }}
                      className="font-weight-bold"
                    >
                      {doc && doc.data && doc.data.header && doc.data.header.Address2
                        ? doc.data.header.Address2 : "N/A"}
                    </td>
                    <td
                      style={{ border: "0px solid #ddd", textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      {doc && doc.data && doc.data.header && doc.data.header.Address
                        ? doc.data.header.Address : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Información de Timbrado */}
          {doc?.data?.header?.SELLODIGITAL &&
            <div className="d-flex flex-row mt-5">
              {/* Imagen */}
              <div className="image-section p-0 m-0" style={{ width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  style={{ maxWidth: '100%', height: 'auto' }}
                  src={doc?.data?.header?.QR ? (config.BASE_URL + SERVICE_API.getQR + '/' + doc?.data?.header?.QR) : require('../images/noImage.png')}
                  alt="QR"
                />
              </div>
              {/* Información */}
              <div className="table-section" style={{ width: '75%', paddingLeft: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '10px' }}>
                  <thead>
                    <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                      <th style={{ textAlign: 'center' }}>UUID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'center' }} className="font-weight-bold">
                        {doc?.data?.header?.UUID || "Sin información"}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '10px' }}>
                  <thead>
                    <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                      <th style={{ textAlign: 'center' }}>Sello digital</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'center', wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '300px' }} className="font-weight-bold">
                        {doc?.data?.header?.SELLODIGITAL || "Sin información"}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                      <th style={{ textAlign: "center" }}>
                        Folio digital
                      </th>
                      <th style={{ textAlign: "center" }}>
                        Serie digital
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center", fontWeight: "bold" }} className="font-weight-bold">
                        {doc?.data?.header?.FOLIO || "Sin información"}
                      </td>
                      <td style={{ textAlign: "center" }} className="font-weight-bold">
                        {doc?.data?.header?.SERIEFISCAL || "Sin información"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          }

          {/* Tabla info body */}
          <div className="mt-5 d-flex flex-column bd-highlight text-center">
            <table className="text-center">
              <tr className="text-white" style={{ backgroundColor: config.navBar.iconColor }}>
                <th>Cód. Fabricante</th>
                <th>Código SAP</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Precio total</th>
                <th>Descuento</th>
              </tr>
              {!!doc &&
                doc.data.body.map((item, index) => {
                  const precioTotalItem = parseFloat(item.LineTotal);
                  totalPrecioTotal += precioTotalItem;
                  return (
                    <tr key={index}>
                      <th>{item.SuppCatNum ? item.SuppCatNum : 'N/A'}</th>
                      <th>{item.ItemCode ? item.ItemCode : '--'}</th>
                      <th>{item.Dscription ? item.Dscription : 'N/A'}</th>
                      <th>{item.Quantity ? parseInt(item.Quantity) : '--'}</th>
                      <th>{item.Price ? `$ ${parseFloat(item.Price).toFixed(2)} MXN` : '--'}</th>
                      <th>{item.LineTotal ? `$ ${parseFloat(item.LineTotal).toFixed(2)} MXN` : '--'}</th>
                      <th>{item.Price ? `$ ${(item.Price - item.PriceBeforeDiscount).toFixed(2)} MXN` : '--'}</th>
                    </tr>
                  );
                })}
            </table>
          </div>

          {/* Tabla total de total */}
          <div className="d-flex justify-content-end mt-5 text-center">
            <table className="font-weight-bold">
              <tr>
                <th
                  className="text-white p-2"
                  style={{ backgroundColor: config.navBar.iconColor }}
                >
                  Subtotal
                </th>
                <td>{totalPrecioTotal ? `$ ${parseFloat(totalPrecioTotal).toFixed(2)} MXN` : '--'}</td>
              </tr>
              <tr>
                <th
                  className="text-white"
                  style={{ backgroundColor: config.navBar.iconColor }}
                >
                  IVA 16%
                </th>
                <td className="font-weight-bold">
                  {totalPrecioTotal ? `$ ${(totalPrecioTotal * 0.16).toFixed(2)} MXN` : '--'}
                </td>
              </tr>
              <tr>
                <th
                  style={{ border: "0px solid #ddd", backgroundColor: config.navBar.iconColor }}
                  className="text-center text-white font-weight-bold"
                >
                  Total
                </th>
                <td className="font-weight-bold">
                  {doc.data.header ? '$ ' + parseFloat(doc.data.header.DocTotal).toFixed(2) + ' MXN' : "--"}
                </td>
              </tr>
            </table>
          </div>
          <br></br>
          <div style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: 'row'
          }}>
            <span className="font-weight-bold">Comentario:</span>
            <span style={{ marginLeft: '10px' }}>{doc?.data?.header?.Comments || 'Sin comentario disponible'}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default InvocesPrintFormat;