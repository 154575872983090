import React from "react";
import { config } from "../libs/utils/Const";

class ExpiredInvoicesPrintFormat extends React.Component {
  render() {
    const { doc, user } = this.props;

    return (
      <div style={{ pageBreakAfter: 'always' }}>
        <div className="d-flex flex-row">
          <div>
            <img
              style={{ width: "80%", height: "auto", maxWidth: 200 }}
              src={config.navBar.icon}
              alt="Logo"
              />
            <br />
            <div>
              <h2 class="mt-4 text-left font-weight-bold">
                Perez de la Mora
              </h2>
              <h5 class="mb-2 text-left">Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000, Jalisco.</h5>
              <h5 class="text-left">ventas@perezdelamora.com</h5>
              <h5 className="text-left">Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37</h5>
            </div>
          </div>

          <div
            className="d-flex justify-content-end"
            style={{
              flexWrap: "wrap",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              content: "center",
              marginLeft: "auto"
            }}
            >
            <div style={{ textAlign: "center" }}>
              <h2 style={{ fontWeight: "bold" }}>Facturas pendientes</h2>
            </div>
            {/* Tabla información de cliente */}
            <table style={{ width: "90%", borderCollapse: "collapse", textAlign: "center" }}>
              <thead>
                <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Nº Cliente
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Nombre
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "0px solid #ddd",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                    className="font-weight-bold"
                  >
                    {user?.CardCode ?? 'N/A'}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {user?.CardName ?? 'N/A'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tr style={{ backgroundColor: config.navBar.iconColor, paddingTop: '12px', paddingBottom: '12px', textAlign: 'left', color: 'white' }}>
            <th style={{ visibility: 'hidden' }}>a</th>
            <th style={{ textAlign: 'center' }}>Codigo del fabricante</th>
            <th style={{ textAlign: 'center' }}>Cantidad</th>
            <th style={{ textAlign: 'center' }}>Cantidad entregada</th>
            <th style={{ textAlign: 'center' }}>Cantidad faltante</th>
            <th style={{ textAlign: 'center' }}>Precio</th>
            <th style={{ textAlign: 'center' }}>Precio Total</th>
            <th style={{ visibility: 'hidden' }}>a</th>
          </tr>
          {!!doc && doc.data.body.map((item, index) => {
            return (
              <tr>
                <th style={{ visibility: 'hidden' }}>a</th>
                <th style={{ textAlign: 'center' }}>{item.SuppCatNum}</th>
                <th style={{ textAlign: 'center' }}>{item.Quantity}</th>
                <th style={{ textAlign: 'center' }}>{item.DelivrdQty}</th>
                <th style={{ textAlign: 'center' }}>{item.OpenQty}</th>
                <th style={{ textAlign: 'right' }}>{'$' + parseFloat(item.Price).toFixed(2) + ' MXN'}</th>
                <th style={{ textAlign: 'right' }}>{'$' + parseFloat(item.LineTotal).toFixed(2) + ' MXN'}</th>
                <th style={{ visibility: 'hidden' }}>a</th>
              </tr>
            );
          })
          }
        </table>
      </div>
    )
  }
}

export default ExpiredInvoicesPrintFormat;