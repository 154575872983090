import React from "react";
import moment from "moment";
import {config} from '../libs/utils/Const.js';
import {ApiClient} from "../libs/apiClient/ApiClient";
let apiClient = ApiClient.getInstance();


class ReportDevolutionModal extends React.Component {

  render() {
    const { radiobutton, data, user, date, seller, folio,shipDate } = this.props;
    // console.log('data', data)
    // console.log('seller', seller)
    let fechaActual = new Date();
        let year = fechaActual.getFullYear();
        let month = fechaActual.getMonth() + 1;
        let day = fechaActual.getDate();
    let SubTotal = 0;
    if(data){
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        SubTotal += element.Price;
      }
    }
    return (
     <div>
       <div style={{ pageBreakBefore: 'always', padding: "30px 50px" }}>
        <table style={{ width: '100%' }}>
          <thead>
            <th style={{ width: '300px', textAlign: 'right', paddingTop: '70px' }}>
              <img style={{ width: '100%', height: 'auto' }} src={config.navBar.icon} />
            </th>
            <th style={{ textAlign: 'center' }}>
              <h3 style={{ fontWeight: 'bold', fontSize: '30px' }}>Perez de la Mora</h3>
              <h4>Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000, Jalisco.</h4>
              <h4>ventas@perezdelamora.com</h4>
              <h4>Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37</h4>
            </th>
            <th style={{ width: ' 50px', visibility: 'hidden' }}><p>Oculto</p></th>
          </thead>
        </table>
        <br></br>
        <div>
          <table style={{ width: '85%' }}>
            <thead>
              <th style={{ textAlign: 'center' }}>
                <h6 style={{ fontWeight: 'bold', textAlign: 'center' }}>ORDEN DE REVISIÓN</h6>
              </th>
              <th style={{ textAlign: 'center' }}>
                <h6 style={{ fontWeight: 'bold', textAlign: 'center' }}>FOLIO: DEV-{folio}</h6>
              </th>
            </thead>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000'}}>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6 style={{ fontWeight: 'bold' }}>FECHA DE ORDEN:</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6 style={{ fontWeight: 'bold' }}>CAPTURA</h6></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6>{date}</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}>
                <h6>
                  <input type="text" className="form-control" style={{ color: '#000' }} name='captura' id="captura" placeholder="Captura" autoComplete="off" onChange={this.handelChange} value={data[0].U_FMB_Handel_Captura ? data[0].U_FMB_Handel_Captura :''} />
                </h6>
              </th>
            </tr>
          </table>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
          <tr style={{ paddingTop: '12px', paddingLeft: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
            <th style={{ textAlign: 'center' }}><h5 style={{ fontWeight: 'bold' }}>CONCEPTO</h5></th>
          </tr>
        </table>
        <form>
          <div class="table-responsive">
            <table className="col-12" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
              <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>GARANTIA</h6></th>
                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                  <input type="radio" id="garantia" name="concepto" value="garantia" style={{ width: '20px', backgroundColor:'#fff'}} onChange={this.handelChange} checked={radiobutton===0} />
                </th>
                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>ACLARACION</h6></th>
                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                  <input type="radio" id="aclaracion" name="concepto" value="aclaracion" style={{ width: '20px', backgroundColor: '#fff' }} onChange={this.handelChange} checked={radiobutton===1} />
                </th>
                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>DEVOLUCIÓN</h6></th>
                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                  <input type="radio" id="devolucion" name="concepto" value="devolucion" style={{ width: '20px', backgroundColor: '#fff' }} onChange={this.handelChange} checked={radiobutton===2}/>
                </th>
              </tr>
            </table>
          </div>
        </form>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '25px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>CLIENTE</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>POBLACION</h6></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{data ? data[0].CardName : ''}</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>
                <h4>
                  {/* <input type="text" className="form-control" style={{fontWeight:'bold', color:'#000'}} name='poblacion' id="poblacion" placeholder="Población" autoComplete="off" onChange={this.handelChange} value={datos.poblacion ? datos.poblacion : ''}/> */}
                  <h6 >{data[0].CityS}</h6>
                </h4>
              </th>
            </tr>
          </table>
        </div>
       <br></br>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>No. CLIENTE</h6></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{data ? data[0].CardCode : ''}</h6></th>
            </tr>
          </table>
        </div>
       <br></br>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>FOLIO DE FACTURA</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>FECHA DE COMPRA</h6></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{data ? data[0].BaseRef : ''}</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{moment(shipDate).utc().format('YYYY-MM-DD')}</h6></th>
            </tr>
          </table>
        </div>
        <br></br>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center' }}><h6 style={{ fontWeight: 'bold' }}>ARTÍCULOS</h6></th>
              <th style={{ textAlign: 'left', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h4 style={{ fontWeight: 'bold',fontSize: '10pt'}}>APLICAR A FACTURA</h4></th>
            </tr>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', fontWeight: 'none' }}>Los artículos deben de ser de la misma factura.</th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>
                <h4 style={{ fontWeight: 'bold' }}>
                  <input type="text" className="form-control" style={{ fontWeight: 'bold', color: '#000' }} name='aplicar' id="aplicar" placeholder="Aplicar a factura" autoComplete="off" onChange={this.handelChange} value={''} />
                </h4>
              </th>
            </tr>
          </table>
        </div>
        <div class=" col-12 table-responsive">
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ backgroundColor: config.navBar.iconColor, paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: 'white' }}>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>#</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Clave</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Descripción</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Cantidad</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Precio</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Importe</th>
            </tr>
            {!!data && data.map((item, index) => {
            return (
              <tr>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{index+1}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{item.ItemCode}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{item.ItemName}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{parseInt(item.Quantity)}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>$ {Number(item.Price).toFixed(2)}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>$ {Number((item.Price - (item.Price * (item.Discount || 0 / 100) / 100)) * (item.Quantity)).toFixed(2)}</th>
            </tr>
                );
            })
           }
            <tr>
              <th colspan="6" style={{ textAlign: 'right' }}></th>
            </tr>
            <tr>
              <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>Subtotal:</th>
              <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat(SubTotal).toFixed(2)}</th>
            </tr>
            <tr>
              <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>I.V.A:</th>
              <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat(SubTotal*.16).toFixed(2)}</th>
            </tr>
            <tr>
              <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>Total:</th>
              <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat((SubTotal*.16) + SubTotal).toFixed(2)}</th>
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', backgroundColor: '#c7c3c3' }}><h5 style={{ fontWeight: 'bold', fontSize: '14pt', border: '1px solid black' }}>MOTIVO</h5></th>
            </tr>
            <tr style={{ paddingTop: '12px', width: '100%', height: '100px', paddingBottom: '12px', textAlign: 'center', color: '#000', border: '1px solid black' }}>
              <textarea type="text" className="form-control" style={{ color: '#000', height: '100px' }} name='motivo' id="motivo" placeholder="Escribe el motivo" autoComplete="off" onChange={this.handelChange} value={data[0].U_FMB_Handel_Motivo ? data[0].U_FMB_Handel_Motivo :''} />
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000', backgroundColor: 'rgb(199, 195, 195)' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h5 style={{ fontWeight: 'bold' }}>Vendedor</h5></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6 style={{ fontWeight: 'none' }}></h6>{(data[0].firstName ? data[0].firstName:'') +' '+ (data[0].middleName ? data[0].middleName:'') +' '+ (data[0].lastName ? data[0].lastName:'')}</th>
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <div class="table-responsive">
          <table class="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ width: '100%', color: '#000', verticalAlign: 'bottom', height: '70px' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
            </tr>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CLIENTE</h4><h6 style={{ fontSize: '10pt' }}>ENTREGUE</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CHOFER</h4><h6 style={{ fontSize: '10pt' }}>RECIBI EN BUEN ESTADO</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>ALMACEN</h4><h6 style={{ fontSize: '10pt' }}>RECIBI EN BUEN ESTADO</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CLIENTE</h4><h6 style={{ fontSize: '10pt' }}>RECIBI ARTÍCULO</h6></th>
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
          <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' , backgroundColor:'rgb(199, 195, 195)'}}>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}><h4 style={{ fontWeight: 'bold', fontSize: '10pt' }}>APLICACIÓN CRÉDITO Y COBRANZA</h4></th>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}>
              <h4 style={{ fontWeight: 'bold' }}>
                <input type="text" className="form-control" style={{ fontWeight: 'bold', color: '#000' }} name='credito' id="credito" placeholder="Aplicación crédito y cobranza" autoComplete="off" onChange={this.handelChange} value={''} />
              </h4>
            </th>
          </tr>
          <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' , backgroundColor:'rgb(199, 195, 195)'}}>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}><h4 style={{ fontWeight: 'bold', fontSize: '10pt' }}>FOLIO NOTA DE CRÉDITO</h4></th>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}>
              <h4 style={{ fontWeight: 'bold' }}>
                <input type="text" className="form-control" style={{ fontWeight: 'bold', color: '#000' }} name='foliocredito' id="foliocredito" placeholder="Folio nota de crédito" autoComplete="off" onChange={this.handelChange} value={''} />
              </h4>
            </th>
          </tr>
        </table>

        <div className="modal-footer">
          <span style={{fontSize:'10pt', fontWeight:'bold'}}>Original</span>
        </div>
      </div>
      <div style={{ pageBreakBefore: 'always', padding: "30px 50px" }}>
        <table style={{ width: '100%' }}>
          <thead>
            <th style={{ width: '300px', textAlign: 'right', paddingTop: '70px' }}>
              <img style={{ width: '100%', height: 'auto' }} src={config.navBar.icon} />
            </th>
            <th style={{ textAlign: 'center' }}>
              <h3 style={{ fontWeight: 'bold', fontSize: '30px' }}>Perez de la Mora</h3>
              <h4>Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000, Jalisco.</h4>
              <h4>ventas@perezdelamora.com</h4>
              <h4>Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37</h4>
            </th>
            <th style={{ width: ' 50px', visibility: 'hidden' }}><p>Oculto</p></th>
          </thead>
        </table>
        <br></br>
        <div>
          <table style={{ width: '85%' }}>
            <thead>
              <th style={{ textAlign: 'center' }}>
                <h6 style={{ fontWeight: 'bold', textAlign: 'center' }}>ORDEN DE REVISIÓN</h6>
              </th>
              <th style={{ textAlign: 'center' }}>
                <h6 style={{ fontWeight: 'bold', textAlign: 'center' }}>FOLIO: DEV-{folio}</h6>
              </th>
            </thead>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000'}}>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6 style={{ fontWeight: 'bold' }}>FECHA DE ORDEN:</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6 style={{ fontWeight: 'bold' }}>CAPTURA</h6></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6>{date}</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}>
                <h6>
                  <input type="text" className="form-control" style={{ color: '#000' }} name='captura' id="captura" placeholder="Captura" autoComplete="off" onChange={this.handelChange} value={data[0].U_FMB_Handel_Captura ? data[0].U_FMB_Handel_Captura :''} />
                </h6>
              </th>
            </tr>
          </table>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
          <tr style={{ paddingTop: '12px', paddingLeft: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
            <th style={{ textAlign: 'center' }}><h5 style={{ fontWeight: 'bold' }}>CONCEPTO</h5></th>
          </tr>
        </table>
        <form>
          <div class="table-responsive">
            <table className="col-12" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
              <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>GARANTIA</h6></th>
                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                  <input type="radio" id="garantia" name="concepto" value="garantia" style={{ width: '20px', backgroundColor:'#fff'}} onChange={this.handelChange} checked={radiobutton===0} />
                </th>
                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>ACLARACION</h6></th>
                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                  <input type="radio" id="aclaracion" name="concepto" value="aclaracion" style={{ width: '20px', backgroundColor: '#fff' }} onChange={this.handelChange} checked={radiobutton===1} />
                </th>
                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>DEVOLUCIÓN</h6></th>
                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                  <input type="radio" id="devolucion" name="concepto" value="devolucion" style={{ width: '20px', backgroundColor: '#fff' }} onChange={this.handelChange} checked={radiobutton===2}/>
                </th>
              </tr>
            </table>
          </div>
        </form>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '25px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>CLIENTE</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>POBLACION</h6></th>

            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{data ? data[0].CardName : ''}</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>
                <h4>
                  {/* <input type="text" className="form-control" style={{fontWeight:'bold', color:'#000'}} name='poblacion' id="poblacion" placeholder="Población" autoComplete="off" onChange={this.handelChange} value={datos.poblacion ? datos.poblacion : ''}/> */}
                  <h6 >{data[0].CityS}</h6>
                </h4>
              </th>
            </tr>
          </table>
        </div>
       <br></br>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>No. CLIENTE</h6></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{data ? data[0].CardCode : ''}</h6></th>
            </tr>
          </table>
        </div>
       <br></br>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>FOLIO DE FACTURA</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>FECHA DE COMPRA</h6></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{data ? data[0].BaseRef : ''}</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{moment(shipDate).utc().format('YYYY-MM-DD')}</h6></th>
            </tr>
          </table>
        </div>
        <br></br>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center' }}><h6 style={{ fontWeight: 'bold' }}>ARTÍCULOS</h6></th>
              <th style={{ textAlign: 'left', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h4 style={{ fontWeight: 'bold',fontSize: '10pt'}}>APLICAR A FACTURA</h4></th>
            </tr>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', fontWeight: 'none' }}>Los artículos deben de ser de la misma factura.</th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>
                <h4 style={{ fontWeight: 'bold' }}>
                  <input type="text" className="form-control" style={{ fontWeight: 'bold', color: '#000' }} name='aplicar' id="aplicar" placeholder="Aplicar a factura" autoComplete="off" onChange={this.handelChange} value={''} />
                </h4>
              </th>
            </tr>
          </table>
        </div>
        <div class=" col-12 table-responsive">
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ backgroundColor: config.navBar.iconColor, paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: 'white' }}>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>#</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Clave</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Descripción</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Cantidad</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Precio</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Importe</th>
            </tr>
            {!!data && data.map((item, index) => {
            return (
              <tr>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{index+1}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{item.ItemCode}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{item.ItemName}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{parseInt(item.Quantity)}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>$ {Number(item.Price).toFixed(2)}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>$ {Number((item.Price - (item.Price * (item.Discount || 0 / 100) / 100)) * (item.Quantity)).toFixed(2)}</th>
            </tr>
                );
            })
           }
            <tr>
              <th colspan="6" style={{ textAlign: 'right' }}></th>
            </tr>
            <tr>
              <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>Subtotal:</th>
              <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat(SubTotal).toFixed(2)}</th>
            </tr>
            <tr>
              <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>I.V.A:</th>
              <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat(SubTotal*.16).toFixed(2)}</th>
            </tr>
            <tr>
              <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>Total:</th>
              <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat((SubTotal*.16) + SubTotal).toFixed(2)}</th>
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', backgroundColor: '#c7c3c3' }}><h5 style={{ fontWeight: 'bold', fontSize: '14pt', border: '1px solid black' }}>MOTIVO</h5></th>
            </tr>
            <tr style={{ paddingTop: '12px', width: '100%', height: '100px', paddingBottom: '12px', textAlign: 'center', color: '#000', border: '1px solid black' }}>
              <textarea type="text" className="form-control" style={{ color: '#000', height: '100px' }} name='motivo' id="motivo" placeholder="Escribe el motivo" autoComplete="off" onChange={this.handelChange} value={data[0].U_FMB_Handel_Motivo ? data[0].U_FMB_Handel_Motivo :''} />
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000', backgroundColor: 'rgb(199, 195, 195)' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h5 style={{ fontWeight: 'bold' }}>Vendedor</h5></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6 style={{ fontWeight: 'none' }}></h6>{(data[0].firstName ? data[0].firstName:'') +' '+ (data[0].middleName ? data[0].middleName:'') +' '+ (data[0].lastName ? data[0].lastName:'')}</th>
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <div class="table-responsive">
          <table class="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ width: '100%', color: '#000', verticalAlign: 'bottom', height: '70px' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
            </tr>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CLIENTE</h4><h6 style={{ fontSize: '10pt' }}>ENTREGUE</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CHOFER</h4><h6 style={{ fontSize: '10pt' }}>RECIBI EN BUEN ESTADO</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>ALMACEN</h4><h6 style={{ fontSize: '10pt' }}>RECIBI EN BUEN ESTADO</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CLIENTE</h4><h6 style={{ fontSize: '10pt' }}>RECIBI ARTÍCULO</h6></th>
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
          <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' , backgroundColor:'rgb(199, 195, 195)'}}>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}><h4 style={{ fontWeight: 'bold', fontSize: '10pt' }}>APLICACIÓN CRÉDITO Y COBRANZA</h4></th>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}>
              <h4 style={{ fontWeight: 'bold' }}>
                <input type="text" className="form-control" style={{ fontWeight: 'bold', color: '#000' }} name='credito' id="credito" placeholder="Aplicación crédito y cobranza" autoComplete="off" onChange={this.handelChange} value={''} />
              </h4>
            </th>
          </tr>
          <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' , backgroundColor:'rgb(199, 195, 195)'}}>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}><h4 style={{ fontWeight: 'bold', fontSize: '10pt' }}>FOLIO NOTA DE CRÉDITO</h4></th>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}>
              <h4 style={{ fontWeight: 'bold' }}>
                <input type="text" className="form-control" style={{ fontWeight: 'bold', color: '#000' }} name='foliocredito' id="foliocredito" placeholder="Folio nota de crédito" autoComplete="off" onChange={this.handelChange} value={''} />
              </h4>
            </th>
          </tr>
        </table>

        <div className="modal-footer">
          <span style={{fontSize:'10pt', fontWeight:'bold'}}>Cliente</span>
        </div>
      </div>
      <div style={{ pageBreakBefore: 'always', padding: "30px 50px" }}>
        <table style={{ width: '100%' }}>
          <thead>
            <th style={{ width: '300px', textAlign: 'right', paddingTop: '70px' }}>
              <img style={{ width: '100%', height: 'auto' }} src={config.navBar.icon} />
            </th>
            <th style={{ textAlign: 'center' }}>
              <h3 style={{ fontWeight: 'bold', fontSize: '30px' }}>Perez de la Mora</h3>
              <h4>Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000, Jalisco.</h4>
              <h4>ventas@perezdelamora.com</h4>
              <h4>Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37</h4>
            </th>
            <th style={{ width: ' 50px', visibility: 'hidden' }}><p>Oculto</p></th>
          </thead>
        </table>
        <br></br>
        <div>
          <table style={{ width: '85%' }}>
            <thead>
              <th style={{ textAlign: 'center' }}>
                <h6 style={{ fontWeight: 'bold', textAlign: 'center' }}>ORDEN DE REVISIÓN</h6>
              </th>
              <th style={{ textAlign: 'center' }}>
                <h6 style={{ fontWeight: 'bold', textAlign: 'center' }}>FOLIO: DEV-{folio}</h6>
              </th>
            </thead>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000'}}>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6 style={{ fontWeight: 'bold' }}>FECHA DE ORDEN:</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6 style={{ fontWeight: 'bold' }}>CAPTURA</h6></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6>{date}</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}>
                <h6>
                  <input type="text" className="form-control" style={{ color: '#000' }} name='captura' id="captura" placeholder="Captura" autoComplete="off" onChange={this.handelChange} value={data[0].U_FMB_Handel_Captura ? data[0].U_FMB_Handel_Captura :''} />
                </h6>
              </th>
            </tr>
          </table>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
          <tr style={{ paddingTop: '12px', paddingLeft: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
            <th style={{ textAlign: 'center' }}><h5 style={{ fontWeight: 'bold' }}>CONCEPTO</h5></th>
          </tr>
        </table>
        <form>
          <div class="table-responsive">
            <table className="col-12" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
              <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>GARANTIA</h6></th>
                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                  <input type="radio" id="garantia" name="concepto" value="garantia" style={{ width: '20px', backgroundColor:'#fff'}} onChange={this.handelChange} checked={radiobutton===0} />
                </th>
                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>ACLARACION</h6></th>
                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                  <input type="radio" id="aclaracion" name="concepto" value="aclaracion" style={{ width: '20px', backgroundColor: '#fff' }} onChange={this.handelChange} checked={radiobutton===1} />
                </th>
                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>DEVOLUCIÓN</h6></th>
                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                  <input type="radio" id="devolucion" name="concepto" value="devolucion" style={{ width: '20px', backgroundColor: '#fff' }} onChange={this.handelChange} checked={radiobutton===2}/>
                </th>
              </tr>
            </table>
          </div>
        </form>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '25px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>CLIENTE</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>POBLACION</h6></th>

            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{data ? data[0].CardName : ''}</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>
                <h4>
                  {/* <input type="text" className="form-control" style={{fontWeight:'bold', color:'#000'}} name='poblacion' id="poblacion" placeholder="Población" autoComplete="off" onChange={this.handelChange} value={datos.poblacion ? datos.poblacion : ''}/> */}
                  <h6 >{data[0].CityS}</h6>
                </h4>
              </th>
            </tr>
          </table>
        </div>
       <br></br>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>No. CLIENTE</h6></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{data ? data[0].CardCode : ''}</h6></th>
            </tr>
          </table>
        </div>
       <br></br>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>FOLIO DE FACTURA</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>FECHA DE COMPRA</h6></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{data ? data[0].BaseRef : ''}</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{moment(shipDate).utc().format('YYYY-MM-DD')}</h6></th>
            </tr>
          </table>
        </div>
        <br></br>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center' }}><h6 style={{ fontWeight: 'bold' }}>ARTÍCULOS</h6></th>
              <th style={{ textAlign: 'left', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h4 style={{ fontWeight: 'bold',fontSize: '10pt'}}>APLICAR A FACTURA</h4></th>
            </tr>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', fontWeight: 'none' }}>Los artículos deben de ser de la misma factura.</th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>
                <h4 style={{ fontWeight: 'bold' }}>
                  <input type="text" className="form-control" style={{ fontWeight: 'bold', color: '#000' }} name='aplicar' id="aplicar" placeholder="Aplicar a factura" autoComplete="off" onChange={this.handelChange} value={''} />
                </h4>
              </th>
            </tr>
          </table>
        </div>
        <div class=" col-12 table-responsive">
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ backgroundColor: config.navBar.iconColor, paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: 'white' }}>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>#</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Clave</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Descripción</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Cantidad</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Precio</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Importe</th>
            </tr>
            {!!data && data.map((item, index) => {
            return (
              <tr>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{index+1}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{item.ItemCode}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{item.ItemName}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{parseInt(item.Quantity)}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>$ {Number(item.Price).toFixed(2)}</th>
              <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>$ {Number((item.Price - (item.Price * (item.Discount || 0 / 100) / 100)) * (item.Quantity)).toFixed(2)}</th>
            </tr>
                );
            })
           }
            <tr>
              <th colspan="6" style={{ textAlign: 'right' }}></th>
            </tr>
            <tr>
              <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>Subtotal:</th>
              <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat(SubTotal).toFixed(2)}</th>
            </tr>
            <tr>
              <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>I.V.A:</th>
              <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat(SubTotal*.16).toFixed(2)}</th>
            </tr>
            <tr>
              <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>Total:</th>
              <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat((SubTotal*.16) + SubTotal).toFixed(2)}</th>
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', backgroundColor: '#c7c3c3' }}><h5 style={{ fontWeight: 'bold', fontSize: '14pt', border: '1px solid black' }}>MOTIVO</h5></th>
            </tr>
            <tr style={{ paddingTop: '12px', width: '100%', height: '100px', paddingBottom: '12px', textAlign: 'center', color: '#000', border: '1px solid black' }}>
              <textarea type="text" className="form-control" style={{ color: '#000', height: '100px' }} name='motivo' id="motivo" placeholder="Escribe el motivo" autoComplete="off" onChange={this.handelChange} value={data[0].U_FMB_Handel_Motivo ? data[0].U_FMB_Handel_Motivo :''} />
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000', backgroundColor: 'rgb(199, 195, 195)' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h5 style={{ fontWeight: 'bold' }}>Vendedor</h5></th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h6 style={{ fontWeight: 'none' }}></h6>{(data[0].firstName ? data[0].firstName:'') +' '+ (data[0].middleName ? data[0].middleName:'') +' '+ (data[0].lastName ? data[0].lastName:'')}</th>
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <div class="table-responsive">
          <table class="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
            <tr style={{ width: '100%', color: '#000', verticalAlign: 'bottom', height: '70px' }}>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
              <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
            </tr>
            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CLIENTE</h4><h6 style={{ fontSize: '10pt' }}>ENTREGUE</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CHOFER</h4><h6 style={{ fontSize: '10pt' }}>RECIBI EN BUEN ESTADO</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>ALMACEN</h4><h6 style={{ fontSize: '10pt' }}>RECIBI EN BUEN ESTADO</h6></th>
              <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CLIENTE</h4><h6 style={{ fontSize: '10pt' }}>RECIBI ARTÍCULO</h6></th>
            </tr>
          </table>
        </div>
        <p><br></br></p>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
          <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' , backgroundColor:'rgb(199, 195, 195)'}}>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}><h4 style={{ fontWeight: 'bold', fontSize: '10pt' }}>APLICACIÓN CRÉDITO Y COBRANZA</h4></th>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}>
              <h4 style={{ fontWeight: 'bold' }}>
                <input type="text" className="form-control" style={{ fontWeight: 'bold', color: '#000' }} name='credito' id="credito" placeholder="Aplicación crédito y cobranza" autoComplete="off" onChange={this.handelChange} value={''} />
              </h4>
            </th>
          </tr>
          <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' , backgroundColor:'rgb(199, 195, 195)'}}>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}><h4 style={{ fontWeight: 'bold', fontSize: '10pt' }}>FOLIO NOTA DE CRÉDITO</h4></th>
            <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}>
              <h4 style={{ fontWeight: 'bold' }}>
                <input type="text" className="form-control" style={{ fontWeight: 'bold', color: '#000' }} name='foliocredito' id="foliocredito" placeholder="Folio nota de crédito" autoComplete="off" onChange={this.handelChange} value={''} />
              </h4>
            </th>
          </tr>
        </table>

        <div className="modal-footer">
          <span style={{fontSize:'10pt', fontWeight:'bold'}}>Recolección</span>
        </div>
      </div>
      <div style={{ pageBreakBefore: 'always', padding: "30px 50px" }}>
              <div className="p-5">
                  <div className="container m-1">
                      <div className="row mb-5 gap-3">
                          <div className="col-2 pl-0 pr-3">
                              <img width="250px" height="auto"
                                  src={config.navBar.icon}
                                  alt="Logo" />
                          </div>
                          <div className="col-10 d-flex justify-content-end align-items-center p-0">
                              <h2 style={{fontWeight: 'bold'}}>Formulario solicitud de reclamación devolución</h2>
                          </div>
                      </div>

                      <div>
                          <div className="d-flex justify-content-end mb-3">
                            <p style={{fontSize: 25}}>México, Pachuca a {day} de {month} del {year}</p>
                          </div>
                            
                            <div>
                                <p style={{ fontSize: 25 }}>México, Pachuca a {day} de {month} del {year}</p>
                                <div>
                                    <p style={{ fontSize: 25 }}>Por medio del presente, yo, {data[0].CardName} solicito a Pérez de la Mora S.A de C.V la devolución de la factura {}.</p>
                                </div>
                                <div className="mt-5">
                                    <p style={{ fontSize: 25 }}>El motivo para solicitar dicha devolución es: </p>
                                </div>
                            </div>
                            <div>
                                <p className="mt-3" style={{ fontSize: 25 }}> {data[0].U_FMB_Handel_Motivo ? data[0].U_FMB_Handel_Motivo:''} </p>
                            </div>
                            <div className="mt-5">
                                <p style={{ fontSize: 25 }}>Cordialmete.</p>
                            </div>
                            <div className="d-flex flex-row justify-content-center mt-5">
                                <div className="d-flex flex-row justify-content-center mt-5">
                                    <div>
                                    <p className='mb-0' style={{ fontSize: 25, textAlign: "center" }}>{data ? data[0].CardName : ''}</p>
                                        <p className="border-top border-12 border-black" style={{ fontSize: 25 }}>Nombre y Firma del solicitante.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" p-5" style={{ marginTop: '4rem' }}>
                    <div className="container m-1">
                        <div className="row mb-5 gap-3">
                            <div className="col-2 pl-0 pr-3">
                                <img width="250px" height="auto"
                                    src={config.navBar.icon}
                                    alt="Logo" />
                            </div>
                            <div className="col-10 d-flex justify-content-end align-items-center p-0">
                                <h2 style={{ fontWeight: 'bold' }}>Formulario solicitud de reclamación cancelación</h2>
                            </div>
                        </div>

                        <div>
                          <div>
                              <p style={{ fontSize: 25 }}>México, Pachuca a {day} de {month} del {year}</p>
                              <div>
                                  <p style={{ fontSize: 25 }}>Por medio del presente, yo, {data ? data[0].CardName : ''} solicito a Pérez de la Mora S.A de C.V la cancelación de la factura {}.</p>
                              </div>
                              <div className="mt-5">
                                  <p style={{ fontSize: 25 }}>El motivo para solicitar dicha cancelación es: </p>
                              </div>
                          </div>
                            <div>
                                <p className="mt-3" style={{ fontSize: 25 }}> {data[0].U_FMB_Handel_Motivo ? data[0].U_FMB_Handel_Motivo :''} </p>
                            </div>
                            <div className="mt-5">
                                <p style={{ fontSize: 25 }}>Cordialmete.</p>
                            </div>
                            <div className="d-flex flex-row justify-content-center align-items-center mt-5" >
                                <div>
                                    <p className='mb-0' style={{ fontSize: 25, textAlign: "center" }}>{data ? data[0].CardName : ''}</p>
                                    <p className="border-top border-12 border-black" style={{ fontSize: 25 }}>Nombre y Firma del solicitante.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     </div>
    );
  }
}

export default ReportDevolutionModal;