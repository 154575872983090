import React, {Component} from 'react';
import {config, SERVICE_API,DISPATCH_ID} from "../libs/utils/Const";
import CurrencyFormat from 'react-currency-format';
import {ApiClient} from "../libs/apiClient/ApiClient";
import { ProgressBar } from "./index";
import $ from 'jquery';
import {connect} from 'react-redux';
let apiClient = ApiClient.getInstance();
class OrderDetailsModal extends Component {
    async addToShopingCart(){
        //Los PROPS los consigues de abajo
        const {order,notificationReducer: {showAlert},configReducer,enableSpinner} = this.props;
        //Lista de los productos del documento
        const orders = []; 

        for(var indice = 0; indice < order.body.length; indice++) {
            const arreglo = order.body[indice];
            let esDuplicado = false;
            for(var i = 0; i < orders.length; i++) {

                if (orders[i].ItemCode === arreglo.ItemCode) {
                    orders[i].Quantity += parseInt(arreglo.Quantity);
                    esDuplicado = true;
                    break;
                }
            }
            if (!esDuplicado) {
                orders.push(arreglo);
            }
        }

        let items = [];
        //Evadir el Articulo de envio
        orders.map(item => {
            if(item.ItemCode !== "MANIOBRAS" && item.ItemCode !== "MANIOBRAS II"){
                items.push({ItemCode: item.ItemCode, quantity: parseInt(item.Quantity) });
            }     
        });
        //Lista sin el Envio o Traslado
        //Comineza a pasarse al carro
        enableSpinner(true);
        //agregar al carro directo con sql
        let responsesd = await apiClient.updateShoppingCartLocal(items);
        if(responsesd.status === 1){
            $('#orderDetailsModal').modal('hide');
            showAlert({type: 'success', message: ' Agregado a carrito correctamente ', timeOut: 0});
            configReducer.history.goShoppingCart();
        }
        enableSpinner(false);
    }
    render() {
        const {order,guia, invoices, configReducer:{currency}} = this.props;
        return (
            <div className="modal fade" id="orderDetailsModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{border: "none", textAlign: 'center'}}>
                <div className="modal-dialog modal-xl" role="document" style={{margin: '1.75rem auto', maxWidth:'90%'}}>
                    <div className="modal-content" style={{border:"1px solid #00000073"}}>
                        <div className="modal-header" style={{background: config.navBar.iconColor}}>
                            <h5 className="modal-title" style={{color: config.navBar.textColor2}}>Productos de la orden</h5>
                            <button type="button" style={{color: config.navBar.textColor2}} className="close"data-dismiss="modal"aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3" style={{maxHeight:'700px', overflowY:'scroll'}}>
                            <ProgressBar guiaStatus = {guia} currentDocInfo = {Array.isArray(order.header) ? order.header[0] : order.header} type = {'ORDR'} invoices={invoices} />
                            <h3 className="text-center font-weight-bold">Detalle del documento</h3>
                            <div className="text-left card" style={{ border: 'none'}}>
                                <div className="row" style={{ padding: 0, textAlign: 'center' }}>
                                    <div className="col-sm-12" style={{paddingLeft:0, paddingRight:0}}>
                                        <div className="container-fluid p-0">
                                            <div className="row font-weight-bold">
                                                <div className="col-md-12 table-responsive" style={{overflowY:'scroll'}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr style={{backgroundColor: config.navBar.iconColor, color: "white"}}>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap'}} >Clave</th>
                                                                <th className="text-center" scope="col" style={{ whiteSpace: 'normal', width: '40%' }}>Nombre del Artículo</th>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap'}} >Cantidad</th>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap'}} >Cantidad <br/>entregada</th>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap'}} >Cantidad <br/> faltante</th>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap'}} >Precio <br/>sin IVA</th>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap'}} >% <br/> descuento</th>
                                                                <th className="text-center" style={{ whiteSpace: 'nowrap' }} scope="col-lg-auto">Precio con<br/> descuento</th>
                                                                <th className="text-center" style={{ whiteSpace: 'nowrap' }} scope="col-lg-auto">Subtotal <br/>de línea</th>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap'}} >Linea <br/> total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {order.body.map(item => (
                                                                <tr key={item.ItemCode}>
                                                                    <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}} > {item.ItemCode} </td>
                                                                    <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '40%', padding: '0.3rem', fontSize: '15px' }}>{item.ItemName}</td>
                                                                    <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}} > {parseInt(item.Quantity)} </td> 
                                                                    <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}} >{item.DeliveredQty >= 0 ? parseInt(item.DeliveredQty) : "--"}</td>
                                                                    <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}} >{item.OpenQty >= 0 ? parseInt(item.OpenQty) : "--"}</td>
                                                                    <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}}>
                                                                        <CurrencyFormat 
                                                                            value={item.Price}
                                                                            displayType={'text'} 
                                                                            thousandSeparator={true} 
                                                                            fixedDecimalScale={true} 
                                                                            decimalScale={2} 
                                                                            prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod+' '}
                                                                            suffix={' ' + item.Currency}>
                                                                        </CurrencyFormat>
                                                                    </td>
                                                                    <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}} >{parseInt(item.DiscPrcnt)}%</td>
                                                                    <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}} >
                                                                        <CurrencyFormat 
                                                                            value={item.Price - (item.Price * (item.DiscPrcnt /100))} 
                                                                            displayType={'text'} 
                                                                            thousandSeparator={true} 
                                                                            fixedDecimalScale={true} 
                                                                            decimalScale={2} 
                                                                            prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod+' '}
                                                                            suffix = {' ' + item.Currency}
                                                                            >
                                                                        </CurrencyFormat>   
                                                                        </td>
                                                                        <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}}>
                                                                            <CurrencyFormat 
                                                                                value={(item.Price - (item.Price * (item.DiscPrcnt / 100))) * item.Quantity}
                                                                                displayType={'text'} 
                                                                                thousandSeparator={true} 
                                                                                fixedDecimalScale={true} 
                                                                                decimalScale={2} 
                                                                                prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod + ' '}
                                                                                suffix={' ' + item.Currency}>
                                                                            </CurrencyFormat>   
                                                                        </td>
                                                                        <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}}>
                                                                        <CurrencyFormat 
                                                                            value={item.LineTotal * 1.16}
                                                                            displayType={'text'} 
                                                                            thousandSeparator={true} 
                                                                            fixedDecimalScale={true} 
                                                                            decimalScale={2} 
                                                                            prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod+' '}
                                                                            suffix = {' ' + item.Currency}>
                                                                        </CurrencyFormat>   
                                                                    </td>                       
                                                                </tr>   
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3 className="text-center font-weight-bold mt-4">Totales</h3>
                            <div className="text-left card" style={{ border: 'none' }}>
                                <div className="row" style={{ padding: 10, textAlign: 'center' }}>
                                    <div className="col-sm-12">
                                        <div className="container p-0">
                                            <div className="row font-weight-bold">
                                                <div className="col-md-12 table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                                                                <th className="text-center" scope="col-lg-auto">Subtotal</th>
                                                                <th className="text-center" scope="col-lg-auto">IVA 16%</th>
                                                                <th className="text-center" scope="col-lg-auto">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-center">
                                                                    <CurrencyFormat
                                                                        value={order.body.reduce((acc, item) => acc + (item.Price - (item.Price * (item.DiscPrcnt / 100))) * item.Quantity, 0).toFixed(2)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        prefix={currency.DocCurrCod + ' '}
                                                                        suffix={' ' + config.general.currency}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <CurrencyFormat
                                                                        value={order.body.reduce((acc, item) => acc + (item.Price - (item.Price * (item.DiscPrcnt / 100))) * item.Quantity * 0.16, 0).toFixed(2)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        prefix={currency.DocCurrCod + ' '}
                                                                        suffix={' ' + config.general.currency}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <CurrencyFormat
                                                                        value={parseFloat(order.header.DocTotal).toFixed(2)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        prefix={currency.DocCurrCod + ' '}
                                                                        suffix={' ' + config.general.currency}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <div style={{
                                marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                flexDirection: 'row'
                            }}>
                                <span className="font-weight-bold">Comentario:</span>
                                <span style={{ marginLeft: '10px' }}>{order?.header?.Comments || 'Sin comentario disponible'}</span>
                            </div>

                            {/* {order.body.map(item => {
                                return <div key={item.ItemCode} className="row">
                                    <div className="col-md-4">
                                        <img className="img-fluid"
                                            style={{
                                                backgroundColor: 'white',
                                                maxHeight: 130
                                            }}
                                            src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png')}
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="container p-0">
                                            <div className="row text-left">
                                                <div className="col-md-12"  style={{borderBottom: '1px solid rgb(124, 124, 125)'}}>
                                                    {item.Dscription}
                                                </div>
                                            </div>
                                            <div className="row font-weight-bold ">
                                                <div className="col-md-3">
                                                    Clave
                                                </div>
                                                <div className="col-md-3">
                                                    Cantidad
                                                </div>
                                                <div className="col-md-3">
                                                    Precio
                                                </div>
                                                <div className="col-md-3">
                                                    Precio Total
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    {item.ItemCode}
                                                </div>
                                                <div className="col-md-3">
                                                    {item.Quantity}
                                                </div>
                                                <div className="col-md-3">
                                                    <CurrencyFormat 
                                                        value={item.Price} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={`${config.general.currency} `}>
                                                    </CurrencyFormat>                                                    
                                                </div>
                                                <div className="col-md-3">
                                                    <CurrencyFormat 
                                                        value={item.LineTotal}
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={`${config.general.currency} `}>
                                                    </CurrencyFormat>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })} */}
                        </div>
                        <div className="modal-footer">
                            {/* <div className="btn text-white" data-dismiss="modal" style={{ background: config.navBar.iconColor }}>
                                <CurrencyFormat
                                    value={parseFloat(order.header.DocTotal).toFixed(2)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={currency.DocCurrCod + ' '}
                                    suffix={' ' + config.general.currency}
                                >
                                </CurrencyFormat>
                            </div> */}
                            <button type="button" className="btn text-white"  style={{background: "#86C03F"}} onClick={()=> this.addToShopingCart()}>
                                <img src={config.icons.shoppingCartW} alt="Agregar al carrito" style={{ width: '15px', height: '15px', marginRight: '5px' }}/>
                                &nbsp; Agregar al carrito
                            </button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setItemDetailsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value}),
        setItemsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEMS, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrderDetailsModal);