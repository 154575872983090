import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import PostBanner from './views/banners/postbanner';
import { connect } from 'react-redux';
import './App.css';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AWN from "awesome-notifications";
import { DISPATCH_ID, config, ROLES } from './libs/utils/Const';
import { SearchItemsActions, Footer, NavBar } from './components';
import CacheBuster from './CacheBuster';
import AnalisisViews from './views/analisis/AnalisisViews';
import TimesView from './views/time/TimesView';
import DetallesView from './views/details/DetallesView'
import GeneralView from './views/general/GeneralView';

const DashboardView = lazy(() => import('./views/dashboard/DashboardView'));
const DashboardView2 = lazy(() => import('./views/dashboard/DashboardView2'));

const LoginView = lazy(() => import('./views/login/LoginView'));
const RedCompensasZoneView = lazy(() => import('./views/redZone/RedCompensasZoneView'));
const ResetPointsView = lazy(() => import('./views/resetPoints/ResetPointsView'));

const ShoppingCartView = lazy(() => import('./views/shoppingCart/ShoppingCartView'));
const BackOrderView = lazy(() => import('./views/backOrder/BackOrderView'));

const ItemsView = lazy(() => import('./views/items/ItemsView'));
const ItemsDetailsView = lazy(() => import('./views/items/ItemsDetailsView'));

const ProfileView = lazy(() => import('./views/profile/ProfileView'));
const OrdersView = lazy(() => import('./views/orders/OrdersView'));
const billingViwes = lazy(() => import('./views/bill/billingViwes'));
const reportsView = lazy(() => import('./views/reports/reportsView'));
const QuotaionViews = lazy(() => import('./views/quotation/quotationViews'));
const deliveryViews = lazy(() => import('./views/delivery/deliveryViews'));
const savedViews = lazy(() => import('./views/saved/savedViews'));
const preliminaryViews = lazy(() => import('./views/preliminary/preliminaryViews'));
const collectinViews = lazy(() => import('./views/colletion/collectionViews'));
const overdueViews = lazy(() => import('./views/overdue/overdueViews'));

const AboutUsView = lazy(() => import('./views/aboutUs/AboutUsView'));
const ContactUsView = lazy(() => import('./views/contactUs/ContactUsView'));
// const MercadoLibreView = lazy(() => import('./views/mercadoLibre/MercadoLibreView'));

const Reclamo = lazy(() => import('./views/claim/Reclamo'));
const Politicas= lazy(() => import('./views/Questions/Politicas'));
const Preguntas= lazy(() => import('./views/Questions/Preguntas'));

// const Transbank= lazy(() => import('./views/webpay/transbank'));
// const MercadoPago= lazy(() => import('./views/webpay/mercadoPago'));
const  SelectAddressView= lazy(() => import('./views/selectAddress/SelectAddressView'));
const  EditAddressView= lazy(() => import('./views/selectAddress/EditAddressView'));
const  ValidateOrderView= lazy(() => import('./views/validateOrder/ValidateOrderView'));
const  CreteOrderView= lazy(() => import('./views/createOrder/CreteOrderView'));
const  BannerAdminIndex= lazy(() => import('./views/banners/index'));
const  Selector= lazy(() => import('./views/selector/Selector'));
const MyProfile = lazy(() => import('./views/MyProfile/MyProfile'));
const SubirArchivo = lazy(() => import('./views/SubirArchivo/SubirArchivo'));

//Autorizaciones
const AutorizacionesView = lazy(() => import('./views/autorizaciones/AutorizacionesView'));

//Middlware
const MiddlewareView = lazy(() => import('./views/middleware/MiddlewareView'));

const ItemsPolarView = lazy(() => import('./views/items/ItemsPolarView'));
const ItemsBlancaView = lazy(() => import('./views/items/ItemsBlancaView'));
const ItemsRoutletView = lazy(() => import('./views/items/ItemsRoutletView'));

const TermsView = lazy(() => import('./views/terms/Terms'));
const PoliticsView = lazy(() => import('./views/politics/PoliticsView'));
const RedAliadoView= lazy(() => import('./views/redAliado/RedAliado'));
const PrivacyView= lazy(() => import('./views/privacy/Privacy'));

const AddAddressView = lazy(() => import('./views/addAddress/AddAddressView'));
const AccountDataView = lazy(() => import('./views/aboutUs/AccountDataView'));

const JobView = lazy(() => import('./views/job/JobView'));

const SucursalView = lazy(() => import('./views/sucursales/SucursalView'));

const AboutRedCompensasZoneView = lazy(() => import('./views/redZone/AboutRedCompensasZoneView'));

const SafeShoppingView = lazy(() => import('./views/safeShopping/SafeShoppingView'));
const PaymentMethodView = lazy(() => import('./views/safeShopping/PaymentMethodView'));

const DevolutionView = lazy(() => import('./views/devolution/DevolutionView'));

const adminNewsBlogsView= lazy(() => import('./views/newsBlog/adminNewsBlogsView'));
const NewsBlogView= lazy(() => import('./views/newsBlog/NewsBlogView'));

const SpecialPricesView = lazy(() => import('./views/specialPrices/SpecialPricesView'));

const PromocionalesView = lazy(() => import('./views/promocionales/PromocionalesView'));

const Catalogs = lazy(() => import('./views/catalogos/Catalogo'));

const AnalyticsView = lazy(() => import('./views/analytics/AnalyticsView'));
const PlantillaView = lazy(() => import('./views/plantilla/PlantillaView'));

const RegisterItems = lazy(() => import('./views/RegisterItems/RegisterItems'));
const ItemsRefacciones = lazy(() => import('./views/items/ItemsRefacciones'));
const ItemsFood = lazy(() => import('./views/items/ItemsFood'));
const ItemsPaking = lazy(() => import('./views/items/ItemsPaking'));
const ItemsFerreteria = lazy(() => import('./views/items/ItemsFerreteria'));
const PreguntasEnvio= lazy(() => import('./views/Questions/PreguntasEnvio'));

const ClientAllItems = lazy(() => import('./views/boletin/boletinView'));
const AboutUsView1 = lazy(() => import('./views/boletin/AboutUsView'));
const QuoteView = lazy(() => import('./views/quote/QuoteView'));
const AppsView = lazy(() => import('./views/apps/AppsView')); 
const SubirArchivoPadres = lazy(() => import('./views/SubirArchivoPadres.js/SubirArchivoPadres'));

// const Webpay = require('./views/controllers/WebpayNormalController');
class App extends Component {

    UNSAFE_componentWillMount = async () => {
        
        const { setBusiness, setToken, setUser, setRememberUser, setWhsGeneral, setWhsGeneralName,  setRole, setLogOutReference } = this.props;

        //users
        let token = localStorage.getItem(config.general.localStorageNamed + 'Token');
        let role = localStorage.getItem(config.general.localStorageNamed + 'Role');
        let user = localStorage.getItem(config.general.localStorageNamed + 'CurrentUser');
        let rememberUser = localStorage.getItem(config.general.localStorageNamed + 'RememberUser');

        user = JSON.parse(user) || {};

        setRole(role || ROLES.PUBLIC);
        setBusiness(config.general.business);
        setToken(token);
        setUser(user);
        setRememberUser(rememberUser);
        //Comentar para pruebas para que NO se cierre la sesión
        // setLogOutReference(this.logOut);
    }

    logOut = async () => {
        const { configReducer, setBusiness, setToken, setUser, setRememberUser, setRole, shoppingCartReducer: { getShoppingCart } } = this.props;
        localStorage.removeItem(config.general.localStorageNamed + 'Role');
        localStorage.removeItem(config.general.localStorageNamed + 'Token');
        localStorage.removeItem(config.general.localStorageNamed + 'CurrentUser');
        localStorage.removeItem(config.general.localStorageNamed + 'PartnerUser');
        localStorage.removeItem(config.general.localStorageNamed + 'RememberUser');
        localStorage.removeItem(config.general.localStorageNamed + 'vendor');
        localStorage.clear();
        configReducer.history.goHome();

        setRole(ROLES.PUBLIC);
        setBusiness(config.general.business);
        setToken(undefined);
        setUser({});
        setRememberUser(false);

        setTimeout(async () => {
            await getShoppingCart(true);
        }, 300);

    };

    componentDidMount() {
        const { addNotificationReference } = this.props;
        addNotificationReference(this.createNotification);
    }

    createNotification = action => {
        let notifierOptions = {
            position: 'top-right',
            durations: {
                global: action.timeOut ? action.timeOut + 1000 : 3000
            },
            labels: {
                info: "Información",
                success: "Exitoso",
                warning: "Advertencia",
                alert: "ALERTA",
            }
        }
        let notifier = new AWN(notifierOptions);
        switch (action.type) {
            case 'info':
                notifier.info(
                    action.message ? action.message : ''
                );
                // NotificationManager.info(
                //     action.message ? action.message : '',
                //     action.title ? action.title : '',
                //     action.timeOut ? action.timeOut : 3000,
                //     action.callback ? action.callback : null
                // );
                break;
            case 'success':
                notifier.success(
                    action.message ? action.message : ''
                );
                // NotificationManager.success(
                //     action.message ? action.message : '',
                //     action.title ? action.title : '',
                //     action.timeOut ? action.timeOut : 3000,
                //     action.callback ? action.callback : null
                // );
                break;
            case 'warning':
                notifier.warning(
                    action.message ? action.message : ''
                );
                // NotificationManager.warning(
                //     action.message ? action.message : '',
                //     action.title ? action.title : '',
                //     action.timeOut ? action.timeOut : 3000,
                //     action.callback ? action.callback : null
                // );
                break;
            case 'error':
                notifier.alert(
                    action.message ? action.message : ''
                );
                // NotificationManager.error(
                //     action.message ? action.message : '',
                //     action.title ? action.title : '',
                //     action.timeOut ? action.timeOut : 3000,
                //     action.callback ? action.callback : null
                // );
                break;
            default:
                return;
        }
    };

    render() {
        const { spinner } = this.props.configReducer;
        const { addNotificationReference, /*itemsReducer: { location } descomentar para permalinks */ configReducer: { viewNavBar} } = this.props;

        addNotificationReference(this.createNotification);
        return (
            <CacheBuster>
                {
                    ({loading, isLatestVersion, refreshCacheAndReload }) => {
                        // Descomentar en b2c para permalinks
                        // if (!location === 'itemsDetailsRoute'){
                            if (loading) return null;
                            if (!loading && !isLatestVersion) {
                                refreshCacheAndReload();
                            }
                        // }
                        return(
                        <div className="scroll-tip" style={{width: "100%"}}>
                            {spinner && (
                                <div className="loader" style={{ backgroundColor: config.general.loaderBackground }}>
                                    <div className="text-center">
                                        <div class="Custom-spinner">
                                            <i class="fa fa-wrench fa-4x" aria-hidden="true"></i>
                                        </div>
                                        {/* <div className={"spinner-border " + config.general.loaderColor} style={{ width: 45, height: 45 }} role="status">
                                            <span className="sr-only">{config.general.loaderImage}</span>
                                        </div> */}
                                    </div>
                                </div>
                            )}
                            <NotificationContainer />
                            <SearchItemsActions />
                            {(viewNavBar) && (
                                <NavBar/>
                            )}
                            <Suspense fallback={<div style={{}}>Loading...</div>}>
                            <Switch>
                                <Route exact path="/" component={DashboardView} />
                                <Route exact path="/2" component={DashboardView2} />

                                <Route exact path="/login" component={LoginView} />
                                <Route exact path="/redZone" component={RedCompensasZoneView} />
                                <Route exact path="/resetPoints" component={ResetPointsView} />
                                <Route exact path="/items" component={ItemsView} />

                                <Route exact path="/itemsDetails/:params" component={ItemsDetailsView} />
                                {/* <Route exact path="/itemsDetails/:params" component={ItemsDetailsView} /> */}

                                <Route exact path="/terms" component={TermsView} />
                                <Route exact path="/redAliado" component={RedAliadoView} />
                                <Route exact path="/privacy" component={PrivacyView} />

                                <Route exact path="/RegisterItems" component={RegisterItems} />
                                <Route exact path="/itemsMulos" component={ItemsPolarView} />
                                <Route exact path="/itemsBlanca" component={ItemsBlancaView} />
                                <Route exact path="/itemsRoutlet" component={ItemsRoutletView} />
                                <Route exact path="/shoppingCart" component={ShoppingCartView} />
                                <Route exact path="/backOrder" component={BackOrderView} />
                                <Route exact path="/profile" component={ProfileView} />
                                <Route exact path="/orders" component={OrdersView} />
                                <Route exact path="/bill" component={billingViwes} />
                                <Route exact path="/aboutUs" component={AboutUsView} />
                                <Route exact path="/contactUs" component={ContactUsView} />
                                {/* <Route exact path="/mercadoLibre" component={MercadoLibreView} /> */}
                                <Route exact path="/selectAddress" component={SelectAddressView} />
                                <Route exact path="/validateOrder" component={ValidateOrderView} />
                                <Route exact path="/createOrder" component={CreteOrderView} />
                                <Route exact path="/adminBanners" component={BannerAdminIndex} />
                                <Route exact path="/postbanner/:id/:slug" component={PostBanner} />
                                <Route exact path="/selector" component={Selector} />
                                <Route exact path="/reports" component={reportsView} />
                                <Route exact path="/quotations" component={QuotaionViews} />
                                <Route exact path="/delivery" component={deliveryViews} />
                                <Route exact path="/saveds" component={savedViews} />
                                <Route exact path="/prelimnarys" component={preliminaryViews} />
                                <Route exact path="/collections" component={collectinViews} />
                                <Route exact path="/overs" component={overdueViews} />
                                <Route exact path="/myprofile" component={MyProfile} />
                                {config.modules.Autorizaciones &&
                                    <Route exact path="/autorizaciones" component={AutorizacionesView} />
                                }
                                <Route exact path='/canalModerno' component={MiddlewareView}/>
                                <Route exact path="/subirArchivo" component={SubirArchivo} />
                                {/* <Route exact path="/tranbank/init" component={Webpay.init}/> */}
                                <Route exact path="/claim" component={Reclamo}/>
                                <Route exact path="/politics" component={Politicas}/>
                                <Route exact path="/questions" component={Preguntas}/>
                                {/* <Route exact path="/transbank/:action/:name/:token" component={Transbank}/>
                                <Route exact path="/mercadoPago" component={MercadoPago}/>                     */}
                                <Route exact path="/itemsBlanca" component={ItemsBlancaView} />
                                <Route exact path="/itemsRoutlet" component={ItemsRoutletView} />
                                <Route exact path="/terms" component={TermsView} />
                                <Route exact path="/addAddress" component={AddAddressView}/>
                                <Route exact path="/accountData" component={AccountDataView}/>
                                <Route exact path="/sellingPolices" component={PoliticsView}/>
                                <Route exact path="/editAddress" component={EditAddressView}/>
                                <Route exact path="/job" component={JobView}/>
                                <Route exact path="/redAliado" component={RedAliadoView} />
                                <Route exact path="/sucursales" component={SucursalView} />
                                <Route exact path="/aboutRedZone" component={AboutRedCompensasZoneView}/>
                                <Route exact path="/safeShoppig" component={SafeShoppingView}/>
                                <Route exact path="/paymentMethodView" component={PaymentMethodView}/>
                                <Route exact path="/devolutionView" component={DevolutionView}/>
                    
                                <Route exact path="/admiNewsBlog" component={adminNewsBlogsView} />
                                <Route exact path="/newsBlog" component={NewsBlogView} />

                                <Route exact path="/specialPrices" component={SpecialPricesView} />    

                                <Route exact path="/promocionales" component={PromocionalesView} />

                                <Route exact path="/catalogo" component={Catalogs} />
                                <Route exact path="/analitycs" component={AnalyticsView} />

                                <Route exact path="/itemsRefacciones" component={ItemsRefacciones} />
                                <Route exact path="/itemsFood" component={ItemsFood} />
                                <Route exact path="/itemsPaking" component={ItemsPaking} />
                                <Route exact path="/itemsFerreteria" component={ItemsFerreteria} />
                                <Route exact path="/questionshipping" component={PreguntasEnvio}/>
                                <Route exact path="/boletin" component={AboutUsView1} />

                                <Route exact path="/platilla" component={PlantillaView} />
                                <Route exact path="/quote" component={QuoteView} /> 
                                <Route exact path="/apps" component={AppsView}/>
                                <Route exact path="/SubirArchivoPadres" component={SubirArchivoPadres} />
                                <Route exact path="/Analisis" component={AnalisisViews}/>
                                <Route exact path="/Times" component={TimesView}/>
                                <Route exact path="/Details" componet={DetallesView}/>
                                <Route exact path="/General" component={GeneralView}/>

                                {/* Siempre al final */}
                                <Route exact path="*" component={DashboardView} />
                 
                            </Switch>
                            <Footer />
                            </Suspense>
                        </div>
                        );
                    }
                }
            </CacheBuster>
        );
    }
}

const mapStateToProps = store => {
    return { 
        configReducer: store.ConfigReducer, 
        shoppingCartReducer: store.ShoppingCartReducer};
        // itemsReducer: store.ItemsReducer };  Descomentar en b2c para permalinks
};

const mapDispatchToProps = dispatch => {
    return {
        setRole: value => dispatch({ type: DISPATCH_ID.SESSION_SET_ROLE, value }),
        addNotificationReference: value => dispatch({ type: DISPATCH_ID.NOTIFICATION_SET_ALERT_REFERENCE, value }),
        setBusiness: value => dispatch({ type: DISPATCH_ID.SESSION_SET_BUSINESS_ID, value }),
        setToken: value => dispatch({ type: DISPATCH_ID.SESSION_SET_TOKEN, value }),
        setUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_USER, value }),
        setRememberUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_REMEMBER_USER, value }),
        setLogOutReference: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_LOG_OUT_REFERENCE, value }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
