import React, { Component } from 'react';
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID, ROLES } from '../libs/utils/Const';
import { connect } from "react-redux";
import { ItemDetailsModal, Pagination, SideBarItems, ItemCotizacionModal, StockModal } from "./index";
import CurrencyFormat from 'react-currency-format';
import "./ItemSlider.css";
import './card.css';
import { animateScroll as scroll, scroller } from 'react-scroll';
import moment from 'moment';
import $ from "jquery";
import ReactHover, { Trigger, Hover } from "react-hover";

class ItemsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
            vendor: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'vendor')),
            currentPage: null,
            card: true,
            list: false,
            itemSelect: '',
            // itemStocks:''
            renderStock: false,
            renderInfoDiscount: false,
        };
        //this.addCartHover = React.createRef();
        this.mouseOverAddCart = this.mouseOverAddCart.bind(this);
        this.mouseOutAddCart = this.mouseOutAddCart.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.iconMouse = [];
        this.iconMouseOver = [];
    }

    //Funcion cambiar almacen de item
    changeLocalWhs = async (index, item, event) => {
        const { changeQuantity, shoppingCartReducer: { items }, notificationReducer: { showAlert } } = this.props;
        let newWsh = event.nativeEvent.target.value.split(",");

        item.WhsCode = newWsh[0];
        item.OnHandPrincipal = parseInt(newWsh[1]);
        changeQuantity(index, item, item.quantity, true); // add
        //this.validateDisparador();
    };

    // updateShoppingCart = (index, item, event) =>{
    //     const { changeQuantity, notificationReducer: { showAlert }} = this.props;
    //     if( parseInt(item.quantity) > 0){
    //         showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });
    //         changeQuantity(index, item, item.quantity,true); // add
    //     }else{
    //         showAlert({ type: 'warning', message: "Este almacen no tiene este producto disponible. Seleccione otro almacén.", timeOut: 2500 });
    //     }
    // }

    componentDidMount = () => {
        this.scrollToBottom();
    }

    scrollToBottom() {
        scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: 'easeOutQuart',
            isDynamic: true
        })
    }

    changeQuantity = async (index, item, event) => {
        const { changeQuantity, notificationReducer: { showAlert }, itemsReducer: { items: itemsSearch } } = this.props; // Revisar variable items1 con item de changLocalQuantity
        let newQuantity;
        let onHand = item.OnHand;
        let OnHandPrincipal = item.OnHandPrincipal;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                newQuantity = Number(event.nativeEvent.target.value) || 1;
                itemFilter.quantity = newQuantity;
            }
        });
        if (newQuantity) {
            if (OnHandPrincipal >= Number(item.quantity) && onHand >= newQuantity) {
                showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });

            } else if (OnHandPrincipal < Number(item.quantity) && onHand >= newQuantity) {
                showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });
                showAlert({ type: 'warning', message: "Sugerencia cambiar a otro almacen.", timeOut: 4000 });
            } else {
                showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });
                showAlert({ type: 'warning', message: "Articulo sujeto a disponibilidad de proveedores.", timeOut: 4000 });
            }
            changeQuantity(index, item, newQuantity, true); // add
        }
        //cambios almacenes 117
        // if(onHand < 0 ){
        //      showAlert({ type: 'warning', message: "Este producto no tiene existencias en ningún almacén", timeOut: 2000 });
        //      changeQuantity(index,item, newQuantity, false); //delete
        // }else{
        //     if (OnHandPrincipal === 0 || OnHandPrincipal == 0 || OnHandPrincipal == undefined || OnHandPrincipal == ' '){//si onHandPrincipal es igual a 0 entra y se lleva lo que se ingreso 
        //         OnHandPrincipal = newQuantity || 1 ;//revisar posible cambio
        //         //addShoppingCart({ item, quantity: (newQuantity)})
        //         await changeQuantity(index,item, newQuantity, true); // add
        //         showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });
        //     }else{//sino es que onHandPrincipal si tiene stock
        //         if(!newQuantity){
        //             //deleteShoppingCart({ item, deleteAll: false });
        //             await changeQuantity(index,item, newQuantity, false); //delete
        //         }
        //         if(Number(newQuantity) > Number(onHand)) {//valida si lo ingresado es mayor a todos los stock disponibles

        //             if (Number(OnHandPrincipal) !== 0) { 
        //                 showAlert({ type: 'warning', message: "Se excede el número de ARTÍCULOS disponibles de este producto", timeOut: 2500 });
        //             }
        //             newQuantity = OnHandPrincipal;
        //         }
        // await changeQuantity(index,item, newQuantity, true); // add
        //         showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });
        //     }
        // } 
    };

    changLocalQuantity = (index, item, event) => {
        const { itemsReducer: { items: itemsSearch } } = this.props;
        let newQuantity = event.nativeEvent.target.value;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                // if (newQuantity >= 1) {
                    itemFilter.quantity = newQuantity;
                // }
            }
        });
        this.applyFilters(itemsSearch);
    }

    setSepecialPrice = (itemParameter) => {
        let item = itemParameter;
        const { itemsReducer: { specialPrice } } = this.props;

        let CardCode = specialPrice.CardCode;
        let PriceList = specialPrice.ListNum && specialPrice.ListNum !== '' ? specialPrice.ListNum : specialPrice.priceList;

        // Special Prices Validation
        //########################################################################################################################
        let priority2 = "*" + PriceList;
        let formattedDocDate = moment().format('YYYY/MM/DD');

        let flagSN = true; // Socio de negocio
        let flagPriceList = true; // Lista de precios
        let flagPricePriority = true;
        let maxQuantity = 0; // Cantidad maxima a alegir
        let priceItem = -1;
        let discount = -1;
        let priceBeforeDisc = -1;

        item.QuantitySpecial = item.quantity;
        item.PriceTest = -1;

        // Socio de negocios
        if (specialPrice.specialPrices.length > 0) {
            specialPrice.specialPrices.map((itemPrice) => {
                if (item.ItemCode == itemPrice.ItemCode && itemPrice.CardCode == CardCode) {
                    if (itemPrice.children.length > 0) {
                        itemPrice.children.map((child) => {
                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);

                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);

                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if (docDate >= inicialCantidad && docDate <= finalCantidad) {
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if (child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if (maxQuantity != 0) {
                                        flagSN = false;
                                    }
                                    if (flagSN) {
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagSN = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagSN = false;
                                }
                            } else {
                                // General
                                if (flagSN) {
                                    item.DiscountPercentSpecial = itemPrice.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice.Price);
                                    discount = parseFloat(itemPrice.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            }
                        })
                    } else {
                        //General
                        item.DiscountPercentSpecial = itemPrice.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice.Price);
                        discount = parseFloat(itemPrice.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        }
        // Lista de inventario
        if (specialPrice.specialPricesList.length > 0 && flagPricePriority) {
            specialPrice.specialPricesList.map((itemPrice2) => {
                if (itemPrice2.ItemCode == item.ItemCode && itemPrice2.CardCode == priority2) {
                    if (itemPrice2.children.length > 0) {
                        itemPrice2.children.map((child) => {

                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);

                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);
                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice2.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice2.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if (docDate >= inicialCantidad && docDate <= finalCantidad) {
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if (child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if (maxQuantity != 0) {
                                        flagPriceList = false;
                                    }
                                    if (flagPriceList) {
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagPriceList = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagPriceList = false;
                                }
                            } else {
                                // General
                                if (flagPriceList) {
                                    item.DiscountPercentSpecial = itemPrice2.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice2.Price);
                                    discount = parseFloat(itemPrice2.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            }
                        })
                    } else {
                        // General
                        item.DiscountPercentSpecial = itemPrice2.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice2.Price);
                        discount = parseFloat(itemPrice2.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        }
        //No tiene precio especial
        if (flagPricePriority) {
            item.DiscountPercentSpecial = 0;
        }

        // Precios por descuentos especiales
        if (priceBeforeDisc != -1) {
            item.Price = Number(priceItem);
            item.PriceTest = Number(priceItem);
            item.PriceBeforeDiscount = Number(priceBeforeDisc);
        }

        let price = item.PriceTest !== -1 ? Number(item.Price).toFixed(2) : -1;
        return price;
    }

    // changLocalQuantity = (index, item, event)=>{
    //     const {shoppingCartReducer: {items},itemsReducer: {items: itemsSearch}} = this.props;
    //     let newQuantity =  event.nativeEvent.target.value;

    //     // color success
    //     // document.getElementById(item.ItemCode).style.backgroundColor = "#28a745"; 
    //     // document.getElementById(item.ItemCode).style.borderColor = "#28a745"; 

    //     if (newQuantity % 1 == 0) {
    //         itemsSearch.map( itemFilter => {
    //             if (itemFilter.ItemCode === item.ItemCode) {
    //                 if(newQuantity <= parseInt(item.MaximoStock)){
    //                     itemFilter.quantity = newQuantity;
    //                 }
    //                 let specialPrice = this.setSepecialPrice(itemFilter);
    //                 if(specialPrice !== -1){
    //                     itemFilter.Price = specialPrice;
    //                 }
    //             }
    //         });
    //         this.applyFilters(itemsSearch);
    //     } else {
    //         return;
    //     } 
    // }

    applyFilters = data => {
        const { setItemsFilterSearch } = this.props;
        // enableSpinner(true);
        setItemsFilterSearch(data);
        // enableSpinner(false);

        // $("#menuCategories").removeClass('open-menu-categories');
    };

    searchPageRes = async (page = 0) => {
        const { setNextPage, itemsReducer: { searchByCategories, searchByKey, category, idCategory, location, searchByDash, viewCategory }, items } = this.props;
        page = page === 1 ? 0 : ((page - 1) * 50);
        console.log('con<location::::::::::', location, 'viewCategory:', viewCategory);
        if (location === 'menuCategories') {
            setNextPage(page);
            await searchByCategories(idCategory, page, viewCategory);
        } else if (location === 'navBar') {
            await searchByKey(page);
        } else if (location === 'redView') {
            await searchByKey(page, 'redView');
        } else if (location === 'whiteView') {
            await searchByKey(page, 'whiteView');
        } else if (location === 'outletView') {
            await searchByKey(page, 'outletView');
        } else if (location === 'promocion') {
            await searchByKey(page, 'promocion');
        } else if (location === 'destacado') {
            await searchByKey(page, 'destacado');
        } else if (location === 'masvendidos') {
            await searchByKey(page, 'masvendidos');
        } else if (location === 'nuevosproductos') {
            await searchByKey(page, 'nuevosproductos');
        } else if (location === 'remates') {
            await searchByKey(page, 'remates');
        } else if (location === 'vitrinaView') {
            await searchByKey(page, 'vitrinaView');
        } else if (location === 'Mulos') {
            await searchByKey(page, 'Mulos');
        }
    }

    searchPage = async (page = 0) => {
        const { itemsReducer: { searchByKey } } = this.props;
        page = page === 1 ? 0 : ((page - 1));
        await searchByKey(page);
    }

    mouseOverAddCart = (index, flag, event) => {
        if (this.iconMouse && this.iconMouse.length > 0) {
            this.iconMouse[index].style.display = "none";
            this.iconMouseOver[index].style.display = "block";
        }
    }

    mouseOutAddCart = (index, flag, event) => {
        if (flag == 'green') {
            event.currentTarget.style.backgroundColor = "#89c33f";
            event.currentTarget.className = "btn btn-block botonAgregar"
        } else if (flag == 'yellow') {
            event.currentTarget.style.backgroundColor = "#efc964";
            event.currentTarget.className = "btn btn-block botonAgregarYellow"
        } else {
            event.currentTarget.style.backgroundColor = "#e27753";
            event.currentTarget.className = "btn btn-block botonAgregarAgotado"
        }
        // Íconos
        if (this.iconMouse && this.iconMouse.length > 0) {
            this.iconMouse[index].style.display = "block";
            this.iconMouseOver[index].style.display = "none";
        }
    }

    changeFilter = (type) => {
        this.setState({
            card: type === 'card' ? true : false,
            list: type === 'list' ? true : false
        });
    }

    /*Funcionalidad del boton de las caracteristicas*/
    barraSide = () => {
        const linkFiltro = document.querySelectorAll(".linkFilter");
        const btnToggle = document.querySelector(".toggle-btn");

        btnToggle.addEventListener("click", (function () {
            document.getElementById("sidebar").classList.toggle("active");
            document.querySelector(".toggle-btn").classList.toggle("active");
        }()));

        linkFiltro.forEach((el) => el.addEventListener("click", function () {
            document.getElementById("sidebar").classList.remove("active");
            document.querySelector(".toggle-btn").classList.remove("active");
        }));
    }

    dejanosMensaje = (item) => {
        this.setState({
            itemSelect: item
        });
        setTimeout(() => {
            $('#itemCotizaModal').modal('show');
        }, 300);
    };

    //Para abrir el modal descomenta aquí - Litzy
    // handleOpenModal = () => {
    //     this.setState({ showModal: true });
        
    // };

    // handleCloseModal = () => {
    //     this.setState({ showModal: false });
    // };

    // stkMod = async (item) => {
    //     await this.setState({itemStocks: item});
    //     await $('#StckModel').modal('show');
    // };
    stockTrafficLight = (item) => {
        const { OnHandPrincipal, quantity, OnHand } = item;
        const { carritoVerde, carritoAmarillo, carritoAgotado } = config.shoppingCartIcons;
        let icon;
        let buttonClass;
    
        if (quantity <= OnHand && quantity <= OnHandPrincipal) {
            icon = carritoVerde;
            buttonClass = "btn btn-block botonAgregar";
        } else if (quantity > OnHand && quantity <= OnHandPrincipal) {
            icon = carritoAmarillo;
            buttonClass = "btn btn-block botonAgregarYellow p-2";
        } else {
            icon = carritoAgotado;
            buttonClass = "btn btn-block botonAgregarAgotado p-2";
        }
    
        return {
            src: icon,
            class: buttonClass
        };
    }
    

    render() {
        const { items, type, openDetails, itemsReducer: { TotalPage, itemsCategories, whsGeneralName, whsGeneralType, getStockDetails, getInfoDiscount }, viewOne, configReducer: { currency }, sessionReducer, sessionReducer: { user, role } } = this.props;
        const { card, list, itemSelect, seller, renderStock, renderInfoDiscount} = this.state;
        const locationStock = 'itemList';
        return (
            <div>
                <ItemCotizacionModal
                    Usuario={user}
                    datos={sessionReducer.role}
                    item={itemSelect}
                />

                <div id="scrollDownPlease" >
                    <div className="row ml-0 mr-0">
                        <div className="col-md-6">
                            <div className="row justify-content-md-start">
                                <div className="col-md-auto p-2 justify-content-center text-center mr-md-2 mb-2 mt-4">
                                    <img onClick={() => { this.changeFilter('card') }} className="large mb-2" src={config.icons.larg}alt="Vista en tarjeta" style={{ marginTop:'4px',height: '15px', width: '15px', cursor: 'pointer', marginRight: '15px' }} />
                                    <img onClick={() => { this.changeFilter('list') }} className="list" src={config.icons.list} alt="Vista en lista" style={{ marginTop:'-4px', height: '15px', width: '15px', cursor: 'pointer' }} />
                                    <span className="font-weight-bold"> {TotalPage} Productos </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid row ml-0 mr-0" style={{ margin: 0, display: "flex", justifyContent: "center", fontFamily: "Helvetica" }}>
                        {(!type || type !== 'RedCompensasView') &&
                            // <div className="col-md-3" style ={{height:"100%"}}> 
                            //     <SideBarItems totalRows = {TotalPage} dataCategories = {itemsCategories} viewOne = {viewOne ? viewOne : ''}/>
                            // </div>
                            <div className="col-md-2 colmd" style={{ height: "auto", marginBottom: "1.5rem", top: "-1rem", marginLeft:'-70px' }}>
                                <div className="toggle-btn" onClick={() => { this.barraSide() }}>
                                    <img className="filter" src={config.icons.filter} alt="filter" style={{ width: '15px', height: '18px', marginTop: "0px", cursor: 'pointer'}}/>Filtros
                                </div>
                                <div id="sidebar" style={{ OverflowY: "scroll", marginTop: "28px", maxWidth: '250px' }}>

                                    <div style={{ padding: "1rem 1rem 1rem 1rem" }}>
                                        <SideBarItems totalRows={TotalPage} dataCategories={itemsCategories} viewOne={viewOne ? viewOne : ''} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className={(!type || type !== 'RedCompensasView') ? "col-md-8 colmdd" : ""}>
                            <div className='row mr-0 ml-0 columnas' style={{justifyContent:'normal'}}>
                                {(items && items.length > 0) && items.map((item, index) => {
                                    const { ItemName, ItemCode, Discount, U_FMB_Handel_Promo, isDestacado, PicturName, FATHER, PriceBeforeDisc, FinalPrice, flagM2, Currency, OnHandPrincipal } = item; 
                                    const { OnHand, quantity, flagStock, whsCodes, U_web, flagInfoDiscount, infoDiscount } = item; 
                                    const flagDiscount = Boolean(!FATHER && U_web && Discount && Discount > 0);
                                    // Calculo de sumatoria de almacenes para Hover
                                        const totalStock = whsCodes?.reduce((total, current) => total + parseFloat(current.Stock), 0);
                                    return (
                                        <>
                                            <div id="CardItem" className={card === true ? "item card imageCard card-container-all" : 'row listCards justify-content-center align-items-center card-container-all ml-0 mr-0'} style={{ margin: card === true ? 12 : "15px 0 15px 0", borderBottom: card === true ? "" : "", position: "relative", textAlign: "center", padding: 0, width: card === true? '':'70rem', borderRadius: card === true ? '15px' : '' }} key={ItemCode + ItemName + FinalPrice}>
                                                <div className={card === true ? "item card" : ' row justify-content-center align-items-center ml-0 mr-0'} style={{ width: card === true ? "250px" : "100%", minHeight: card === true ? "460px" : "", padding: "1rem", borderRadius: card === true ? "" : "15px", border: card === true ? "" : "" }}>
                                                    {Boolean(isDestacado || U_FMB_Handel_Promo) && (
                                                        <div style={{ display: 'table'}}>
                                                            <div className="font-weight-bold ribbon" onClick={() => openDetails(item)}><span><blink> {isDestacado ? 'DESTACADO' : 'PROMOCIÓN'} </blink></span></div>
                                                        </div> 
                                                    )}
                                                    <div class={card === true ? 'container-img p-1' : 'p-4'}>
                                                        {flagDiscount && (
                                                            <>
                                                                <div className='rounded' style={{ position: 'absolute', backgroundColor: 'red', color: 'white', top: '5px', right: '10px', padding: '4px', marginTop:'5px'}}>
                                                                    {Discount + '% OFF'}
                                                                </div>
                                                                <div className='rounded' style={{ position: 'absolute', top: '45px', right: '10px', padding: '4px', marginTop: '5px' }}>
                                                                    <ReactHover options={true} >
                                                                        <Trigger type="trigger">
                                                                            <img
                                                                                src={config.icons.discountIcon}
                                                                                width={'40px'}
                                                                                height={'40px'}
                                                                                alt='Icono Descuento'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={async (e) => { await getInfoDiscount(ItemCode, locationStock); await this.setState({ renderInfoDiscount: true }); }}
                                                                            />
                                                                        </Trigger>
                                                                        {(renderInfoDiscount && flagInfoDiscount) &&
                                                                            <Hover type="hover">
                                                                                <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-90px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                                    <p className="card-title text-left" style={{ fontSize: 13, color: 'black' }}>
                                                                                        <span style={{ fontWeight: 'bold' }}> Tipo de Descuento: </span> <span>{infoDiscount.typeDiscount || ''}</span><br></br>
                                                                                        <span style={{ fontWeight: 'bold' }}> Descuento: </span> <span>{infoDiscount.Discount || ''} %</span>
                                                                                    </p>
                                                                                    {(infoDiscount?.Quantities && infoDiscount.Quantities?.length > 0 ) && (
                                                                                        <>
                                                                                            <p className="card-title text-left" style={{ fontSize: 13, color: 'black' }}>
                                                                                                <span style={{ fontWeight: 'bold' }}> Información del descuento.</span>
                                                                                            </p>
                                                                                            {infoDiscount.Quantities.map((item, index) => (
                                                                                                <p key={index} className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'black' }}>
                                                                                                    <span style={{ fontWeight: 'bold' }}>Cantidad Min: </span><span>{item.Amount}</span><br></br>
                                                                                                    <span style={{ fontWeight: 'bold' }}>Descuento: </span><span>{`${item.Discount} %`}</span><br></br>
                                                                                                    <span style={{ fontWeight: 'bold' }}>Precio </span><span>
                                                                                                        <CurrencyFormat
                                                                                                            value={Number(item.Price || 0) * 1.16}
                                                                                                            displayType={'text'}
                                                                                                            decimalScale={2}
                                                                                                            thousandSeparator={true}
                                                                                                            fixedDecimalScale={true}
                                                                                                            prefix={' ' + currency.DocCurrCod + ' '}
                                                                                                            suffix={' ' + config.general.currency}
                                                                                                        >
                                                                                                        </CurrencyFormat>
                                                                                                    </span>
                                                                                                </p>
                                                                                            ))}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </Hover>
                                                                        }
                                                                    </ReactHover>
                                                                </div>
                                                            </>
                                                        )}
                                                        <img
                                                            onClick={() => openDetails(item)}
                                                            className={card === true ? "card-img-top" : "card-img-top"}
                                                            style={{
                                                                height: card === true ? "140px" : "100px",
                                                                width: card === true ? "146px" : "100px", // Ajusta el ancho deseado para el modo lista
                                                                backgroundColor: "transparent",
                                                                borderTopRightRadius: "10px",
                                                                cursor: "pointer",
                                                                marginRight: "auto",
                                                                marginLeft: "auto",
                                                                padding: 0,
                                                            }}
                                                            src={PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + PicturName) : require('../images/noImage.png')}
                                                            alt="Imagen de Articulo"
                                                        />
                                                    </div>

                                                    <div className={card === true ? "col-12 " : " col-8 detailsItemList"} style={{/*paddingLeft: 10*/ }}>
                                                        <div className="card-body" style={{ display:'flex', flexDirection:'column', height: "fit-content", margin: 0, padding: "2px", minHeight: card === true ? "215px" : "auto", color: config.itemsList.textColor }}>
                                                            <div>
                                                                {!FATHER && (
                                                                    <div className="card-subtitle font-weight-bold text-left" style={{ marginBottom: 5, color: config.itemsList.textPrice2 }}>
                                                                        {(!U_web) ?
                                                                            <h1 class="quote-h1">Solicite su cotización</h1>
                                                                        :
                                                                            <> 
                                                                                {flagDiscount && (
                                                                                    <div>
                                                                                        <span style={{ color: 'red', fontSize: '14px' }}>Antes</span>
                                                                                        <span className="font-weight-bold" style={{ fontSize: 14, color: "#666666", textDecoration: 'line-through', marginLeft: '5px' }} >
                                                                                            <CurrencyFormat
                                                                                                value={PriceBeforeDisc || ''}
                                                                                                displayType={'text'}
                                                                                                thousandSeparator={true}
                                                                                                fixedDecimalScale={true}
                                                                                                decimalScale={2}
                                                                                                prefix={(currency?.DocCurrCod || '') + ' '}
                                                                                                suffix={' ' + Currency}>
                                                                                            </CurrencyFormat>
                                                                                            {flagM2 && <span style={{ marginLeft: '12px', color: '#89c33f' }}>m²</span>}
                                                                                        </span>
                                                                                    </div>
                                                                                )}     
                                                                                <h1 class="price">
                                                                                    <CurrencyFormat
                                                                                        value={FinalPrice || ''}
                                                                                        displayType={'text'}
                                                                                        thousandSeparator={true}
                                                                                        fixedDecimalScale={true}
                                                                                        decimalScale={2}
                                                                                        prefix={(currency?.DocCurrCod || '') + ' '}
                                                                                        suffix={' ' + Currency}>
                                                                                    </CurrencyFormat>
                                                                                    {flagM2 && <span style={{ marginLeft: '12px', color: '#89c33f' }}>m²</span>}
                                                                                </h1>
                                                                            </> 
                                                                        }
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="overflow-auto">
                                                                <p className="card-title text-left ItemName" style={{ padding: 1, fontSize: 12, fontWeight: 600, overflowWrap: 'break-word', maxWidth: '173px' }}>
                                                                    {ItemName}
                                                                </p>
                                                            </div>
                                                            <span className="nameItemCardList">
                                                                <div>
                                                                    <div className='container-info'>
                                                                        {Boolean(!FATHER) && (
                                                                            <h2 style={{ fontSize: 13, marginLeft: card === true ? 0 : 0 }} className='sku-h2'>SKU: {ItemCode}</h2>
                                                                        )}   
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>

                                                        {/* inicio button */}
                                                        <div className="card-footer" style={{ padding: 0, border: 'none', marginTop: card === true ? "-14px" : "10px"/*borderTop: "transparent"*/ }}>
                                                            <div className={card === true ? 'row justify-content-center ml-0 mr-0' : 'row justify-content-start ml-0 mr-0'} style={{ margin: 0, padding: 0 }}>
                                                                {Boolean(FATHER) && (
                                                                    <>
                                                                        <div style={{ margin: 0, padding: 0 }} onClick={() => openDetails(item)} >
                                                                            <div>
                                                                                {seller && seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ?
                                                                                    <div className="btn-container" style={{ marginLeft: card === true ? "" : "25px" }}>
                                                                                        <div className="info-stock">
                                                                                            <h3 className="title-stock">Stock disponible</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="btn-container">
                                                                                        <div className="info-stock">
                                                                                            <h3 className="title-stock">Más modelos disponibles</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}   

                                                                {Boolean(!FATHER && U_web) &&
                                                                    <div className="d-flex" style={{ alignItems: 'center', padding: 0 }}>
                                                                        {whsGeneralType === 1 && (
                                                                            // (seller && seller.empID !== "1") || (OnHand > 0 && (role === ROLES.PUBLIC || seller?.empID === "1")) ?
                                                                            <>
                                                                                <div className="col-3" style={{ padding: 0 }}>
                                                                                    <input
                                                                                        id={'input-quantity-' + ItemCode + index}
                                                                                        type="number"
                                                                                        min="1"
                                                                                        max={Number(OnHandPrincipal) - 1}
                                                                                        value={ Number(quantity) < 1 ? "" : Number(quantity)}
                                                                                        className="form-control"
                                                                                        name={'quantity' + ItemCode}
                                                                                        placeholder="1"
                                                                                        style={{ textAlign: "left", height: 40.5, width: 60 }}
                                                                                        onChange={(event) => { this.changLocalQuantity(index, item, event) }}
                                                                                        onFocus={(event) => event.target.select()}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-6 botonAgregar">
                                                                                    <button style={{ textAlign: "center" }}
                                                                                        type="button"
                                                                                        className={this.stockTrafficLight(item).class}
                                                                                        value={(quantity ? Number(quantity) : '1')}
                                                                                        onClick={(event) => { this.changeQuantity(index, item, event) }}
                                                                                    >
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                            // :
                                                                            // <>
                                                                            //     <div style={{ padding: 0 }}>
                                                                            //         <div className="btn-container">
                                                                            //             <div className="without-stock" style={{backgroundColor: config.navBar.iconColor}} >
                                                                            //                 <h3 className="title-stock">No hay stock por ahora</h3>
                                                                            //             </div>
                                                                            //         </div>
                                                                            //     </div>
                                                                            // </>
                                                                        )}
                                                                        <div>
                                                                            {/* {seller && seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 && ( */}
                                                                                <div style={{ position: "relative", marginLeft: "8px" }} >
                                                                                    <ReactHover options={true} >
                                                                                        <Trigger type="trigger">
                                                                                            <img
                                                                                                src={config.icons.stock}
                                                                                                width={'40px'}
                                                                                                height={'40px'}
                                                                                                alt='Icono stock'
                                                                                                style={{ cursor: 'pointer' }} // Add this line to set the cursor style
                                                                                                onClick={async(e) => {await getStockDetails(ItemCode, locationStock); await this.setState({renderStock: true});}}
                                                                                            />
                                                                                        </Trigger>
                                                                                        {(renderStock && flagStock) &&
                                                                                            <Hover type="hover">
                                                                                                <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-135px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                                                    <p className="card-title text-left" style={{ fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                        <span style={{ fontWeight: 'bold' }}> Stock : </span> <span>{parseInt(totalStock) || '0'}</span>
                                                                                                    </p>
                                                                                                    {whsCodes && whsCodes.length > 0 ?
                                                                                                        whsCodes.map((whs, index) => (
                                                                                                            <p key={index} className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                                <span style={{ fontWeight: 'bold' }}> Almacén {whs.WhsName ? `'${whs.WhsName}'` : `'N/A'`} : </span><span>{parseInt(whs.Stock) || '0'}</span>
                                                                                                            </p>
                                                                                                        ))
                                                                                                    :
                                                                                                        <p className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                            <span style={{ fontWeight: 'bold' }}> Almacén {whsGeneralName ? `'${whsGeneralName}'` : `'defecto'`} : </span><span>{OnHandPrincipal || '0'}</span>
                                                                                                        </p>
                                                                                                    }
                                                                                                </div>        
                                                                                            </Hover>
                                                                                        }
                                                                                    </ReactHover>
                                                                                </div>
                                                                             {/* )} */}
                                                                            
                                                                            {/* {((role === ROLES.PUBLIC || seller?.empID === '1' ) && OnHand > 0) && (
                                                                                <div className="icoCarrito col-2 text-center align-middle" style={{ fontSize: 33, padding: 0, textAlign: "right" }}>
                                                                                    <img 
                                                                                        ref={iconMouse => this.iconMouse[index] = iconMouse} 
                                                                                        src={this.stockTrafficLight(item).src} 
                                                                                        style={{ display: "block", cursor: "auto" }} 
                                                                                    />
                                                                                </div>
                                                                            )} */}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Pagination
                            refresh={this.searchPage}
                            totalRowsRefresh={TotalPage} 
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
        setNextPage: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value }),
        searchByDashOption: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH_OPTION, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ItemsList);