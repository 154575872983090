import React, {Component} from 'react';
import {config, SERVICE_API,DISPATCH_ID} from "../libs/utils/Const";
import {ApiClient} from "../libs/apiClient/ApiClient";
import {ExportDevolutionReportPDF} from '../components'
import { ProgressBar } from "./index";
import $ from 'jquery';
import {connect} from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { PayPalButton } from "react-paypal-button-v2";
import moment from "moment";
let paypalOptions = {
    intent: 'capture',
    commit: true,
    currency : 'MXN',
};

let  buttonStyles = {
    layout: 'horizontal',
    shape: 'rect',
    color: 'blue'
};

let apiClient = ApiClient.getInstance();

class DevolutionModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkboxDataTables: new Map(),
            datos : {},
        };
    };

    async addToShopingCart(){
        //Los PROPS los consigues de abajo
        const {order,notificationReducer: {showAlert},configReducer,enableSpinner,shoppingCartReducer, seller} = this.props;
        const {checkboxDataTables,datos} = this.state;
        
        if(shoppingCartReducer.items.length > 0 && !shoppingCartReducer.isDevolution){
            showAlert({ type: 'error', message: "Para agregar artículos de devolución necesitas vaciar el carrito actual", timeOut: 3000 });
            return;
        }
        
        if(!datos.captura){
            showAlert({ type: 'warning', message: "Necesitas llenar el campo captura", timeOut: 3000 });
            return;
        }
        if (!datos.concepto){
            showAlert({ type: 'warning', message: "Por favor selecciona el concepto", timeOut: 3000 });
            return;
        }
        // if (!datos.poblacion){
        //     showAlert({ type: 'warning', message: "Necesitas llenar el campo población", timeOut: 3000 });
        //     return;
        // }
        // if (!datos.aplicar){
        //     showAlert({ type: 'warning', message: "Necesitas llenar el campo aplicar a factura", timeOut: 3000 });
        //     return;
        // }
        if (!datos.motivo){
            showAlert({ type: 'warning', message: "Necesitas llenar el campo motivo", timeOut: 3000 });
            return;
        }
        // if (!datos.credito){
        //     showAlert({ type: 'warning', message: "Necesitas llenar el campo aplicación crédito y cobranza", timeOut: 3000 });
        //     return;
        // }
        // if (!datos.foliocredito){
        //     showAlert({ type: 'warning', message: "Necesitas llenar el campo folio nota de crédito", timeOut: 3000 });
        //     return;
        // }
        datos.vendedor = seller.firstName + ' '+ seller.lastName;
        
        let dataDevolution ={
            datos,
            order
        };
        localStorage.setItem(config.general.localStorageNamed + 'dataDevolution', JSON.stringify(dataDevolution));
        
        for (let index = 0; index < shoppingCartReducer.items.length; index++) {
            const element = shoppingCartReducer.items[index];
            if(element.BE !== order.body[0].DocEntry){
                showAlert({ type: 'error', message: "Solo es posible seleccionar artículos de la misma factura", timeOut: 3000 });
                $('#devolutionModal').modal('hide');
                return;
            }
        }
        
        let flag = false;
        // for (let [id, value] of checkboxDataTables.entries()) {            
        //     if (value) {
        //         flag = true;
        //         shoppingCartReducer.items.push({ItemCode: order.body[id].ItemCode, quantity: parseInt(order.body[id].newQuantity), WhsCode: order.body[id].WhsCode,BE: order.body[id].DocEntry, BL: order.body[id].BaseLine});
        //     }
        // }
        
        if(order){
            flag = true;
            shoppingCartReducer.items.push({ItemCode: order.ItemCode, quantity: parseInt(order.newQuantity), WhsCode: order.WhsCode,BE: order.DocEntry, BL: order.BaseLine});
        }
        console.log('con<shoppingCartReducer',shoppingCartReducer.items);
        
        if(!flag){
            showAlert({type: 'warning', message: 'Aun no has seleccionado ningun artículo para devolución', timeOut: 0});
            return;
        }
        

        enableSpinner(true);
        //agregar al carro directo con sql
        let responsesd = await apiClient.updateShoppingCartLocal(shoppingCartReducer.items);
        if(responsesd.status === 1){
            $('#devolutionModal').modal('hide');
            showAlert({type: 'success', message: 'Artículo a devolver agregado correctamente', timeOut: 0});
            configReducer.history.goShoppingCart();
        }
        enableSpinner(false);
    }

    toggleDocumentSelected = (id) => {
        this.setState(state => {
            const checkboxDataTables = new Map(state.checkboxDataTables);
            checkboxDataTables.set(id, !checkboxDataTables.get(id));
            return {checkboxDataTables};
        });
    };

    handelChange = ({ target }) => {
        const { name, value } = target;
        const { datos } = this.state;
        datos[name] = value;
        this.setState({
            datos,
        });
    };

    render() {
        const { order, configReducer: { currency }, changLocalQuantity, date, seller, autorizacion,folio} = this.props;
        const { checkboxDataTables, datos } = this.state;
        let checkbox = {
            data: checkboxDataTables,
            selectOne: this.toggleDocumentSelected,
        } 
        const shipDate = autorizacion ? order.ShipDate : order.DocDate;
        const DocDate = autorizacion ? moment(date).utc().format('DD-MM-YYYY') : date;
        return (
            <div className="modal fade" id="devolutionModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: 'center' }}>
                <div className="modal-dialog modal-xl" role="document" style={{ margin: '1.75rem auto' }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: config.navBar.iconColor }}>
                            <h5 className="modal-title" style={{ color: config.navBar.textColor2 }}>Devolver mercancía</h5>
                            <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3">
                            {/* <div>
                                
                                        <div className=' text-left card ' style={{ border: 'none'}}>
                                            <div className='row' style={{ padding: 10, textAlign: 'center' }}> */}
                                                {/* <div className='col-sm-2' style={{ margin: 0 }}>
                                                    
                                                </div> */}
                                                {/* <div className='col-sm-12'>
                                                    <div className="container p-0"> */}
                                                        {/* <div className="row">
                                                            <div className="col-12" style={{ borderBottom: '1px solid rgb(124, 124, 125)' }}>
                                                                <div className='text-left' style={{color: "#808080"}}>
                                                                    {item.Dscription}
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="row font-weight-bold">
                                                            <div className="col-md-12 table-responsive ">
                                                                <table className="table">
                                                                    <thead style={{fontSize:"1rem"}}>
                                                                        <tr style={{backgroundColor: config.navBar.iconColor, color: "white"}}>
                                                                            <th scope="col"></th>
                                                                            <th scope="col">Clave</th>
                                                                            <th scope="col">Cantidad maxima a devolver</th>
                                                                            <th scope="col">Cantidad</th>
                                                                            <th scope="col">Precio</th>
                                                                            <th scope="col">Precio Total</th>
                                                                            <th scope="col"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody> */}
                                                                        {/* {!!order && order.map((item, index) => { */}
                                                                            {/* return (
                                                                                    <tr>
                                                                                        <td> 
                                                                                            <img className="img-fluid"
                                                                                                style={{backgroundColor: 'white', maxHeight: 130}}
                                                                                                src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png')}
                                                                                                alt=""
                                                                                            />
                                                                                        </td>
                                                                                        <td>{item.ItemCode}</td>
                                                                                        <td style={{fontSize:"1rem"}}>{parseInt(item.OpenQty)}</td>
                                                                                        <td style={{fontSize:"1rem"}}>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control mb-1"
                                                                                                style={{ textAlign: 'center', borderRadius: 20, borderColor: "#666666", color: "#666666", minWidth: "10rem" }}
                                                                                                max={parseInt(item.OpenQty)}
                                                                                                onChange={(event) => { changLocalQuantity(index, item, event); this.refreshModel()}}
                                                                                                min='1'
                                                                                                value = {item.newQuantity}
                                                                                                // onBlur={(event) => { this.changeQuantity(index, order, event) }}
                                                                                                // onFocus={(event) => event.target.select()}
                                                                                            />
                                                                                        </td> 
                                                                                        <td>
                                                                                            <CurrencyFormat 
                                                                                                value={item.Price} 
                                                                                                displayType={'text'} 
                                                                                                thousandSeparator={true} 
                                                                                                fixedDecimalScale={true} 
                                                                                                decimalScale={2} 
                                                                                                prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod}
                                                                                                suffix = {' ' + item.Currency}>
                                                                                            </CurrencyFormat>   
                                                                                        </td> */}
                                                                                        {/* <td>
                                                                                            <CurrencyFormat 
                                                                                                value={item.LineTotal} 
                                                                                                displayType={'text'} 
                                                                                                thousandSeparator={true} 
                                                                                                fixedDecimalScale={true} 
                                                                                                decimalScale={2} 
                                                                                                prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod}
                                                                                                suffix = {' ' + currency.MainCurncy}>
                                                                                            </CurrencyFormat>
                                                                                        </td> */}
                                                                                        {/* <th scope="row" className="text-right" style={{fontSize:"1rem"}}>
                                                                                            <button type="button" className="btn text-white" style={{background: "#86C03F"}} 
                                                                                                value={(order.newQuantity ? Number(order.newQuantity) : '1')}
                                                                                                onClick={(event)=> {this.changeQuantity(index, order, event)}} >
                                                                                                <i className="fas fa-cart-plus"/>
                                                                                                &nbsp; Agregar artículo para devolver
                                                                                            </button>
                                                                                        </th> */}
                                                                                        {/* <td className="text-center">
                                                                                            <input type="checkbox" style={{minWidth: '100%'}} id="cbox2"
                                                                                                // disabled={batch.Quantity-batch.Stock <=0 ? true : false}
                                                                                                checked={!!checkbox.data.get(index)}
                                                                                                onChange={() => checkbox.selectOne(index)}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                    ) */}
                                                                                {/* })} */}
                                                                    {/* </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                            </div> */}
                            <div style={{ margin: '10 50 10 50' }}>
                                <img style={{ width: '30%', height: 'auto' }} src={config.navBar.icon} />
                                <div>
                                    <table style={{ width: '100%' }}>
                                        <thead style={{ display: 'flex', flexWrap: "wrap", alignItems: "center", justifyContent: "center", alignContent: "center" }}>
                                            <th style={{ textAlign: 'center' }}>
                                                <h4 style={{ fontWeight: 'bold', fontSize: '20px' }}>Perez de la Mora</h4>
                                                <h5>Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000, Jalisco.</h5>
                                                <h5>ventas@perezdelamora.com</h5>
                                                <h5>Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37</h5>
                                            </th>
                                            <th style={{ width: ' 50px', visibility: 'hidden' }}><p>Oculto</p></th>
                                        </thead>
                                    </table>
                                </div>
                                <p><br></br></p>
                                <div>
                                    <table style={{ width: '85%' }}>
                                        <thead>
                                            <th style={{ textAlign: 'center' }}>
                                                <h6 style={{ fontWeight: 'bold', textAlign: 'center' }}>ORDEN DE REVISIÓN</h6>
                                            </th>
                                            <th style={{ textAlign: 'center' }}>
                                                <h6 style={{ fontWeight: 'bold', textAlign: 'center' }}>{autorizacion ? 'FOLIO: DEV-'+ folio :''}</h6>
                                            </th>
                                        </thead>
                                    </table>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000'}}>
                                            <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6 style={{ fontWeight: 'bold' }}>FECHA DE ORDEN:</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6 style={{ fontWeight: 'bold' }}>CAPTURA</h6></th>
                                        </tr>
                                        <tr>
                                            <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><h6>{DocDate}</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}>
                                                <h6>
                                                    <input type="text" className="form-control" style={{ color: '#000' }} name='captura' id="captura" placeholder="Captura" autoComplete="off" onChange={this.handelChange} value={(autorizacion ? order.U_FMB_Handel_Captura: datos.captura)||''} />
                                                </h6>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                                <p><br></br></p>
                                <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                    <tr style={{ paddingTop: '12px', paddingLeft: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                                        <th style={{ textAlign: 'center' }}><h5 style={{ fontWeight: 'bold' }}>CONCEPTO</h5></th>
                                    </tr>
                                </table>
                                {autorizacion ? 
                                    <form>
                                    <div class="table-responsive">
                                        <table className="col-12" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                            <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                                                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>GARANTIA</h6></th>
                                                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                                                    <input type="radio" id="garantia" name="concepto" value="0" style={{ width: '20px', backgroundColor: order.U_FMB_Handel_concepto === '0' ? '#000' : '#fff' }} onChange={this.handelChange} disabled={autorizacion} checked={order.U_FMB_Handel_Concepto ===0}/>
                                                </th>
                                                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>ACLARACION</h6></th>
                                                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                                                    <input type="radio" id="aclaracion" name="concepto" value="1" style={{ width: '20px', backgroundColor: order.U_FMB_Handel_concepto === '1' ? '#000' : '#fff', }} onChange={this.handelChange} disabled={autorizacion} checked={order.U_FMB_Handel_Concepto ===1} />
                                                </th>
                                                <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>DEVOLUCIÓN</h6></th>
                                                <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                                                    <input type="radio" id="devolucion" name="concepto" value="2" style={{ width: '20px', backgroundColor: order.U_FMB_Handel_concepto === '2' ? '#000' : '#fff' }} onChange={this.handelChange} disabled={autorizacion} checked={order.U_FMB_Handel_Concepto ===2}/>
                                                </th>
                                            </tr>
                                        </table>
                                    </div>
                                </form>
                                :
                                <form>
                                <div class="table-responsive">
                                    <table className="col-12" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                        <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                                            <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>GARANTIA</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                                                <input type="radio" id="garantia" name="concepto" value="0" style={{ width: '20px', backgroundColor: datos.concepto === '0' ? '#000' : '#fff' }} onChange={this.handelChange}/>
                                            </th>
                                            <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>ACLARACION</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                                                <input type="radio" id="aclaracion" name="concepto" value="1" style={{ width: '20px', backgroundColor: datos.concepto === '1' ? '#000' : '#fff', }} onChange={this.handelChange} />
                                            </th>
                                            <th style={{ textAlign: 'left', border: '1px solid black' }}><h6 style={{ fontWeight: 'bold' }}>DEVOLUCIÓN</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black', padding: '0px' }}>
                                                <input type="radio" id="devolucion" name="concepto" value="2" style={{ width: '20px', backgroundColor: datos.concepto === '2' ? '#000' : '#fff' }} onChange={this.handelChange}/>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                                </form>
                                }
                                <p><br></br></p>
                                <div>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                        <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                                            <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>CLIENTE</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>POBLACION</h6></th>

                                        </tr>
                                        <tr>
                                            <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{order ? order.CardName : ''}</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black' }}>
                                                <h4>
                                                    {/* <input type="text" className="form-control" style={{fontWeight:'bold', color:'#000'}} name='poblacion' id="poblacion" placeholder="Población" autoComplete="off" onChange={this.handelChange} value={datos.poblacion ? datos.poblacion : ''}/> */}
                                                    <h6 >{order ? order.CityS : ''}</h6>
                                                </h4>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                                <p><br></br></p>
                                <div>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                        <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                                            <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>No. CLIENTE</h6></th>
                                        </tr>
                                        <tr>
                                            <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{order ? order.CardCode : ''}</h6></th>
                                        </tr>
                                    </table>
                                </div>
                                <p><br></br></p>
                                <div>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                        <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                                            <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>FOLIO DE FACTURA</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black', backgroundColor: '#c7c3c3' }}><h6 style={{ fontWeight: 'bold' }}>FECHA DE COMPRA</h6></th>
                                        </tr>
                                        <tr>
                                            <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{autorizacion ? order.BaseRef : order.DocNum }</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black' }}><h6>{moment(shipDate).utc().format('DD-MM-YYYY')}</h6></th>
                                        </tr>
                                    </table>
                                </div>
                                <p><br></br></p>
                                <div>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                        <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                                            <th style={{ textAlign: 'center' }}><h6 style={{ fontWeight: 'bold' }}>ARTÍCULOS</h6></th>
                                            <th style={{textAlign:'left',border: '1px solid black', backgroundColor: '#c7c3c3'}}><h4 style={{fontWeight:'bold', fontSize: '10pt'}}>APLICAR A FACTURA</h4></th>
                                        </tr>
                                        <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                                            <th style={{ textAlign: 'center', fontWeight: 'none' }}>Los artículos deben de ser de la misma factura.</th>
                                            <th style={{textAlign:'center',border: '1px solid black'}}>
                                            <h4 style={{fontWeight:'bold'}}>
                                                <input type="text" className="form-control" style={{fontWeight:'bold', color:'#000'}} name='aplicar' disabled={true} id="aplicar" placeholder="Aplicar a factura" autoComplete="off" onChange={this.handelChange} value={datos.aplicar ? datos.aplicar : ''}/>
                                            </h4>
                                        </th>
                                        </tr>
                                    </table>
                                </div>
                                <div class=" col-12 table-responsive">
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                        <tr style={{ backgroundColor: config.navBar.iconColor, paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: 'white' }}>
                                            <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>#</th>
                                            <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Clave</th>
                                            <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Descripción</th>
                                            <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Cantidad</th>
                                            <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Precio</th>
                                            <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', fontSize: '10pt' }}>Importe</th>
                                        </tr>
                                        <tr>
                                            <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{1}</th>
                                            <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{order.ItemCode}</th>
                                            <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{order.Dscription || order.ItemName }</th>
                                            <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>{order.newQuantity || parseInt(order.Quantity)}</th>
                                            <th scope='col-auto' style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>$ {Number(order.Price).toFixed(2)}</th>
                                            <th scope='col-auto'style={{ textAlign: 'center', border: '1px solid black', whiteSpace: 'nowrap', fontSize: '10pt' }}>$ {Number((order.Price - (order.Price * (order.Discount || 0 / 100) / 100)) * (order.newQuantity || order.Quantity)).toFixed(2)}</th>
                                        </tr>
                                        <tr>
                                            <th colspan="6" style={{ textAlign: 'right' }}></th>
                                        </tr>
                                        <tr>
                                            <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>Subtotal:</th>
                                            <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat(order.Price * (order.newQuantity || order.Quantity)).toFixed(2)}</th>
                                        </tr>
                                        <tr>
                                            <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>I.V.A:</th>
                                            <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat((order.Price * .16) * (order.newQuantity || order.Quantity)).toFixed(2)}</th>
                                        </tr>
                                        <tr>
                                            <th colspan="5" style={{ textAlign: 'right', fontSize: '10pt' }}>Total:</th>
                                            <th style={{ textAlign: 'right', fontSize: '10pt', whiteSpace: 'nowrap' }}>$ {parseFloat((order.Price * (order.newQuantity || order.Quantity)) + ((order.Price * .16) * (order.newQuantity || order.Quantity))).toFixed(2)}</th>
                                        </tr>
                                    </table>
                                </div>
                                <p><br></br></p>
                                <div>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                        <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000', border: '1px solid black' }}>
                                            <th style={{ textAlign: 'center', backgroundColor: '#c7c3c3' }}><h5 style={{ fontWeight: 'bold', fontSize: '14pt' }}>MOTIVO</h5></th>
                                        </tr>
                                        <tr style={{ paddingTop: '12px', width: '100%', height: '100px', paddingBottom: '12px', textAlign: 'center', color: '#000', border: '1px solid black'}}>
                                            <textarea type="text" className="form-control" style={{ color: '#000', height: '100px' }} name='motivo' id="motivo" placeholder="Escribe el motivo" autoComplete="off" disabled={autorizacion} onChange={this.handelChange} value={datos.motivo ? datos.motivo : ''|| order.U_FMB_Handel_Motivo ? order.U_FMB_Handel_Motivo :''} />
                                        </tr>
                                    </table>
                                </div>
                                <p><br></br></p>
                                <div>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                        <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000', backgroundColor: 'rgb(199, 195, 195)' }}>
                                            <th style={{ textAlign: 'center', border: '1px solid black' }}><h5 style={{ fontWeight: 'bold' }}>Vendedor</h5></th>
                                        </tr>
                                        <tr> {seller && seller.empID !== "1" ?
                                            <th style={{ textAlign: 'center', border: '1px solid black' }}><h6 style={{ fontWeight: 'none' }}>{(seller ? seller.firstName + ' ' + seller.lastName:'') || (order.firstName ? order.firstName:'') +' '+ (order.middleName ? order.middleName:'') +' '+ (order.lastName ? order.lastName:'')}</h6></th>
                                        :
                                        <th style={{ textAlign: 'center', border: '1px solid black' }}><h6 style={{ fontWeight: 'none' }}>{(order.firstName ? order.firstName:'') +' '+ (order.middleName ? order.middleName:'') +' '+ (order.lastName ? order.lastName:'')}</h6></th>
                                        }
                                        </tr>
                                    </table>
                                </div>
                                <p><br></br></p>
                                <div class="table-responsive">
                                    <table class="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                        <tr style={{ width: '100%', color: '#000', verticalAlign: 'bottom', height: '70px' }}>
                                            <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black', width: '25%' }}><label style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nombre y Firma</label></th>
                                        </tr>
                                        <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' }}>
                                            <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CLIENTE</h4><h6 style={{ fontSize: '10pt' }}>ENTREGUE</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CHOFER</h4><h6 style={{ fontSize: '10pt' }}>RECIBI EN BUEN ESTADO</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>ALMACEN</h4><h6 style={{ fontSize: '10pt' }}>RECIBI EN BUEN ESTADO</h6></th>
                                            <th style={{ textAlign: 'center', border: '1px solid black' }}><h4 style={{ fontSize: '10pt' }}>CLIENTE</h4><h6 style={{ fontSize: '10pt' }}>RECIBI ARTÍCULO</h6></th>
                                        </tr>
                                    </table>
                                </div>
                                <p><br></br></p>
                                <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                    <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000', backgroundColor:'rgb(199, 195, 195)' }}>
                                        <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}><h4 style={{ fontWeight: 'bold', fontSize:'10pt' }}>APLICACIÓN CRÉDITO Y COBRANZA</h4></th>
                                        <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}>
                                            <h4 style={{ fontWeight: 'bold' }}>
                                                <input type="text" className="form-control" style={{ fontWeight: 'bold', color: '#000' }} name='credito' disabled={true} id="credito" placeholder="Aplicación crédito y cobranza" autoComplete="off" onChange={this.handelChange} value={datos.credito ? datos.credito : ''} />
                                            </h4>
                                        </th>
                                    </tr>
                                    <tr style={{ paddingTop: '12px', width: '100%', paddingBottom: '12px', textAlign: 'center', color: '#000' , backgroundColor:'rgb(199, 195, 195)'}}>
                                        <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}><h4 style={{ fontWeight: 'bold', fontSize:'10pt' }}>FOLIO NOTA DE CRÉDITO</h4></th>
                                        <th style={{ textAlign: 'left', border: '1px solid black', width: '50%' }}>
                                            <h4 style={{ fontWeight: 'bold' }}>
                                                <input type="text" className="form-control" style={{ fontWeight: 'bold', color: '#000' }} name='foliocredito' disabled={true} id="foliocredito" placeholder="Folio nota de crédito" autoComplete="off" onChange={this.handelChange} value={datos.foliocredito ? datos.foliocredito : ''} />
                                            </h4>
                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn text-white" style={{ background: "#86C03F", display: !autorizacion ? "block" : "none"}} onClick={() => this.addToShopingCart()}>
                                <img src={config.icons.shoppingCartW} alt="Agregar al carrito" style={{ width: '15px', height: '15px', marginRight: '5px' }}/>
                                &nbsp; Devolver articulo
                            </button>
                            {autorizacion ?
                                <ExportDevolutionReportPDF
                                    date={DocDate} // date
                                    radiobutton={order.U_FMB_Handel_Concepto}
                                    data={order}
                                    folio={folio}
                                    user={'user'} //user
                                    shipDate={shipDate}
                                    seller={''}
                                />
                            :''
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setItemDetailsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value}),
        setItemsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEMS, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DevolutionModal);
