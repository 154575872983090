import React, {Component} from 'react';
import {config, SERVICE_API,DISPATCH_ID} from "../libs/utils/Const";
import {connect} from 'react-redux';
import ExportReportGeneral from '../components/ExportReportGeneral';
import './card.css'

class DevolutionModal2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')),
            message: '',
            messageCancelación: ''
        };
    };

    handleChange = (e) => {
        this.setState({ message: e.target.value });
    }

    handleChangeCancelación = (e) => {
        this.setState({ messageCancelación: e.target.value });
    }
    
    render() { 
        const { customer, message, messageCancelación} = this.state;
        const { devolution } = this.props;
        let fechaActual = new Date();
        let year = fechaActual.getFullYear();
        let month = fechaActual.getMonth() + 1;
        let day = fechaActual.getDate();

        console.log('con>',devolution);
        console.log('con>',messageCancelación);

        return (
            <div className="modal fade" id="devolutionModal2" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: 'center' }}>
                <div className="modal-dialog modal-lg" role="document" style={{ margin: '1.75rem auto' }}>
                    <div className="modal-header" style={{ background: config.navBar.iconColor }}>
                        <h5 className="modal-title" style={{ color: config.navBar.textColor2 }}>Devolver mercancía</h5>
                        <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-content">
                        <div className="modal-body bg3">
                            <div className="p-2">
                                <div className="container m-1">
                                    <div className="row mb-5 gap-3">
                                        <div className="col-12 col-md-2 pl-0 pr-3">
                                            <img style={{ width: '100%', maxWidth: '250px', height: 'auto' }} src={config.navBar.icon} alt="Logo" />
                                        </div>
                                        <div className="col-12 col-md-10 d-flex justify-content-end align-items-center p-0">
                                            <h4 style={{ fontWeight: 'bold' }}>Formulario solicitud de reclamación devolución</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-end mb-3">
                                            <p style={{ fontSize: 15 }}>México, Pachuca a {day} de {month} del {year}</p>
                                        </div>
                                        {customer && (
                                            <div className='d-flex justify-content-start'>
                                                <p style={{ fontSize: 15 }}>Por medio del presente, yo, {customer.CardName} solicito a Pérez de la Mora S.A de C.V la devolución de la factura {devolution.DocNum}.</p>
                                            </div>
                                        )}


                                        <div className="mt-3">
                                            <label htmlFor="exampleFormControlTextarea1" className="d-flex justify-content-start form-label">El motivo para solicitar dicha devolución es:</label>
                                            <textarea className="form-control text-area-devolver" id="exampleFormControlTextarea1" rows="3" value={this.state.message} onChange={this.handleChange}></textarea>
                                        </div>

                                        <div className="mt-5">
                                            <p style={{ fontSize: 15 }}>Cordialmente.</p>
                                        </div>
                                        {customer && (
                                            <div className="d-flex flex-row justify-content-center" style={{ marginTop: '6rem' }}>
                                                <div>
                                                    <p className='mb-0'>{customer.CardName}</p>
                                                    <p className='border-top border-12 border-black' style={{ fontSize: 15 }}>Nombre y Firma del solicitante.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className=" p-2 mt-5">
                                <div className="container m-1">
                                    <div className="row mb-5 gap-3">
                                        <div className="col-12 col-md-2 pl-0 pr-3">
                                            <img style={{ width: '100%', maxWidth: '250px', height: 'auto' }} src={config.navBar.icon} alt="Logo" />
                                        </div>
                                        <div className="col-12 col-md-10 d-flex justify-content-end align-items-center p-0">
                                            <h4 style={{ fontWeight: 'bold' }}>Formulario solicitud de reclamación cancelación</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-end mb-3">
                                            <p style={{ fontSize: 15 }}>México, Pachuca a {day} de {month} del {year}</p>
                                        </div>
                                        {customer && (
                                            <div>
                                                <p style={{ fontSize: 15 }}>México, Pachuca a {day} de {month} del {year}</p>
                                                <div className='d-flex justify-content-start'>
                                                    <p style={{ fontSize: 15 }}>Por medio del presente, yo, {customer.CardName} solicito a Pérez de la Mora S.A de C.V la cancelación de la factura {devolution.DocNum}.</p>
                                                </div>
                                                <div className="mt-3">
                                                    <label htmlFor="exampleFormControlTextarea1" className="d-flex justify-content-start form-label">El motivo para solicitar dicha cancelación es:</label>
                                                </div>
                                            </div>
                                        )}
                                        <div className="mt-3">
                                            <label htmlFor="exampleFormControlTextarea1" className="d-flex justify-content-start form-label">El motivo para solicitar dicha cancelación es:</label>
                                            <textarea className="form-control text-area-devolver" id="exampleFormControlTextarea1" rows="3" value={this.state.messageCancelación} onChange={this.handleChangeCancelación}></textarea>
                                        </div>
                                        <div className="mt-5">
                                            <p style={{ fontSize: 15 }}>Cordialmente.</p>
                                        </div>
                                        {customer && (
                                            <div className="d-flex flex-row justify-content-center" style={{ marginTop: '6rem' }}>
                                                <div>
                                                    <p className='mb-0'>{customer.CardName}</p>
                                                    <p className='border-top border-12 border-black' style={{ fontSize: 15 }}>Nombre y Firma del solicitante.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            </div>
                            <div className="modal-footer">
                                <div className='d-flex justify-content-end'>
                                    <ExportReportGeneral
                                        view={'return2'}
                                        fechaActual={fechaActual}
                                        devolution={devolution}
                                        message = {message}
                                        messageCancelación = {messageCancelación}
                                    />
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        
         );
    }
}

export default connect(
)(DevolutionModal2);