import React, { Component } from 'react';
import { NavBar } from "../components";
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID, SERVICE_RESPONSE, ROLES } from '../libs/utils/Const';
import { connect } from "react-redux";
import './searchItemsListModal.css';
import $ from 'jquery';
import 'react-multi-carousel/lib/styles.css';
import { ApiClient } from '../libs/apiClient/ApiClient';
import moment from 'moment';

let apiClient = ApiClient.getInstance();

class SearchItemsListModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SearchItems: [],
            page: '0',
            itemsNumber: '',
        };
    }

    render() {
        const { isLoaded, fechaFinal, updateItemCode, childsFather, addChilds, removeChilds, ChildsToFather, Father } = this.props;
        const { SearchItems, page } = this.state;
        return (
            <div className="modal fade bd-example-modal-lg" id="SearchListModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{ minWidth: 300, maxWidth: '80vw', margin: '1.75rem auto', borderRadius: 15 }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: config.navBar.iconColor, color: '#FFFFFF' }}>
                            <h5 className="modal-title">Buscar articulo</h5>
                            <button type="button" style={{ color: "#FFFFFF", opacity: 1, margin: 0, padding: 0 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body row">
                            <div className='col-8' style={{ marginTop: 18 }}>
                                {this.predictive()}

                                <div className="row col-12" >
                                    <button className='col-2 pagination-buttons' onClick={() => { this.changePage('back') }}><img src={config.icons.flechaD} alt="Página anterior" style={{ width: '15px', height: '15px' }}/></button>
                                    <button className='col-2 pagination-buttons' onClick={() => { this.changePage('first') }}><img src={config.icons.backward} alt="Primera página" style={{ width: '15px', height: '15px' }}/></button>
                                    <span className='col-1 pagination-number' >{Number(page) + 1}</span>
                                    <button className='col-2 pagination-buttons' onClick={() => { this.changePage('next') }}><img src={config.icons.stepForward} alt="Página siguiente" style={{ width: '15px', height: '15px'}}/></button>
                                    <button className='col-2 pagination-buttons' onClick={() => { this.changePage('last') }}><img src={config.icons.FlechaI} alt="Última página" style={{ width: '15px', height: '15px' }}/></button> 
                                </div>
                                <div className='col-items-select'>
                                    {SearchItems.length > 0 ?
                                        SearchItems.map((item, index) => {
                                            let Exist = childsFather.filter(childs => childs.Child.ItemCode == item.ItemCode);
                                            let imagesArray = item.U_FMB_Handel_ImagesArray || '';
                                            imagesArray = imagesArray.split('|');
                                            return (
                                                <div>
                                                    <div className={'row product col-12 ' + (Exist.length > 0 ? ' product-Selected' : ' ')}
                                                        onClick={async () => { addChilds(item, index); this.refreshModel() }}
                                                    >
                                                        <div className="col-md-2 img-Search-Item" >
                                                            <img className='img-fluid img-edit '
                                                            //src={config.shoppingCartIcons.imagenDefecto}
                                                                src={config.BASE_URL + SERVICE_API.getImage + '/' + (item.PicturName ? item.PicturName : item.U_FMB_Handel_ImagesArray ? imagesArray[0] : 'noImagexd.png')}
                                                                alt="Sample Image"
                                                                style={{ height: 75 }}
                                                            />
                                                        </div>
                                                        <div className="col-md-10 product-detail">
                                                            <h4>{item.ItemCode}</h4>
                                                            <p>{item.ItemName}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <hr class="dotted col-12"></hr>
                                                    </div>
                                                </div>
                                            )
                                        })

                                        :
                                        <div className='row col-12 NoFound-col'style={{fontSize: "9pt"}} >
                                            <img src={config.icons.times} alt="times" style={{ width: '20px', height: '20px'}} />
                                            <h3 className='row col-12'  style={{fontSize: "12pt"}}> Su búsqueda no obtuvo ningún resultado.</h3>
                                            <h3 className='row col-12'  style={{fontSize: "12pt"}}>Pruebe con nuevos criterios de búsqueda.</h3>
                                        </div>

                                    }
                                </div>

                            </div>
                            <div className='col-4'>
                                <div className='row col-12 col-ItemsChilds' >
                                    {childsFather.length > 0 ?
                                        <div className='row'>
                                            {
                                                childsFather.map((child, index) => {
                                                    let imagesArray = child.Child.U_FMB_Handel_ImagesArray || '';
                                                    imagesArray = imagesArray.split('|');
                                                    return (
                                                        <div>
                                                            <div className='row col-12 row all-child-col '
                                                                style={{ padding: 0 }}
                                                            >
                                                                <div className='col-12 row'>
                                                                    {child.Child.ItemCode}
                                                                </div>
                                                                <div className='col-12 row'>
                                                                    <div className='col-6'>
                                                                        {child.Child.ItemName}
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <img className='img-fluid img-child-Selected '
                                                                            src={config.BASE_URL + SERVICE_API.getImage + '/' + (child.Child.PicturName ? child.Child.PicturName : child.Child.U_FMB_Handel_ImagesArray ? imagesArray[0] : 'noImagexd.png')}
                                                                            alt="Sample Image"
                                                                            style={{ height: 75 }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className='row col-12 col-DeleteChild ' >
                                                                <div className=' DeleteChild '
                                                                    onClick={() => { removeChilds(child.Child, index); this.refreshModel(); }}
                                                                >
                                                                    <img src={config.icons.times} alt="times" style={{ width: '20px', height: '20px'}} />
                                                                </div>
                                                            </div>

                                                            <div className="row ">
                                                                <hr class="dotted col-12"></hr>
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div>

                                        </div>

                                    }
                                </div>

                                <div className='row col-12 col-buton'>

                                    <div className='col-10'>
                                        <button
                                            type="button"
                                            className="btn btn-dark col-md-12"
                                            onClick={() => { ChildsToFather(Father.CODE, childsFather) }}
                                        >
                                            Guardar
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    refreshModel = () => {
        this.forceUpdate();
    }

    predictive() {
        const { SearchItems, itemsNumber } = this.state;
        let timeout;
        let search = '';
        return (
            <div className='row col-12 field search-Input-col justify-content-center'>
                <input
                    id="autoComplite"
                    className="col-9 text-left autoComplite "
                    name="autoComplite"
                    placeholder="Nombre, codigo de articulo"
                    onKeyDown={event => event.keyCode === 13 && this.searchItemsKey(event.target.value)}
                    autocomplete='off'
                    onChange={(e) => {
                        let busqueda = '';
                        if (e.target.value) {
                            busqueda = e.target.value;
                        }
                        clearTimeout(timeout)
                        timeout = setTimeout(() => {
                            this.setState({
                                page: '0'
                            });
                            this.searchItemsKey(busqueda)
                            clearTimeout(timeout)
                        }, 800)
                    }
                    }
                />
                <div className='col-3-md result-col'>
                    <span className='result-text'>  {itemsNumber} Resultados</span>
                </div>
            </div>
        )
    }


    searchItemsKey = async (key1) => {
        const { notificationReducer: { showAlert, }, enableSpinner } = this.props;
        const { page } = this.state;
        let itemsNumber1 = '0';
        let SearchItems = [];

        enableSpinner(true)
        let response = await apiClient.searchByKeyNew(key1, page, 'C', 'Lite');
        if (response.status == SERVICE_RESPONSE.SUCCESS) {
            SearchItems = response.data.items;
            if (response.data.nItems.length > 0) {
                itemsNumber1 = response.data.nItems[0].NUMBER;
            } else {
                itemsNumber1 = '0';
            }
            this.setState({
                SearchItems,
                itemsNumber: itemsNumber1,
            })
        } else {
            showAlert({ type: 'warning', message: response.message, timeOut: 5000 });
        }
        // showAlert({type: 'warning', message: response.message , timeOut: 5000});
        enableSpinner(false);
    }

    changePage = async (option) => {
        const { itemsNumber, } = this.state;
        let lastNum = (((parseInt(itemsNumber) / 20).toString()).split('.')[0]);
        let { page, } = this.state
        switch (option) {
            case 'first':
                page = '0';
                break;

            case 'back':
                if (Number(page) > 0) {
                    page--;
                }
                break;

            case 'next':
                if (Number(page) < Number(lastNum)) {
                    page++;
                }
                break;

            case 'last':
                page = lastNum;
                break;

            default:

                break;

        }

        page = page.toString();
        await this.setState({
            page
        });
        this.searchItemsKey(document.getElementById('autoComplite').value);
    }


}


const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
        setNextPage: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value }),
        searchByDashOption: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH_OPTION, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SearchItemsListModal);

