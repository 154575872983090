let local = "perez";

export default {
    general: {
        titleWindowsExplorer: 'PEREZ DE LA MORA',
        iconWindowsExplorer: '',
        loaderImage: 'Cargando...', //require('./images/loader/831.gif'),
        loaderBackground: '#201e1ebf',
        loaderColor: 'text-white',
        business: 1,
        imagesPath: 'perez',
        currency:'MXN',
        localStorageNamed: local + 'E-commerce',
        mailenterprise: 'ventas@perezdelamora.com',
    },

    icons: {  
        angle:require('../../images/perez/iconos/H_angle.svg'),
        list: require('../../images/perez/iconos/H_fa-th-list.svg'),
        larg: require('../../images/perez/iconos/H_fa-th-large.svg'),
        circleUser: require('../../images/perez/iconos/H_fa-usuario-circulo-o.svg'),
        backwardIz: require('../../images/perez/iconos/H_fa fa-step-forward.svg'),
        times: require('../../images/perez/iconos/H_fa-fa-times.svg'),
        circleCenter: require('../../images/perez/iconos/H_circle_center.svg'),
        forward:require('../../images/perez/iconos/H_fa-fast-forward.svg'),
        add:require('../../images/perez/iconos/H_fa-fa-plus.svg'),
        clock:require('../../images/perez/iconos/H_fa-fa-clock-o.svg'),
        starto:require('../../images/perez/iconos/H_fa-fa-star-o.svg'),
        paper:require('../../images/perez/iconos/H_fa-fa-paper-plane.svg'),
        backward:require('../../images/perez/iconos/H_fa-fa-step-backward.svg'),
        bullhorn:require('../../images/perez/iconos/H_fa-fa-bullhorn.svg'),
        eye:require('../../images/perez/iconos/H_fa-fa-eye.svg'),
        eyeslash:require('../../images/perez/iconos/H_fa-fa-eye-slash.svg'),
        upload:require('../../images/perez/iconos/H_fa-fa-upload.svg'),
        pencil:require('../../images/perez/iconos/H_fa-fa-pencil.svg'),
        facebook:require('../../images/perez/iconos/H_fa-fa-facebook.svg'),
        linkedin:require('../../images/perez/iconos/H_fa-fa-linkedin.svg'),
        filter:require('../../images/perez/iconos/H_fa-fa-filter.svg'),
        marker:require('../../images/perez/iconos/H_fa-map-marker.svg'),
        gift:require('../../images/perez/iconos/H_fa-fa-gift.svg'),
        searchG:require('../../images/perez/iconos/H_fa-fa-search-gray.svg'),
        moreCircle:require('../../images/perez/iconos/H_fa-fa-plus-circle.svg'),
        userBlack:require('../../images/perez/iconos/H_fas-fa-user-black.svg'),
        userWhite:require('../../images/perez/iconos/H_fas-fa-user-white.svg'),
        password:require('../../images/perez/iconos/H_fa-unlock-alt.svg'),
        passwordB:require('../../images/perez/iconos/H_fa-unlock.svg'),
        unlock:require('../../images/perez/iconos/H_unlock.svg'),
        search:require('../../images/perez/iconos/H_fa-fa-search.svg'),
        searchWhite:require('../../images/perez/iconos/H_fa-fa-search-white.svg'),
        shoppingCart:require('../../images/perez/iconos/H_fa-fa-shopping-cart.svg'),
        shoppingCartW:require('../../images/perez/iconos/H_fa-fa-shopping-cartw.svg'),
        start:require('../../images/perez/iconos/H_fa-fa-star.svg'),
        halfstar:require('../../images/perez/iconos/H_fa-fa-star-half-alt.svg'),
        excel:require('../../images/perez/iconos/H_fa-fa-file-excel-o.svg'),
        excelV:require('../../images/perez/iconos/H_fa-fa-file-excel-o-verde.svg'),
        stepForward:require('../../images/perez/iconos/H_fa-fa-step-forward.svg'),
        signature:require('../../images/perez/iconos/H_fa-fa-signature.svg'),
        building:require('../../images/perez/iconos/H_fa-fa-building.svg'),
        phone:require('../../images/perez/iconos/H_fa-fa-phone.svg'),
        pin:require('../../images/perez/iconos/H_fa-fa-mapa-pin.svg'),
        road:require('../../images/perez/iconos/H_fa-fa-road.svg'),
        map:require('../../images/perez/iconos/H_fa-fa-map.svg'),
        city:require('../../images/perez/iconos/H_fa-fa-city.svg'),
        envelope:require('../../images/perez/iconos/H_fa-fa-envelope-open-text.svg'),
        globalAmericas:require('../../images/perez/iconos/H_fa-fa-globe-americas.svg'),
        shield:require('../../images/perez/iconos/H_fa-fa-shield.svg'),
        favorite:require('../../images/perez/iconos/H_fa-fa-heart.svg'),
        trash:require('../../images/perez/iconos/H_fa-fa-trash.svg'),
        trashRed:require('../../images/perez/iconos/H_fa-fa-trash-red.svg'),
        trashWhite:require('../../images/perez/iconos/H_fa-fa-trash-white.svg'),
        backOrderTrue: require('../../images/perez/iconos/H_fa-fa-plus.svg'),
        backOrderFalse: require('../../images/perez/iconos/H_fa-fa-minus.svg'),
        arrowLeft:require('../../images/perez/iconos/H_fa-fa-caret-left.svg'),
        arrowRight:require('../../images/perez/iconos/H_fa-fa-caret-right.svg'),
        datasheet:require('../../images/perez/iconos/H_fa-fa-file-pdf-34.svg'),
        word:require('../../images/perez/iconos/H_fa-fa-file-word.svg'),
        cfdi:require('../../images/perez/iconos/H_fa-fa-file-invoice-dollar.svg'),
        report:require('../../images/perez/iconos/H_fa-fa-file-pdf-31.svg'),
        xml:require('../../images/perez/iconos/H_fa-fa-file-alt.svg'),
        detail:require('../../images/perez/iconos/H_fa-fa-info-circle.svg'),
        suscription:require('../../images/perez/iconos/H_fa-fa-chevron-circle-right.svg'),
        select:require('../../images/perez/iconos/H_fa-fa-check.svg'),
        selectB:require('../../images/perez/iconos/H_fa-fa-check-black.svg'),
        whatsapp:require('../../images/perez/iconos/H_fa-fa-whatsapp.svg'),
        iconFacebook: require('../../images/perez/iconos/H_facebook.svg'),
        iconInstagram: require('../../images/perez/iconos/H_instagram.svg'),
        iconLinkedin: require('../../images/perez/iconos/H_linkedin.svg'),
        iconTiktok: require('../../images/perez/iconos/H_tiktok.svg'),
        iconyoutube: require('../../images/perez/iconos/H_youtube.svg'),
        flechabajo: require('../../images/perez/iconos/S_fa-fa-chevron-down.svg'),
        flechaW:require('../../images/perez/iconos/S_fa-fa-chevron-downW.svg'),
        flecharriba: require('../../images/perez/iconos/S_fa-fa-chevron-up.svg'),
        chekdetails: require('../../images/perez/iconos/H_fa-check-circle.svg'),
        chekdetailsG: require('../../images/perez/iconos/H_check_details-green.svg'),
        box:require('../../images/perez/iconos/H_fa-box-open.svg'),
        play:require('../../images/perez/iconos/H_fa-play.svg'),
        timesc:require('../../images/perez/iconos/H_fa-times-circle.svg'),
        stop:require('../../images/perez/iconos/H_fa-stop.svg'),
        tint:require('../../images/perez/iconos/H_fa-fa-tint.svg'),
        minus:require('../../images/perez/iconos/H_fa-fa-minus.svg'),
        caret:require('../../images/perez/iconos/H_fa-fa-caret-right.svg'),
        flopy:require('../../images/perez/iconos/H_fa fa-floppy-o.svg'),
        line:require('../../images/perez/iconos/H_fa-fa-line-chart.svg'),
        flechaD:require('../../images/perez/iconos/H_fa-fast-backward.svg'),
        FlechaI:require('../../images/perez/iconos/H_fa-fast-forward.svg'),
        circle1:require('../../images/perez/iconos/H_fa-fa-circle-o.svg'),
        circle2:require('../../images/perez/iconos/H_fa-circle.svg'),
        tool: require('../../images/perez/iconosCarrito/tool.svg'),
        tools: require('../../images/perez/iconosCarrito/tools.svg'),
        stock: require('../../images/perez/iconosCarrito/stock.svg'),
        discountIcon: require('../../images/perez/iconos/discountIcon.svg'),
        justStoreIcon: require('../../images/perez/iconos/entrega_en_tienda.svg')
    },

    trademarks:{
        marcasRegistradas: require('../../images/perez/trademarks.png'),
        pagoTarjeta: require('../../images/perez/img-1PagoTarjeta.png'),
        enviosRepublica: require('../../images/perez/img-2Envios.png'),
        comprasSeguras: require('../../images/perez/img-3ComprasSeguras.png'),
        // prmoRed: require('../../images/perez/promored.png'),
        // buscados: require('../../images/perez/+buscados.png'),
        disponible: require('../../images/perez/nuevamente-disponibles.png'),
        nuestraTarjeta: require('../../images/perez/Tarjeta-Zona-Redcompensas.png'),
        // refrigeracionIndustrial: require('../../images/perez/Descubre2.png'),
    },

    navBar: {
        icon: require('../../images/perez/navBarIcon.svg'),
        icono2Login: require('../../images/perez/logotipo2Login.png'),
        searchAdvanced: require('../../images/perez/searchAdvanced.png'),
        menu: require('../../images/perez/menu.png'),
        shoppingCart: require('../../images/perez/carrito.svg'),
        backgroundColor: '#fff',
        iconColor: '#122759',
        iconColor2: '#FFFFFF',
        iconColor3: '#666666',
        iconColorPaginacion: "#E3E3E3",
        textColorPaginacion: "#666666",
        textColor: 'black',
        textColor2: 'white',
        textColor3: '#fff',
        headerCards: '#F07C00',
        menuCategoriesBackground: '#fff',
        textColorCategorie: 'black',
        menuCategoriesBackgroundHover: '#122759',
        textCategorieColorHover: '#122759',
        textColorCategorieHover: 'black',
        iconModal: 'white',
        iconBackground: '#F07C00',
        iconSearch: require('../../images/perez/busAvanzada.png'),
        carrito: require('../../images/perez/carrito.svg'),
        avatar: require('../../images/perez/navBar/avatar.png'),
        quote: require('../../images/perez/searchLite.png'),
        dropdownMore: require('../../images/icons/dropdown_more.svg'),
        dropdownHide: require('../../images/icons/dropdown_hide.svg'),
        entregas: require('../../images/perez/apps/M-entregas.svg'),
        android: require('../../images/perez/androide.png'),
        ventas: require('../../images/perez/apps/M-ventas.svg')
    },

    shoppingCartIcons: {
        camionAgotado: require('../../images/perez/iconosCarrito/carrito-01.png'),
        camionVerde: require('../../images/perez/iconosCarrito/carrito-02.png'),
        camionAmarillo: require('../../images/perez/iconosCarrito/carrito-03.png'),
        carritoVerde: require('../../images/perez/iconosCarrito/carrito-04.png'),
        carritoAgotado: require('../../images/perez/iconosCarrito/carrito-05.png'),
        carritoAmarillo: require('../../images/perez/iconosCarrito/carrito-06.png'),
        barraProgresoCarrito: require('../../images/perez/shoppingCartIcons/progressBarCarrito.svg'),
        barraProgresoDirrecion: require('../../images/perez/shoppingCartIcons/progressBarDirecciones.svg'),
        barraProgresoFinalizar: require('../../images/perez/shoppingCartIcons/progressBarFinalizar.svg'),
        imagenDefecto: require('../../images/perez/iconosCarrito/ImagenDefault.png')
        
    },

    NavarIconos: {
        iconOne: require('../../images/perez/marcas/nav_1.png'),
        iconTwo: require('../../images/perez/marcas/nav_2.png'),
        iconThree: require('../../images/perez/marcas/nav_3.png'),
        iconFour: require('../../images/perez/marcas/nav_4.png'),
        iconFive: require('../../images/perez/marcas/nav_5.png'),
    },

    marcasScroll: {
        One: require('../../images/perez/marcasCarousel/Brands_1.png'),
        Two: require('../../images/perez/marcasCarousel/Brands_2.png'),
        Three: require('../../images/perez/marcasCarousel/Brands_3.png'),
        Four: require('../../images/perez/marcasCarousel/Brands_4.png'),
        Five: require('../../images/perez/marcasCarousel/Brands_5.png'),
        Six: require('../../images/perez/marcasCarousel/Brands_6.png'),
        // Seven: require('../../images/perez/marcasCarousel/Brands_7.png'),
        // Eight: require('../../images/perez/marcasCarousel/Brands_8.png'),
        // Nine: require('../../images/perez/marcasCarousel/Brands_9.png'),
        // Ten: require('../../images/perez/marcasCarousel/Brands_10.png'),
        // Eleven: require('../../images/perez/marcasCarousel/Brands_11.png'),
        // Twelve: require('../../images/perez/marcasCarousel/Brands_12.png'),
        // Thirteen: require('../../images/perez/marcasCarousel/Brands_13.png'),
        // Fourteen: require('../../images/perez/marcasCarousel/Brands_14.png'),
        // Fifteen: require('../../images/perez/marcasCarousel/Brands_15.png'),
        // Sixteen: require('../../images/perez/marcasCarousel/Brands_16.png'),
    },

    openPay:{
        credit: require('../../views/validateOrder/cards1.png'),
        debit: require('../../views/validateOrder/cards2.png'),
        logo: require('../../views/validateOrder/openpay.png'),
        security: require('../../views/validateOrder/security.png'),

    },

    sucursalView:{
        iconSucursal: require('../../images/perez/SUCURSAL/sucursal-01.png'),
    },

    dataSucursal: [
        {
            imagen: require('../../images/perez/sucursales/sucursales-icono.png'),
            name: 'CULIACÁN',
            address: 'Carretera a El Dorado, Km 8.6, El Quemadito.',
            zipcode: '80300',
            block: 'Culiacán, Sinaloa. México.',
            phones: ['800 700 4772']
        },
        {
            imagen: require('../../images/perez/sucursales/sucursales-icono.png'),
            name: 'GUADALAJARA',
            address: 'Av. San Miguel No. 399 N° int. 46 Fracc. San Juan de Ocotán',
            zipcode: '45019',
            block: 'Zapopan, Jalisco. México.',
            phones: ['(333) 620 2520']
        },
        {
            imagen: require('../../images/perez/sucursales/sucursales-icono.png'),
            name: 'URUAPAN',
            address: 'Calle Atenas #13 esquina con Manuel Pérez Coronado Col. Jardines del Cupatitzio.',
            zipcode: '60080',
            block: 'Uruapan, Michoacán. México.',
            phones: ['(452) 688 2033']
        },
        {
            imagen: require('../../images/perez/sucursales/sucursales-icono.png'),
            name: 'SAN QUINTÍN',
            address: 'Carretera Transpeninsular Km 167.5, M 36, L7 S/N Ejido Emiliano Zapata',
            zipcode: '22920',
            block: 'Vicente Guerrero, B.C. México.',
            phones: ['(616) 165 2538']
        }, 
        {
            imagen: require('../../images/perez/sucursales/representanteVentas.png'),
            name: 'ENSENADA',
            address: 'Manuel Romero',
            zipcode: '',
            block: 'mromero@industriasrochin.com',
            phones: ['(646) 210 1459']
        }, 
        {
            imagen: require('../../images/perez/sucursales/representanteVentas.png'),
            name: 'TORREÓN',
            address: 'Fernando Torres',
            zipcode: '',
            block: 'ftorres@perezmx.com',
            phones: ['(811) 660 2500']
        },    
        {
            imagen: require('../../images/perez/sucursales/representanteVentas.png'),
            name: 'CHIHUAHUA',
            address: 'David Molinar',
            zipcode: '',
            block: 'dmolinar@industriasrochin.com',
            phones: ['(614) 176 5128']
        },    
        // {
        //     imagen: require('../../images/perez/sucursales/representanteVentas.png'),
        //     name: 'LOS MOCHIS',
        //     address: 'Sergio Caballet',
        //     zipcode: '',
        //     block: 'sacaballetv@Perez De la Mora.com',
        //     phones: ['(668) 125 0657']
        // }, 
    ],

    Back:{
        backgroundColor:"#fff"
    },
    BarraTelefono:{
        Whats: require('../../images/perez/barraTelefono/IconWhatsapp.svg'),
        IconUbicacion: require('../../images/perez/barraTelefono/Sucursales.svg'),
        IconCel: require('../../images/perez/barraTelefono/Telefono.svg'),
    },

    dashboard: 'Carousel',
    slider: {
    },
    carousel: {
        images: []
    },
    /*Edicion nosotros*/
    aboutUs: {
        active: true,
        company:`"Su satisfacción es nuestra razón de ser"`,
        company2:`Pérez de la Mora es una empresa que nace hace poco más de 25 años, iniciando principalmente como distribuidor de materiales para construcción, gracias a la buena aceptación de sus clientes y a su buen servicio agregó
         a su catálogo productos ferreteros logrando posicionarse como la mejor opción en el mercado.`,
        company3:`Gracias al apoyo de sus clientes, Pérez de la Mora se convierte en uno de los principales Mayoristas de la Región dando servicio
        a cerca de 100 poblaciones en los estados de Jalisco, Colima y Michoacán.`,
        company4:`En el 2009 la empresa Ambiente Cerámico se convierte en parte de Pérez de la Mora para dar servicio a nuestros clientes en Pisos, Azulejos y Muebles para baño logrando cubrir la mayor parte de necesidades de la construcción.`,
        description: `
        En Pérez de la Mora nos esforzamos por cada día dar lo mejor de nosotros, trabajando con pasión,
        promoviendo el desarrollo integral de cada colaborador, 
        interesándonos en contribuir a mejorar la calidad de vida de los integrantes de esta gran familia, teniendo presente que el servicio y la satisfacción es nuestra razón de ser.
        En Pérez de la Mora hemos evolucionado para satisfacer las necesidades de nuestros clientes y rebasar sus expectativas al mismo tiempo que el personal crece laboralmente
        y aumentamos nuestra calidad. En Pérez de la Mora hemos evolucionado para satisfacer las necesidades de todos los que colaboramos en esta empresa, pero principalmente rebasar
        las expectativas de nuestros clientes creando un compromiso constante y una relación rentable para todos.`,
        p1:`Valores`,
        p2:`Responsabilidad`,
        p3:`Integridad`,
        p4:`Profesionalismo`,
        p5:`Espíritu de servicio`,
        p6:`Respeto`,
        mission: 'Ayudamos a construir tus sueños, comprometiéndonos con la satisfacción de nuestros clientes y colaboradores.',
        vision: 'Consolidarnos como el principal distribuidor ferretero, manteniendo un crecimiento constante que asegure la disponibilidad del producto, servicio y precio.',
        values: [],
        images: require('../../images/perez/about/quienes-somos.jpg'),
    },

    contactUs: {
        active: true,
        iconColor: '#F07C00',
        colorMail: '#212529',
        locations: [
            {
                name: 'Oficina principal',
                street: 'Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000 ',
                country: 'Ciudad Guzman, Jalisco.',
                phone: 'Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37  ',//Whatsapp: 3313207614
                email: 'ventas@perezdelamora.com',
                maps: 'https://goo.gl/maps/EnuZQ576RRD9sCLx9',
            },
        ]
    },

    claim: {
        active: false,
    },

    termPage: {
        active: false,
    },

    questions: {
        active: false,
        bannerImage: require('../../images/perez/preguntasFrecuentes/banner-preguntas.jpg'),
    },

    itemsList: {
        backgroundColor: 'rgba(255,255,255, 0.5)',
        textColor: 'rgba(0,0,0, 0.8)',
        textColor2: '#C55930',
        iconColor: '#a91c17',
        textPrice: '#C55930',
        textPrice2: '#666666',
    },

    shoppingList: {
        productList: '#000',
        textProductList: 'white',
        summaryList: '#122759',
        textsummaryList: 'white'
    },

    pdf: {
        VERterminosCondiciones: false,
        terminosCondiciones: 'Términos_y_condiciones_IRCO.pdf',
        VERpoliticasVentas: false,
        politicasVentas: 'politicasVentas.pdf',
        VERpoliticasEnviosGarantias: false,
        politicasEnviosGarantias: 'Términos_de_envío_IRCO.pdf',
        VERpoliticasGarantia: false,
        politicasGarantia: 'Política_de_Garantía_y_Devoluciones_IRCO.pdf',
        VERpoliticasUsoAvisoPrivacidad : false,
        politicasUsoAvisoPrivacidad: 'politicasUsoAvisoPrivacidad.pdf',
        politicasRecompensas: 'politicasRecompensa.pdf',
        politicasFooter: 'politicasFooter.pdf',
        nosotros: 'nosotros.pdf',
    },

    footer: {
        icon: require('../../images/perez/footer/iconLogo.svg'),
        power: require('../../images/perez/footer/logofmb.png'),
        backgroundColor: '#F07C00',
        iconColor: '#666666',
        textColor: '#fff',
        colorP: '#C55930',
        /*Cambia la informacion del footer */
        info: {
            Icon30:  require('../../images/perez/footer/logofmb.png'),
            // hoursIcon: require('../../images/perez/footer/clock-regular.svg'),
            // hours: ' El horario de atención es de 8 a 5:30pm de lunes a viernes y de 8 a 1pm los sábados.',//Sábado 9:00 am a 2:00 pm
            calendarIcon: require ('../../images/perez/footer/envelope-regular.svg'),
            calendar: "Lunes a Viernes",
            emailIcon: require('../../images/perez/footer/envelope-regular.svg'),
            email: 'ventas@perezdelamora.com',
            phoneIcon: require('../../images/perez/footer/phone-alt-solid.svg'),
            phone: '(01 34) 1413-55-99',
            phoneWhats: '(01 34) 1410-53-37',
            linkPhoneWhats: '',
            // "https://wa.me/message/ZPC6FBIMZR2ME1",
            addressIcon: 'fas fa-2x fa-location-arrow',
            address: 'Calle Ramon Corona 525, Ciudad Guzman Centro C.P. 49000, Ciudad Guzmán, Jalisco',
        },

        socialMedia2: [
            {
                visibility: false,
                //cambia el recurso al ultimo por su href y su recurso multimedia
                icon: require('../../images/perez/footer/youtube.svg'),
                linkref: 'https://www.youtube.com/channel/UCKXH3Y4ePOk_hnN3tnmHPYQ',
            },
            {
                visibility: true,
                icon: require('../../images/perez/footer/instagram.svg'),
                linkref: 'https://www.instagram.com/perezdelamora/',
            },
            {
                visibility: true,
                icon: require('../../images/perez/footer/facebook.svg'),
                linkref: 'https://www.facebook.com/PerezdelaMoraFerreteria',
            },
            {
                visibility: false,
                icon: 'fab fa-4x fa-twitter',
                linkref: 'https://twitter.com/gruporedhogar',
            },
            {
                visibility: false,
                icon: require('../../images/perez/footer/whatsap.png'),
                linkref: 'https://api.whatsapp.com/send?phone=%2B523411462554&data=ARDOhG-s1vyySkxPvdEJNF5pdW-mylipLTriPyPousn9zCwTnCTWsHBJLCeYL7wAHP9XuOH6bL6Kg7MsM0OVOsL6KSICDm-woPe9wIRN3YKsFWucnnDeTm1HI-uJkIQ5XqYWgs3onqkyIAlFztc0sdUFgg&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR2hYqsDWyyXVpmrfMn_ADOOd7QSqwtEtzU8mqyEj9geVz65YiWUIesMIDc',
            },
            {
                visibility: true,
                icon: require("../../images/perez/footer/linkedin.svg"),
                linkref: 'https://www.linkedin.com/company/perez-de-la-mora/',
            },
        ]
    },

    paymentMethod: {
        paypal: {
            enable: true,
            clienId: 'AcgfSWjvFifar4P_3alUSPkhf0Z5g21gs1ieaFCF0HoFojz5GnYxYFE5L-uIH4tZEqWffcTzN_jnqf6l',
            //clienId: 'Aa2jAA2wV-jR9wXcDcaBnAatmI5nbANCx0TaQks_UWDAEKWAkuRwEvCap3krt7my-8FciPFo73rbOCJH',
        },
        transbank: {
            enable: false,
        },
        mercadoPago:{
            enable:true,
        },
        openPay:{
            enable:true,
        }
    },

    privacy:{
        banner: require('../../images/perez/avisoPrivacidad/avisoprivacidad.png'),
    },

    recompensas:{
        banner : require('../../images/perez/AboutRecompensas/banner.png'),
        card : require('../../images/perez/card.png'),
        vitrina1 : require('../../images/perez/vitrinaRedCompensas/icon-10.png'),
        vitrina2 : require('../../images/perez/vitrinaRedCompensas/icon-11.png'),
        vitrina3 : require('../../images/perez/vitrinaRedCompensas/icon-12.png'),
    },
    Avatar:{
        bronze1 : require('../../images/perez/Avatar/bronze1.png'),
        silver1 : require('../../images/perez/Avatar/silver1.png'),
        gold1 : require('../../images/perez/Avatar/gold1.png'),
        platinum1 : require('../../images/perez/Avatar/platinum1.png'),
        diamond1 : require('../../images/perez/Avatar/diamond1.png'),
        mercado1 : require('../../images/perez/Avatar/mercado1.png'),
        red1 : require('../../images/perez/Avatar/AVATAR.png'),

        bronze2 : require('../../images/perez/Avatar/bronze2.png'),
        silver2 : require('../../images/perez/Avatar/silver2.png'),
        gold : require('../../images/perez/Avatar/gold2.png'),
        platinum2 : require('../../images/perez/Avatar/platinum2.png'),
        diamond2 : require('../../images/perez/Avatar/diamond2.png'),
        mercado2 : require('../../images/perez/Avatar/mercado2.png'),
        red2 : require('../../images/perez/Avatar/AVATAR-2.png'),
    },

    gif:{
        gif: require('../../images/perez/GIF-prueba.gif'),
    },

    platilla : {
        arhivoPlantila: require('../../images/perez/plantillaPDLM.xlsx'),
        iconoExcel: require('../../images/perez/footer/excel.svg'),
        color: 'green'   
    },
    modules:{
        points : false,
        pasarelaCarrito : false,
        redZone : true,
        redAliado : true,
        subirArchivoExcel : true,
        BolsaTrabajo : true,
        cupon : true,
        Comments : false,
        Devoluciones : true,
        footerRedAliado : false,
        autoAddShopping: true,
        Autorizaciones: true,
        hamburgesaCategorias: false, //Menu de hamburguesa
    },

    catalogo: [
        // {
        //     nombre: `Catálogo IRCO Commercial`,
        //     imagen: require('../../images/perez/catalogos/1.jpg'),
        //     pdf: '1.pdf',
        // },
        {
            nombre: `Catálogo Acero Ferretero 2020`,
            imagen: require('../../images/perez/catalogos/2.jpg'),
            pdf: '2.pdf',
        },
        {
            nombre: `Catálogo Packing 2020`,
            imagen: require('../../images/perez/catalogos/3.jpg'),
            pdf: '3.pdf',
        },
        {
            nombre: `Catálogo Jacto`,
            imagen: require('../../images/perez/catalogos/JACTO-CATALOGO.png'),
            pdf: 'CATALOGO_JACTO_2.pdf',
        },
        {
            nombre: `Catálogo Paskal`,
            imagen: require('../../images/perez/catalogos/CATALOGO-PASKAL.png'),
            pdf: 'CATALOGO_PASKAL.pdf', 
        },
        // {
        //     nombre: `Catálogo de Marcas`,
        //     imagen: require('../../images/perez/catalogos/PACKING-CATALOGO.png'),
        //     pdf: 'DÍPTICO_DE_MARCAS_IRCO.pdf',   
        // }
    ],

    // Activar o Desactivar funciones/secciones
    renderPaymentTerms: false,

    // //IP LOCAL Gus
    // BASE_URL:'http://localhost:3058/api',
    // ASSETS:'http://localhost:3058/api/uploads/',

    // IP PRUEBAS PEREZ
    BASE_URL: 'http://b1.ativy.mx:33244/api',
    ASSETS: 'http://b1.ativy.mx:33244/api/uploads/',
    
    // IP PRODUCTIVO PEREZ
    // BASE_URL: 'http://b1.ativy.mx:38244/api',
    // ASSETS: 'http://b1.ativy.mx:38244/api/uploads/',
    // IP PRODUCTIVO PEREZ - DOMINIO SEGURO
    // BASE_URL: 'https://perezdelamora.com:38244/api',
    // ASSETS: 'https://perezdelamora.com:38244/api/uploads/',
    localStorageNamed: local + 'E-commerce',
};