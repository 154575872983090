import React from "react";
import moment from "moment";
import { config } from "../libs/utils/Const";

class HistoryPrintFormat extends React.Component {
  render() {
    const { user, history } = this.props;
    let totalPrecioTotal = 0;
    //PDF Historial de compra
    return (
      <div style={{ pageBreakBefore: 'always', padding: "30px 50px" }}>
        <div className="d-flex flex-column" style={{ marginTop: "20px" }}>
          {/* Cabecera información */}
          <div className="d-flex flex-row">
            <div>
              <img
                style={{ width: "80%", height: "auto", maxWidth: 200 }}
                src={config.navBar.icon}
                alt="Logo"
              />
              <br />
              <div>
                <h2 class="mt-4 text-left font-weight-bold">
                  Perez de la Mora
                </h2>
                <h5 class="mb-2 text-left">Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000, Jalisco.</h5>
                <h5 class="text-left">ventas@perezdelamora.com</h5>
                <h5 className="text-left">Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37</h5>
              </div>
            </div>

            <div
              className="d-flex justify-content-end"
              style={{
                flexWrap: "wrap",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                content: "center",
                marginLeft: "auto"
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h2 style={{ fontWeight: "bold" }}>Historial de compra</h2>
              </div>

              {/* Tabla información de cliente */}
              <table style={{ width: "90%", borderCollapse: "collapse", textAlign: "center" }}>
                <thead>
                  <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                    <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                      Nº Cliente
                    </th>
                    <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                      Nombre
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        border: "0px solid #ddd",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                      className="font-weight-bold"
                    >
                      {user ? user.CardCode : 'N/A'}
                    </td>
                    <td
                      style={{ border: "0px solid #ddd", textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      {user ? user.CardName : 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Tabla información de N° Pedido y fecha */}
          {/* <table style={{ width: "90%", borderCollapse: "collapse" }}>
                <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Nº Pedido
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Fecha
                  </th>
                </tr>
                <tr>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {history ? history.DocEntry : 'N/A'}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {history ? historyDate
                      : "N/A"}
                  </td>
                </tr>
              </table> */}

          {/* Tabla información del vendedor y direcciones */}
          {/* <table style={{ width: "90%", borderCollapse: "collapse" }}>
                <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Vendedor
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Domicilio de factura
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Domicilio de entrega
                  </th>
                </tr>
                <tr>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc &&
                    doc.data &&
                    doc.data.header &&
                    doc.data.header.U_FMB_Handel_Creador
                      ? doc.data.header.U_FMB_Handel_Creador
                      : "N/A"}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc && doc.data && doc.data.header && doc.data.header.Address
                      ? doc.data.header.Address
                      : "N/A"}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {history? history.Address2 : 'N/A'}
                  </td>
                </tr>
              </table> */}
          {/* </div>
          </div> */}

          {/* Tabla contenido de historial de compras */}
          <div className="mt-5 d-flex flex-column bd-highlight text-center">
            <table className='text-center'>
              <tr className="text-white" style={{ backgroundColor: config.navBar.iconColor }}>
                <th>Fecha</th>
                <th>No. pedido</th>
                <th>ID del artículo</th>
                <th>Artículo</th>
                <th>Marca</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>% Descuento</th>
                <th>Precio con descuento</th>
                <th>Precio con IVA</th>
              </tr>

              {!!history &&
                history.map((item, index) => {
                  const precioTotalItem = parseFloat(item.Price * 1.16);
                  totalPrecioTotal += precioTotalItem;
                  return (
                    <tr key={index}>
                      <th>{moment(item.DocDate).utc().format('YYYY-MM-DD')}</th>
                      <th>{item.DocNum ? item.DocNum : '--'}</th>
                      <th>{item.ItemCode ? item.ItemCode : '--'}</th>
                      <th>{item.ItemName ? item.ItemName : 'N/A'}</th>
                      <th>{item.U_Marca ? item.U_Marca : 'N/A'}</th>
                      <th>{item.Quantity ? parseInt(item.Quantity) : '--'}</th>
                      <th>{item.OriginalPrice ? '$ ' + parseFloat(item.OriginalPrice).toFixed(2) + ' MXN' : '--'}</th>
                      <th>{item.DiscPrcnt ? parseInt(item.DiscPrcnt) + '%' : '--'}</th>
                      <th>{item.Price ? '$ ' + parseFloat(item.Price).toFixed(2) + ' MXN' : '--'}</th>
                      <th>{item.Price ? '$ ' + parseFloat(item.Price * 1.16).toFixed(2) + ' MXN' : '--'}</th>
                    </tr>
                  );
                })}
            </table>
          </div>

          {/* Tabla total de total */}
          {/* <div className="d-flex justify-content-end mt-5 text-center">
            <table className="font-weight-bold">
              <tr>
                <th
                  className="text-white p-2"
                  style={{ backgroundColor: config.navBar.iconColor }}
                >
                  Subtotal
                </th>
                <td>
                  {totalPrecioTotal
                    ? `$ ${totalPrecioTotal.toFixed(2)} MXN`
                    : '--'
                  }
                </td>
              </tr>
              <tr>
                <th
                  className="text-white"
                  style={{
                    backgroundColor: config.navBar.iconColor,
                  }}
                >
                  IVA 16%
                </th>
                <td className="font-weight-bold">
                  {totalPrecioTotal
                    ? `$ ${(totalPrecioTotal * 0.16).toFixed(2)} MXN`
                    : '--'
                  }
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0px solid #ddd",
                    backgroundColor: config.navBar.iconColor,
                  }}
                  className="text-center text-white font-weight-bold"
                >
                  Total
                </th>
                <td className="font-weight-bold">
                  {totalPrecioTotal
                    ? `$ ${(((totalPrecioTotal) + (totalPrecioTotal * 0.16)).toFixed(2) + " MXN")}`
                    : '--'
                  }
                </td>
              </tr>
            </table>
          </div> */}
        </div>
      </div>
    );
  }
}

export default HistoryPrintFormat;