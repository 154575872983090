import React, { Component } from 'react';
import { config, SERVICE_API, DISPATCH_ID } from "../libs/utils/Const";
import { ApiClient } from "../libs/apiClient/ApiClient";
import $ from 'jquery';
import { connect } from 'react-redux';

let apiClient = ApiClient.getInstance();

class PadresModal extends Component {
    
    Close = ()=>{
        $('#PadresModal').modal('hide');
    }

    render() {
        const { Father } = this.props;
        return (
            <div className="modal fade" id="PadresModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: 'center' }}>
                <div className="modal-dialog modal-lg" role="modal" style={{ margin: '1.75rem auto' }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: config.navBar.iconColor }}>
                            <h5 className="modal-title" style={{ color: config.navBar.textColor2 }}>Plantilla de Padres Agregados</h5>
                            <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3" style={{overflowY:'scroll', maxHeight:'600px'}}>
                            {Father && Father?.map(Father => {
                                return (
                                    <div key={Father.CODE} className="row mb-3" style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
                                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                                            <img className="img-fluid" style={{ backgroundColor: 'white', maxHeight: 80 }} alt="imagen de padres" 
                                                src={Father.IMAGES ? (config.BASE_URL + SERVICE_API.getImage + '/images' + Father.IMAGES) : require('../images/noImage.png')} />
                                        </div>
                                        <div className="col-md-3 d-flex flex-column justify-content-center">
                                            <span style={{fontSize:'13px', fontWeight: 'bolder'}}>Nombre del Padre: </span>
                                            <span style={{fontSize:'13px'}}>{Father.NAME}</span>
                                        </div>
                                        <div className="col-md-3 d-flex flex-column justify-content-center">
                                            <span style={{fontSize:'13px', fontWeight: 'bolder'}}>Estado: </span>
                                            <span style={{fontSize:'13px'}}>{Father.ENABLED === "1" ? "Activo" : "Inactivo"}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="modal-footer">
                            <button style={{background: config.navBar.iconColor, color:'white'}} type="button" className="btn" onClick={this.Close}>Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemDetailsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PadresModal);