import React, { Component } from 'react';
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID, ROLES} from '../libs/utils/Const';
import {connect} from "react-redux";
import ReactHover, { Trigger, Hover } from "react-hover";
import {ItemDetailsModal} from "./index";
import CurrencyFormat from 'react-currency-format';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ItemSlider.css';
import './card.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import moment from 'moment';
import $ from "jquery";

// definir las variables responsivas para el carrusel
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1920 },
        items: 5,
        partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
    },
    desktop2: {
        breakpoint: { max: 1920, min: 1200 },
        items: 4,
        partialVisibilityGutter: 0 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1200, min: 992 },
      items: 3,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet1: {
      breakpoint: { max: 992, min: 767 },
      items: 3,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet2: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
      },
    mobile: {
      breakpoint: { max: 463, min: 0 },
      items: 1,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
}

class CarouselPodriaInteresarte extends Component {

    constructor(props){
        super(props);
        this.state = {
            seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
            renderStock: false,
            renderInfoDiscount: false,
        };
        this.mouseOverAddCart = this.mouseOverAddCart.bind(this);
        this.mouseOutAddCart = this.mouseOutAddCart.bind(this);
        this.iconMouse = [];     
        this.iconMouseOver = []; 
    }

    changeQuantity = (index, item, event) =>{
        const {items1,changeQuantity, notificationReducer: {showAlert},shoppingCartReducer: { isDevolution }} = this.props; // Revisar variable items1 con item de changLocalQuantity
        const itemsSearch = items1;
        if(isDevolution){
            showAlert({ type: 'error', message: "Tienes articulos pendientes para devolver", timeOut: 3000 });
            return;
        }
        let newQuantity ;
        let onHand = item.OnHand;
        let OnHandPrincipal = item.OnHandPrincipal;       
        itemsSearch.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
               newQuantity =  Number(event.nativeEvent.target.value) || 1 ;
               itemFilter.quantity = newQuantity;
            }
        });
        if(newQuantity){
            if(OnHandPrincipal >= newQuantity  && onHand >= newQuantity){
                showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });
            }else if( OnHandPrincipal < newQuantity  && onHand >= newQuantity ){
                showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });
                showAlert({ type: 'warning', message: "Sugerencia cambiar a otro almacen.", timeOut: 4000 });
            }else{
                showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });
                showAlert({ type: 'warning', message: "Articulo sujeto a disponibilidad de proveedores.", timeOut: 4000 });
            }
            changeQuantity(index,item, newQuantity, true); // add
        }
    };

    changLocalQuantity = (index, item, event)=>{
        const {items1: itemsSearch} = this.props;
        let newQuantity =  event.nativeEvent.target.value;
        itemsSearch.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                // if(newQuantity >= 1 ){
                    itemFilter.quantity = newQuantity;
                // }
            }
        });
        this.applyFilters(itemsSearch);
    }

    // No aplica
    changeBackOrder= (item, addItem) => {
        const {itemsReducer : {deleteBackOrder, addBackOrder}} = this.props;
        if(addItem){
            addBackOrder({item, quantity: 1})
        }else{
            deleteBackOrder({item, deleteAll: false});
        }
    };
    
    applyFilters = data => {
        const {setItemsFilterSearch2} = this.props;
        setItemsFilterSearch2(data);
    };

    mouseOverAddCart = (index, flag, event)=>{
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "none";
            this.iconMouseOver[index].style.display = "block";
        }
    }

    mouseOutAddCart = (index, flag, event)=>{
        if(flag == 'green'){
            event.currentTarget.style.backgroundColor = "#89c33f";
            event.currentTarget.className = "btn btn-block botonAgregar"
        } else if(flag == 'yellow'){
            event.currentTarget.style.backgroundColor = "#efc964";
            event.currentTarget.className = "btn btn-block botonAgregarYellow"
        } else {
            event.currentTarget.style.backgroundColor = "#e27753";
            event.currentTarget.className = "btn btn-block botonAgregarAgotado"
        }
        // Íconos
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "block";
            this.iconMouseOver[index].style.display = "none";
        }
    }

    render() {
        const {items1, itemsReducer : { searchItemsFilter, updateFavorite, deleteShoppingCart, openItemDetails, whsGeneralName,whsGeneralType, getStockDetails, getInfoDiscount}, dashboard, sessionReducer, changeBackOrder, viewStock, getCarruseles} = this.props;
        const { seller, renderStock, renderInfoDiscount } = this.state;
        let valor = seller ? seller.U_FMB_Handel_Perfil : '0';
        let x = window.matchMedia("(max-width: 50px)")
        let locationStock = 'sliderReco2';
        return (
            <div>
                <ItemDetailsModal view={VIEW_NAME.ITEMS_VIEW}/>
                <div className="SliderItems">
                <Carousel autoPlay partialVisible={false} responsive={responsive} infinite={true} autoPlaySpeed={500000} removeArrowOnDeviceType={["tablet", "mobile", "tablet1", "tablet2"]} >                      
                        {items1 && items1.map((item, index) => {
                            item.ItemName = item.ItemName || '';
                            let dashboardForTwoSliders = dashboard ? dashboard : '';
                            let imagesArray = item.U_Handel_ImagesArray || '';
                            imagesArray = imagesArray.split('|');
                            const quantity = item.quantity ?? 1;
                            let imagenShow = item.PicturName ||  imagesArray[0] ? (config.BASE_URL + SERVICE_API.getImage + '/' + (item.PicturName ? item.PicturName :imagesArray[0]) ) : require('../images/noImage.png');
                            return (
                                <div id="ItemsSlider2" style={{ display:"flex", justifyContent:"center", maxWidth:"20rem", maxHeight:"45rem", position:"relative",paddingLeft:"1rem",paddingRight:"1rem", paddingBottom:'1rem',  border:"none !important"}} key={index}>
                                    <div className="item card card-container-all" style={{ position: "relative", width: "380px", marginTop: "4px", minHeight: "450px", backgroundColor: config.itemsList.backgroundColor, border: "solid !important" }}>
                                        <div className="imageCard">
                                            <div style={{display: (!item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 ? 'none' : 'table'), position:"relative"}}>
                                                <div className="font-weight-bold ribbon" style={{position: "absolute"}} onClick={async() => {await openItemDetails(item); await viewStock(); await getCarruseles(item.ItemCode, item.U_SubDepartamento, item.U_Division)}}>
                                                    <span>
                                                        <blink>{item.isDestacado ? 'DESTACADO' : 'PROMOCIÓN'}</blink>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-center container-img'>
                                                {item.Discount && item.Discount > 0 && item.FinalPrice && (item.U_web !== 0) ?
                                                    <div className='rounded' style={{ position: 'absolute', backgroundColor: 'red', color: 'white', top: '5px', right: '10px', padding: '4px', marginTop:'5px'}}>
                                                        {item.Discount + '% OFF'}
                                                    </div>
                                                    : <></>
                                                }
                                                {item.Discount && item.Discount > 0 && item.FinalPrice && (item.U_web !== 0) ?
                                                    <div className='rounded' style={{ position: 'absolute', top: '45px', right: '10px', padding: '4px', marginTop: '5px' }}>
                                                        <ReactHover options={true} >
                                                            <Trigger type="trigger">
                                                                <img
                                                                    src={config.icons.discountIcon}
                                                                    width={'40px'}
                                                                    height={'40px'}
                                                                    alt='Icono Descuento'
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={async (e) => { await getInfoDiscount(item.ItemCode, locationStock); await this.setState({ renderInfoDiscount: true }); }}
                                                                />
                                                            </Trigger>
                                                            {renderInfoDiscount && (item.flagInfoDiscount ? item.flagInfoDiscount : false) &&
                                                                <Hover type="hover"  >
                                                                    <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-90px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                        <p className="card-title text-left" style={{ fontSize: 13, color: 'black' }}>
                                                                            <span style={{ fontWeight: 'bold' }}> Tipo de Descuento: </span> <span>{item.infoDiscount.typeDiscount || ''}</span><br></br>
                                                                            <span style={{ fontWeight: 'bold' }}> Descuento: </span> <span>{item.infoDiscount.Discount || ''} %</span>
                                                                        </p>
                                                                        {item.infoDiscount.Quantities && item.infoDiscount.Quantities?.length > 0 ?
                                                                            <>
                                                                                <p className="card-title text-left" style={{ fontSize: 13, color: 'black' }}>
                                                                                    <span style={{ fontWeight: 'bold' }}> Información del descuento.</span>
                                                                                </p>
                                                                                {item.infoDiscount.Quantities.map((item, index) => (
                                                                                    <p key={index} className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'black' }}>
                                                                                        <span style={{ fontWeight: 'bold' }}>Cantidad Min: </span><span>{item.Amount}</span><br></br>
                                                                                        <span style={{ fontWeight: 'bold' }}>Descuento: </span><span>{`${item.Discount} %`}</span><br></br>
                                                                                        <span style={{ fontWeight: 'bold' }}>Precio </span><span>
                                                                                            <CurrencyFormat
                                                                                                value={Number(item.Price || 0) * 1.16}
                                                                                                displayType={'text'}
                                                                                                decimalScale={2}
                                                                                                thousandSeparator={true}
                                                                                                fixedDecimalScale={true}
                                                                                                prefix={'$ '}
                                                                                                suffix={' ' + config.general.currency}
                                                                                            >
                                                                                            </CurrencyFormat>
                                                                                        </span>
                                                                                    </p>
                                                                                ))}
                                                                            </>
                                                                            : <></>
                                                                        }
                                                                    </div>
                                                                </Hover>
                                                            }
                                                        </ReactHover>
                                                    </div>
                                                    : <></>}
                                                <img
                                                    onClick={async() => {await openItemDetails(item); await viewStock(); await getCarruseles(item.ItemCode, item.U_SubDepartamento, item.U_Division)}}
                                                    className="card-img-top cardImg"
                                                    style={{
                                                        height: "150px" ,
                                                        width:"150px", // Ajusta el ancho deseado para el modo lista
                                                        backgroundColor: "transparent",
                                                        borderTopRightRadius: "10px",
                                                        cursor: "pointer",
                                                        marginRight: "auto",
                                                        marginLeft: "auto",
                                                        padding: 0,
                                                        objectFit: "cover",
                                                    }}
                                                    // src={config.shoppingCartIcons.imagenDefecto}
                                                    src={imagenShow}
                                                    alt="Imagen del articulo"
                                                />
                                            </div>
                                            
                                            <div className="card-body" id={'idCardBody'+index}  style={{ height: "fit-content", margin: 0, minHeight: "180px", color: config.itemsList.textColor/*margin: 0, padding: 0, color: config.itemsList.textColor*/ }}>
                                            <div>
                                                    <p className="card-subtitle" style={{ fontSize:19, color: config.itemsList.textPrice2 }}>
                                                        {item.U_web === 0 || item.U_web === null
                                                            ? <h1 class="quote-h1">Solicite su cotización</h1>
                                                            :
                                                            <>
                                                                {item.Discount && item.Discount > 0 && item.FinalPrice && (item.U_web !== 0) ?
                                                                    <>
                                                                        <div className='row d-flex justify-content-start aling-items-center'>
                                                                            <span style={{ color: 'red', fontSize: '12px' }}>Antes</span>
                                                                            <span className="font-weight-bold" style={{ fontSize: 14, color: "#666666", textDecoration: 'line-through', marginLeft: '5px' }} >
                                                                                <CurrencyFormat
                                                                                    value={item.U_SubDepartamento === "PIS-05" && item.U_M2 ? item?.OriginalPriceTax / item.U_M2 : item?.OriginalPriceTax || 0}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix={'$ '}
                                                                                    suffix={' ' + item.Currency}>
                                                                                </CurrencyFormat>
                                                                                {item.U_SubDepartamento === "PIS-05" && item.U_M2 ? <span style={{ marginLeft: '12px', color: '#89c33f' }}>m²</span> : <></>}
                                                                            </span>
                                                                        </div>
                                                                        <h1 class="price">
                                                                            <CurrencyFormat
                                                                                value={item.U_SubDepartamento === "PIS-05" && item.U_M2 ? item?.FinalPriceTax / item.U_M2 : item?.FinalPriceTax || 0}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={'$ '}
                                                                                suffix={' ' + item.Currency}>
                                                                            </CurrencyFormat>
                                                                            {item.U_SubDepartamento === "PIS-05" && item.U_M2 ? <span style={{ marginLeft: '12px', color: '#89c33f' }}>m²</span> : <></>}
                                                                        </h1>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div>
                                                                            <h1 className="price card-subtitle" >
                                                                                <CurrencyFormat
                                                                                    value={item.PriceBeforeDiscount}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix={'$'}
                                                                                    suffix={' ' + item.Currency}>
                                                                                </CurrencyFormat>
                                                                            </h1>
                                                                            {item.DiscountPercentSpecial ?
                                                                                <span style={{ fontSize: 10 }} className="text-danger">{parseFloat(item.DiscountPercentSpecial || 0).toFixed(2)}% OFF</span>
                                                                                : ""
                                                                            }
                                                                        </div>
                                                                        <h1 className="price card-subtitle" >
                                                                            <CurrencyFormat
                                                                                value={item.Price * 1.16}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={'$'}
                                                                                suffix={' ' + item.Currency}>
                                                                            </CurrencyFormat>
                                                                        </h1>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    {item.U_web === 1 &&
                                                        " " 
                                                    }
                                                </p>
                                                {/* {seller && seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ?
                                                    <div style={{ position: 'relative' }} >
                                                        <ReactHover  options = { true } >
                                                            <Trigger type="trigger">
                                                                <div style={{ background: config.navBar.iconBackground, width: '50px', borderRadius: 21, padding: 7 }}> <i class="fa fa-dropbox fa-2x" aria-hidden="true" style={{color: 'white'}}></i> </div>
                                                            </Trigger>
                                                                    <Hover type="hover"  >
                                                                        <div style={{background: '#efefef',padding: '8px 8px 10px 10px', borderRadius: 18 , zIndex:'1000000', position: 'relative', top: '-154px', left: '-210px', width: '250px' }}>
                                                                            <p className="card-title text-left font-weight-bold" style={{ fontSize: 15, color:'rgb(0 0 0 / 69%)' }}>
                                                                                <span> Stock : </span> <span    >{item.OnHand || '0'}</span>
                                                                            </p>
                                                                            <p className="card-title text-left font-weight-bold" style={{ paddingTop: 5 , fontSize: 15, color:'rgb(0 0 0 / 69%)' }}>
                                                                                <span> Stock almacen { whsGeneralName ? `'${whsGeneralName}'` : `'defecto'`} : </span><span  >{item.OnHandPrincipal || '0'}</span>
                                                                            </p>
                                                                        </div>
                                                                    </Hover>
                                                        </ReactHover>
                                                    </div>
                                                    :
                                                    <div className="icoCarrito col-2 text-center align-middle" style={{ fontSize: 33, padding: 0, textAlign: "right" }}>
                                                        <img ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() + index] = iconMouse} src={ Number(item.OnHandPrincipal) >= Number(item.quantity) && Number(item.OnHandPrincipal) > 1 ? config.shoppingCartIcons.carritoVerde :  Number(item.OnHand) >=  Number(item.quantity) ? config.shoppingCartIcons.carritoAmarillo : config.shoppingCartIcons.carritoAgotado } style={{ display: "block", cursor: "pointer" }} />
                                                    </div> 
                                                } */}
                                            </div>
                                                <div className="overflow-auto ItemName">
                                                    {/* <p className="card-subtitle text-left" style={{ fontSize:15, color: config.itemsList.textPrice2 }}>
                                                        {item.U_web === 0 || item.U_web === null
                                                            ? "Solicite su cotización"                                                            
                                                                : <>
                                                                    <div style={{textAlign:'right'}}>
                                                                        <span className="card-subtitle text-left" style={{ fontSize:15, color: config.itemsList.textPrice2 }} >
                                                                            <CurrencyFormat
                                                                                value={item.PriceBeforeDiscount}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={'$'}>
                                                                            </CurrencyFormat>
                                                                        </span>
                                                                        {item.DiscountPercentSpecial ? 
                                                                                <span style={{fontSize:10 }} className = "text-danger">{parseFloat(item.DiscountPercentSpecial || 0).toFixed(2)}% OFF</span>
                                                                            : ""
                                                                        }
                                                                    </div>
                                                                    <span className="card-subtitle text-left  precioPromocionItemList">
                                                                        <div className="pricePromoItemSlider" style = {{fontSize: 15}}>
                                                                            <CurrencyFormat
                                                                                value={item.Price}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={'$'}>
                                                                            </CurrencyFormat>
                                                                        </div>
                                                                    </span>
                                                                </>
                                                        }
                                                        {item.U_web === 1 &&
                                                            " " 
                                                        }
                                                    </p> */}
                                                    <p className="card-title text-left ItemName" style={{ padding: 1, fontSize: 13, fontWeight: 600, overflowWrap: 'break-word', maxWidth: '170px' }}>
                                                        {/* {item.ItemName ? (item.ItemName).length > 32 ? (item.ItemName).substring(0, 31) + " ... ": item.ItemName : " "} */}
                                                        {item.ItemName}
                                                    </p>
                                                </div>
                                                <span className="nameItemCardList">
                                                    <div>
                                                        <div>
                                                            <div className='container-info'>
                                                                <h2 className='sku-h2'>SKU: {item.ItemCode}</h2> 
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{ paddingBottom: 10, border: 'none', marginTop: "21px", backgroundColor:'rgb(255 255 255)'/*borderTop: "transparent"*/ }}>
                                            <div className="row text-center" style={{ margin: 0, padding: 0 }}>
                                                {item.U_web === 1 && whsGeneralType === 1 ?
                                                        //sino
                                                        <div className="col-12" style={{padding:0}}>
                                                            <div className="row text-center" style={{ margin: 0, padding: 0 }}>
                                                                {seller && seller.empID !== "1" ? 
                                                                    <>
                                                                        <div className="col-3" style={{padding:0}}>
                                                                            <input
                                                                                id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                                type="number"
                                                                                min="1"
                                                                                max={Number(item.OnHandPrincipal) - 1}
                                                                                // value={ parseInt(item.quantity) >= 0 ? parseInt(item.quantity) : 1 }
                                                                                value={ Number(item.quantity) <1 ? "" : Number(item.quantity) }
                                                                                className="form-control"
                                                                                name={'quantity' + item.ItemCode}
                                                                                placeholder="1"
                                                                                style={{textAlign: "left", height: 40.5, width: 65}}
                                                                                onFocus={(event) => event.target.select()}
                                                                                onChange={(event) => {this.changLocalQuantity(dashboard.toString() + index, item, event)}}
                                                                                />
                                                                        </div>
                                                                        <div className="col-6 botonAgregar">
                                                                            <button style={{ textAlign: "center" }}
                                                                                type="button"
                                                                                className= { Number(item.OnHandPrincipal) >= Number(item.quantity ?? quantity) && Number(item.OnHandPrincipal) >= 1 ? "btn btn-block botonAgregar"  :  Number(item.OnHand) >=  Number(item.quantity ?? quantity) ? "btn btn-block botonAgregarYellow p-2" : "btn btn-block botonAgregarAgotado p-2"}  
                                                                                value={(item.quantity ? Number(item.quantity) : '1')}
                                                                                onClick={(event)=>{this.changeQuantity(index, item, event)}}
                                                                            >
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                :
                                                                    <>
                                                                        {/* {parseFloat(item.OnHand) > 0 && parseFloat(item.OnHandPrincipal) > 0 && ((seller && seller.empID === "1") || sessionReducer.role === ROLES.PUBLIC) ? */}
                                                                            <>
                                                                                <div className="col-3" style={{padding:0}}>
                                                                                    <input
                                                                                        id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                                        type="number"
                                                                                        min="1"
                                                                                        max={Number(item.OnHandPrincipal) - 1}
                                                                                        value={ Number(item.quantity) <1 ? "" : Number(item.quantity) }
                                                                                        className="form-control"
                                                                                        name={'quantity' + item.ItemCode}
                                                                                        placeholder="1"
                                                                                        style={{textAlign: "left", height: 40.5, width: 65}}
                                                                                        onFocus={(event) => event.target.select()}
                                                                                        onChange={(event) => {this.changLocalQuantity(dashboard.toString() + index, item, event)}}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-6 botonAgregar">
                                                                                    <button style={{ textAlign: "center" }}
                                                                                        type="button"
                                                                                        className= { Number(item.OnHandPrincipal) >= Number(item.quantity ?? quantity) && Number(item.OnHandPrincipal) >= 1 ? "btn btn-block botonAgregar"  :  Number(item.OnHand) >=  Number(item.quantity ?? quantity) ? "btn btn-block botonAgregarYellow p-2" : "btn btn-block botonAgregarAgotado p-2"}  
                                                                                        value={(item.quantity ? Number(item.quantity) : '1')}
                                                                                        onClick={(event)=>{this.changeQuantity(index, item, event)}}
                                                                                    >
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        {/* :
                                                                            <div style={{ padding: 0 }}>
                                                                                <div className="btn-container">
                                                                                    <div className="without-stock" style={{backgroundColor: config.navBar.iconColor}}>
                                                                                        <h3 className="title-stock">No hay stock por ahora</h3>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        } */}
                                                                    </>                
                                                                }
                                    
                                                                <div>
                                                                    {/* {seller && seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ? */}
                                                                        <div style={{ position: 'relative' }} >
                                                                            <ReactHover  options = { true } >
                                                                                <Trigger type="trigger">
                                                                                <img
                                                                                    src={config.icons.stock}
                                                                                    width={'40px'}
                                                                                    height={'40px'}
                                                                                    alt='Icono stock'
                                                                                    style={{ cursor: 'pointer' }} // Add this line to set the cursor style
                                                                                    onClick={async(e) => {await getStockDetails(item.ItemCode, locationStock); await this.setState({renderStock: true});}}                                                 
                                                                                />
                                                                                </Trigger>
                                                                                {renderStock && (item.flagStock ? item.flagStock : false)  && 
                                                                                    <Hover type="hover"  >
                                                                                        {item.whsCodes && item.whsCodes.length > 0 ?
                                                                                            <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-135px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                                                <p className="card-title text-left" style={{ fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                    <span style={{ fontWeight: 'bold' }}> Stock : </span> <span>{parseInt(item.OnHand) || '0'}</span>
                                                                                                </p>
                                                                                                {item.whsCodes.map((item, index) => (
                                                                                                    <p key={index} className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                        <span style={{ fontWeight: 'bold' }}> Almacén {item.WhsName ? `'${item.WhsName}'` : `'N/A'`} : </span><span>{parseInt(item.Stock) || '0'}</span>
                                                                                                    </p>
                                                                                                ))}
                                                                                            </div>
                                                                                            :
                                                                                            <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-135px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                                                <p className="card-title text-left" style={{ fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                    <span style={{ fontWeight: 'bold' }}> Stock: </span> <span>{parseInt(item.OnHand) || '0'}</span>
                                                                                                </p>
                                                                                                <p className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                    <span style={{ fontWeight: 'bold' }}> Almacén {whsGeneralName ? `'${whsGeneralName}'` : `'defecto'`} : </span><span>{parseInt(item.OnHandPrincipal) || '0'}</span>
                                                                                                </p>
                                                                                            </div>
                                                                                        }
                                                                                    </Hover>
                                                                                }
                                                                            </ReactHover>
                                                                        </div>
                                                                        {/* :
                                                                        <>
                                                                            {((seller && seller.empID === "1") || sessionReducer.role === ROLES.PUBLIC)  && parseFloat(item.OnHand) > 0 && parseFloat(item.OnHandPrincipal) > 0?
                                                                                <div className="icoCarrito col-2 text-center align-middle" style={{ fontSize: 33, padding: 0, textAlign: "right" }}>
                                                                                    <img ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() + index] = iconMouse} src={ Number(item.OnHandPrincipal) >= Number(item.quantity ?? quantity) && Number(item.OnHandPrincipal) >= 1 ? config.shoppingCartIcons.carritoVerde :  Number(item.OnHand) >=  Number(item.quantity ?? quantity) ? config.shoppingCartIcons.carritoAmarillo : config.shoppingCartIcons.carritoAgotado } style={{ display: "block", cursor: "auto" }} />
                                                                                </div> 
                                                                        :''}
                                                                        </>
                                                                    } */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                :
                                                    <>
                                                        {item.U_web === 1 ?
                                                            <div>
                                                                {/* {seller && seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ? */}
                                                                    <div style={{ position: 'relative' }} >
                                                                        <ReactHover  options = { true } >
                                                                            <Trigger type="trigger">
                                                                                <img
                                                                                    src={config.icons.stock}
                                                                                    width={'40px'}
                                                                                    height={'40px'}
                                                                                    alt='Icono stock'
                                                                                    style={{ cursor: 'pointer' }} 
                                                                                    onClick={async(e) => {await getStockDetails(item.ItemCode, locationStock); await this.setState({renderStock: true});}}
                                                                                />
                                                                            </Trigger>
                                                                            {renderStock && (item.flagStock ? item.flagStock : false)  && 
                                                                                <Hover type="hover"  >
                                                                                    {item.whsCodes && item.whsCodes.length > 0 ?
                                                                                        <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-135px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                                            <p className="card-title text-left" style={{ fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                <span style={{ fontWeight: 'bold' }}> Stock : </span> <span>{parseInt(item.OnHand) || '0'}</span>
                                                                                            </p>
                                                                                            {item.whsCodes.map((item, index) => (
                                                                                                <p key={index} className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                    <span style={{ fontWeight: 'bold' }}> Almacén {item.WhsName ? `'${item.WhsName}'` : `'N/A'`} : </span><span>{parseInt(item.Stock) || '0'}</span>
                                                                                                </p>
                                                                                            ))}
                                                                                        </div>
                                                                                        :
                                                                                        <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-135px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                                            <p className="card-title text-left" style={{ fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                <span style={{ fontWeight: 'bold' }}> Stock: </span> <span>{parseInt(item.OnHand) || '0'}</span>
                                                                                            </p>
                                                                                            <p className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                <span style={{ fontWeight: 'bold' }}> Almacén {whsGeneralName ? `'${whsGeneralName}'` : `'defecto'`} : </span><span>{parseInt(item.OnHandPrincipal) || '0'}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                </Hover>
                                                                            }
                                                                        </ReactHover>
                                                                    </div>
                                                                    {/* :
                                                                    <div className="icoCarrito col-2 text-center align-middle" style={{ fontSize: 33, padding: 0, textAlign: "right" }}>
                                                                        <img ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() + index] = iconMouse} src={ Number(item.OnHandPrincipal) >= Number(item.quantity ?? quantity) && Number(item.OnHandPrincipal) >= 1 ? config.shoppingCartIcons.carritoVerde :  Number(item.OnHand) >=  Number(item.quantity ?? quantity) ? config.shoppingCartIcons.carritoAmarillo : config.shoppingCartIcons.carritoAgotado } style={{ display: "block", cursor: "pointer" }} />
                                                                    </div> 
                                                                } */}
                                                            </div>

                                                        : ''}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUser: value => dispatch({type: DISPATCH_ID.LOGIN_SET_USER, value}),
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsFilterSearch2: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER2, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CarouselPodriaInteresarte);