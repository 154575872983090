import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { ApiClient } from "../libs/apiClient/ApiClient";
import OrderPrintFormat from './OrderPrintFormat';
import AutoPrintFormat from './AutoPrintFormat';
import QuotationPrintFormat from './QuotationPrintFormat';
import DeliverysPrintFormat from './DeliverysPrintFormat';
import SavedCartsPrintFormat from './SavedCartsPrintFormat';
import InvocesPrintFormat from './InvocesPrintFormat';
import ExpiredInvoicesPrintFormat from './ExpiredInvoicesPrintFormat';
import CollectPrintFormat from './CollectPrintFormat';
import GeneralOrdersPrintFormat from './GeneralOrdersPrintFormat';
import HistoryPrintFormat from './HistoryPrintFormat';
import ReturnPrintFormat from './ReturnPrintFormat';
import ReturnTwoPrintFormat from './ReturnTwoPrintFormat';
import QuotationPrintFormat1 from './QuotationPrintFormat1';
import Return2Format from './Return2Format';
import { jsPDF } from 'jspdf';
//import AccountStatusPrintFormat from './AccountStatusPrintFormat';

import { connect } from 'react-redux';
import { config, DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE } from "../libs/utils/Const";

const apiClient = ApiClient.getInstance();
let docs = []
const ExportReportGeneral = ({newTitle, data, itemsGift, items, address, bill, enableSpinner, notificationReducer: { showAlert }, typeDocs, view, collect, orders, seller, devolution, message, messageCancelación, fechaActual, user, currentCart, date, currentCart1, date1, orderGeneral, customer, historyP, general, history, savedCart, isForSend }) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSendPDF = async () => { //Enviar pdf por correo
    enableSpinner(true);
    try {
        let info = {
          data: typeDocs === 'collect' ? data : docs || [],
          title: newTitle || '',
        }
        let response = await apiClient.sendPDFEmail(info);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
          showAlert({ type: 'success', message: response.message })
        } else {
          showAlert({ type: 'error', message: response.message })
        }
    } catch (error) {
      enableSpinner(false);
      console.error('Error al enviar el PDF por correo. Error:', error);
    }
  };

  const getData = async (key) => {
    let response = ""
    enableSpinner(true);
    // if(key === "accountStatus"){ docs.push(data); }
    for (let index = 0; index < data.length; index++) {
      switch (key) {
        case "orders":
          response = await apiClient.getOrder(data[index]);
          break;
        case "quotations":
          response = await apiClient.getDataQuotation(data[index]);
          break;
        case "auto":
          response = await apiClient.getDataPreliminary(data[index]);
          break;
        case "deliverys":
          response = await apiClient.getDataDelivery(data[index]);
          break;
        case "savedCarts":
          response = await apiClient.getDataProduct(data[index]);
          break;
        case "invoices":
          response = await apiClient.getDataBill(data[index]);
          break;
        case "collect":
          let obj = {
            status: 1
          }
          response = obj;
          break;
        case "quotations2":
          response = data;
          break;
        case "generalOrders":
          response = await apiClient.getOrder(data[index]);
          docs.push(response); //Necesario para mostrar pdf
          break;
        // case "orders":
        //   response =  await apiClient.getOrder(data[index]);
        //   break;
        case "returns":
          response = await apiClient.getCreditNotesDetails(data[index]);
          break;
        case "returnRequest":
          response = await apiClient.getReturnRequestDetails(data[index]);
          break;
        case "returnsTwo":
          response = await apiClient.getDatailsAuto(data[index]);
        break;


        default:
          break;
      }
      if (key !== 'collect' && key !== 'history' && key !== 'generalOrders' && key !== 'quotations2' && /*key !== "returns" && key !== "returnRequest" &&*/ key !== "accountStatus") {
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
          docs.push(response)
        } else {
          showAlert({ type: 'error', message: response.message })
        }
      }
    }
    enableSpinner(false);
  }

  const print = async (typeDocs)=>{
    docs = []
    await getData(typeDocs);
    if (isForSend) {
      handleSendPDF();
    } else {
      handlePrint();
    }
  };

  const return2Print = (view) => {
    handlePrint();
  };

  return (
    <div>
      {view && view == 'validateOrder' ?
        <button
          onClick={() => print(typeDocs)}
          className="btn btn-block text-white"
          style={{
            backgroundColor: config.navBar.iconColor,
            color: config.navBar.textColorCategorieHover,
            fontWeight: "bold",
          }}>
          {isForSend ? 'Enviar Cotización por correo' : 'Imprimir Cotización'}
        </button>
        :
        view === 'return2' ?
          <button className="btn float-left impr mr-sm-2" style={{ background: config.navBar.iconColor, color: "white" }} onClick={() => return2Print(view)}>
            Devolver mercancía 2
          </button>
          :
          <button className="btn float-left impr mr-sm-2" style={{ marginTop: "15px", background: config.navBar.iconColor, color: "white" }} onClick={() => print(typeDocs)}>
            <img src={config.icons.report} alt="Imprimir" style={{ width: '15px', height: '15px', marginRight: '10px' }} />
            <span style={{ marginLeft: '10px' }}>{isForSend ? 'Enviar por correo' : 'Imprimir PDF'}</span>
          </button>
      }
      {!isForSend &&
      <div style={{ display: "none" }}>
        <div ref={componentRef} id='DocsToPrint2'>
          {/* {PDF Cotización despues de continuar compra} */}
          {typeDocs === "quotations2" &&
            <QuotationPrintFormat doc={data[0]} itemsGift={itemsGift} address={address} bill={bill} items={items} />
          }
          {/* {PDF Cobranza} */}
          {typeDocs === "collect" ?
            data.map((doc, index) => <React.Fragment key={index}> <CollectPrintFormat collect={doc} user={user} /> </React.Fragment>) : <></>
          }
          {/* {PDF Estado de cuenta} */}
          {/*typeDocs === "accountStatus" &&
            <AccountStatusPrintFormat doc={docs}/>
          */}
          {/* {PDF Historial de compra} */}
          {typeDocs === "history" &&
            <HistoryPrintFormat history={data} user={user} />
          }
          {/* {typeDocs === "returns" &&
            <ReturnPrintFormat returns={data} user={user} orders={orders} returnRequest={false}/>
          }
          {typeDocs === "returnRequest" &&
            <ReturnPrintFormat returns={data} user={user} orders={orders} returnRequest={true}/>
          } */}
          {view === 'return2' &&
            <Return2Format devolution={devolution} fechaActual={fechaActual} message={message} messageCancelación={messageCancelación}/>
          }

          {docs.map((doc, index) => {
            return (
              <React.Fragment key={index}>
                {/* {PDF Pedidos} */}
                {typeDocs === "orders" &&
                  <OrderPrintFormat doc={doc} orders={orders} />
                }
                {/* {PDF Oferta de venta} */}
                {typeDocs === "quotations" &&
                  <QuotationPrintFormat1 doc={doc} orders={orders} />
                }
                {/* {PDF Autorizaciones} */}
                {typeDocs === "auto" &&
                  <AutoPrintFormat doc={doc} orders={orders[index]} />
                }
                {/* {PDF Entregas} */}
                {typeDocs === "deliverys" &&
                  <DeliverysPrintFormat doc={doc} />
                }
                {/* {PDF Guardados} */}
                {typeDocs === "savedCarts" &&
                  <SavedCartsPrintFormat doc={doc} savedCart={savedCart[index]} user={user} seller={seller} />
                }
                {/* {PDF Facturas} */}
                {typeDocs === "invoices" &&
                  <InvocesPrintFormat doc={doc} />
                }
                {typeDocs === "expiredInvoices" &&
                  <ExpiredInvoicesPrintFormat doc={doc} />
                }
                {/* {PDF Pedidos Generales} */}
                {typeDocs === "generalOrders" &&
                  <GeneralOrdersPrintFormat doc={doc} general={general[index]} />
                }
                {typeDocs === "returnsTwo" &&
                  <ReturnTwoPrintFormat returns={doc} user={user}/>
                }
                {/* Notas de Crédito */}
                {typeDocs === "returns" &&
                  <ReturnPrintFormat returns={doc} user={user} returnRequest={false}/>
                }
                {/* Solicitudes de Devolución */}
                {typeDocs === "returnRequest" &&
                  <ReturnPrintFormat returns={doc} user={user} returnRequest={true}/>
                }
              </React.Fragment>
            )
          })}
        </div>
      </div>
      }
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    notificationReducer: store.NotificationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    enableSpinner: (value) =>
      dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportReportGeneral);