import { DISPATCH_ID } from '../../libs/utils/Const';

export const ConfigReducer = (state = { spinner: false, promiseCount: 0, history: null, logOut: null, beforeUnload: null,load: false, viewNavBar:true, currency: {}, packageFilter: [],idFamilyFilter: [], idCategoryFilter: [], idCategoriesMenu: [], idMarcasMenu: [] }, action,) => {
    switch (action.type) {
        case DISPATCH_ID.CONFIG_SET_SPINNER:
            const { promiseCount } = state;
            let count = 0;
            let enable = false;

            if (action.value) {
                count = promiseCount + 1;
                enable = action.value;
            } else {
                count = promiseCount - 1;
            }

            if (count === 0) {
                enable = action.value;
            }

            return Object.assign({}, state, { spinner: enable, promiseCount: count });
        case DISPATCH_ID.CONFIG_SET_HISTORY_REFERENCE:
            return Object.assign({}, state, { history: action.value });
        case DISPATCH_ID.CONFIG_SET_LOG_OUT_REFERENCE:
            return Object.assign({}, state, { logOut: action.value });
        case DISPATCH_ID.CONFIG_SET_CURRENCY:
            return Object.assign({}, state, { currency: action.value });
        case DISPATCH_ID.CONFIG_SET_BEFORE_UNLOAD_REFERENCE:
            return Object.assign({}, state, { beforeUnload: action.value });
        case DISPATCH_ID.CONFIG_SET_PACKAGE:
            return Object.assign({}, state, { packageFilter: action.value });
        case DISPATCH_ID.CONFIG_CLEAN_REDUCER:
            return Object.assign({}, state, { spinner: false, promiseCount: 0, history: null, logOut: null, beforeUnload: null });
        case DISPATCH_ID.CONFIG_SET_ID_FAMILY_FILTERS:
            return Object.assign({}, state, { idFamilyFilter: action.value });
        case DISPATCH_ID.CONFIG_SET_ID_CATEGORY_FILTERS:
            return Object.assign({}, state, { idCategoryFilter: action.value });
        // ######### REGION MENU NAVBAR ############
        case DISPATCH_ID.CONFIG_SET_CATEGORIES_MENU:
            return Object.assign({}, state, { idCategoriesMenu: action.value });
        case DISPATCH_ID.CONFIG_SET_MARCAS_MENU:
            return Object.assign({}, state, { idMarcasMenu: action.value});    
        // ######### FIN REGION ############
        case DISPATCH_ID.CONFIG_SET_PRELOAD_COMPONENTS:
            return Object.assign({}, state, {load: action.value});
        case DISPATCH_ID.CONFIG_SET_VIEW_NAVBAR:
            return Object.assign({}, state, {viewNavBar: action.value});
        default:
            return state;
    }
};