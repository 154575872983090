import React, { Component } from 'react';
import $ from 'jquery';
import { config } from "../libs/utils/Const";

const banks = [
    {
        name: "BANCOMER",
        branch: "448",
        account: "0132973049",
        clabe: "012342001329730492"
    },
    {
        name: "BANORTE",
        branch: "",
        account: "0489511655",
        clabe: "072342004895116558"
    },
    {
        name: "SANTANDER",
        branch: "4752",
        account: "92000518728",
        clabe: "014342920005187286"
    },
    {
        name: "BANAMEX",
        branch: "424",
        account: "5485625",
        clabe: "002342042454856252"
    },
    /*{
        name: "HSBC",
        branch: "824",
        account: "4018499004",
        clabe: "021342040184990047"
    }*/
];
class BankAccountsModal extends Component {

    componentDidMount() {
        this.closeAction();
    }

    closeConfirm = async () => {
        $('#bankAccounts').modal('hide');
        await this.removeBackdrop();
    }

    closeAction = async () => {
        $('#bankAccounts').on('hidden.bs.modal', function () {
        });
        await this.removeBackdrop();
    };

    Continue = async () => {
        const { changeBankStatus } = this.props;
        await changeBankStatus(true);
        await $('#bankAccounts').modal('hide');
        await this.removeBackdrop();
    }

    removeBackdrop = () => {
        document.body.classList.remove('modal-open');
        document.body.style.overflow = ''; // Restablece el scroll si está bloqueado
        const backdrop = document.querySelector('.modal-backdrop');// Elimina manualmente cualquier backdrop que haya quedado en el DOM
        if (backdrop) {
            backdrop.remove();
        }
    }

    render() {

        return (
            <div className="modal" tabindex="-1" role="dialog" id='bankAccounts' data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: config.navBar.iconColor }}>
                            <h5 className="modal-title" style={{ color: config.navBar.textColor2 }} >Recordatorio</h5>
                        </div>
                        <div className="modal-body">
                            <p>
                                Recuerda que para que tu pedido sea procesado debes enviar vía WhatsApp el comprobante de pago al número
                                <strong>{' 3411009856'}</strong>.
                            </p>
                            <p>
                                Números de cuentas bancarias.
                            </p>
                            <div className="row d-flex">
                                {banks.map((bank, index) => (
                                    <div
                                        key={index}
                                        className="col-6"
                                        style={{
                                            fontSize: "12px",
                                            border: "2px solid #D6DCE0",
                                            textAlign: "left",
                                            padding: "5px"
                                        }}
                                    >
                                        <span>
                                            <strong>{bank.name}</strong>
                                        </span>
                                        <br />
                                        <span>
                                            Suc. {bank.branch || ''}
                                        </span>
                                        <br />
                                        Cta. {bank.account}
                                        <br />
                                        <span>Clabe. {bank.clabe}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn"
                                style={{
                                    backgroundColor: config.navBar.iconColor,
                                    color: config.navBar.backgroundColor,
                                    fontWeight: "bold",
                                }}
                                onClick={() => this.Continue()}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default BankAccountsModal;