import React, { Component } from 'react';
import {config} from "../libs/utils/Const";
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';

class DecisionModal extends Component{

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.closeAction();
    }

    closeConfirm = async () => {
        const { notFuntion } = this.props;
        $('#DecisionModal').modal('hide');
        notFuntion();
    }

    closeAction = () => {
        $('#DecisionModal').on('hidden.bs.modal', function () {
        });
    };

    Continue =()=>{
        const { yesFuntion } = this.props;
        $('#DecisionModal').modal('hide');
        yesFuntion();
    }

    render() {
        const { Question, OptionYes, OptionNot } = this.props;
        return (
                <div class="modal" tabindex="-1" role="dialog" id="DecisionModal" >
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-body">   
                            <p>{Question}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.Continue}> {OptionYes}  </button>
                            <button type="button" className="btn btn-warning" onClick={this.closeConfirm}>{OptionNot}</button>
                            <button type="button" className="btn btn-danger"  data-dismiss="modal" /*onClick={this.Continue}*/ > Cancelar  </button>
                        </div>
                        </div>
                    </div>
                </div>
        );  
    }
}
export default DecisionModal;