import React, { Component } from 'react';
import { SERVICE_RESPONSE, DISPATCH_ID, config, ROLES} from "../libs/utils/Const";
import $, { isNumeric } from 'jquery';
import {ApiClient} from "../libs/apiClient/ApiClient";
import {connect} from "react-redux";
let apiClient = ApiClient.getInstance();

class AttributeModal extends Component{

    constructor(props) {
        super(props);
        this.state = {
            AttributeCode:'',
            Attribute : [],
            AllAttribute : [],
            NameAttribute : '',
            Todos_Nuevo : true,
            ViewTodos_Nuevo : false,
            disabledArtAttr : true,
            disableSave : false,
        };
    };

    componentDidMount = () => {
        const {NewAttribute} = this.props;
        this.fillAttribute();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps != this.props ){
            this.fillAttribute();
        }
    }

    fillAttribute = async () => {
        const { notificationReducer: {showAlert}, NewAttribute } = this.props;
        
        let result = await apiClient.getAttribute(NewAttribute);
        let result1 = await apiClient.getAttribute('');
        
        if(result.status === SERVICE_RESPONSE.SUCCESS && result1.status === SERVICE_RESPONSE.SUCCESS){
            this.setState({ 
                Attribute : result.data.results || [],
                NameAttribute: '',
                AttributeCode: NewAttribute || '',
                Todos_Nuevo : true,
                ViewTodos_Nuevo : false,
                AllAttribute: result1.data.results || [],
                disableSave : false,
             });
        }else{
            showAlert({type: 'warning', message: result.message, timeOut: 5000});
        }
        
    }

    render() {
        const  { Attribute, NameAttribute, Todos_Nuevo, ViewTodos_Nuevo, AllAttribute, disabledArtAttr, disableSave } = this.state;
        const {NewAttribute, codeAtribut} = this.props;
        return (
            <div className="modal fade bd-example-modal-lg" id="AttributeModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{minWidth:300 ,maxWidth: 600, margin: '1.75rem auto', borderRadius: 15}}>
                    <div className="modal-content">

                        <div className="modal-header" style={{background: config.navBar.iconColor, color: '#FFFFFF'}}>
                            <h5 className="modal-title">Lista de atributos</h5>
                            <button type="button" style={{color:"#FFFFFF", opacity:1, margin:0, padding:0}} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label style={{fontWeight: "bold"}}>Seleccione atributo de familia</label>
                                </div>
                                <div className="form-row" style={{padding: "0px 50px 0px 50px"}}>
                                    <div className="form-group col-md-12" style={{display:"flex"}}>
                                        <label htmlFor="articulo" style={{alignSelf:"end", marginRight:15}}>Attributos</label>
                                        <select
                                            id='atributo'
                                            className="form-control text-left"
                                            disabled={ViewTodos_Nuevo}
                                            onChange={(event) => (this.changeAttribute())}
                                            style={{ textAlign : 'center', height: 30, padding: 0 }}
                                            >
                                            <option value='' > Seleccione atributo </option>
                                            {Attribute != undefined && Attribute.length > 0 &&
                                                Attribute.map((ListAttribut) => {
                                                    let Contiene = codeAtribut.find(codeAttribute => (codeAttribute == ListAttribut.U_codeAtribut));
                                                    if(Contiene != ListAttribut.U_codeAtribut){
                                                        return <option value={ListAttribut.U_codeAtribut} >{ListAttribut.U_nameAtribut}</option>
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/* Componente de agregar*/}
                                <div className="form-row" hidden={!ViewTodos_Nuevo} style={{padding: "0px 50px 0px 50px"}}>
                                    <div className="form-group col-md-12" style={{display:"flex"}}>
                                        <div className="col-md-6">
                                            <button type="button" className="btn"  onClick={ () => { this.setState({ Todos_Nuevo : true, disableSave : false,}); }  }  style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}} > Todos los atributos </button>
                                        </div>
                                        <div className="col-md-6">
                                            <button type="button" className="btn"  onClick={ () => { this.setState({ Todos_Nuevo : false, disableSave : false,}); }  }  style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}} > Nuevo atributo </button>
                                        </div>
                                    </div>

                                    <div className="form-group col-md-12" style={{display:"flex"}}>
                                        {Todos_Nuevo ?
                                            <div className="col-md-12" >
                                                 <label htmlFor="articulo" style={{alignSelf:"end", marginRight:15}}>Lista de atributos  </label>
                                                    <select
                                                        id='atributoTodos'
                                                        className="form-control text-left"
                                                        onChange={(event) => (this.changeAllAttribute())}
                                                        style={{ textAlign : 'center', height: 30, padding: 0 }}
                                                        >
                                                        <option value='' > Seleccione atributo </option>
                                                        {AllAttribute != undefined && AllAttribute.length > 0 &&
                                                            AllAttribute.map((ListAttribut) => {
                                                                let Contiene = codeAtribut.find(codeAttribute => (codeAttribute == ListAttribut.U_codeAtribut));
                                                                if(Contiene != ListAttribut.U_codeAtribut){
                                                                    return <option value={ListAttribut.U_codeAtribut} >{ListAttribut.U_nameAtribut}</option>
                                                                }
                                                            })
                                                        }
                                                    </select>
                                            </div>
                                            :
                                            <div className="col-md-12" >
                                                 <label htmlFor="articulo" style={{alignSelf:"end", marginRight:15}}>Nuevo atributo  </label>
                                                 <input type="text"
                                                    id='atributoNuevo'
                                                    className="form-control" 
                                                    autoComplete="off" 
                                                    onChange={(event) => (this.changeAllAttribute())}
                                                />
                                            </div>
                                        }
                                    </div>

                                </div>

                            </form>
                        </div>
                        <div className="modal-footer row">
                            <button type="button" className="btn col-3" 
                            disabled={ViewTodos_Nuevo} onClick={ () => { this.setState({ ViewTodos_Nuevo : true, disabledArtAttr : true }); }  } 
                            style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}} > Agregar atributo nuevo a familia </button>

                            <button type="button" className="btn col-2" 
                            onClick={ () => { this.cleanModal() } } 
                            style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}} > Cancelar </button>

                            <button type="button" className="btn col-2" 
                            onClick={ () => { this.SaveAttribute() } } 
                            disabled={!ViewTodos_Nuevo || !disableSave} 
                            style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}} > Guardar</button>

                            <button type="button" className="btn col-3" 
                            disabled={ViewTodos_Nuevo || disabledArtAttr} onClick={ () => { this.AddAttributteFamily() } } 
                            style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}} > Agregar atributo a articulo </button>

                        </div>
                    </div>
                </div>
            </div>
        );  
    }

    cleanModal = () => {
        this.fillAttribute();
        this.setState({
            AttributeCode:'',
            Attribute : [],
            AllAttribute : [],
            NameAttribute : '',
            Todos_Nuevo : true,
            ViewTodos_Nuevo : false,
            disabledArtAttr : true,
            disableSave : false,
        });
    }

    SaveAttribute = async () => {
        const {notificationReducer: {showAlert}, NewAttribute, itemCode, recargar} = this.props;
        const  { Attribute,  NameAttribute, AttributeCode, Todos_Nuevo } = this.state;
        let Attribut ='';
        if(Todos_Nuevo){
            Attribut = document.getElementById('atributoTodos');
        }else{
            Attribut = document.getElementById('atributoNuevo');
        }
        if(Attribut.value != undefined  ){   
            let result = [];
            if(Todos_Nuevo){
                result = await apiClient.NewAttrubuteFamiy(NewAttribute,Attribut.value );
               
            }else{
                result = await apiClient.NewAttribute(Attribut.value,'0',NewAttribute );
            }
            if(result.status == SERVICE_RESPONSE.SUCCESS){
                showAlert({type: 'success', message: result.message, timeOut: 5000});
                setTimeout(() => {
                    $('#AttributeModal').modal('hide') ;
                }, 200);
                recargar();
                this.cleanModal();
            }else{
                showAlert({type: 'warning', message: result.message, timeOut: 5000});
            }
        }
       

    }

    changeAttribute = () =>{
        const  { Attribute } = this.state;
        let Attribut = document.getElementById('atributo');
        if(Attribut.value != undefined  ){
            let ResultList = Attribute.filter((List) => (Attribut.value == List.U_codeAtribut));
            if(ResultList && ResultList.length > 0 ){
                this.setState({
                    NameAttribute : ResultList[0].U_nameAtribut,
                    disabledArtAttr: false,
                });
            }
        }
    }
    
    changeAllAttribute = () =>{
        const  { AllAttribute, ViewTodos_Nuevo, disableSave, Todos_Nuevo } = this.state;
        let Attribut ='';
        if(Todos_Nuevo){
            Attribut = document.getElementById('atributoTodos');
        }else{
            Attribut = document.getElementById('atributoNuevo');
        }
        if(Attribut.value != undefined  ){
            let ResultList = AllAttribute.filter((List) => (Attribut.value == List.U_codeAtribut));
            if(ResultList && ResultList.length > 0 ){
                this.setState({
                    NameAttribute : ResultList[0].U_nameAtribut,
                    disableSave : true,
                });
            }else if(!ViewTodos_Nuevo || !disableSave){
                this.setState({
                    NameAttribute : Attribut.value,
                    disableSave : true,
                });
            }
        }
        
    }
    AddAttributteFamily = async () => {
        const {notificationReducer: {showAlert}, NewAttribute, itemCode, recargar} = this.props;
        const  { Attribute,  NameAttribute, AttributeCode,Todos_Nuevo } = this.state;
        let Attribut = document.getElementById('atributo');
        if(Attribut.value != undefined && Attribut.value != '') {
            let result = await apiClient.AddAttribute(itemCode, NewAttribute, Attribut.value, '0');
            if(result.status == SERVICE_RESPONSE.SUCCESS){
                showAlert({type: 'success', message: result.message, timeOut: 5000});
                setTimeout(() => {
                    $('#AttributeModal').modal('hide') ;
                }, 200);
                recargar();
                Attribut.value = '';
            }else{
                showAlert({type: 'warning', message: result.message, timeOut: 5000});
            }
        }else{
            showAlert({type: 'warning', message: 'Seleccione un porfavor', timeOut: 2000});
        }
    }


}


const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        sessionReducer: store.SessionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AttributeModal);