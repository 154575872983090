import React from "react";
import moment from "moment";
import { config } from "../libs/utils/Const";
import CurrencyFormat from 'react-currency-format';
class ReturnTwoPrintFormat extends React.Component {
    render() {
        const { user, returns } = this.props;
        if (returns.length === 0) {
            return <div></div>;
        }
        //PDF Devoluciones 2
        return (
            <div style={{ pageBreakBefore: 'always', padding: "30px 50px" }}>
                <div className="d-flex flex-column" style={{ marginTop: "20px" }}>
                    {/* Cabecera información */}
                    <div className="d-flex flex-row">
                        <div>
                            <img
                                style={{ width: "80%", height: "auto", maxWidth: 200 }}
                                src={config.navBar.icon}
                                alt="Logo"
                            />
                            <br />
                            <div>
                                <h2 class="mt-4 text-left font-weight-bold">
                                    Perez de la Mora
                                </h2>
                                <h5 class="mb-2 text-left">Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000, Jalisco.</h5>
                                <h5 class="text-left">ventas@perezdelamora.com</h5>
                                <h5 className="text-left">Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37</h5>
                            </div>
                        </div>

                        <div
                            className="d-flex justify-content-end"
                            style={{
                                flexWrap: "wrap",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                                content: "center",
                                marginLeft: "auto"
                            }}
                        >
                            <div style={{ textAlign: "center" }}>
                                <h2 style={{ fontWeight: "bold" }}>Devoluciones 2</h2>
                            </div>

                            {/* Tabla información de cliente */}
                            <table style={{ width: "90%", borderCollapse: "collapse", textAlign: "center" }}>
                                <thead>
                                    <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                                        <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                                            Nº Cliente
                                        </th>
                                        <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                                            Nombre
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{
                                                border: "0px solid #ddd",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                            }}
                                            className="font-weight-bold"
                                        >
                                            {user ? user?.CardCode : 'N/A'}
                                        </td>
                                        <td
                                            style={{ border: "0px solid #ddd", textAlign: "center" }}
                                            className="font-weight-bold"
                                        >
                                            {user ? user?.CardName : 'N/A'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* Tabla contenido de devoluciones */}
                    <div className="mt-5 d-flex flex-column bd-highlight text-center">
                        <table className='text-center'>
                            <tr className="text-white" style={{ backgroundColor: config.navBar.iconColor }}>
                                <th>Fecha del documento</th>
                                <th>Fecha de vencimiento</th>
                                <th>No. documento</th>
                                <th>Clave</th>
                                <th>Descripción</th>
                                <th>Cantidad</th>
                                <th>Total</th>
                            </tr>
                            {returns &&
                                returns?.data?.body?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <th>{moment(item.DocDate).utc().format('YYYY-MM-DD')}</th>
                                            <th>{moment(item.DocDueDate).utc().format('YYYY-MM-DD')}</th>
                                            <th>{item.DocNum ? item.DocNum : '--'}</th>
                                            <th>{item.ItemCode ? item.ItemCode : '--'}</th>
                                            <th>{item.ItemName ? item.ItemName : 'N/A'}</th>
                                            <th>{item.Quantity ? parseInt(item.Quantity) : '--'}</th>
                                            <th><CurrencyFormat
                                                    value={item.DocTotal || 0}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    suffix={' ' + item.Currency}>
                                                </CurrencyFormat>
                                            </th>
                                        </tr>
                                    );
                                })}
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReturnTwoPrintFormat;