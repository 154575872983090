import React, { Component } from 'react';
import { connect } from "react-redux";
import { config, ROLES, DISPATCH_ID } from '../libs/utils/Const';
import $ from "jquery";
import { ApiClient } from "../libs/apiClient/ApiClient";
import moment from 'moment';
import ReactDOM from 'react-dom';
import './MenuNavbar.css';

class MenuNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: null,
        };
    }



    subCategorySearchRes = async (valor,seccion, subCategory = null) => {
        const {setIdCategory, setLocation,itemsReducer: {searchByCategories},setNextPage,setView, setItemsFilters, setItemsCategoryFilters} = this.props;
        console.log('con<subCategorySearch','valor:',valor,'seccion:',seccion, 'subCategory:',subCategory);
        setIdCategory(valor);
        setNextPage(0); 
        await setLocation('menuCategories');
        setView('')
        await setItemsFilters({});
        let dataFilterCat = {
            cateogry: valor,
            page: 0,
            view: seccion,
            subCategory: subCategory
        }
        await setItemsCategoryFilters(dataFilterCat);
        await searchByCategories(valor, 0, seccion, subCategory);
    };

    subCategorySearch = async (val) => {
        const { itemsReducer:{ searchByKey }, setLocation, setNewFilters } = this.props;
        await setLocation(null); // Necesario para volver a solicitar filtros de búsqueda nueva
        await setLocation('departmentMenu');
        await setNewFilters({department: val});
        await searchByKey();
    };  

    handleCategoryHover = (category) => {
        this.setState({ selectedCategory: category });
        this.renderSubCategories(category);
    };

    // ############## REGION RENDER DE SUBCATEGORIAS (NIVEL 2) ##############
    renderSubCategories = (categoryL1) => {
        let divSubCategories = document.getElementById('subMenuNivel2');
        let divSubFamilies = document.getElementById('subMenuNivel3');  // Prueba
        if(categoryL1 && categoryL1.categoriesL2.length > 0){            
            const listSubCategory = categoryL1.categoriesL2.map((subCategory) =>
                <div 
                // style={{border : '2px solid #122759', borderRadius : '14px' , margin : '3px'}} 
                >
                    <div 
                        className='nav-link' 
                        data-toggle="collapse"
                        data-target="#desplegable"
                        aria-expanded="false"
                        aria-controls="desplegable" 
                        style={{fontSize:"0.8rem", /*height:50,*/ fontWeight:'700', color:'rgb(238 149 67)' }}
                        onClick={() => this.subCategorySearch(subCategory.Code , "productos", categoryL1.Code)}
                        // onMouseOver = {() => this.renderSubFamilies(subCategory)}
                    >
                        <img classNname="circle-o" src={config.icons.circleCenter} alt="circleCenter" aria-hidden="true" style={{width:'10px', height:'10px'}}></img>
                        {' '+subCategory.U_name}
                    </div>
                    {/* ############## REGION DE SUBFAMILIAS (NIVEL 3) ############## */}
                    {subCategory.categoriesL3 && subCategory.categoriesL3.map((subFamily) =>
                        <div onClick={() => this.subCategorySearch(subFamily.Code, "productos2", subCategory.Code)}>
                        <span
                            className='nav-link'
                            data-toggle="collapse"
                            data-target="#desplegable"
                            aria-expanded="false"
                            aria-controls="desplegable"
                            style={{ fontSize: "0.8rem", paddingBottom: 0, marginLeft: '15px' }}
                            // Aplicar estilo de subrayado al pasar el cursor por encima (hover)
                            onMouseOver={(e) => { e.target.style.textDecoration = "underline"; }}
                            // Eliminar el estilo de subrayado al salir del hover
                            onMouseOut={(e) => { e.target.style.textDecoration = "none"; }}
                        >
                            <img 
                                src={config.icons.circle2}
                                alt="Círculo"
                                className="fa-lg"
                                style={{ width: 10, height: 10}}
                                />

                            {' ' + subFamily.U_name}
                        </span>
                    </div>
                    )}
                    {/* <div class="dropdown-divider"></div> */}
                    {/* // ############## FIN REGION ############## */}

                </div>
            );
            ReactDOM.render(
                <span>
                    <div className=' designScroll row' style={{maxHeight: 300, width:'100%', justifyContent:'flex-start', alignItems : 'start', cursor:'pointer'}}>
                        {listSubCategory}
                    </div>
                </span>
                , divSubCategories
            );
            divSubCategories.style.display = "block";
            // divSubFamilies.style.display = "none";
        }else{
            if(divSubCategories){
            divSubCategories.style.display = "none";
            // divSubFamilies.style.display = "none";
            }
        }
    };
    // ############## FIN REGION ##############

    render() {
        const { configReducer: { history, idCategoriesMenu }, sessionReducer: { user }, sessionReducer } = this.props;
        return (
            <div>
                <div data-toggle="collapse" href="#desplegable" aria-expanded="false" aria-controls="desplegable" 
                    style={ {width:'150vw' , height : '150vh', background : 'transparemt', zIndex : '-1',position : 'absolute', top : '-173px',/* border : '10px solid yellow'*/ } }>   
                </div>
                
                <div className='collapse' id='Categorys' style={{display:"flex", padding:0}}>
                    
                    {/* ############## REGION DE CATEGORIAS (NIVEL 1) ############## */}
                    <div id ="Drop-Familia" style={{ background: config.navBar.headerCards, cursor: 'pointer', color:'white', fontWeight:'bold' }}>
                    <div className='designScroll Departamentos-lvl-1'>
                        {idCategoriesMenu.length > 0 && idCategoriesMenu.map(categoryL1 => {
                            return (
                                <div className={`category ${this.state.selectedCategory === categoryL1 ? 'selected' : ''}`}>
                                    <span
                                        className='nav-link'
                                        style={{ fontSize: "0.8rem", height: '3rem'}}
                                        onMouseOut={() => this.renderSubCategories(categoryL1)}
                                        onMouseOver={() => this.handleCategoryHover(categoryL1)}
                                    >
                                        {categoryL1.U_name}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                    <div  id='subMenuNivel2' className='designScroll Departamentos-lvl-2 '/*'wrapper'*/ style={{ background : 'white', }} >
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),

        setRole: value => dispatch({ type: DISPATCH_ID.SESSION_SET_ROLE, value }),
        addNotificationReference: value => dispatch({ type: DISPATCH_ID.NOTIFICATION_SET_ALERT_REFERENCE, value }),
        setBusiness: value => dispatch({ type: DISPATCH_ID.SESSION_SET_BUSINESS_ID, value }),
        setToken: value => dispatch({ type: DISPATCH_ID.SESSION_SET_TOKEN, value }),
        setUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_USER, value }),
        setRememberUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_REMEMBER_USER, value }),
        setLogOutReference: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_LOG_OUT_REFERENCE, value }),
        setIdCategory: value => dispatch({type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value}),
        setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        setLocation:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_LOCATION, value}),
        setView:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_VIEWCATEGORY, value}),
        setItemsFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value }),
        setItemsCategoryFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_CATEGORY_FILTER, value }),
        setNewFilters: value => dispatch({ type: DISPATCH_ID.SET_NEW_FILTERS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuNavbar);