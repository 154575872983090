import React, { Component } from 'react';
import { config, SERVICE_API, DISPATCH_ID, SERVICE_RESPONSE } from "../libs/utils/Const";
import { ApiClient } from "../libs/apiClient/ApiClient";
import $ from 'jquery';
import { connect } from 'react-redux';
const apiClient = ApiClient.getInstance();

class EmailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email1: '',
            email2: '',
            user: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')),
            omitFirstEmail: false,
        };
    }

    handleSendPDF = async () => {
        const { typeDocs, data, enableSpinner, newTitle, notificationReducer: { showAlert } } = this.props;
        const { email1, email2, user, omitFirstEmail } = this.state;

        let flagError = false;
        let docs = [];

        if (typeDocs === 'invoices') {
            enableSpinner(true);
            for (let index = 0; index < data.length; index++) {
                let response = await apiClient.getDataBill(data[index]);

                if (response.status === SERVICE_RESPONSE.SUCCESS) {
                    docs.push(response);
                } else {
                    console.error('Error fetching invoice:', response);
                }
            }
            enableSpinner(false);
        }

        enableSpinner(true);
        try {
            const firstEmail = user?.Email_SAP || user?.U_FMB_Handel_Email || '';
            const isFirstEmailValid = !!firstEmail; // Valida si hay un correo en el primer campo

            const destinatario = [
                !omitFirstEmail && isFirstEmailValid ? firstEmail : null,
                email2 ? email2 : null,
            ]
                .filter(Boolean)
                .join(';');

            let info = {
                data: typeDocs === 'collect' ? data : docs || [],
                title: newTitle || '',
                mails: destinatario,
            }
            let response = await apiClient.sendPDFEmail(info);
            enableSpinner(false);

            if (response.status === SERVICE_RESPONSE.SUCCESS) {
                showAlert({ type: 'success', message: response.message });
            } else {
                showAlert({ type: 'error', message: response.message });
                flagError = true;
            }
        } catch (error) {
            enableSpinner(false);

            flagError = true;
        }
        return { flagError };
    };

    handleInputChange = (e) => {
        const { id, value } = e.target;
        this.setState({ [id]: value });
    };

    Continue = async () => {
        const { notificationReducer: { showAlert }, closeConfirm } = this.props;
        const { email1, email2, omitFirstEmail, user } = this.state;
    
        // Obtener email1 directamente desde `user` para asegurar su existencia
        const firstEmail = user?.Email_SAP || user?.U_FMB_Handel_Email || '';
        const isFirstEmailValid = !!firstEmail && this.validateEmail(firstEmail);
    
        // Validar email1 si omitFirstEmail no está activado
        if (!omitFirstEmail) {
            if (!isFirstEmailValid) {
                showAlert({ type: 'warning', message: "El primer correo electrónico no es válido o no existe.", timeOut: 4000 });
                return;
            }
        }
    
        // Validar email2 si omitFirstEmail está activado o si email2 está presente
        if (email2 && !this.validateEmail(email2)) {
            showAlert({ type: 'warning', message: "Por favor ingrese un correo electrónico válido en el segundo campo.", timeOut: 4000 });
            return;
        }
    
        // Si omitFirstEmail está activado y no hay ningún correo válido
        if (omitFirstEmail && !email2) {
            showAlert({ type: 'warning', message: "Debe ingresar al menos un correo electrónico válido.", timeOut: 4000 });
            return;
        }
    
        // Enviar el correo electrónico
        let res = await this.handleSendPDF();
        if (res?.flagError) {
            return;
        }
    
        this.setState({ email2: '' });
        await closeConfirm();
    };
    

    validateEmail = (email) => {
        // Split the input by semicolon to handle multiple emails
        const emails = email.split(';');
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        
        // Check each email in the array
        for (let i = 0; i < emails.length; i++) {
            if (!regex.test(emails[i].trim())) {
                return false; // Return false if any email is invalid
            }
        }
        return true; // Return true if all emails are valid
    };
    
    
    validateEmail1 = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const isValid = regex.test(email);
        return isValid;
    };

    closeConfirm = () => {
        this.setState({ email2: '' });
        $('#emailModal').hide();
    };

    render() {
        const { notificationReducer: { showAlert }, configReducer, enableSpinner, closeConfirm } = this.props;
        const { email1, email2, user, omitFirstEmail } = this.state;

        const firstEmail = user?.Email_SAP || user?.U_FMB_Handel_Email || '';
        const isFirstEmailValid = !!firstEmail; // Valida si hay un correo en el primer campo

        const destinatario = [
            !omitFirstEmail && isFirstEmailValid ? firstEmail : null,
            email2 ? email2 : null,
        ]
            .filter(Boolean)
            .join(';');

        return (
            <div className="modal" tabIndex="-1" role="dialog" id="emailModal" style={{ display: 'none', position: 'fixed', zIndex: 1050, left: 0, top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-dialog modal-lg" role="document" style={{ position: 'relative', margin: '10% auto', padding: 0 }}>
                    <div className="modal-content" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)', padding: '20px' }}>
                        <div className="modal-header" style={{ borderBottom: '1px solid #ddd', paddingBottom: '15px' }}>
                            <span className="modal-title" style={{ fontSize: '12px', fontWeight: 'bold', textAlign:'center' }}>Enviar documentos por correo electrónico.</span>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => closeConfirm()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ padding: '15px 0' }}>
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="form-group" style={{ marginBottom: '1.5rem' }}>
                                    <input type="email" className="form-control" id="email1" value={isFirstEmailValid ? firstEmail : 'Correo no válido o no existe.'} autoComplete="off" placeholder="Correo electrónico 1" disabled 
                                        style={{borderRadius: '4px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', padding: '12px 15px', width: '100%', color: isFirstEmailValid ? '#000' : '#dc3545'}}/>
                                    </div>
                                    {isFirstEmailValid && (
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="omitFirstEmail" checked={omitFirstEmail} onChange={(e) => this.setState({ omitFirstEmail: e.target.checked })} />
                                            <label className="form-check-label" htmlFor="omitFirstEmail">Omitir primer correo electrónico</label>
                                        </div>
                                    )}
                                    <span className="modal-title" style={{ fontSize: '10px', fontWeight: 'bold' }}>Escribe los correos electrónicos</span>
                                    <div className="form-group" style={{ marginBottom: '1.5rem' }}>
                                        <input type="email" className="form-control" id="email2" value={email2} onChange={(event) => this.handleInputChange(event)} autoComplete="off" placeholder="Correo electrónico 2" style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', padding: '12px 15px', width: '100%' }} />
                                    </div>
                                    <span style={{ display: 'block', wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '100%' }}>
                                        <strong style={{ fontSize: '12px' }}>Destinatario:</strong> {destinatario || 'Ningún correo seleccionado'}
                                    </span>
                                    {/* Mensaje de advertencia */}
                                    {destinatario && destinatario.includes(';') && (
                                        <span style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>Los correos concatenados deben ser así: correo1@gmail.com;correo2@gmail.com</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer" style={{ padding: '10px 0 0', textAlign: 'right' }}>
                            <button type="button" className="btn" onClick={() => closeConfirm()} style={{ padding: '10px 20px', fontSize: '1rem', borderRadius: '5px', backgroundColor: config.navBar.iconColor, color: 'white', border: 'none', marginRight: '10px' }}
                                onMouseOver={(e) => (e.target.style.backgroundColor = config.navBar.iconColor)}
                                onMouseOut={(e) => (e.target.style.backgroundColor = config.navBar.iconColor)}>
                                Cerrar
                            </button>
                            <button type="button"
                                className="btn btn-success"
                                onClick={() => this.Continue()}
                                style={{ padding: '10px 20px', fontSize: '1rem', borderRadius: '5px', backgroundColor: config.navBar.iconColor, color: 'white', border: 'none' }}
                                onMouseOver={(e) => (e.target.style.backgroundColor = config.navBar.iconColor)}
                                onMouseOut={(e) => (e.target.style.backgroundColor = config.navBar.iconColor)}>
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemDetailsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmailModal);