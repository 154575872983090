import React, { Component } from 'react';
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID } from "../libs/utils/Const";
import CurrencyFormat from 'react-currency-format';
import { connect } from "react-redux";
import '../index.css';
import $ from 'jquery';
import { ApiClient } from "../libs/apiClient/ApiClient";
import ReactTooltip from 'react-tooltip';
import Tooltip from 'react-light-tooltip'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {Promociones} from '../components/index';
import './ShoppingCartList.css';
import ReactHover, { Trigger, Hover } from "react-hover";

let apiClient = ApiClient.getInstance();


class ShoppingCartList extends Component {
    state = {
        promo: [],
        itemPromo:[],
        promociones:[],
        // whsCodes:[],
        seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
        renderInfoDiscount: false,
    }

    componentDidMount = async () => {
        $(document).ready(function(){
            $('[data-toggle="popover"]').popover();
        });
        // await this.cargarDatos();
    };

    cargarDatos = async () => {
        let user = localStorage.getItem(config.general.localStorageNamed + 'CurrentUser');
        user = JSON.parse(user) || {};
        let datos ={
            CardCode : user.CardCode,
            Canal : user.U_SYP_RICO_CCANAL || '',
            Region : user.U_SYP_RICO_CREGION || '',
            SubCanal : user.U_SYP_RICO_CSBCAN || ''
        }
        let newdatas = [] // await apiClient.getPromo(datos);
        if(newdatas.status !== 1){
           return;
        }
        if(newdatas.data.length > 0){
            this.setState({
                promociones: newdatas.data,
            });
            // await this.validateDisparador();
        }
    };

    validateDisparador = async () => {
        const {promociones} = this.state;
        // const {enableSpinner, shoppingCartReducer: {items}} = this.props;
        const {enableSpinner, data ,refeshState} = this.props;
       //let data = items;
        let arrayPromoVol = [];
        let NotificacionIems = [];
        let itemsNotificacion = [];
        //##########---VALIDA ARTICULOS POR TIPO DE DISPARADOR----###################
        for (let index = 0; index < promociones.length; index++) {//----promociones
            const promo = promociones[index];
            let arrayItems = [];
            for (let index = 0; index < promo.length; index++) {//----Articulos por promoción
                const disparador = promo[index];
                if (disparador.fkTipoDisparador === 1) {  //---VOLUMEN--------------------------------
                    for (let index = 0; index < data.length; index++) {//----Articulos por promoción
                        const item = data[index];
                        if (item.ItemCode === disparador.idProducto) {
                            arrayItems.push(disparador);
                        }
                    };
                }else if (disparador.fkTipoDisparador === 2) { //--------------------------------MONTO--------------------------------

                }
            }
            if(arrayItems.length > 0){
                arrayPromoVol.push(arrayItems);

            }
        }
        if(arrayPromoVol.length > 0){
            let itemsPromo = [];

            let bonifNoti =[];
            for (let index = 0; index < arrayPromoVol.length; index++) {
                const promociones = arrayPromoVol[index];
                for (let index = 0; index < promociones.length; index++) {
                    const item = promociones[index];
                    itemsPromo.push(item);
                }
            }
            enableSpinner(true);
            bonifNoti = await apiClient.getBonificacion(itemsPromo);
            enableSpinner(false);
            for (let index = 0; index < itemsPromo.length; index++) {
                const element = itemsPromo[index];
                 const result = NotificacionIems.find(itemDisp => itemDisp.idProducto === element.idProducto);
                 if(!result){
                    NotificacionIems.push(element);
                 }
            }


            if(bonifNoti.data.length > 0){
                for (let index = 0; index < itemsPromo.length; index++) {
                    const item = itemsPromo[index];
                    let notificacion ={
                        itemDisparador: item.idProducto,
                        tipoVenta: item.fkSubTipo,
                        bonificacion : [],
                        idDisparador: item.disparador,
                        itemQuantity: item.cantidad,
                    }
                    for (let index = 0; index < bonifNoti.data.length; index++) {
                        const element = bonifNoti.data[index];
                        let itemsTemp = [];
                        for (let index = 0; index < element.length; index++) {
                            const bonificacion = element[index];
                            if(item.disparador === bonificacion.disparador && item.relacion === bonificacion.relacion){
                                itemsTemp.push(bonificacion);
                            }
                        }

                       let items = [];
                        let registrados = [];
                        for (let index = 0; index < itemsTemp.length; index++) {
                            const element = itemsTemp[index];
                             let include = registrados.includes(element.indexPack);
                             if(!include){
                                let arrayTemp = itemsTemp.filter(item => (item.indexPack === element.indexPack));
                                registrados.push(element.indexPack);
                                items.push(arrayTemp);
                             }


                        }   
                        
                        if(itemsTemp.length > 0){
                            notificacion.bonificacion = items;
                        }

                    }

                    itemsNotificacion.push(notificacion);
                }



                
                for (let index = 0; index < NotificacionIems.length; index++) {
                    const element = NotificacionIems[index];
                    let notificacion =[];
                    for (let index = 0; index < itemsNotificacion.length; index++) {
                        const promo = itemsNotificacion[index];
                        if(element.idProducto === promo.itemDisparador){
                            notificacion.push(promo);
                        }
                    }
                    element.notificacion = notificacion;
                }
                // if(filters.length > 0){
                //     NotificacionIems.push(filters);
                // }
            }
        }
        //##########---DISPARADOR TIPO VOLUMEN VALIDA SUBTIPO----###################
        let promoECD = [];
        let promoEDC = [];
        for (let index = 0; index < arrayPromoVol.length; index++) {
            const itemsPromo = arrayPromoVol[index];
            for (let index = 0; index < itemsPromo.length; index++) {
                const item = itemsPromo[index];
                if (item.fkSubTipo === 1) {  //---En la compra de--------------------------------
                    promoECD.push(itemsPromo);
                    break;
                } else if (item.fkSubTipo === 2) {//---En la compra decualquera de--------------------------------
                    promoEDC.push(itemsPromo);
                    break;
                }
            }
        }


        let itemsPromo = [];
         //##########--- EN LA COMPRA DE, VALIDA STOCK----###################
        for (let index = 0; index < promoECD.length; index++) {
            const promo = promoECD[index];
            for (let index = 0; index < promo.length; index++) {
                const element = promo[index];
                for (let index = 0; index < data.length; index++) {
                    const item = data[index];
                    if((parseInt(item.quantity,10) >= element.cantidad) && (item.ItemCode === element.idProducto)){
                        element.newQuantity = parseInt(parseInt(item.quantity,10) / element.cantidad);
                        itemsPromo.push(element);
                    }
                }
            }
        }
         //##########--- EN LA COMPRA DE CUALQUIERA DE, VALIDA STOCK  ----###################
        for (let index = 0; index < promoEDC.length; index++) {
            const promo = promoEDC[index];
            let sumTotal = 0;
            for (let index = 0; index < promo.length; index++) {
                const element = promo[index];

                for (let index = 0; index < data.length; index++) {
                    const item = data[index];

                     if(item.ItemCode === element.idProducto){
                        sumTotal+=parseInt(item.quantity,10);
                     }

                }
            }
            if(sumTotal >= promo[0].cantidad){
                for (let index = 0; index < promo.length; index++) {
                    const elementPromo = promo[index];
                    elementPromo.newQuantity = parseInt(sumTotal / promo[0].cantidad);
                    itemsPromo.push(elementPromo);
                }
            }
        }

        let arrayRelacional = [];
        let itemsBonificacion;
        if(itemsPromo.length > 0){
            enableSpinner(true);
                itemsBonificacion = await apiClient.getBonificacion(itemsPromo);
            enableSpinner(false);
            if(itemsBonificacion.data.length > 0){
                for (let index = 0; index < itemsPromo.length; index++) {
                    const itemsList = itemsPromo[index];
                    for (let index = 0; index < itemsBonificacion.data.length; index++) {
                        const bonificacionList = itemsBonificacion.data[index];

                        for (let index = 0; index < bonificacionList.length; index++) {
                            const element = bonificacionList[index];
                            if(itemsList.bonificacion === element.bonificacion && itemsList.relacion === element.relacion){
                                let promo ={
                                    itemDisparador: itemsList.idProducto,
                                    tipoDisparador: itemsList.fkSubTipo,
                                    bonificacion : element,
                                    tipoSeleccion: element.idTipoSeleccion,
                                    use : false,
                                    idDisparador: element.disparador,
                                    itemQuantity: itemsList.cantidad ,
                                    homeItemQuantity: itemsList.newQuantity,
                                }
                                arrayRelacional.push(promo);
                            }
                        }

                    }
                }

            }
        }
        refeshState(arrayRelacional,NotificacionIems);
    };

    changeQuantity = async (index, item, event) => {
        const { changeQuantity, sessionReducer: {user}, shoppingCartReducer: { items }, setShoppingCart , notificationReducer: { showAlert } } = this.props;
        let newQuantity = event.nativeEvent.target.value;

        
        let whsUser = item.WhsList.filter(item => item.WhsCode === user.wareHouse);
        whsUser.map(whs => {
            if (newQuantity < whs.OnHand){
                item.WhsCode = whs.WhsCode;
                item.OnHandPrincipal = whs.OnHand;
                setShoppingCart(item);
            }
        });
        
        changeQuantity(index, item, newQuantity, true); // add
        this.validateDisparador();
    };


    //BOTON AGREGAR AL CARRITO

    changLocalQuantity = (index, item, event) => {
        const { shoppingCartReducer: { items } ,sessionReducer: {user}, setShoppingCart , notificationReducer: { showAlert }} = this.props;
        //const {whsCodes} = this.state;
        let newQuantity = event.nativeEvent.target.value;
        let onHand = parseInt(item.OnHand);

        items.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                if(newQuantity >= 1 || newQuantity == '' ){
                    itemFilter.quantity = newQuantity;
                }
            }
        });
            
            // Validacion para regresar a almacen original al bajar el onHand(Stock) principal #Ulises-UPP
            // item.WhsList.map(whs => {
            //     //Compara que el item con la lista de almacenes sea la correcta
            //     if (whs.ItemCode === item.ItemCode){
            //         //Compara que el codigo del almacen de la lista con el codigo del almacen del usuario sea el mismo
            //         if(whs.WhsCode === user.wareHouse){
            //             //compara que sea mayor o igualbtn text-white btn-lg div-aplicaBtn--button el stock del almacen principal que la cantidad requerida
            //             if( parseInt(whs.OnHand) >= parseInt(item.quantity)){
            //                 item.WhsCode = whs.WhsCode;
            //                 item.OnHandPrincipal = whs.OnHand;
            //             }
            //         }
            // }
            // });
            // color success
            // document.getElementById(item.ItemCode).style.backgroundColor = "#28a745";
            // document.getElementById(item.ItemCode).style.borderColor = "#28a745";
        setShoppingCart(items);
        // }
        // else {
        //      return;
        // }
    }
    
    //Evento para capturar los valores de los campos de almacen
    changeLocalWhs = async (index, item, event) => {
        const { changeQuantity, shoppingCartReducer: { items }, setShoppingCart , notificationReducer: { showAlert } } = this.props;
        let newWsh = event.nativeEvent.target.value.split(",");
        item.WhsCode = newWsh[0];
        item.OnHandPrincipal = parseInt(newWsh[1]);
        changeQuantity(index, item, item.quantity, true); // add
    };

    changeWhs = async (index, item) => {
        const {data, sessionReducer: {user}, changeQuantity, shoppingCartReducer: { items }, setShoppingCart , notificationReducer: { showAlert } ,enableSpinner} = this.props;
        let responseWhs = await apiClient.getWshCode(item.ItemCode);
        if(responseWhs.status === 0){
            return;
        }
        if(responseWhs.data.length === 0){
            return;
        }
        data.map(items => {
            if(items.ItemCode === item.ItemCode){
                items.WhsList = responseWhs.data.filter( 
                    Whs=> (
                        parseInt(Whs.OnHand) >= parseInt(item.quantity) || user.wareHouse == Whs.WhsCode
                    )
                );
                
                items.WhsList.map(
                    almacen => {
                        (almacen.WhsCode == item.WhsCode) ? item.OnHandPrincipal = almacen.OnHand : item.OnHandPrincipal = item.OnHandPrincipal
                    }
                );
            
            }
        });
        setShoppingCart(data);
    };


    validateQuantity = (index, item, e) => {
        if (!e.target.value) {
            let event = {
                nativeEvent: {
                    target: {
                        value: 1,
                    }
                }
            };
            this.changeQuantity(index, item, event);
        }
    };

    validateStock = item => {
        if ((item.quantity > parseInt(item.OnHand)) && (!!item.OnHand || parseInt(item.OnHand) === 0) && !item.BE) {            
            return (
                <div className='col-12' style={{ color: 'red', fontSize: 12, padding: 0 }}>
                    Articulo sujeto a disponibilidad de proveedores.
                </div>
            )
        } else if ((item.quantity > parseInt(item.OnHandPrincipal)) && (!!item.OnHandPrincipal || parseInt(item.OnHandPrincipal) === 0) && !item.BE) {            
            return (
                <div className='col-12' style={{ color: 'red', fontSize: 15, padding: 0 }}>
                    Se recomienda comprar desde otro almacen.
                </div>
            )
        }
    };

    validateStockBonificacion = item => {
        if ((item.cantidad > item.Stock) && !!item.Stock) {
            return (
                <div className='col-12 font-weight-bold ' style={{ color: 'red', fontSize: 15, padding: 0 }}>
                    El artículo no tiene stock para la bonificación.
                </div>
            )
        }
    };

    deleteItemLocal = async ({ item = null, deleteAll = null })=>{
        const {deleteItem} = this.props;
        await deleteItem({ item, deleteAll: false });
        this.validateDisparador();
    };

    renderBonificaciones =(element,ItemCode)=>{
        const {deleteItemPromo} = this.props;
        const {seller} = this.state;
        let itemRegalo = element.bonificacion;
        if(ItemCode === element.itemDisparador){
            return (
                <div className='row' style={{ padding: 0, textAlign: 'center' }}>
                    <div className='col-sm-2' style={{ margin: 0 }}>
                    <img
                        className="img-fluid "
                        style={{
                            backgroundColor: 'white',
                            maxHeight: 80
                        }}
                        // src={config.shoppingCartIcons.imagenDefecto}
                        src={itemRegalo.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + itemRegalo.PicturName) : require('../images/noImage.png')}
                        alt=""
                    />
                    </div>
                    <div className='col-sm-10'>
                        <div className="container p-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className='text-left'>
                                        {itemRegalo.ItemName}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                        <div className="col-md-12 table-responsive">
                            <table className="table" style={{margin:'0px'}}>
                                <thead>
                                    <tr >
                                        <th scope="col" style={{padding:'0px'}}>Código</th>
                                        <th scope="col" style={{padding:'0px'}}>Cantidad</th>
                                        {seller ? seller.U_FMB_Handel_Perfil != '0' ? 
                                            <th scope="col" style={{padding:'0px'}}></th>
                                            : "" : ""
                                        }
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <td style={{ padding: '0px' }}>
                                        <img className="gift" src={config.icons.gift} alt="gift" style={{ width: '20px', height: '20px', marginTop: '7px', cursor: 'pointer', marginLeft: '7px'}} data-toggle="tooltip" title="Artículo de regalo" />
                                        {itemRegalo.idProducto}
                                    </td>
                                    <td style={{padding:'0px'}}>

                                        <input
                                            disabled={true}
                                            id={'input-quantity-' + itemRegalo.idProducto + 1}
                                            type="number"
                                            min="1"
                                            value={itemRegalo.cantidad ? Number(itemRegalo.cantidad) : ''}
                                            className="form-control"
                                            name={'quantity' + itemRegalo.idProducto}
                                            placeholder="Cantidad"
                                            style={{ textAlign: 'center' }}/>
                                    </td>
                                    <td style={{padding:'0px'}}>
                                        {itemRegalo.idTipoSeleccion === 2 &&
                                            <img className="trashRed" src={config.icons.trashRed} alt="trashRed" style={{width: '15px', height: '18px',marginTop: '-3px', cursor: 'pointer'}} onClick={() => deleteItemPromo(element)}/>}
                                    </td>
                                    {seller ? seller.U_FMB_Handel_Perfil != '0' ? 
                                        <td style={{padding:'0px'}}>
                                            {this.validateStockBonificacion(itemRegalo)}
                                        </td>
                                        : "" : ""
                                    }
                                    <td></td>
                                </tbody>

                            </table>

                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            )
        }
    };

    renderBoxesProm = (itemPromo,itemCode) =>{
        const {selectItemsPromo} = this.props;
        if((itemPromo.itemDisparador === itemCode) && itemPromo.tipoSeleccion === 2){
            return (
                <img src={config.icons.box} alt={itemPromo.bonificacion.nombre} data-toggle="tooltip" title={itemPromo.bonificacion.nombre} style={{ cursor: 'pointer', paddingRight: "5px", color: config.Back.color, width: '20px', height: '20px' }} onClick={() => selectItemsPromo(itemPromo, itemCode)}/>
            )
        }
    };

    render() {
        const { data, view,deleteItemPromo, sessionReducer: {user}, itemsReducer: { addShoppingCart, deleteShoppingCart, getInfoDiscount }, shoppingCartReducer: { items },itemPromociones ,selectItemsPromo,itemsRegalos,itemsRegalosSelect,itemDisparador,boxes,itemsNotificacion, businessPartnerInfo,configReducer:{currency},isDevolution} = this.props;
        const { MainCurncy = '', DocCurrCod = '' } = currency;
        const { seller, renderInfoDiscount } = this.state;
        
        return (
            <div>
                {/* overflowY:'auto' */}
                <div >
                    {data.map((item, index) => {       
                        const { ItemName, ItemCode, Discount, PriceBeforeTax, PicturName, PriceBeforeDisc, FinalPrice, Currency, OnHandPrincipal } = item; 
                        const { FinalPriceBeforeTax, quantity } = item; 
                        const flagDiscount = Boolean(Discount && Discount > 0);                 
                        return (
                            <div key={index} className=' text-left card ' style={{ border: 'none', minWidth:"50rem" }}>
                                <div className='row' style={{ textAlign: 'center' }}>
                                    {/* {item.U_FMB_Handel_Promo !== null && item.U_FMB_Handel_Promo !== '' && item.U_FMB_Handel_Promo !== 'null' && 
                                    <div className='col-12' style={{marginBottom: 5, padding: 5, backgroundColor: config.navBar.backgroundColor, color: config.navBar.textColor}}>
                                        Promoción--
                                    </div>
                                } */}
                                    <div className='col-sm-2' style={{ margin: 0 }}>
                                        <img className="img-fluid" style={{ backgroundColor: 'white', maxHeight: 80 }}
                                        // src={config.shoppingCartIcons.imagenDefecto}
                                            src={PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + PicturName) : require('../images/noImage.png')}
                                            alt="" />

                                        {itemsNotificacion.map((element) => {
                                            return (
                                                <div className="input-group details">
                                                    {item.ItemCode === element.idProducto &&
                                                        <Promociones
                                                            itemsNotificacion={element.notificacion} />}
                                                </div>
                                            )
                                        })
                                        }
                                        {flagDiscount && (
                                            <>
                                                <div className='rounded d-flex justify-content-center'>
                                                    <ReactHover options={true} >
                                                        <Trigger type="trigger">
                                                            <img
                                                                src={config.icons.discountIcon}
                                                                width={'40px'}
                                                                height={'40px'}
                                                                alt='Icono Descuento'
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={async (event) => { await getInfoDiscount(item.ItemCode, 'ShoppinCart'); await this.setState({ renderInfoDiscount: true }); }}
                                                            />
                                                        </Trigger>
                                                        {(renderInfoDiscount && item.flagInfoDiscount) &&
                                                            <Hover type="hover">
                                                                <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-90px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-50px', display: 'flex', flexDirection: 'column' }}>
                                                                    <p className="card-title text-left" style={{ fontSize: 13, color: 'black' }}>
                                                                        <span style={{ fontWeight: 'bold' }}> Tipo de Descuento: </span> <span>{item.infoDiscount.typeDiscount || ''}</span><br></br>
                                                                        <span style={{ fontWeight: 'bold' }}> Descuento: </span> <span>{item.infoDiscount.Discount || ''} %</span>
                                                                    </p>
                                                                    {(item.infoDiscount?.Quantities && item.infoDiscount.Quantities?.length > 0) && (
                                                                        <>
                                                                            <p className="card-title text-left" style={{ fontSize: 13, color: 'black' }}>
                                                                                <span style={{ fontWeight: 'bold' }}> Información del descuento.</span>
                                                                            </p>
                                                                            {item.infoDiscount.Quantities.map((item, index) => (
                                                                                <p key={index} className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'black' }}>
                                                                                    <span style={{ fontWeight: 'bold' }}>Cantidad Min: </span><span>{item.Amount}</span><br></br>
                                                                                    <span style={{ fontWeight: 'bold' }}>Descuento: </span><span>{`${item.Discount} %`}</span><br></br>
                                                                                    <span style={{ fontWeight: 'bold' }}>Precio </span><span>
                                                                                        <CurrencyFormat
                                                                                            value={Number(item.Price || 0) * 1.16}
                                                                                            displayType={'text'}
                                                                                            decimalScale={2}
                                                                                            thousandSeparator={true}
                                                                                            fixedDecimalScale={true}
                                                                                            prefix={'$ '}
                                                                                            suffix={' ' + Currency}>
                                                                                        </CurrencyFormat>
                                                                                    </span>
                                                                                </p>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </Hover>
                                                        }
                                                    </ReactHover>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className='col-sm-10'>
                                        <div className="container p-0">
                                            <div className="row ">

                                                <div className="col-12">
                                                    <div className='text-left' style={{ color: "#808080" }}>
                                                        {ItemName}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table" style={{margin:'0px'}}>
                                                        <thead>
                                                            <tr>
                                                                {isDevolution &&
                                                                    <th scope="col" style={{ padding: '0px' }}>Número de factura</th>
                                                                }
                                                                <th scope="col" style={{padding:'0px'}}>Clave</th>
                                                                <th scope="col" style={{ padding: '0px' }}>Cantidad</th>
                                                                {/* <th scope="col" style={{ padding: '0px' }}>Almacén</th> */}

                                                                {(seller && seller.U_FMB_Handel_Perfil != '0' && !isDevolution) &&
                                                                    <th scope="col" style={{ padding: '0px' }}>Cant. Disponible</th>
                                                                }
                                                                {/* {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 && */}
                                                                {flagDiscount && (
                                                                    <>
                                                                        <th scope="col" style={{ padding: '0px' }}>Precio or. sin IVA</th> 
                                                                        {/* {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 && */}
                                                                        <th scope="col" style={{ padding: '0px' }}>Precio or. con IVA</th>
                                                                        {/* {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 && */}
                                                                        <th scope="col" style={{ padding: '0px' }}>% Descuento aplicado</th>
                                                                    </>
                                                                )}
                                                                <th scope="col" style={{ padding: '0px' }}>Precio</th>
                                                                <th scope="col" style={{ padding: '0px' }}>Precio con IVA</th>
                                                                <th scope="col" style={{ padding: '0px' }}>Total</th>
                                                                {/* {item.U_FMB_Handel_PNTA == "1" &&
                                                                    businessPartnerInfo.U_FMB_Handel_CardAct == "1" &&
                                                                    businessPartnerInfo.U_FMB_Handel_RedCard != null &&
                                                                    config.modules.points &&
                                                                    <th scope="col">Consiguelo por</th>
                                                                } */}
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {isDevolution &&
                                                                <td style={{minWidth: "5rem"}}>
                                                                    {parseInt(item.BE)}
                                                                </td>
                                                            }
                                                            <td className="td-code" style={{ color: "#000",minWidth: "5rem"}}>
                                                                {ItemCode }
                                                            </td>
                                                            <td>
                                                                <input
                                                                    // disabled={!(!!item.OnHandPrincipal)}
                                                                    id={'input-quantity-' + ItemCode + index}
                                                                    type="number" 
                                                                    disabled = {isDevolution} 
                                                                    value={ Number(quantity) <1 ? "" : Number(quantity) }
                                                                    className="form-control mb-1" name={'quantity' + ItemCode}
                                                                    placeholder="Cantidad" style={{ textAlign: 'center', borderRadius: 20, borderColor: "#666666", color: "#666666",minWidth: "8rem" }}
                                                                    onChange={(event) => { this.changLocalQuantity(index, item, event) }}
                                                                    onBlur={(event) => { this.changeQuantity(index, item, event) }}
                                                                    onFocus={(event) => event.target.select()}
                                                                />
                                                            </td>
                                                            {/* <td style={{minWidth: "9rem"}} >
                                                                <select 
                                                                // F458 Validación para habilitar o deshabilitar la elección de almacen deacuerdo al whs principal y la cantidad
                                                                disabled={(user.wareHouse === item.WhsCode && parseInt(item.OnHandPrincipal) >= parseInt(item.quantity)) ? true : ""}
                                                                // disabled={(whsCodes !== undefined) && (user.wareHouse === item.WhsCode) ? !(whsCodes.length > 0 &&  item.MaximoStock <  parseInt(item.quantity)) : ""}
                                                                name="NombrePaqueteria" 
                                                                className="form-control text-left" 
                                                                onChange={(event) => { this.changeLocalWhs(index, item, event) }}
                                                                onClick = { (event)=> {this.changeWhs(index, item)}}
                                                                style={{ textAlign: 'center', height: 30, padding: 0 }}>
                                                                    <option value={[user.wareHouse,parseInt(item.OnHandPrincipal)] }>{'Seleccione Almacen'}</option>ESTO NOOO VA 
                                                                    {item.WhsList !== undefined && item.WhsList.length > 0 ?
                                                                       item.WhsList.map((whs) => {
                                                                            if (whs.WhsCode == item.WhsCode ){
                                                                                return <option value={[whs.WhsCode, whs.OnHand] } selected>{whs.WhsName}</option>
                                                                            }else{
                                                                                return <option value={[whs.WhsCode, whs.OnHand]} >{whs.WhsName}</option>
                                                                            }
                                                                        })
                                                                        :
                                                                        <option value={[user.wareHouse, item.OnHandPrincipal]} >{item.WhsName}</option>
                                                                    }
                                                                </select>
                                                            </td  > */}
                                                            {seller && seller.U_FMB_Handel_Perfil != '0' && !isDevolution &&
                                                                <td style={{minWidth: "5rem"}}>
                                                                    {OnHandPrincipal}
                                                                </td>
                                                            }
                                                            {/* Precio original sin iva */}
                                                            {/* {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 && */}
                                                            {flagDiscount &&
                                                                <>
                                                                    <td style={{ color: "#666666",minWidth: "8rem"}}>
                                                                        <CurrencyFormat
                                                                            value={PriceBeforeTax || 0}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2}
                                                                            prefix={MainCurncy !== Currency ? '' : DocCurrCod}
                                                                            suffix={' ' + Currency || ''}>
                                                                        </CurrencyFormat>
                                                                    </td>
                                                                
                                                                    {/* Precio original con iva */}
                                                                    {/* {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 && */}
                                                                    <td style={{ color: "#666666",minWidth: "8rem"}}>
                                                                        <CurrencyFormat
                                                                            value={PriceBeforeDisc || 0}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2}
                                                                            prefix={MainCurncy !== Currency ? '' : DocCurrCod || ''}
                                                                            suffix={' ' + Currency || ''}>
                                                                        </CurrencyFormat>
                                                                    </td>
                                                                    
                                                                    {/* Porcentaje de descuento */}
                                                                    {/* {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 && */}
                                                                    {/* <td style={{minWidth: "5rem"}} scope="col">{item.DiscountPercentSpecial} %</td> */}
                                                                    <td style={{minWidth: "5rem"}}>{Discount} %</td>
                                                                </>
                                                            }
                                                            {/* Precio sin iva */}
                                                            <td style={{ color: "#666666",minWidth: "8rem" }}>
                                                                <CurrencyFormat
                                                                    value={FinalPriceBeforeTax || 0}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={MainCurncy !== Currency ? '' : DocCurrCod}
                                                                    suffix={' ' + Currency || ''}>
                                                                </CurrencyFormat>
                                                            </td>
                                                            {/* Precio con iva */}
                                                            <td style={{ color: "#666666",minWidth: "8rem" }}>
                                                                <CurrencyFormat
                                                                    value={FinalPrice || 0}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={MainCurncy !== Currency ? '' : DocCurrCod}
                                                                    suffix={' ' + Currency || ''}>
                                                                </CurrencyFormat>
                                                            </td>
                                                            {/* Total */}
                                                            <td style={{ color: "#DF7654",minWidth: "8rem" }}>
                                                                <CurrencyFormat
                                                                    value={(FinalPrice * quantity) || 0}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={MainCurncy !== Currency ? '' : DocCurrCod}
                                                                    suffix={' ' + Currency || ''}>
                                                                </CurrencyFormat>
                                                            </td>
                                                            {/* {
                                                                item.U_FMB_Handel_PNTA == "1" &&
                                                                businessPartnerInfo.U_FMB_Handel_CardAct == "1" &&
                                                                businessPartnerInfo.U_FMB_Handel_RedCard != null &&
                                                                config.modules.points &&
                                                                <td style={{minWidth: "8rem"}} >
                                                                    <CurrencyFormat
                                                                        value={Number((item.Price * item.quantity) * 10).toFixed(0)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        fixedDecimalScale={true}>
                                                                    </CurrencyFormat>
                                                                    {` puntos`}
                                                                </td>
                                                            } */}
                                                            <td>
                                                                {quantity &&
                                                                    <img className="trash" src={config.icons.trashRed} alt="trashRed" style={{ width: '15px', height: '18px', marginTop: '-3px', cursor: 'pointer'}} onClick={() => this.deleteItemLocal({ item, deleteAll: false })}/>}
                                                                    {this.validateStock(item)}
                                                            </td>
                                                            <td></td>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-md-12" >
                                                    {itemsRegalos.map((elemenet) => { return this.renderBonificaciones(elemenet, item.ItemCode) })}
                                                    {itemsRegalosSelect.map((elemenet) => { return this.renderBonificaciones(elemenet, item.ItemCode) })}
                                                </div>

                                                <div className="col-md-12" >
                                                    {boxes.map((itemPromo, index) => { return this.renderBoxesProm(itemPromo, item.ItemCode) })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {/* <div className="card-body" style={{overflow:'auto'}}>
                    <table className="table scroll" style={{borderCollapse: 'collapse', borderSpacing: 0, width : '100%', border: '0 solid #ddd'}}>                       
                        <thead >
                            <tr className="text-light bg-primary" >
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px',border: '2px solid #000',borderRadius: '10px'}}></th> 
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px',border: '2px solid #000',borderRadius: '10px'}}></th> 
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px',border: '2px solid #000',borderRadius: '10px'}}>Clave</th> 
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Nombre</th>
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Cantidad</th>
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Cant. Disponible</th>
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Precio</th> 
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Precio con IVA</th>
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Total</th> 
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}></th>                                       
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => {
                                return (
                                <tr key={index}>
                                    <td scope="col" style={{fontSize: 12, padding: '0px',border: '2px solid #000',borderRadius: '10px'}}>{index+1}</td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px',border: '2px solid #000',borderRadius: '10px'}}>
                                        <img className="img-fluid " style={{backgroundColor: 'white',maxHeight: 70}} src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png')}/>
                                        {itemsNotificacion.map((element)=>{
                                            return (
                                                <div className="input-group details">
                                                    {item.ItemCode === element.idProducto &&
                                                    <Promociones
                                                    itemsNotificacion = {element.notificacion}/>}
                                                </div>
                                            )})
                                        }
                                    </td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px',border: '2px solid #000',borderRadius: '10px'}}>{item.ItemCode}</td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px'}}>{item.ItemName}</td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px'}}>
                                        <input
                                            disabled={!(!!item.OnHandPrincipal)}
                                            id={'input-quantity-' + item.ItemCode + index}
                                            type="number"
                                            min="1"
                                            value={item.quantity ? Number(item.quantity) : ''}
                                            className="form-control mb-1"
                                            name={'quantity' + item.ItemCode}
                                            placeholder="1"
                                            style={{ textAlign: 'center', borderRadius: 20, borderColor: "#666666", color: "#666666",width: '60%'}}
                                            onChange={(event) => {  this.changeQuantity(index, item, event) }}
                                            onBlur={(e) => this.validateQuantity(index, item, e)} />
                                    </td>
                                    {seller ? seller.U_FMB_Handel_Perfil != '0' ? 
                                        <td scope="col" style={{fontSize: 12, padding: '0px'}}>
                                            {item.OnHandPrincipal}
                                        </td>
                                        : "" : ""
                                    }
                                    <td scope="col" style={{fontSize: 12, padding: '0px'}}>
                                        <CurrencyFormat
                                            value={item.Price}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            prefix={'$'}>
                                        </CurrencyFormat>
                                    </td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px'}}>
                                        <CurrencyFormat
                                            value={item.priceTax}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            prefix={'$'}>
                                        </CurrencyFormat>
                                    </td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px'}}>
                                        <CurrencyFormat
                                            value={item.priceTax * item.quantity}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            prefix={'$'}>
                                        </CurrencyFormat>
                                    </td>
                                    <td scope="col" style={{padding: '0px'}}>
                                        {item.quantity &&
                                            <i className="fa fa-trash" style={{ cursor: 'pointer', color: '#DF7654' }} onClick={() => this.deleteItemLocal({ item, deleteAll: false })} />}
                                        {this.validateStock(item)}
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        sessionReducer: store.SessionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartList);