import React from "react";
import moment from "moment";
import { config } from "../libs/utils/Const";

class Return2Format extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')),
        };
    };

  render() {
    const { customer} = this.state;
    const { devolution, fechaActual, message, messageCancelación} = this.props;
    console.log('con>message',message);
    
    let year = fechaActual.getFullYear() || '';
    let month = fechaActual.getMonth() + 1 || '';
    let day = fechaActual.getDate() || '';
    //PDF Devolver mercancía 2
    return (
            <div>
              <div className="p-5">
                  <div className="container m-1">
                      <div className="row mb-5 gap-3">
                          <div className="col-2 pl-0 pr-3">
                              <img width="250px" height="auto"
                                  src={config.navBar.icon}
                                  alt="Logo" />
                          </div>
                          <div className="col-10 d-flex justify-content-end align-items-center p-0">
                              <h2 style={{fontWeight: 'bold'}}>Formulario solicitud de reclamación devolución</h2>
                          </div>
                      </div>

                      <div>
                          <div className="d-flex justify-content-end mb-3">
                            <p style={{fontSize: 25}}>México, Pachuca a {day} de {month} del {year}</p>
                          </div>
                            {customer && (
                                <div>
                                    <p style={{ fontSize: 25 }}>México, Pachuca a {day} de {month} del {year}</p>
                                    <div>
                                        <p style={{ fontSize: 25 }}>Por medio del presente, yo, {customer.CardName || ''} solicito a Pérez de la Mora S.A de C.V la devolución de la factura {devolution.DocNum}.</p>
                                    </div>
                                    <div className="mt-5">
                                        <p style={{ fontSize: 25 }}>El motivo para solicitar dicha devolución es: </p>
                                    </div>
                                </div>
                            )}
                            <div className="mt-5">
                                <p style={{ fontSize: 25 }}>El motivo para solicitar dicha devolución es: </p>
                            </div>
                            <div>
                                <p className="mt-3" style={{ fontSize: 25 }}> {message || ''} </p>
                            </div>
                            <div className="mt-5">
                                <p style={{ fontSize: 25 }}>Cordialmete.</p>
                            </div>
                            <div className="d-flex flex-row justify-content-center mt-5">
                                {customer && (
                                    <div className="d-flex flex-row justify-content-center mt-5">
                                        <div>
                                            <p className='mb-0' style={{ fontSize: 25 }}>{customer.CardName || ''}</p>
                                            <p className="border-top border-12 border-black" style={{ fontSize: 25 }}>Nombre y Firma del solicitante.</p>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>

                <div className=" p-5" style={{ marginTop: '10rem' }}>
                    <div className="container m-1">
                        <div className="row mb-5 gap-3">
                            <div className="col-2 pl-0 pr-3">
                                <img width="250px" height="auto"
                                    src={config.navBar.icon}
                                    alt="Logo" />
                            </div>
                            <div className="col-10 d-flex justify-content-end align-items-center p-0">
                                <h2 style={{ fontWeight: 'bold' }}>Formulario solicitud de reclamación cancelación</h2>
                            </div>
                        </div>

                        <div>
                            {customer && customer.CardName && (
                                <div>
                                    <p style={{ fontSize: 25 }}>México, Pachuca a {day} de {month} del {year}</p>
                                    <div>
                                        <p style={{ fontSize: 25 }}>Por medio del presente, yo, {customer.CardName} solicito a Pérez de la Mora S.A de C.V la cancelación de la factura {devolution.DocNum}.</p>
                                    </div>
                                    <div className="mt-5">
                                        <p style={{ fontSize: 25 }}>El motivo para solicitar dicha cancelación es: </p>
                                    </div>
                                </div>
                            )}
                            <div>
                                <p className="mt-3" style={{ fontSize: 25 }}> {messageCancelación || ''} </p>
                            </div>
                            <div className="mt-5">
                                <p style={{ fontSize: 25 }}>Cordialmete.</p>
                            </div>
                            <div className="d-flex flex-row justify-content-center align-items-center mt-5">
                                <div>
                                    <p className='mb-0' style={{ fontSize: 25 }}>{customer?.CardName || 'Default Name'}</p>
                                    <p className="border-top border-12 border-black" style={{ fontSize: 25 }}>Nombre y Firma del solicitante.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
  }
}

export default Return2Format;