import React from "react";
import moment from "moment";
import {config} from '../libs/utils/Const.js';

class OrderPrintFormat extends React.Component {
  render() {
    const { doc } = this.props;
    if (doc.length === 0 || !doc.data || !doc.data.header) {
      return <div></div>;
    }
    let totalPrecioTotal = 0;
    //PDF Pedidos
    return (
      <div style={{ pageBreakBefore: 'always', padding: "30px 50px" }}>
        <div className="d-flex flex-column" style={{ marginTop: "20px" }}>
          {/* Cabecera información */}
          <div className="d-flex flex-row">
            <div>
              <img
                style={{ width: "80%", height: "auto", maxWidth: 200 }}
                src={config.navBar.icon}
                alt="Logo"
              />
              <br />
              <div>
                <h2 class="mt-4 text-left font-weight-bold">
                  Perez de la Mora
                </h2>
                <h5 class="mb-2 text-left">Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000, Jalisco.</h5>
                <h5 class="text-left">ventas@perezdelamora.com</h5>
                <h5 className="text-left">Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37</h5>
              </div>
            </div>

            <div
              className="d-flex justify-content-end"
              style={{
                flexWrap: "wrap",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                content: "center",
              }}
            >
              <div className="text-center">
                <h2 className="font-weight-bold">Pedidos</h2>
              </div>

              {/* Tabla información de cliente */}
              <table style={{ width: "90%", borderCollapse: "collapse" }}>
                <thead>
                  <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                    <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                      Nº Cliente
                    </th>
                    <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                      Nombre
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        border: "0px solid #ddd",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                      className="font-weight-bold"
                    >
                      {doc.data.header && doc.data.header.CardCode
                        ? doc.data.header.CardCode : "N/A"}
                    </td>
                    <td
                      style={{ border: "0px solid #ddd", textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      {doc.data.header && doc.data.header.CardName
                        ? doc.data.header.CardName : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Tabla información de N° Pedido y Fecha */}
              <table style={{ width: "90%", borderCollapse: "collapse" }}>
                <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Nº Pedido
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Fecha
                  </th>
                </tr>
                <tr>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc.data.header && doc.data.header.DocNum
                      ? doc.data.header.DocNum : "--"}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc && doc.data && doc.data.header && doc.data.header.DocDate
                      ? moment(doc.data.header.DocDate).utc().format('YYYY-MM-DD') : "N/A"}
                  </td>
                </tr>
              </table>

              {/* Tabla información de Vendedor Asignado y Direcciones */}
              <table style={{ width: "90%", borderCollapse: "collapse" }}>
                <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Vendedor Asignado
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Domicilio de factura
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Domicilio de entrega
                  </th>
                </tr>
                <tr>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc.data.header && doc.data.header.SlpName
                      ? doc.data.header.SlpName : "N/A"}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc && doc.data && doc.data.header && doc.data.header.Address
                      ? doc.data.header.Address : "N/A"}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc && doc.data && doc.data.header && doc.data.header.Address2
                      ? doc.data.header.Address2 : "N/A"}
                  </td>
                </tr>
              </table>

            </div>
          </div>

          {/* Tabla información de pedidos */}
          <div className="mt-5 d-flex flex-column bd-highlight text-center">
            <table className="text-center">
              <tr className="text-white" style={{ backgroundColor: config.navBar.iconColor }}>
                <th>Cód. Fabricante</th>
                <th>Código SAP</th>
                <th>Cantidad</th>
                <th>Cantidad entregada</th>
                <th>Cantidad faltante</th>
                <th>Precio</th>
                <th>Suma total</th>
              </tr>

              {!!doc &&
                doc.data?.body?.map((item, index) => {
                  const precioTotalItem = parseFloat(item.LineTotal);
                  totalPrecioTotal += precioTotalItem;
                  return (
                    <tr key={index}>
                      <th>{item.SuppCatNum ? item.SuppCatNum : 'N/A'}</th>
                      <th>{item.ItemCode ? item.ItemCode : '--'}</th>
                      <th>{item.Quantity >= 0 ? parseInt(item.Quantity) : "--"}</th>
                      <th>{item.DeliveredQty >= 0 ? parseInt(item.DeliveredQty) : "--"}</th>
                      <th>{item.OpenQty >= 0 ? parseInt(item.OpenQty) : "--"}</th>
                      <th>{item.Price ? "$ " + parseFloat(item.Price).toFixed(2) + " MXN" : '--'}</th>
                      <th>{item.LineTotal ? "$ " + parseFloat(item.LineTotal).toFixed(2) + " MXN" : '--'}</th>
                    </tr>
                  );
                })}
            </table>
          </div>

          {/* Tabla total de total */}
          <div className="d-flex justify-content-end mt-5 text-center">
            <table className="font-weight-bold">
              <tr>
                <th
                  className="text-white p-2"
                  style={{ backgroundColor: config.navBar.iconColor }}
                >
                  Subtotal
                </th>
                <td>{totalPrecioTotal >=0 ? `$ ${totalPrecioTotal.toFixed(2)} MXN` : '--'}</td>
              </tr>
              <tr>
                <th
                  className="text-white"
                  style={{ backgroundColor: config.navBar.iconColor }}
                >
                  IVA 16%
                </th>
                <td className="font-weight-bold">
                  {totalPrecioTotal >=0 ? `$ ${(totalPrecioTotal * 0.16).toFixed(2)} MXN` : '--'}
                </td>
              </tr>
              <tr>
                <th
                  style={{ border: "0px solid #ddd", backgroundColor: config.navBar.iconColor }}
                  className="text-center text-white font-weight-bold"
                >
                  Total
                </th>
                <td className="font-weight-bold">
                  {doc && doc.data && doc.data.header && doc.data.header.DocTotal
                    ? "$ " + parseFloat(doc.data.header.DocTotal).toFixed(2) + " MXN" : "--"}
                </td>
              </tr>
            </table>
          </div>
          <br></br>
          <div style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: 'row'
          }}>
            <span className="font-weight-bold">Comentario:</span>
            <span style={{ marginLeft: '10px' }}>{doc?.data?.header?.Comments || 'Sin comentario disponible'}</span>
          </div>
        </div>
      </div>
    );
  }
}
export default OrderPrintFormat;