import React, { Component } from 'react';
import { config, SERVICE_API, DISPATCH_ID } from "../libs/utils/Const";
import CurrencyFormat from 'react-currency-format';
import { ApiClient } from "../libs/apiClient/ApiClient";
import $ from 'jquery';
import { connect } from 'react-redux';
let apiClient = ApiClient.getInstance();


class RequetsModal extends Component {
    render() {
        const { order, guia, relations, configReducer: { currency } } = this.props;
        return (
            <div className="modal fade" id="RequetsModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: 'center' }}>
                <div className="modal-dialog modal-xl" role="document" style={{ margin: '1.75rem auto' }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: config.navBar.iconColor }}>
                            <h5 className="modal-title" style={{ color: config.navBar.textColor2, fontSize:'14px'}}>Detalle de la Solicitud de Devolución</h5>
                            <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3">
                            {/* Tabla de mapa de relaciones */}
                                <div className="modal-body" >
                                    <div className="contanier">
                                        <div className="row justify-content-around">
                                            <div className="col-12">
                                                <div className="table table-responsive">
                                                    <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                                        <table id="relations" className="display" style={{width: '100%',overflow:'auto'}}>
                                                            <thead className="table-header">
                                                                <tr key="header-relations">
                                                                    <th key="invoices" className="table-header-yellow">Facturas de origen</th>
                                                                    <th key="separator1" className="table-header-yellow" />
                                                                    <th key="returnRequest" className="table-header-yellow">Solicitud de devolución</th>
                                                                    <th key="separator2" className="table-header-yellow" />
                                                                    <th key="return" className="table-header-yellow">Devolución</th>
                                                                    <th key="separator3" className="table-header-yellow" />
                                                                    <th key="creditNote" className="table-header-yellow">Nota de Crédito</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr key="body-relations">
                                                                    <td key="body-invoices" className="dt-row-primary align-middle">
                                                                        {relations?.invoices?.map( invoice => 
                                                                            <>
                                                                                <label>
                                                                                    <i className={`mr-2 fa ${invoice.CANCELED === "Y" ? "fa-close" : ( invoice.DocStatus === "C" ? "fa-lock" : "fa-unlock")}`} title={invoice.CANCELED === "Y" ? "Cancelado" : ( invoice.DocStatus === "C" ? "Cerrado" : "Abierto")} />
                                                                                    {invoice.DocNum}
                                                                                </label>
                                                                                <br />
                                                                            </>)
                                                                        }
                                                                    </td>
                                                                    <td key="body-separator1" className="dt-row-primary align-middle">
                                                                        <img src={config.icons.flechabajo} alt="flechabajo" style={{width: '15px',height: '15px', transform: 'rotate(-90deg)' }} />
                                                                    </td>
                                                                    <td key="body-returnRequest" className="dt-row-primary-light align-middle">
                                                                        {relations?.returnRequests?.map( returnRequest => 
                                                                            <>
                                                                                <label>
                                                                                    <img
                                                                                        src={
                                                                                            returnRequest.CANCELED === "Y"
                                                                                                ? config.icons.times
                                                                                                : returnRequest.DocStatus === "C"
                                                                                                    ? config.icons.unlock
                                                                                                    : config.icons.passwordB
                                                                                        }
                                                                                        alt={returnRequest.CANCELED === "Y" ? "Cancelado" : ( returnRequest.DocStatus === "C" ? "Cerrado" : "Abierto")}
                                                                                        title={returnRequest.CANCELED === "Y" ? "Cancelado" : ( returnRequest.DocStatus === "C" ? "Cerrado" : "Abierto")}
                                                                                        style={{ width: '15px', height: '15px', transform: 'rotate(0deg)', marginRight: '0.5rem' }}
                                                                                    />
                                                                                    {returnRequest.DocNum}
                                                                                </label>
                                                                                <br />
                                                                            </>)
                                                                        }
                                                                    </td>
                                                                    <td key="body-separator2" className="dt-row-primary-light align-middle">
                                                                        <img src={config.icons.flechabajo} alt="flechabajo" style={{width: '15px',height: '15px', transform: 'rotate(-90deg)' }} />
                                                                    </td>
                                                                    <td key="body-return" className="dt-row-primary align-middle">
                                                                        {relations?.returns?.map( returnDoc =>
                                                                            <>
                                                                                <label>
                                                                                    <img
                                                                                        src={
                                                                                            returnDoc.CANCELED === "Y"
                                                                                                ? config.icons.times
                                                                                                : returnDoc.DocStatus === "C"
                                                                                                    ? config.icons.unlock
                                                                                                    : config.icons.passwordB
                                                                                        }
                                                                                        alt={returnDoc.CANCELED === "Y" ? "Cancelado" : ( returnDoc.DocStatus === "C" ? "Cerrado" : "Abierto")}
                                                                                        title={returnDoc.CANCELED === "Y" ? "Cancelado" : ( returnDoc.DocStatus === "C" ? "Cerrado" : "Abierto")}
                                                                                        style={{ width: '15px', height: '15px', transform: 'rotate(90deg)', marginRight: '0.5rem' }}
                                                                                    />
                                                                                    {returnDoc.DocNum}
                                                                                </label>
                                                                                <br />
                                                                            </>)
                                                                        }
                                                                    </td>
                                                                    <td key="body-separator3" className="dt-row-primary align-middle">
                                                                        <img src={config.icons.flechabajo} alt="flechabajo" style={{width: '15px',height: '15px', transform: 'rotate(-90deg)' }} />
                                                                    </td>
                                                                    <td key="body-creditNote" className="dt-row-primary-light align-middle">
                                                                        {relations?.creditNotes?.map( creditNote => 
                                                                            <>
                                                                                <label>
                                                                                    <img
                                                                                        src={
                                                                                            creditNote.CANCELED === "Y"
                                                                                                ? config.icons.times
                                                                                                : creditNote.DocStatus === "C"
                                                                                                    ? config.icons.unlock
                                                                                                    : config.icons.passwordB
                                                                                        }
                                                                                        alt={creditNote.CANCELED === "Y" ? "Cancelado" : ( creditNote.DocStatus === "C" ? "Cerrado" : "Abierto")}
                                                                                        title={creditNote.CANCELED === "Y" ? "Cancelado" : ( creditNote.DocStatus === "C" ? "Cerrado" : "Abierto")}
                                                                                        style={{ width: '15px', height: '15px', transform: 'rotate(90deg)', marginRight: '0.5rem' }}
                                                                                    />
                                                                                    {creditNote.DocNum}
                                                                                </label>
                                                                                <br />
                                                                            </>)
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/*  Fin de Tabla de mapa de relaciones*/}
                            <div>
                                {order.body.map(item => {
                                    return (
                                        <div key={item.ItemCode} className=' text-left card ' style={{ border: 'none' }}>
                                            <div className='row' style={{ padding: 10, textAlign: 'center' }}>
                                                <div className='col-sm-10'>
                                                    <div className="container p-0">
                                                        <div className="row">
                                                            <div className="col-12" style={{ borderBottom: '1px solid rgb(124, 124, 125)' }}>
                                                                <div className='text-left' style={{ color: "#808080" }}>
                                                                    {item.ItemName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row font-weight-bold">
                                                            <div className="col-md-12 table-responsive ">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                                                                            <th className="sticky-header" style={{ backgroundColor: config.navBar.iconColor }}>
                                                                                Artículo
                                                                            </th>
                                                                            <th className="sticky-header" style={{ maxWidth: 800, backgroundColor: config.navBar.iconColor }}>
                                                                                Descripción
                                                                            </th>
                                                                            {/* {(newOrderModel.OriginDocumentCreate === "returnRequest" || newOrderModel.OriginDocumentCreate === "return" || newOrderModel.OriginDocumentCreate === "creditNote") && */}
                                                                            <th className="sticky-header" style={{/*maxWidth: 100,*/minWidth: 115, backgroundColor: config.navBar.iconColor }}>
                                                                                Factura de origen
                                                                            </th>
                                                                            {/* } */}
                                                                            <th className="sticky-header" style={{/*maxWidth: 100,*/minWidth: 115, backgroundColor: config.navBar.iconColor }}>
                                                                                Almacén
                                                                            </th>
                                                                            <th className="sticky-header" style={{/*maxWidth: 100,*/minWidth: 115, backgroundColor: config.navBar.iconColor }}>
                                                                                Cantidad en Almacén
                                                                            </th>
                                                                            {/* {isSelectTo &&  */}
                                                                            <td className="sticky-header" style={{ backgroundColor: config.navBar.iconColor }}>
                                                                            Estatus de devolución
                                                                            </td>
                                                                            <td className="sticky-header" style={{ backgroundColor: config.navBar.iconColor }}>
                                                                            Concepto de devolución
                                                                            </td>
                                                                            <td className="sticky-header" style={{ backgroundColor: config.navBar.iconColor }}>
                                                                            Cantidad
                                                                            </td>
                                                                            {/* } */}
                                                                            <td className="sticky-header" style={{ width: 130, backgroundColor: config.navBar.iconColor }}>
                                                                            Precio
                                                                            </td>

                                                                            {/* Motivo, acción y comentarios de devolución */
                                                                                <>
                                                                                    <td className="sticky-header" style={{ width: 250, backgroundColor: config.navBar.iconColor }}>
                                                                                        Motivo
                                                                                    </td>
                                                                                    <td className="sticky-header" style={{ width: 250, backgroundColor: config.navBar.iconColor }}>
                                                                                        Acción
                                                                                    </td>
                                                                                    <td className="sticky-header" style={{ width: 250, backgroundColor: config.navBar.iconColor }}>
                                                                                        Comentarios
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td className="sticky-header" style={{ minWidth: 115, backgroundColor: config.navBar.iconColor }}>
                                                                                %Desc
                                                                            </td>
                                                                            <td className="sticky-header" style={{ width: 130, backgroundColor: config.navBar.iconColor }}>
                                                                                Precio Bruto
                                                                            </td>
                                                                            <th scope="col">Precio Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td> {item.ItemCode} </td>
                                                                            <td> {item.Dscription} </td>
                                                                            <td> {item.FMB_InvoiceDocNum} </td>
                                                                            <td> {item.WhsCode} </td>
                                                                            <td> {parseInt(item.OnHand)} </td>
                                                                            <td> {item.U_FMB_Return_Status  === 0 ? "En revisión" :
                                                                                    item.U_FMB_Return_Status  === 1 ? "En recolección" :
                                                                                        item.U_FMB_Return_Status  === 2 ? "Recolectado" :
                                                                                            item.U_FMB_Return_Status  === 3 ? "En taller de servicio" :
                                                                                                item.U_FMB_Return_Status  === 4 ? "En entrega cliente" :
                                                                                                    item.U_FMB_Return_Status  === 5 ? "Entregado" :
                                                                                                        item.U_FMB_Return_Status  === 6 ? "Aprobado" :
                                                                                                            item.U_FMB_Return_Status  === 7 ? "Rechazado" :
                                                                                                                item.U_FMB_Return_Status  === 8 ? "Pre Recolección" : "Sin Información" } </td>

                                                                            <td> {item.U_FMB_Handel_Concepto === 0 ? "Garantía" :
                                                                                    item.U_FMB_Handel_Concepto === 1 ? "Aclaración" :
                                                                                        item.U_FMB_Handel_Concepto === 2 ? "Devolución" : "Sin info"} </td>
                                                                            <td> {parseInt(item.Quantity)} </td>
                                                                            <td>
                                                                                <CurrencyFormat
                                                                                    value={item.Price}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix={currency.MainCurncy !== item.Currency ? '' : currency.DocCurrCod}
                                                                                    suffix={' ' + item.Currency}>
                                                                                </CurrencyFormat>
                                                                            </td> 
                                                                             <>
                                                                                <td>{item.Razon || ""}</td>
                                                                                <td>{item.Accion || ""}</td>
                                                                                <td style={{ minWidth: 250 }}>
                                                                                    <textarea
                                                                                    type="text"
                                                                                    className="form-control styleTextarea"
                                                                                    id="txtComments"
                                                                                    placeholder="250 caracteres máximo"
                                                                                    maxLength={250}
                                                                                    disabled={true}
                                                                                    value={item.Comentario || ''}
                                                                                    style={{ backgroundColor: 'transparent', borderColor: '#ced4da', color: '#000' }} />
                                                                                </td>
                                                                            </>                                                                            
                                                                            <td> {item?.DiscountPercent ?? '0.00'} </td>
                                                                            <td>
                                                                                <CurrencyFormat
                                                                                    value={item.LineTotal}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix={currency.MainCurncy !== item.Currency ? '' : currency.DocCurrCod}
                                                                                    suffix={' ' + currency.MainCurncy}>
                                                                                </CurrencyFormat>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <br/>
                            <div
                                style={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                }}
                                >
                                <span className="font-weight-bold">Comentario:</span>
                                <span style={{ marginLeft: '10px' }}>
                                    {order?.body[0]?.Comments || 'Sin comentario disponible'}
                                </span>
                                </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemDetailsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RequetsModal);