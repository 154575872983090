import React, { Component } from 'react';
import { NavBar } from "../components";
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID, SERVICE_RESPONSE, ROLES } from '../libs/utils/Const';
import {connect} from "react-redux";
import { ItemDetailsModal } from "./index";
import './FamilysEditor.css';
import $ from 'jquery';
import 'react-multi-carousel/lib/styles.css';
import { ApiClient } from '../libs/apiClient/ApiClient';
import moment from 'moment';

let apiClient = ApiClient.getInstance();

class FamilysEditor extends Component {

    constructor(props){
        super(props);
        this.state = {
            familySelected : {},
        };
    }
    
    render () {
        const {Familys : {Familys, },} = this.props
        return (
            <div className="content-fluid row" >

                <div className = ' col-12 showList'
                id='showList'
                >
                    {this.showList()}
                </div>
                <div className='col-12 familyChecks d-none'
                id='familyChecks'
                >
                    {this.familyChecks()}
                </div>

            </div>
        );
    }

    showList  () {
        const {Familys : { Familys,  } ,} = this.props;
        return (
            <div className='col-12 p-0'>
                {Familys && Familys.length > 0 &&
                Familys.map((Family) => {
                    return(
                        <div>
                            <div className='col-12 row family-col'
                            style={{backgroundColor: '#ff520094'}}
                            onClick={ () => { this.openFamily(Family) 
                            } }
                            >
                                    <div className='col-6 family-name'>
                                        {Family.Name}
                                    </div>
                                    <div className='col-6 family-nCampos'>
                                        N de campos : {Family.U_campos.length}
                                    </div>
                            </div>
                            <div className="row ">
                                <hr class="Rounded col-12"></hr>
                            </div>
                        </div>
                        )
                    }
                )
                }
            </div>
        );
    }

    familyChecks () {
        const {Familys : {allAtributes, } , refresh} = this.props;
        let { familySelected } = this.state;
        let checkedA = false ;
        
        return(
            <div className='col-12'>
                {allAtributes && allAtributes.length > 0 &&
                    allAtributes.map( (attribute) => {
                        checkedA = false ;
                        if( Object.keys(familySelected).length > 0 && familySelected.U_campos.length > 0 ){
                            checkedA =  familySelected.U_campos.filter( ( campos => campos == attribute.column ) ).length > 0;
                        }
                        return(
                            <div >
                                <div className='col-12 row col-ckeck-text' >
                                    <input className='check-atributes col-1'checked = {checkedA} type="checkbox" id={'CheckF'+attribute.code}
                                    onClick={() => {
                                        this.changeCheck(attribute.column, attribute.code );
                                    }} />
                                    <div className = 'col-check col-5' style={{ alingItems: 'center' }} >
                                        <label className='text-atribut ' for={'CheckF'+attribute.code} > {attribute.name} </label>
                                    </div>
                                    <div className='col-6 col-text-atribut' style={{ alignSelf: 'center' }} >
                                        <img src={config.icons.caret} alt="caret" style={{ width: '15px', height: '15px'}} />
                                        <span className='text-atribut-holver'>{attribute.code}</span>
                                        <label className='text-atribut' > {attribute.code.substring(0, 15 ) + '...'} </label>
                                    </div>
                                </div>
                                <div className = "row col-12" >
                                    <hr class="Rounded col-12"></hr>
                                </div>
                            </div>
                            )
                        }
                    )
                }
                <div className='row'>
                        <div className='col-md-3 Button-C-S-E' style={{marginTop:'10px'}}> 
                            <button 
                                type="button" className="btn btn-dark col-md-10"
                                style={{backgroundColor: config.navBar.iconColor}} 
                                onClick={() => {
                                    this.save();
                                } }
                            >
                                Guardar 
                            </button>
                        </div>

                        <div className='col-md-3 Button-C-S-E' style={{marginTop:'10px'}}> 
                            <button 
                                type="button" className="btn btn-dark col-md-10" 
                                style={{backgroundColor: config.navBar.iconColor}}
                                onClick={() => {
                                    this.cancel();
                                } }
                            >
                                Cancelar 
                            </button>
                        </div>
                </div>
            </div>
        )
    }

    changeCheck(column , code) {
        let { familySelected } = this.state;
        let newAray = [];

        let check = $('#CheckF'+code).prop("checked");

        if(check){
            familySelected.U_campos.push(column);
        }else{
            
           for (let index = 0; index < familySelected.U_campos.length; index++) {
               const element = familySelected.U_campos[index];
               if(element != column){
                newAray.push(element);
               }
           }
           familySelected.U_campos = newAray;
        }

        this.setState({
            familySelected
        });
    }

    cancel(){
        let { familySelected } = this.state;
        const {Familys : {allAtributes, } , refresh} = this.props;
        refresh();
        this.openList();
    }

    save  = async () =>{
        const {notificationReducer: {showAlert, }, Familys : {allAtributes, } , refresh} = this.props;
        let { familySelected } = this.state;;
        let response = await apiClient.updateFamilys( familySelected.Code, familySelected.U_campos );

        if(response.status == SERVICE_RESPONSE.SUCCESS){
            this.openList();
            showAlert({ type: 'success', message: 'Familia actualizada' });
        }else{
            showAlert({type: 'warning', message: response.message , timeOut: 5000});
        }
    }

    openList(){
        $('#familyChecks').addClass('d-none');
        $('#showList').removeClass('d-none');
    }

    openFamily ( family ) {
        // const { Familys, } = this.props;
        let { familySelected } = this.state;
        
        this.setState({
            familySelected: family,
        });
        
        $('#familyChecks').removeClass('d-none');
        $('#showList').addClass('d-none');

    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsFilterSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value}),
        setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        searchByDashOption: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH_OPTION, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FamilysEditor);

