import React from 'react';
import ReactToPrint from 'react-to-print';
import ReportDevolutionModal from './ReportDevolutionModal';
import {config} from "../libs/utils/Const";
 
class ExportDevolutionReportPDF extends React.Component {
    render() {
      const {date,data,user,Total, seller,folio,shipDate,radiobutton} = this.props;
      return (
        <div>
           <ReactToPrint
           seller={seller}
                content={() => this.componentRef}
                trigger={() =>  <button className="btn impr botonResumen" style={{ backgroundColor: config.navBar.iconColor, color: config.navBar.textColor2, fontWeight: "bold"}}><i class="fas fa-print"></i> Imprimir PDF</button>}
            />
            <div style={{ display: "none" }}>
                <ReportDevolutionModal ref={(response) => (this.componentRef = response)} radiobutton={radiobutton} date={date} data={[data]} user={user} Total={Total} seller={seller} folio={folio} shipDate={shipDate}/>
            </div>
        </div>
      );
    }
}
 
export default ExportDevolutionReportPDF;