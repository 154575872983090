import React, { Component } from 'react';
import { config, SERVICE_API, DISPATCH_ID } from "../libs/utils/Const";
import { ApiClient } from "../libs/apiClient/ApiClient";
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';

let apiClient = ApiClient.getInstance();

class StockDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    handleOpenModal = () => {
        this.setState({ showModal: true });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { itemsReducer: { itemDetails, whsGeneralName} } = this.props;
        return (
            <div>
                {itemDetails && (
                        <div className="modal fade" id="StckDet" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{border: "none", textAlign: 'center'}}>
                        <div className="modal-dialog modal-lg" role="document" style={{margin: '1.75rem auto'}}>
                                <div className="modal-content">
                                    <div className="modal-header" style={{backgroundColor: config.navBar.iconColor}}>
                                        <h5 className="modal-title text-white">
                                            Stock por almacén
                                        </h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                    <p className="card-title text-left font-weight-bold" style={{ fontSize: 15, color: 'rgb(0 0 0 / 69%)' }}>
                                        <span> Stock : </span> <span>{parseFloat(itemDetails.OnHand).toFixed(2) || '0.00'}</span>
                                    </p>
                                    <p className="card-title text-left font-weight-bold" style={{ paddingTop: 5, fontSize: 15, color: 'rgb(0 0 0 / 69%)' }}>
                                        <span> Stock almacen {whsGeneralName ? `'${whsGeneralName}'` : `'defecto'`} : </span><span>{parseFloat(itemDetails.OnHandPrincipal).toFixed(2) || '0.00'}</span>
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        );

    }
}
const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemDetailsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StockDetalle);