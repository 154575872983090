import React, { Component } from 'react';
import {Session } from '../../components';
import { VIEW_NAME, config } from "../../libs/utils/Const";
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap'; // Importa el componente Button de Bootstrap

class AppsView extends Component {
    render() {
        const { history } = this.props;
        return (
            <div className="content-fluid margenSuperiorMenuCategorias" style={{ marginTop: 0, paddingRight: 0, backgroundColor: "#FFF" }}>
                <Session history={history} view={VIEW_NAME.APPS_US_VIEW} />

                <div className='container'>
                    <div className="row" style={{ display: "flex", flexDirection: "column", alignContent: "center", fontSize: '20px', fontWeight: 'bold', }}>
                        <br />
                        <br />
                        <div>
                            <p>Descargas:</p>
                        </div>
                        <div>
                            <span>Versión 1.0</span>
                        </div>
                        <br />
                        <div className="col-md-12" style={{ display: 'flex', alignContent: 'center', fontSize: '16px', justifyContent: "center", alignItems: "center" }}>
                            <p>¡Bienvenido! Descargue nuestras apps de ventas y entregas, instale la aplicación en su dispositivo y desbloquee un universo de posibilidades.</p>
                        </div>
                    </div>
                    <br />
                    <div className="row" style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
                        <div className="col-md-12" style={{ border: '1px solid gray', padding: '10px', borderRadius: '10px' }}>
                            <div style={{fontSize: '16px', fontWeight: 'bold', display: "flex", aligItems:"center", justifyContent: "center"}}>
                            <span>Descargue nuestra aplicación de ventas y entregas.</span>
                            </div>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        {/* Primera columna */}
                                        <td style={{ textAlign: 'center', borderTop: "1px solid #dee2e652"}}>
                                            <div style={{backgroundColor:'#80808085'}}>
                                                <img src={require('../../images/perez/apps/M-entregas.svg')} alt="Entregas" className="img-fluid" style={{ maxWidth: '100%', height: 'auto', width: '80px', height: '80px' }} />
                                            </div>
                                            <div style={{marginTop: "30px" }}>
                                                <img src={require('../../images/perez/androide.png')} alt="Android" className="img-fluid" style={{ maxWidth: '100%', height: 'auto', width: '80px', height: '80px' }} />
                                            </div>
                                            <div style={{marginTop:'10px'}}>
                                                <span style={{ fontSize: '14px' }}>
                                                    Android Installer
                                                </span>
                                            </div>
                                            <div>
                                                <button style={{ backgroundColor: 'rgb(240, 124, 0)', color: 'white', borderRadius: '10px', fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>
                                                    Descargar
                                                </button>
                                            </div>
                                        </td>
                                        {/* Segunda columna */}
                                        <td style={{ textAlign: 'center', borderTop: "1px solid #dee2e652"}}>
                                            <div style={{backgroundColor:'#80808085'}}>
                                                <img src={require('../../images/perez/apps/M-ventas.svg')} alt="Ventas" className="img-fluid" style={{ maxWidth: '100%', height: 'auto', width: '80px', height: '80px' }} />
                                            </div>
                                            <div style={{marginTop: "30px" }}>
                                                <img src={require('../../images/perez/androide.png')} alt="Android" className="img-fluid" style={{ maxWidth: '100%', height: 'auto', width: '80px', height: '80px' }} />
                                            </div>
                                            <div  style={{marginTop:'10px'}}>
                                                <span style={{ fontSize: '14px' }}>
                                                    Android Installer
                                                </span>
                                            </div>
                                            <div>
                                                <button style={{ backgroundColor: 'rgb(240, 124, 0)', color: 'white', borderRadius: '10px', fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>
                                                    Descargar
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>

        );
    }
}

const mapStateToProps = (store) => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppsView);
