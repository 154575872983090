import React from "react";
import moment from "moment";
import { config } from '../libs/utils/Const.js';

class ReportModal extends React.Component {
  render() {
    const { date, data, user, SubTotal, seller, currentCart, } = this.props;
    if (data.length === 0) {
      return <div></div>;
    }
    // PDF CARRITOS GUARDADOS 'BOTON DETALLE'
    return (
      <div className="d-flex flex-column" style={{ marginTop: "20px" }}>
        {/* Cabecera información */}
        <div className="d-flex flex-row">
          <div>
            <img
              style={{ width: "80%", height: "auto", maxWidth: 200 }}
              src={config.navBar.icon}
              alt="Logo"
            />
            <br />
            <div>
              <h2 class="mt-4 text-left font-weight-bold">
                Perez de la Mora
              </h2>
              <h5 class="mb-2 text-left">Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000, Jalisco.</h5>
              <h5 class="text-left">ventas@perezdelamora.com</h5>
              <h5 className="text-left">Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37</h5>
            </div>
          </div>

          <div
            className="d-flex justify-content-end"
            style={{
              flexWrap: "wrap",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              content: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h2 style={{ fontWeight: "bold" }}>Cotización</h2>
            </div>

            {/* Tabla información de cliente */}
            <table style={{ width: "90%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Nº Cliente
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Nombre
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "0px solid #ddd", textAlign: "center", fontWeight: "bold",
                    }}
                    className="font-weight-bold"
                  >
                    {currentCart ? currentCart.CardCode : 'N/A'}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {user ? user.CardName : 'N/A'}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Tabla información de carrito */}
            <table className='text-center' style={{ width: "90%", borderCollapse: "collapse" }}>
              <tr style={{ backgroundColor: config.navBar.iconColor, color: "white", border: "0px solid #ddd" }}>
                <th >
                  Nº Carrito
                </th>
                <th >
                  Fecha
                </th>
                <th >
                  Vendedor
                </th>
              </tr>
              <tr>
                <td className="font-weight-bold">
                  {currentCart ? currentCart.id : '--'}
                </td>
                <td className="font-weight-bold">
                  {date ? date : 'N/A'}
                </td>
                <td className="font-weight-bold">
                  {seller ? (seller.firstName || '') + ' ' + (seller.lastName || '') : 'N/A'}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          className="d-flex justify-content-around"
          style={{ marginTop: "100px" }}
        >
          {/* Tabla productos de carrito */}
          <table className='text-center' style={{ width: "100%", borderCollapse: "collapse" }}>
            <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
              <th>
                Cód. Fabricante
              </th>
              <th>
                Código SAP
              </th>
              <th>
                Descripción
              </th>
              <th>
                Precio
              </th>
              <th>
                Precio total
              </th>
            </tr>
            {!!data &&
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <th>{item.SuppCatNum ? item.SuppCatNum : 'N/A'}</th>
                    <th>{item.ItemCode ? item.ItemCode : '--'}</th>
                    <th>{item.ItemName ? item.ItemName : 'N/A'}</th>
                    <th>
                      ${" "}
                      {Number(
                        item.Price -
                        (item.Price * (item.Discount || 0 / 100)) / 100
                      ).toFixed(2)} MXN
                    </th>
                    <th>
                      ${" "}
                      {item.newTotal
                        ? Number(item.newTotal).toFixed(2)
                        : Number(
                          (
                            item.Price -
                            (item.Price * (item.Discount || 0 / 100)) / 100
                          ).toFixed(2) * item.Quantity
                        ).toFixed(2)} MXN
                    </th>
                  </tr>
                );
              })}
          </table>
        </div>

        {/* Tabla total de total */}
        <div className="d-flex justify-content-end">
          <table className="p-3 text-center mt-5">
            <tr>
              <th
                style={{
                  padding: "5px",
                  border: "0px solid #ddd",
                  backgroundColor: config.navBar.iconColor,
                }}
                className="text-center text-white font-weight-bold"
              >
                Subtotal
              </th>
              <td className="font-weight-bold">
                $ {parseFloat(SubTotal).toFixed(2)} MXN
              </td>
            </tr>
            <tr>
              <th
                style={{
                  border: "0px solid #ddd",
                  backgroundColor: config.navBar.iconColor,
                }}
                className="text-center text-white font-weight-bold"
              >
                IVA 16%
              </th>
              <td className="font-weight-bold">
                $ {parseFloat(SubTotal * 0.16).toFixed(2)} MXN
              </td>
            </tr>
            <tr>
              <th
                style={{
                  border: "0px solid #ddd",
                  backgroundColor: config.navBar.iconColor,
                }}
                className="text-center text-white font-weight-bold"
              >
                Total
              </th>
              <td className="font-weight-bold">
                $ {parseFloat(SubTotal * 0.16 + SubTotal).toFixed(2)} MXN
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default ReportModal;