import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import {DISPATCH_ID, ROLES, config, SERVICE_RESPONSE} from '../libs/utils/Const';
import {ApiClient} from '../libs/apiClient/ApiClient.js';

let apiClient = ApiClient.getInstance();

class NavBarShoppingCartIcon extends Component {

    componentDidMount() {
        $('.cont-cart').hide();
    }

    refresh = () => {
        const {shoppingCartReducer} = this.props;

        setTimeout(() => {
            if (shoppingCartReducer.items.length === 0) {
                $('.cont-cart').hide();
            } else {
                $('.cont-cart').show();
            }
        }, 50);
    }

    GetShoppingCart = async () =>{
        const {setRole, itemsReducer, sessionReducer, setShoppingCart, notificationReducer: {showAlert}} = this.props;
        let shoppingCart = [];
        if (!sessionReducer.token || !sessionReducer.user) {
            setRole(ROLES.PUBLIC);
            let localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');

            localShoppingCart = JSON.parse(localShoppingCart);

            if (!localShoppingCart) {
                localStorage.setItem(config.general.localStorageNamed + 'shoppingCart', JSON.stringify([]));
            }
            shoppingCart = localShoppingCart || []
        } else {
            setRole(ROLES.CLIENT);
            let response = await apiClient.getProfile();
            if (response.status === SERVICE_RESPONSE.ERROR) {
                if(sessionReducer.user.CardCode){
                    showAlert({type: 'error', message: "Aviso: "+ response.message  , timeOut: 10});
                }
            } else {
                shoppingCart = response.data.shoppingCart;
            }

        }

        let whs = '';
        if(itemsReducer){
            whs = itemsReducer.whsGeneral;
        }
        let response = await apiClient.getShoppingCart(shoppingCart, whs);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            if(sessionReducer.user.CardCode){
                showAlert({type: 'error', message: "Aviso: "+ response.message  , timeOut: 10});
            }
        } else {
            shoppingCart = response.data.shoppingCart;
        }
        setShoppingCart(shoppingCart);
        
    }

    render() {
        const {shoppingCartReducer, itemsReducer: {auxLocation}, configReducer, setAuxLocation} = this.props;

        // Determina si se deben incluir los atributos del modal
        const modalAttributes = auxLocation !== 'ValidateOrder' ? { //Solo desde ValidateOrder no se abre el carrito lateral
            'data-toggle': 'modal',
            'data-target': '#offcanvasRight',
            'aria-controls': 'offcanvasRight'
        } : {};
        return (

            <span className="shooping" /*data-toggle="modal" data-target="#offcanvasRight" aria-controls="offcanvasRight" */ {...modalAttributes}
                onClick={async () => { auxLocation !== 'ValidateOrder' ? await this.GetShoppingCart() : await configReducer.history.goShoppingCart(); await setAuxLocation(null); }}>
                <img src={config.navBar.carrito} className="Img-fluid" alt='Carrito de compras'/>
                <span className="cont-cart" style={{color: "#575757", fontFamily: "Helvetica"}}>{shoppingCartReducer.items.length}</span>{this.refresh()}
                {/* <i className={icon + " fa-lg, shooping"} style={{ color: textColor2, fontSize: 25}}
                onClick={() => history.goShoppingCart()}>&nbsp;
                    <span className="cont-cart ml-1" style={{color: "#575757", fontFamily: "Helvetica"}}>{shoppingCartReducer.items.length}</span>{this.refresh()}
                </i>*/}
            </span>
        );
    }
}

const mapStateToProps = store => {
    return {
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
        setIdCategory: value => dispatch({type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value}),
        setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        setLocation:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_LOCATION, value}),
        setRole: value => dispatch({type: DISPATCH_ID.SESSION_SET_ROLE, value}),
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setAuxLocation: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_AUX_LOCATION, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBarShoppingCartIcon)

