import React, { Component } from 'react';
import { Session, OrderDetailsModal } from "../../components";
import { DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE, VIEW_NAME, config } from "../../libs/utils/Const";
import { ApiClient } from "../../libs/apiClient/ApiClient";
import { connect } from "react-redux";
import moment from 'moment';
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import ExportReportGeneral from '../../components/ExportReportGeneral';
require('datatables.net-bs4');

const apiClient = ApiClient.getInstance();

class GeneralView extends Component {
    csvLink = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            generalItems: [],
            order: {
                header: {},
                body: []
            },
            typeCondition: 0,
            tableToExcel: [],
            guia: []
        };
        this.table = null;
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser'));
        this.setState({
            typeCondition: user ? user.GroupNum : 0
        });
        this.fillDataGeneral();
    }

    fillDataGeneral = () => {
        this.table = $('#tablaGeneral').DataTable({
            "paging": true,
            "info": false,
            "searching": false,
            //"bDestroy": true,	
            "order": [[0, 'desc']],
            "columnDefs": [
                { type: 'num', targets: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] } // Especificar que estas columnas son numéricas para mejor ordenamiento
            ],
            "language": {
                "lengthMenu": "Mostrar _MENU_ registros por página",
                "zeroRecords": "No se encontraron registros",
                "info": "Mostrando página _PAGE_ de _PAGES_",
                "infoEmpty": "No existen registros",
                "infoFiltered": "(filtrado de _MAX_ entradas)",
                "loadingRecords": "Buscando...",
                "processing": "Procesando...",
                "search": "Buscar:",
                "paginate": {
                    "first": "Primero",
                    "last": "Último",
                    "next": "Siguiente",
                    "previous": "Anterior"
                }
            }
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.generalItems != nextProps.generalItems) {
            this.table.destroy();
            this.table = null;
        }
        return true;
    }

    componentDidUpdate() {
        if (this.table == null) {
            this.fillDataGeneral();
        }
    }

    exportToExcel = () => {
        const { generalItems, popularGeneralItems, notificationReducer: { showAlert } } = this.props;

        if (generalItems && generalItems.length > 0 && popularGeneralItems) {
            const tableData = generalItems.map(item => ({
                "Código del Artículo": item.ItemCode || '',
                // "Documentos Totales": item.DocTotals || '',
                "Cotización": item.OQUT || 0,
                "Porcentaje en Cotización": (item.PercentageOQUT ?? "0.0").toFixed(2) + ' %',
                "Cantidad en Cotización": (item.QuantityOQUT ?? "0.0").toFixed(2),
                "Pedidos": item.ORDR || 0,
                "Porcentaje en Pedidos": (item.PercentageORDR ?? "0.0").toFixed(2) + ' %',
                "Cantidad en Pedidos": (item.QuantityORDR ?? "0.0").toFixed(2),
                "Entregas": item.ODLN || 0,
                "Porcentaje en Entregas": (item.PercentageODLN ?? "0.0").toFixed(2) + ' %',
                "Cantidad en Entregas": (item.QuantityODLN ?? "0.0").toFixed(2),
                "Facturas": item.OINV || 0,
                "Porcentaje en Facturas": (item.PercentageOINV ?? "0.0").toFixed(2) + ' %',
                "Cantidad en Facturas": (item.QuantityOINV ?? "0.0").toFixed(2),
            }));

            const popularItemsData = [
                {
                    "En más Cotizaciones": popularGeneralItems?.OQUT?.maxOccurrenceItem?.ItemCode || '',
                    "Mayor Cantidad Cotizado": popularGeneralItems?.OQUT?.maxQuantityItem?.ItemCode || '',
                    "En más Pedidos": popularGeneralItems?.ORDR?.maxOccurrenceItem?.ItemCode || '',
                    "Mayor Cantidad Pedido": popularGeneralItems?.ORDR?.maxQuantityItem?.ItemCode || '',
                    "En más Entregas": popularGeneralItems?.ODLN?.maxOccurrenceItem?.ItemCode || '',
                    "Mayor Cantidad Entregado": popularGeneralItems?.ODLN?.maxQuantityItem?.ItemCode || '',
                    "En más Facturas": popularGeneralItems?.OINV?.maxOccurrenceItem?.ItemCode || '',
                    "Mayor Cantidad Facturado": popularGeneralItems?.OINV?.maxQuantityItem?.ItemCode || '',
                }
            ];

            const worksheetGeneral = XLSX.utils.json_to_sheet(tableData);
            const worksheetPopular = XLSX.utils.json_to_sheet(popularItemsData);

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, worksheetGeneral, "General");
            XLSX.utils.book_append_sheet(wb, worksheetPopular, "Popular"); // Segunda hoja

            // Guardar el archivo
            XLSX.writeFile(wb, "Análisis-General.xlsx");
        } else {
            // Manejar caso donde no hay datos para exportar
            showAlert({ type: 'info', message: 'No se ha podido generar el archivo porque no se encontraron resultados para su búsqueda' });
        }
    }

    // openOrder = async docEntry => {
    //     const { enableSpinner, notificationReducer: { showAlert } } = this.props;
    //     enableSpinner(true);
    //     let response = await apiClient.getDataBill(docEntry);
    //     enableSpinner(false);

    //     if (response.status === SERVICE_RESPONSE.SUCCESS) {
    //         this.setState({
    //             order: response.data,
    //             guia: response.data.statusGuia || [],
    //         });

    //         $('#billModal').modal('show');
    //         return;
    //     }

    //     showAlert({ type: 'error', message: "Aviso: " + response.message })
    // };

    // openPDF = async docEntry => {
    //     const { enableSpinner, notificationReducer: { showAlert } } = this.props;
    //     enableSpinner(true);
    //     let response = await apiClient.getDataBill(docEntry);
    //     enableSpinner(false);

    //     if (response.status === SERVICE_RESPONSE.SUCCESS) {
    //         this.setState({
    //             order: response.data,
    //         });

    //         $('#billModal').modal('show');
    //         return;
    //     }

    //     showAlert({ type: 'error', message: "Aviso: " + response.message })
    // };

    // docChangeName(status) {
    //     let result = '';
    //     switch (status) {
    //         case 'O':
    //             result = "Abierta";
    //             break;
    //         case 'C':
    //             result = "Cerrada";
    //             break;
    //         default:
    //             break;
    //     }
    //     return result;
    // };

    // BaseType = (baseType) => {
    //     let result = '';
    //     switch (baseType) {
    //         case 17:
    //             result = "Pedido";
    //             break;
    //         case 15:
    //             result = "Entrega";
    //             break;
    //         default:
    //             break;
    //     }
    //     return result
    // }

    // docChangeNameFMB(target,base){
    //     let result = '';
    //     let response = target != -1 ? target : base
    //     switch (response) {
    //         case 23:
    //             result = "Sin atender";
    //             break;
    //         case 17:
    //             result = "Atendido";
    //             break;
    //         case 15:
    //             result = "Surtido";
    //             break;
    //         case 13:
    //             result = "Facturado";
    //             break;
    //         case 14:
    //             result = "Nota de crédito";
    //             break;
    //         default:
    //             result = 'Facturado';
    //             break;
    //     }
    //     return result;
    // };

    // exportCSVOrders = async docEntry => {
    //     const { getData, notificationReducer: { showAlert }, configReducer: { currency } } = this.props;
    //     await getData(6);
    //     setTimeout(async () => {
    //         const { generalItems } = this.props;
    //         if (generalItems.length > 0) {
    //             let minNewOrders = [];
    //             generalItems.map((order, index) => {
    //                 minNewOrders.push(
    //                     {
    //                         "Fecha de contabilización": moment(order.DocDate).format('YYYY-MM-DD'),
    //                         "No. de factura": order.DocNum,
    //                         "Documento origen": this.BaseType(order.BaseType),
    //                         "No. de relación": order.BaseRef,
    //                         "Fecha de vencimiento": moment(order.DocDueDate).format('YYYY-MM-DD'),
    //                         "Valor total":  'MXN ' + Number(order.DocTotal).toFixed(2),
    //                         "Total pagado": 'MXN ' + Number(order.PaidToDate).toFixed(2),
    //                         "Estado del documento": this.docChangeName(order.DocStatus),
    //                     }
    //                 );
    //             });
    //             minNewOrders.push(
    //                 {
    //                     "Fecha de contabilización": '',
    //                     "No. de factura": '',
    //                     "Documento origen": '',
    //                     "No. de relación": '',
    //                     "Fecha de vencimiento": '',
    //                     "Valor total": '',
    //                     "Total pagado": '' ,
    //                     "Estado del documento": '',
    //                 }
    //             );
    //             minNewOrders.push(
    //                 {
    //                     "Fecha de contabilización": '',
    //                     "No. de factura": '',
    //                     "Documento origen": 'Clave',
    //                     "No. de relación": 'Nombre',
    //                     "Fecha de vencimiento": 'Cantidad',
    //                     "Valor total": 'Precio',
    //                     "Total pagado": 'Precio total' ,
    //                     "Estado del documento": '',
    //                 }
    //             );
    //             let response = await apiClient.getDataBill(docEntry);
    //             response.data.body.map((body, index) => {
    //                 minNewOrders.push(
    //                     {
    //                         "": '',
    //                         "Artículo": body.ItemCode,
    //                         "Nombre del artículo": body.Dscription,
    //                         "Cantidad": body.Quantity,
    //                         "Precio": (currency.MainCurncy !== body.Currency ? '' : currency.DocCurrCod) + ' ' + Number(body.Price).toFixed(2) + ' ' + body.Currency,
    //                         "Precio total": (currency.MainCurncy !== body.Currency ? '' : currency.DocCurrCod) + ' ' + Number(body.LineTotal).toFixed(2) + ' ' + body.Currency,
    //                         "": '',
    //                     }
    //                 );
    //             })
    //             this.setState({ tableToExcel: minNewOrders });
    //             setTimeout(() => {
    //                 this.csvLink.current.link.click();
    //             }, 500);
    //         } else {
    //             showAlert({ type: 'info', message: 'No se ha podido generar sus archivo, porque no se encontraron resultado para su búsqueda' });
    //         }
    //     }, 500);
    // }

    render() {
        const { order, typeCondition, tableToExcel, guia } = this.state;
        const { generalItems, getData, handleInputDateInicio, handleInputDateFinal, fechaFinal, fechaInicio, fechamin, fechamax, isLoaded, configReducer: { currency }, enableSpinner, docsToPrint, changeDocsToPrint, filter, handleInputFilter, popularGeneralItems, warehouses, analyticWhs, handleSelectedFilter } = this.props

        return (
            <div className="content-fluid" style={{ backgroundColor: config.Back.backgroundColor }}>
                {/* <CSVLink 
                    data={tableToExcel} 
                    filename ={`Factura.csv`}                
                    className="hidden"
                    ref={this.csvLink}
                    target="_blank">
                </CSVLink> */}
                {/* <BillDetailModel order={order} guia={guia}/> */}
                <div className="row text-center" style={{ paddingTop: 16, paddingBottom: 16 }}>
                    <div className="col-md-12 pb-2">
                        <h3 style={{ fontWeight: "bolder", fontSize: "1rem", color: "black" }}>TRANSACCIONES DE ARTÍCULOS EN ÁREAS</h3>
                    </div>
                </div>
                <div className="row text-center" style={{ marginBottom: 16, marginTop: 16 }}>
                    <div className="col-md-3 pb-2">
                        <div>
                            <div class="search" style={{ justifyContent: "center", display: 'flex' }}>
                                <input
                                    id="inputSearch"
                                    className="form-control col-md-6 searchTerm"
                                    type="text"
                                    placeholder="Artículo"
                                    value={filter}
                                    // disabled={docsToPrint?.length !== 0}
                                    onChange={(event) => handleInputFilter(event)}
                                    // onKeyDown={event => event.keyCode === 13 && filter.trim() !== '' && getData('A2', filter)}
                                    onKeyDown={event => event.keyCode === 13 && getData('A2', filter)}
                                />

                                <button
                                    type="button"
                                    className="searchButton"
                                    // onClick={() => filter.trim() !== '' && getData('A2', filter)} disabled={docsToPrint.length !== 0}
                                    onClick={() => getData('A2', filter)}
                                    // disabled={docsToPrint?.length !== 0}
                                    style={{ backgroundColor: config.navBar.menuCategoriesBackgroundHover }}
                                >
                                    <img alt="searchWhite" style={{ width:'16px', height:'16px'}} src={config.icons.searchWhite} />
                                </button>
                            </div>
                        </div>
                    </div>
                    {warehouses ?
                        <div className=" row col-md-3">
                            <select name="warehouses" placeholder="Filtrar por almacén" value={analyticWhs} className="form-control text-left" onChange={async (event) => await handleSelectedFilter('A2', event)} style={{ textAlign: 'center', height: 30, padding: 0 }}>
                                <option value="ALL">Todos los almacenes</option>
                                {
                                    warehouses.map((whs, index) => (
                                        <option value={whs.WhsCode} key={index}>{whs.WhsName}</option>
                                    ))}
                            </select>
                        </div>
                        : <></>}
                    <div className=" row col-md-3">
                        <h4 className="pr-2">Desde:</h4>
                        <input
                            id="fechaInicio"
                            type="date"
                            className="form-control col-md-6"
                            name="fechauno"
                            min={fechamin}
                            max={fechamax}
                            value={!isLoaded ? fechamin : fechaInicio}
                            onChange={(event) => handleInputDateInicio(event)}
                        />
                    </div>
                    <div className="row col-md-4 pb-3">
                        <h4 className="pr-2">Hasta:</h4>
                        <input
                            id="FechaFin"
                            type="date"
                            className="form-control col-md-6"
                            name="fechados"
                            min={fechamin}
                            max={fechamax}
                            value={fechaFinal}
                            onChange={(event) => handleInputDateFinal(event)}
                        />
                    </div>
                    <div className="col-md-2 pb-2">
                        <button
                            onClick={() => getData('A2')}
                            className="btn botonResumen"
                            style={{
                                backgroundColor: config.navBar.menuCategoriesBackgroundHover,
                                color: config.navBar.textColor2,
                                fontWeight: "bold",
                            }}
                        >
                            Ver Generales
                        </button>
                    </div>
                </div>
                <div className="col ml-1">
                    {/* {docsToPrint.length > 0 &&
                        <ExportReportGeneral
                            data={docsToPrint}
                            typeDocs={"invoices"}
                            enableSpinner={enableSpinner}
                        />
                    } */}
                    {/* Button to trigger CSV export */}
                </div>
                <div className="row text-center" style={{ marginTop: '15px' }}>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">En más Cotizaciones</th>
                                    <td>{popularGeneralItems?.OQUT?.maxOccurrenceItem?.ItemCode || ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">En más Pedidos</th>
                                    <td>{popularGeneralItems?.ORDR?.maxOccurrenceItem?.ItemCode || ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">En más Entregas</th>
                                    <td>{popularGeneralItems?.ODLN?.maxOccurrenceItem?.ItemCode || ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">En más Facturas</th>
                                    <td>{popularGeneralItems?.OINV?.maxOccurrenceItem?.ItemCode || ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row text-center mt-2">
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Mayor Cantidad Cotizado</th>
                                    <td>{popularGeneralItems?.OQUT?.maxQuantityItem?.ItemCode || ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Mayor Cantidad Pedido</th>
                                    <td>{popularGeneralItems?.ORDR?.maxQuantityItem?.ItemCode || ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Mayor Cantidad Entregado</th>
                                    <td>{popularGeneralItems?.ODLN?.maxQuantityItem?.ItemCode || ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Mayor Cantidad Facturado</th>
                                    <td>{popularGeneralItems?.OINV?.maxQuantityItem?.ItemCode || ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <br />
                <div className="row">
                    <div style={{ cursor: 'pointer' }}>
                        <button onClick={this.exportToExcel} className="btn btn-success">
                            Exportar a Excel
                        </button>
                    </div>
                    <div className="col-md-12 tableReports table-responsive">
                        <table id="tablaGeneral" className="table table-striped scrolltable">
                            <thead style={{ textAlign: "-webkit-center" }}>
                                <tr style={{ backgroundColor: config.shoppingList.summaryList, color: "white", fontSize: "15px", cursor: 'pointer' }}>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}></th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Código del Artículo</th>
                                    {/* <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Documentos Totales</th> */}
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Cotización</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Porcentaje en Cotización</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Cantidad en Cotización</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Pedidos</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Porcentaje en Pedidos</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Cantidad en Pedidos</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Entregas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Porcentaje en Entregas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Cantidad en Entregas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Facturas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Porcentaje en Facturas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Cantidad en Facturas</th>
                                    {/* <th scope="col-lg-auto">ORDN</th>
                                    <th scope="col-lg-auto">Cantidad</th> */}
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {generalItems && generalItems?.length ?
                                    generalItems.map((generalItem, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope="col-lg-auto row" style={{ verticalAlign: "middle" }}>
                                                    {/* <div class="form-check" style={{ minWidth: "70px" }}>
                                                    <input class="form-check-input" type="checkbox" value={order.DocEntry} onChange={(event) => changeDocsToPrint(event.nativeEvent.target.value)} id="ordersToPrint" />
                                                </div> */}
                                                </th>
                                                <th scope="col-lg-auto row text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{generalItem.ItemCode || ''}</th>
                                                {/* <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{generalItem.DocTotals || ''}</td> */}
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{generalItem.OQUT || 0}</td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }} data-order={parseFloat(generalItem.PercentageOQUT) ?? 0.0}>
                                                    <CurrencyFormat
                                                        value={generalItem.PercentageOQUT ?? "0.0"}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        suffix={' %'}>
                                                    </CurrencyFormat>
                                                </td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }} data-order={parseFloat(generalItem.QuantityOQUT) ?? 0.0}>
                                                    <CurrencyFormat
                                                        value={generalItem.QuantityOQUT ?? "0.0"}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}>
                                                    </CurrencyFormat>
                                                </td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{generalItem.ORDR || 0}</td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }} data-order={parseFloat(generalItem.PercentageORDR) ?? 0.0}>
                                                    <CurrencyFormat
                                                        value={generalItem.PercentageORDR ?? "0.0"}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        suffix={' %'}>
                                                    </CurrencyFormat>
                                                </td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }} data-order={parseFloat(generalItem.QuantityORDR) ?? 0.0}>
                                                    <CurrencyFormat
                                                        value={generalItem.QuantityORDR ?? "0.0"}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}>
                                                    </CurrencyFormat>
                                                </td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{generalItem.ODLN || 0}</td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }} data-order={parseFloat(generalItem.PercentageODLN) ?? 0.0}>
                                                    <CurrencyFormat
                                                        value={generalItem.PercentageODLN ?? "0.0"}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        suffix={' %'}>
                                                    </CurrencyFormat>
                                                </td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }} data-order={parseFloat(generalItem.QuantityODLN) ?? 0.0}>
                                                    <CurrencyFormat
                                                        value={generalItem.QuantityODLN ?? "0.0"}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}>
                                                    </CurrencyFormat>
                                                </td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{generalItem.OINV || 0}</td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }} data-order={parseFloat(generalItem.PercentageOINV) ?? 0.0}>
                                                    <CurrencyFormat
                                                        value={generalItem.PercentageOINV ?? "0.0"}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        suffix={' %'}>
                                                    </CurrencyFormat>
                                                </td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }} data-order={parseFloat(generalItem.QuantityOINV) ?? 0.0}>
                                                    <CurrencyFormat
                                                        value={generalItem.QuantityOINV ?? "0.0"}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}>
                                                    </CurrencyFormat>
                                                </td>
                                                {/* <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{generalItem.ORDN || ''}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{generalItem.QuantityORDN || ''}</td> */}
                                                {/* <td className="col-lg-auto text-center" style={{ fontSize: "15px" }}>
                                                <button
                                                    onClick={() => this.exportCSVOrders(order.DocEntry)}
                                                    className="btn btn-success"
                                                    style={{
                                                        color: 'white',
                                                        fontWeight: "bold",
                                                    }}>
                                                    Exportar a Excel
                                                </button>
                                            </td>
                                            <td style={{ width: '250px' }}>
                                                <a href={`${config.BASE_URL}/${SERVICE_API.getBillspdf}/${order.DownloadFacturaPDF}`} target="_blank" style={{ fontSize: '15px' }}>
                                                    <span style={{ color: config.footer.iconColor }}>Descargar pdf <img src="https://image.flaticon.com/icons/png/512/337/337946.png" style={{ maxHeight: 20 }}></img></span>
                                                </a>
                                                <a href={`${config.BASE_URL}/${SERVICE_API.getBillsxml}/${order.DownloadFacturaXML}`} target="_blank" style={{ fontSize: '15px' }}>
                                                    <span style={{ color: config.footer.iconColor }}>Descargar xml <img src="https://image.flaticon.com/icons/png/512/1548/1548741.png" style={{ maxHeight: 20 }}></img></span>
                                                </a>
                                            </td>
                                            <td className="col-lg-auto" style={{ fontSize: "15px" }}>
                                                <span onClick={() => this.openOrder(order.DocEntry)}>
                                                    <i className={config.icons.detail} style={{ color: config.shoppingList.summaryList, paddingRight: 6 }}></i>
                                                    Detalle
                                                </span>
                                            </td>
                                            <td>
                                                <i className={config.icons.report} style={{ color: config.shoppingList.summaryList, paddingRight: 6 }}></i>
                                                <a href={config.BASE_URL + SERVICE_API.getPDF + order.DocNum + ".pdf"} target="_blank" >PDF</a>
                                            </td>
                                            <td>
                                                <i className={config.icons.xml} style={{ color: config.shoppingList.summaryList, paddingRight: 6 }}></i>
                                                <a href={config.BASE_URL + SERVICE_API.getXML + order.DocNum + ".xml"} target="_blank" >XML</a>
                                            </td> */}
                                            </tr>
                                        );
                                    })
                                    : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneralView);