import React, {Component} from 'react';
import {config, SERVICE_API, VIEW_NAME, DISPATCH_ID} from '../libs/utils/Const';
import {connect} from "react-redux";
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';
import {BreadCrumb} from './index'
import "./ItemSlider.css";
import { Redirect } from 'react-router';

class SideBarItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleAttributes: [], // Estado para controlar la visibilidad de los atributos
            visibleCount: 10,
            attributesList: [],
            itemsFacilities: [],
            secondNavBarFilters: [
                { Code: 1, Name: "PROMOCIONES", location: 'promotions', checked: false },
                { Code: 2, Name: "ULTIMAS PIEZAS", location: 'lastPieces', checked: false },
                { Code: 3, Name: "MÁS BUSCADOS", location: 'mostWanted', checked: false },
                { Code: 4, Name: "NUEVAMENTE DISPONIBLES", location: 'availableAgain', checked: false }
            ], // Filtros del Segundo NavBar
        };
    }

    componentDidMount = () => {
        const { dataCategories: {itemsFacilities, attributesList}} = this.props;
        if (itemsFacilities?.length > 0 || attributesList?.length > 0) this.setState({itemsFacilities, attributesList});
    }

    componentDidUpdate(prevProps) {
        const { dataCategories: { itemsFacilities, attributesList }, itemsReducer: { location } } = this.props;
        if (
            prevProps.dataCategories.itemsFacilities !== itemsFacilities ||
            prevProps.dataCategories.attributesList !== attributesList
        ) {
            this.setState({ itemsFacilities, attributesList });
            this.resetSecondNavBarFilters()
        }
    }

    async componentDidUpdateRes(prevProps) {
        const { itemsReducer: { location } } = this.props;
        const { itemsReducer: { location: prevLocation } } = prevProps;
        // Verifica si el location ha cambiado (Es una nueva busqueda desde otro apartado)
        if (location !== prevLocation) {
            // Desseleccionar los checkboxes de fábricas y Atributos 
            document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
                checkbox.checked = false;
            });
            await this.setState({
                PriceValue: '',
                seleccionados: [],
                visibleAttributes: {},
            });
        }
    }

    toggleVisibility = (i) => {
        this.setState(prevState => ({
            visibleAttributes: {
                ...prevState.visibleAttributes,
                [i]: !prevState.visibleAttributes[i]
            }
        }));
    };
    
    seeAllCategories = ()=>{
        $('#filterCategoriesModal').modal('show');
    }



    deleteFiltersRes = async () => {
        const { itemsReducer, setItemsFilters, viewOne, setLocation, setItemsCategoryFilters, setSearchFilter } = this.props;
    
        // Eliminar todos los filtros aplicados, incluyendo el de fábricas
        await setItemsFilters('');
        await setItemsCategoryFilters({});
        await setLocation('navBar');
        await setSearchFilter('');
    
        // Desseleccionar los checkboxes de fábricas y Atributos
        await document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
            checkbox.checked = false;
        });
    
        // Luego, ejecutar la búsqueda nuevamente
        setTimeout(async () => {
            if (viewOne && viewOne !== '') {
                await itemsReducer.searchByKey(0, viewOne);
            } else {
                await itemsReducer.searchByKey();
            }
        }, 350);
    
        // Restablecer otros estados si es necesario
        await this.setState({
            PriceValue: '',
            seleccionados: [],
            visibleAttributes: {},
        });
    
        // Ocultar el modal después de la eliminación de filtros
        $('#filterCategoriesModal').modal('hide');
    }
    
    deleteFilters = async () => {
        const { setNewFilters, itemsReducer: { searchByKey, NewFilters, location }} = this.props;
        const NewFiltersCopy = {};

        // Guardar la marca que busco el usuario
        if (location === 'brandMenu') NewFiltersCopy.brand = NewFilters.brand;
        if (location === 'departmentMenu' || 'apartmentDetail') NewFiltersCopy.department = NewFilters.department;
        if (location === 'subDepDetail')  NewFiltersCopy.codeSubDep = NewFilters.codeSubDep;

        // Eliminar todos los filtros aplicados
        await setNewFilters(NewFiltersCopy);
    
        // Restablecer estados
        await this.setState({
            visibleAttributes: [],
            visibleCount: 10,
        });

        await this.resetSecondNavBarFilters();

        await searchByKey();
    }

    resetSecondNavBarFilters = async () => {
        this.setState((prevState) => ({
            secondNavBarFilters: prevState.secondNavBarFilters.map((filter) => ({
                ...filter,
                checked: false,
            })),
        }));
    }

    uniqueFilterRes = async (property, value, value2) => {
        const { itemsReducer, setItemsFilters, viewOne } = this.props;
        let data = { property: property, value: value, value2: value2 };
        let attributeValue;
        let attributeFilter = [];

        if (typeof value === 'object' && property === 'attribute') {
            const { key, att } = value;
            const { valueString, code } = att;
            attributeValue = valueString || '';
            let attributeMap = {};
            attributeMap[key] = [code];

            data.value = Object.entries(attributeMap).map(([userField, values]) => ({
                userField,
                value: values.map(v => String(v))
            }));
        } else if (property === 'fabrica') {
            attributeFilter = value;
        }

        const checkbox = await document.getElementById(property === 'attribute' ? attributeValue : value);

        if (checkbox.checked) {
            if (itemsReducer?.uniqueFilter?.property) { // Verificar si ya existían filtros anteriormente

                if (itemsReducer?.uniqueFilter.property === 'attribute' && property === 'fabrica') {
                    // Si ya hay un filtro de atributo y ahora estamos filtrando por fábrica
                    data.property = 'attribute';
                    data.value = itemsReducer?.uniqueFilter.value || [];
                    data.value2 = { property: 'fabrica', value: value, value2: '' };

                    // Deseleccionar el checkbox de la fábrica anterior
                    if (itemsReducer?.uniqueFilter?.value2?.property === 'fabrica') {
                        const prevFabricaCheckbox = document.getElementById(itemsReducer?.uniqueFilter?.value2?.value);
                        if (prevFabricaCheckbox) {
                            prevFabricaCheckbox.checked = false;
                        }
                    }

                } else if (itemsReducer?.uniqueFilter.property === 'attribute') {
                    // Si ya hay un filtro de atributo y agregamos otro atributo
                    let attributeMap = {};
                    for (let index = 0; index < itemsReducer?.uniqueFilter.value.length; index++) {
                        const element = itemsReducer?.uniqueFilter.value[index];
                        if (!attributeMap[element.userField]) {
                            attributeMap[element.userField] = [];
                        }
                        attributeMap[element.userField] = attributeMap[element.userField].concat(element.value);
                    }
                    if (!attributeMap[value.key]) {
                        attributeMap[value.key] = [];
                    }
                    attributeMap[value.key] = attributeMap[value.key].concat(value.att.code);
                    if (attributeMap) {
                        for (const key in attributeMap) {
                            attributeFilter.push({
                                userField: key,
                                value: attributeMap[key].map(v => String(v))
                            });
                        }
                    }
                    data.value = attributeFilter;
                    if (itemsReducer?.uniqueFilter?.value2?.property === 'fabrica' && property === 'attribute') { // Si además ya existía un filtro de fábrica en búsquedas anteriores seguir guardándolo
                        data.value2 = { property: 'fabrica', value: itemsReducer?.uniqueFilter?.value2?.value, value2: '' };
                    }
                } else if (itemsReducer?.uniqueFilter.property === 'fabrica' && property === 'attribute') {
                    // Si ya hay un filtro de fábrica y ahora estamos filtrando por atributo
                    data.value2 = itemsReducer?.uniqueFilter || {};
                    data.value = data.value || [];
                }
            }

            setItemsFilters(data);
        } else {
            // Lógica para eliminar el filtro deseleccionado
            let remainingFilters = { ...itemsReducer?.uniqueFilter };

            if (property === 'attribute') {
                // Eliminar solo el valor específico del atributo
                let updatedAttributes = remainingFilters.value.map(attr => {
                    if (attr.userField === value.key) {
                        // Filtrar y eliminar el valor específico del atributo
                        let filteredValues = attr.value.filter(val => val !== (value.att.code).toString());
                        return {
                            ...attr,
                            value: filteredValues.length > 0 ? filteredValues : []  // Asignar un array vacío si no quedan valores
                        };
                    }
                    return attr;
                }).filter(attr => attr.value.length > 0);

                remainingFilters.value = updatedAttributes;
            } else if (property === 'fabrica') {
                remainingFilters.value2 = '';

                if (remainingFilters?.property === 'fabrica') {
                    remainingFilters.value = '';
                }
            }

            // Si no quedan filtros activos, eliminar todos los filtros
            if ((!remainingFilters.value || remainingFilters.value.length === 0) && !remainingFilters.value2.property) {
                await setItemsFilters(null);
            } else {
                await setItemsFilters(remainingFilters);
            }

        }
        setTimeout(async () => {
            // Ejecutar searchByKey
            if (viewOne && viewOne !== '') {
                await itemsReducer.searchByKey(0, viewOne);
            } else {
                if (itemsReducer.location && itemsReducer.location !== 'navBar' && itemsReducer.location !== 'menuCategories') { // Vienes de una búsqueda de Promoción | Últimas piezas | Más Buscados | Nuevamente Disponibles
                    await itemsReducer.searchByKey(0, itemsReducer.location);
                } else {
                    if (itemsReducer.location === 'menuCategories' && itemsReducer.uniqueCategoryFilter) { // Vienes de una búsqueda de Departamentos o Marcas
                        let filter = itemsReducer.uniqueCategoryFilter;
                        await itemsReducer.searchByCategories(filter.cateogry, filter.page, filter.view, filter.subCategory);
                    } else {
                        await itemsReducer.searchByKey();
                    }
                }
            }
        }, 350);
    
        $('#filterCategoriesModal').modal('hide');
    };
    
    onChangeInputs = async (index, e) =>{
        const { itemsFacilities, attributesList, secondNavBarFilters  } = this.state;
        const {name, id, checked} = e.target;
        const facilitiesCopy = [...itemsFacilities];
        const attributesListCopy = [...attributesList];
        const secondNavBarFiltersCopy = [...secondNavBarFilters]; // Copia de los filtros

        if(name === 'facility'){
            // Se busca si hay una fabrica previa seleccionada para desmarcar
            // const prevCheckFac = facilitiesCopy.findIndex(fac => fac.checked);
            // if (checked && prevCheckFac !== -1) facilitiesCopy[prevCheckFac].checked = false;

            facilitiesCopy[index].checked = checked;
        }

        if(name === 'attribute'){
            attributesListCopy[id][index].checked = checked;
        }

        if (name === 'secondNavBarFilter') {
            secondNavBarFiltersCopy[index].checked = checked;
        }

        await this.setState({itemsFacilities, attributesList, secondNavBarFilters: secondNavBarFiltersCopy});
    }

    uniqueFilter = async (index,  e) => {
        const { itemsReducer:{ searchByKey, NewFilters }, setNewFilters } = this.props;
        const { itemsFacilities, attributesList, secondNavBarFilters } = this.state;
        let filterValues1 = [], filterValues2 = [], filterValues3 = []; 

        // Marcar checks
        await this.onChangeInputs(index, e);
       
        // Fabricas
        const trueFac = itemsFacilities.filter(fac => fac.checked);
        filterValues1 = trueFac || [];

        // Atributos
        const trueAttributes = attributesList.flatMap(subArray => subArray.filter(item => item.checked));
        filterValues2 = trueAttributes || [];

        // Filtros de secondNavBarFilters
        const trueSecondNav = secondNavBarFilters.filter(item => item.checked);
        filterValues3 = trueSecondNav || [];

        await setNewFilters({...NewFilters, filterValues1, filterValues2, secondNavBarFilters: filterValues3 });
        await searchByKey();
    };

    // Función para mostrar más elementos (Reutilizar si implementan mas filtros)
    showMore = () => {
        this.setState(prevState => ({
            visibleCount: prevState.visibleCount + 10,
        }));
    };

    showLess = () => {
        this.setState(prevState => ({
            visibleCount: prevState.visibleCount > 10 ? prevState.visibleCount - 10 : 10,
        }));
    };

    checkFilters() {
        const { itemsFacilities, attributesList, secondNavBarFilters } = this.state;
        const hasCheckedFacility = itemsFacilities.some(fac => fac.checked);
        const hasCheckedAttributes = attributesList.flat().some(item => item.checked);
        const hasCheckedSecondNavBarFilter = secondNavBarFilters.some(item => item.checked);
    
        return hasCheckedFacility || hasCheckedAttributes || hasCheckedSecondNavBarFilter;
    }
    
    render() {
        const { totalRows, itemsReducer: { location } } = this.props;
        const { visibleAttributes, visibleCount, itemsFacilities, attributesList, secondNavBarFilters } = this.state;

        // Variable que limita no. de caracteres de nombre de categorías
        // let categoryStringLimiter = 20;
        // Limitante de categorias mostradas en SideBar
        // let limitNumber = 10;
        // Modal de categorias
        // let twoColumns = dataCategories ? dataCategories.itemsCategories ? dataCategories.itemsCategories.length >= 10 ? Number(((dataCategories.itemsCategories.length + 9) / 2).toFixed(0)) : dataCategories.itemsCategories.length : 0 : 0;
        // let twoColumns = dataCategories ? dataCategories.itemsCategories ? dataCategories.itemsCategories.length >= 10 ? Number(((dataCategories.itemsCategories.length + 9) / 2).toFixed(0)) : dataCategories.itemsCategories.length : 0 : 0;

        return (
            <div>
                {/* <div> <BreadCrumb data={dataCategories.itemsCategories}/></div> */}
                <div className="mb-3 font-weight-bold">
                    <span className="text-muted">{`${totalRows || 0} resultado${totalRows > 1 ? 's' : ''}`}</span>
                </div>

                {this.checkFilters() && (
                    <div className="mb-3">
                        <button
                            className="btn btn-link border-0"
                            onClick={() => this.deleteFilters()}
                            style={{ textDecoration: 'none', fontWeight: 'bold', color: '#F07C00', cursor: 'pointer' }}
                        >
                            Borrar filtros
                        </button>
                    </div>
                )}

                {(itemsFacilities.length < 1 && attributesList.length < 1) && (
                    <div className="text-small">
                        <span>¡No hay filtros para su búsqueda!</span>
                    </div>
                )}
                
                {/* Categorías */}
                {/* {(dataCategories && dataCategories.itemsCategories.length > 0) && 
                    <h5 className="mt-md-5 font-weight-bold TituloCategoria" >Categorías</h5>
                } */}
                {/* {(dataCategories && dataCategories.itemsCategories.length > 0) && 
                    <ul className = "mt-2 SubCategorias" style={{fontSize:'12px', marginLeft:'-15px'}}>
                        <dl>
                            {dataCategories.itemsCategories.map((category, index) => {
                                if(index < limitNumber){
                                    if(category.category.includes(',')){
                                        let categoryNameArray = category.categoryName.split(',');
                                        let length = categoryNameArray.length;
                                        let containerReturn = [];
                                        for (let i = 0; i < categoryNameArray.length; i++) {
                                            let dinamicCategory = categoryNameArray[i].substring(0, categoryStringLimiter);
                                            containerReturn.push(<dd >{(i == 0) && dinamicCategory}
                                                {((i > 0 && i < (length - 1))) && <ol> {dinamicCategory} </ol>}
                                                {(i == (length - 1)) && <ol className = 'linkFilter' onClick = {() => this.uniqueFilter('categorias', category.categoSearch, '')}>
                                                    {dinamicCategory} 
                                                </ol>}
                                            </dd>);
                                        }
                                        return containerReturn;
                                    } else {
                                        return <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('categorias', category.categoSearch, '')}>
                                                {category.categoryName} 
                                            </dd>
                                    }
                                }
                            })}
                            {dataCategories.itemsCategories.length >= 10 && 
                            <dd className = 'linkFilter font-weight-bold' onClick={()=> this.seeAllCategories()}> Ver todas las categorías </dd>}
                        </dl>
                    </ul>
                } */}
                {/* Marcas */}
                {/* {(dataCategories && dataCategories.itemsBrands.length > 0) && 
                    <h5 className="mt-md-4 font-weight-bold TituloCategoria">Marcas</h5>
                } */}
                {/* {(dataCategories && dataCategories.itemsBrands.length > 0) && 
                    <ul className = "mt-2 SubCategorias">
                        <dl>
                            {dataCategories.itemsBrands.map(brand => {
                                return  <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('marca', brand.brand, '')}>
                                            {brand.brand}
                                        </dd>
                            })}
                        </dl>
                    </ul>
                } */}
                {secondNavBarFilters && secondNavBarFilters?.length > 0 && (
                    <>
                        <h5 className="mt-md-4 font-weight-bold TituloCategoria">Buscar en:</h5>
                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <ul className="mt-2 SubCategorias" style={{ fontSize: '12px', marginLeft: '-30px' }}>
                                <dl>
                                    {secondNavBarFilters.map((filter, index) => (
                                        location !== filter.location && (
                                            <dd key={filter.Code} className='linkFilter'>
                                                <label>
                                                    <input
                                                        id={filter.Code}
                                                        style={{ marginRight: '10px' }}
                                                        type="checkbox"
                                                        name='secondNavBarFilter'
                                                        checked={filter.checked}
                                                        onChange={(e) => this.uniqueFilter(index, e)}
                                                    />
                                                    {filter.Name}
                                                </label>
                                            </dd>
                                        )
                                    ))}
                                </dl>
                            </ul>
                        </div>
                    </>
                )}
                {/* Fábricas */}
                {itemsFacilities && itemsFacilities.length > 0 && (
                    <>
                        <h5 className="mt-md-4 font-weight-bold TituloCategoria">Fábricas</h5>
                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <ul className="mt-2 SubCategorias" style={{ fontSize: '12px', marginLeft: '-30px' }}>
                                <dl>
                                    {itemsFacilities.slice(0, visibleCount).map((facility, index) => (
                                        <dd key={facility.Code} className='linkFilter'>
                                            <label>
                                                <input
                                                    id={facility.Code}
                                                    style={{ marginRight: '10px' }}
                                                    type="checkbox"
                                                    name='facility'
                                                    checked={facility.checked}
                                                    onChange={(e) => this.uniqueFilter(index, e)}
                                                />
                                                {facility.Attribute}
                                            </label>
                                        </dd>
                                    ))}
                                </dl>
                            </ul>
                        </div>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            {itemsFacilities.length > visibleCount && (
                                <button
                                    style={{ color: '#00AEEF', cursor: 'pointer', fontSize: 'small', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
                                    onClick={this.showMore}
                                    className="btn btn-link"
                                >
                                    Más
                                    <img src={config.icons.flechabajo} alt="flechabajo" style={{ width: '15px', height: '15px'}} />
                                </button>
                            )}
                            {visibleCount > 10 && itemsFacilities.length > 10 && (
                                <button
                                    style={{ color: '#00AEEF', cursor: 'pointer', fontSize: 'small', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
                                    onClick={this.showLess}
                                    className="btn btn-link"
                                >
                                    Menos
                                    <img src={config.icons.flecharriba} alt="flecharriba" style={{ width: '15px', height: '15px'}} />
                                </button>
                            )}
                        </div>
                    </>
                )}
                {/* Atributos */}
                {attributesList && attributesList.length > 0 && (
                    <>
                        <h5 className="mt-md-4 font-weight-bold TituloCategoria">Atributos</h5>
                        {attributesList.map((attribute, index) => (
                            <div key={index}>
                                <div
                                    className='mt-2 d-flex justify-content-between align-items-center'
                                    style={{ fontSize: '1rem', borderBottom: 'groove', cursor: 'pointer' }}
                                    onClick={() => this.toggleVisibility(index)}
                                >
                                    <span>{attribute[0].Title}</span>
                                    <span>{visibleAttributes[index] ? '−' : '+'}</span>
                                </div>
                                {(visibleAttributes[index] || attributesList[index].every(att =>  att.checked)) && (
                                <ul className="mt-2 SubCategorias" style={{ fontSize: '12px', marginLeft: '-30px' }}>
                                    <dl>
                                        {attribute.map((values, i) => (
                                            <dd key={i} className='linkFilter'>
                                                <label>
                                                    <input
                                                        id={index}
                                                        style={{ marginRight: '10px' }}
                                                        name='attribute'
                                                        type="checkbox"
                                                        checked={values.checked}
                                                        onChange={(e) => this.uniqueFilter(i, e)}
                                                    />
                                                    {values.Attribute}
                                                </label>
                                            </dd>
                                        ))}
                                    </dl>
                                </ul>
                                )}
                            </div>
                        ))}
                    </>
                    )}

                {/* Aparatos */}
                {/* {(dataCategories && dataCategories.itemsDevices.length > 0) && 
                    <h5 className="mt-md-4 font-weight-bold TituloCategoria">Aparatos</h5>
                }
                {(dataCategories && dataCategories.itemsDevices.length > 0) && 
                    <ul className = "mt-2 SubCategorias">
                        <dl>
                            {dataCategories.itemsDevices.map(device => {
                                return  <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('aparato', device.device, '')}>
                                            {device.device}
                                        </dd>
                            })}
                        </dl>
                    </ul>
                } */}
                {/* Refacciones */}
                {/* {(dataCategories && dataCategories.itemsSpareParts.length > 0) && 
                    <h5 className="mt-md-4 font-weight-bold TituloCategoria">Refacciones</h5>
                }
                {(dataCategories && dataCategories.itemsSpareParts.length > 0) && 
                    <ul className = "mt-2 SubCategorias">
                        <dl>
                            {dataCategories.itemsSpareParts.map(part => {
                                return  <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('refaccion', part.part, '')}>
                                            {part.part} 
                                        </dd>
                            })}
                        </dl>
                    </ul>
                } */}
                {/* Precios */}
                {/* {(dataCategories && dataCategories.pricesSideBar) && 
                    <h5 className="mt-md-4 font-weight-bold TituloCategoria">Precios</h5>
                }
                {(dataCategories && dataCategories.pricesSideBar) && 
                    <ul className = "mt-2 SubCategorias">
                        <dl>
                            <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('precio', dataCategories.pricesSideBar.min, '')}>
                                Hasta ${(dataCategories.pricesSideBar.min).toFixed(2)}
                            </dd>
                            {(dataCategories.pricesSideBar.min != dataCategories.pricesSideBar.max) && 
                                <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('precio', dataCategories.pricesSideBar.min, dataCategories.pricesSideBar.max)}>
                                    Entre ${(dataCategories.pricesSideBar.min).toFixed(2)} - ${(dataCategories.pricesSideBar.max).toFixed(2)}
                                </dd>
                            }
                            <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('precio', '', dataCategories.pricesSideBar.max)}>
                                Desde ${(dataCategories.pricesSideBar.max).toFixed(2)}
                            </dd>
                        </dl>
                    </ul>
                } */}
                {/* Stock */}
                {/* {(dataCategories && dataCategories.stock) && 
                    <h5 className="mt-md-4 font-weight-bold TituloCategoria">Existencias</h5>
                } */}
                {/* {(dataCategories && dataCategories.stock) && 
                    <ul className = "mt-2 SubCategorias">
                        <dl>
                            {(dataCategories.stock.available !== 0) &&
                                <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('stock', 'A', '')}>
                                    Disponibles {(dataCategories.stock.available)}
                                </dd>
                            }
                            {(dataCategories.stock.missing !== 0) &&
                                <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('stock', 'B', '')}>
                                    Agotados {(dataCategories.stock.missing)}
                                </dd>
                            }
                        </dl>
                    </ul>
                } */}
                {/* <div className="modal fade" id="filterCategoriesModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{border: "none", textAlign: 'center'}}>
                    <div className="modal-dialog modal-lg" role="document" style={{margin: '1.75rem auto'}}>
                        <div className="modal-content">
                            <div className="modal-header" style={{background: config.navBar.iconColor}}>
                                <h5 className="modal-title" style={{color: config.navBar.textColor2}}>Otras categorías</h5>
                                <button type="button" style={{color: config.navBar.textColor2}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body bg3">
                                <div className = 'row justity-content-center'>
                                <div className = 'col-md-6'>
                                    {dataCategories && dataCategories.itemsCategories.length > 0 && dataCategories.itemsCategories.map((category, index) => {
                                        if(index >= limitNumber && index <= twoColumns){
                                            if(category.category.includes(',')){
                                                let categoryNameArray = category.categoryName.split(',');
                                                let length = categoryNameArray.length;
                                                let containerReturn = [];
                                                for (let i = 0; i < categoryNameArray.length; i++) {
                                                    let dinamicCategory = categoryNameArray[i].substring(0, categoryStringLimiter);
                                                    containerReturn.push(<span>
                                                        {(i == 0) && dinamicCategory}
                                                        {((i > 0 && i < (length - 1))) && '/' + dinamicCategory}
                                                        {(i == (length - 1)) && <span className='linkFilter mr-md-2' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>/{dinamicCategory}</span>}
                                                        {(i == (length - 1)) && <span className='linkFilter mr-md-2' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>({category.times})</span>}
                                                        </span>);
                                                }
                                                containerReturn.push(<br/>);
                                                containerReturn.push(<br/>);
                                                return containerReturn;
                                            } else {
                                                let containerReturn = [];
                                                containerReturn.push(<span className = 'linkFilter mr-md-2' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>
                                                        {category.categoryName} ({category.times})
                                                    </span>);
                                                containerReturn.push(<br/>);
                                                containerReturn.push(<br/>);
                                                return containerReturn;
                                            }
                                        } 
                                    })}
                                </div>
                                <div className = 'col-md-6'>
                                    {dataCategories && dataCategories.itemsCategories.length > 0 && dataCategories.itemsCategories.map((category, index) => {
                                        if(index >= limitNumber && index > twoColumns){
                                            if(category.category.includes(',')){
                                                let categoryNameArray = category.categoryName.split(',');
                                                let length = categoryNameArray.length;
                                                let containerReturn = [];
                                                for (let i = 0; i < categoryNameArray.length; i++) {
                                                    let dinamicCategory = categoryNameArray[i].substring(0, categoryStringLimiter);
                                                    containerReturn.push(<span>
                                                        {(i == 0) && dinamicCategory}
                                                        {((i > 0 && i < (length - 1))) && '/' + dinamicCategory}
                                                        {(i == (length - 1)) && <span className='linkFilter mr-md-2' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>/{dinamicCategory}</span>}
                                                        {(i == (length - 1)) && <span className='linkFilter mr-md-2' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>({category.times})</span>}
                                                        </span>);
                                                }
                                                containerReturn.push(<br/>);
                                                containerReturn.push(<br/>);
                                                return containerReturn;
                                            } else {
                                                let containerReturn = [];
                                                containerReturn.push(<span className = 'linkFilter mr-md-2' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>
                                                        {category.categoryName} ({category.times})
                                                    </span>);
                                                containerReturn.push(<br/>);
                                                containerReturn.push(<br/>);
                                                return containerReturn;
                                            }
                                        } 
                                    })}
                                </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div> */}
        
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShoppingCart: value => dispatch ({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsFilterSearch: value => dispatch ({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value}),
        setNextPage:  value => dispatch ({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        searchByDashOption: value => dispatch ({type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH_OPTION, value}),
        setItemsFilters: value => dispatch({type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value}), 
        setLocation:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_LOCATION, value}),
        setItemsCategoryFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_CATEGORY_FILTER, value }),
        setSearchFilter: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_FILTER, value}),
        setNewFilters: value => dispatch({ type: DISPATCH_ID.SET_NEW_FILTERS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SideBarItems);