import React, {Component} from 'react';
import {config, DISPATCH_ID} from '../libs/utils/Const';
import {connect} from "react-redux";
import { Modal } from './index';
import './NavBarContentSearchItems.css';
import { Link } from 'react-router-dom';
import { ApiClient } from '../libs/apiClient/ApiClient';

let modal = new Modal();
const apiClient = ApiClient.getInstance();
class NavBarContentSearchItems extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
          filter: '',
          suggestions: [],
          text: ''
        }
      }

      searchRes = async () => {
        const { itemsReducer, setIdCategory, setLocation, setSearch, setItemsFilters, setItemsCategoryFilters } = this.props;
        let list = document.querySelector('#dropdownAutoComplete');
        let listFalse = document.querySelector('#dropdownAutoCompleteFake');
        list.style.display = 'none';
        listFalse.style.display = 'block';
        setIdCategory(null);
        await setLocation('navBar');
        await setItemsFilters({});
        await setItemsCategoryFilters({});
        await itemsReducer.searchByKey();
        listFalse.style.display = 'none';
        list.style.display = 'block';
    
        // Resetear el campo de búsqueda a una cadena vacía después de completar la búsqueda
        setSearch(''); // Esto reiniciará el campo de búsqueda
    
        // Alternativamente, puedes también restablecer el estado local si el campo de búsqueda está controlado por el estado local
        // this.setState({ text: '' });
    };

      search = async () => {
        const { itemsReducer: { searchByKey, search }, setPrevSearchVal, setLocation, setSearch, setNewFilters } = this.props;
        let list = document.querySelector('#dropdownAutoComplete');
        let listFalse = document.querySelector('#dropdownAutoCompleteFake');
        list.style.display = 'none';
        listFalse.style.display = 'block';
        await setLocation(null); // Necesario para volver a solicitar filtros de búsqueda nueva
        await setLocation('searchGral');
        await setNewFilters({});
        await setPrevSearchVal({prevSearch: search});
        await searchByKey();
        listFalse.style.display = 'none';
        list.style.display = 'block';
        setSearch(''); 
    };

    searchModal = () => {
        const { marcas, aparatos, refacciones, fabricantes } = this.state;

        return (
            <div className="modal fade" id="searchModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: "center" }}>
                <div className="modal-dialog" role="document" style={{ margin: "1.75rem auto" }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: config.navBar.iconColor }}>
                            <h5 className="modal-title " style={{ color: config.navBar.textColor2 }}>Búsqueda</h5>
                            <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3">
                            <form onSubmit={this.handelSubmit} method="post" encType="text/plain" className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>Marca</h4>
                                        <select name="marca" placeholder="Selecciona una marca" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona una marca</option>
                                            {marcas &&
                                                marcas.map(marca => {
                                                    return <option value={marca.Code} key={marca.Code}>{marca.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Aparato</h4>
                                        <select name="aparato" placeholder="Selecciona un aparato" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona un aparato</option>
                                            {aparatos &&
                                                aparatos.map(aparato => {
                                                    return <option value={aparato.Code} key={aparato.Code}>{aparato.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Refacción</h4>
                                        <select name="refaccion" placeholder="Selecciona una refaccion" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona una refacción</option>
                                            {refacciones &&
                                                refacciones.map(refaccion => {
                                                    return <option value={refaccion.Code} key={refaccion.Code}>{refaccion.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Fabricante</h4>
                                        <select name="fabricante" placeholder="Selecciona un fabricante" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona un fabricante</option>
                                            {fabricantes &&
                                                fabricantes.map(fabricante => {
                                                    return <option value={fabricante.Code} key={fabricante.Code}>{fabricante.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Nombre</h4>
                                        <input type="text" className="form-control" placeholder="Nombre(s)" name="nombre" onChange={this.handelChange} />
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <input type="submit" value="Buscar" className="btn btn-primary mb-2 btn-block "
                                                style={{ fontsize: 25, background: config.navBar.iconColor, color: "white", marginTop: 15 }} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    onTextChanged = async (e) => {
        const {setSearch, setSearchFilter} = this.props;
        let value = e.target.value;
        //{item.ItemCode +' - '+ item.ItemName +' - '+ item.Marca || '' +' - '+ item.Modelo || '';
        let arrayValue = value.split("~");
        if(arrayValue.length === 3){
            value = value.replace(/ ~ /g, ", " );
        }
        // let suggestions = [];
        // if(value.length > 1) {
        //     let apiResponse = await apiClient.AutoComplete(value);
        // //   const regex = new RegExp(`^${value}`, 'i');
        //   suggestions = apiResponse.data.data //apiResponse.data.data.sort().filter(v => regex.test(v));
        // }
        setSearch(value)
        await setSearchFilter(value); //Guardar texto buscado para filtros al interno
        this.setState(() => ({ text: value}));
        if(arrayValue.length === 3){
            setTimeout(()=>{
                this.search();
            }, 150)
        }
    }
    changeFilter = (value) => {
        const {filter} = this.state;
        this.setState({
            filter : value,
        });
    }


    render() {
        const {itemsReducer, setSearch, icon, iconColor, textColor, iconColor3 ,fillDataSearch,} = this.props;
        const { text,filter } = this.state;
        let suggestions =  itemsReducer.itemsAutoComplete;
        let filtros =  itemsReducer.fieldsFilter;
        return (
            <div className="input-group inputSearchItems pr-4">
                <div className='buttonIconSearch' style={{ display: "block" }}>
                    <button className="btn " type="button" data-toggle="dropdown" id="dropdownMenuLink" aria-haspopup="true" aria-expanded="false" 
                    style={{borderColor: "#ced4da",borderRight: "transparent",color: "#666666", color: "#ced4da", height: '100%'}} >
                            <i class="fa fa-caret-square-o-down" aria-hidden="true">
                            </i>
                    </button>
                    <div class="dropdown-menu p-0 border border-secondary" aria-labelledby="dropdownMenuLink" style={{ borderColor: "#ced4da", border: "1px solid #ced4da" }}>
                        <div style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'center', padding: '10px' }} onClick={() => (this.changeFilter(''))}>
                            <input class="form-check-input" style={{ marginLeft: '-5px', marginBottom: '5px' }} type="radio" name="flexRadioDefault" id="flexRadioDefault0"></input>
                            <label class="form-check-label" style={{ marginLeft: '11px' }} for="flexRadioDefault0">
                                Sin filtro
                            </label>
                        </div>
                        {filtros &&
                            filtros.map((filtro, index) => {
                                return (
                                    <div class="dropdown-item form-check" style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'center', padding: '10px' }} onClick={() => (this.changeFilter([filtro.COLUMN_NAME, filtro.Name]))}>
                                        <input class="form-check-input" style={{ marginLeft: '-5px', marginBottom: '5px' }} type="radio" name="flexRadioDefault" id={`flexRadioDefault${index + 1}`} ></input>
                                        <label class="form-check-label" style={{ marginLeft: '11px' }} for={`flexRadioDefault${index + 1}`}>
                                            {filtro.Name}
                                        </label>
                                    </div>
                                )
                            })

                        }
                    </div>
                </div>
                  {/* <span className="divisior"></span> */}
                    {/* Para ocultar que aparece la lista de predictivo */}
                    <input 
                                        id = "dropdownAutoCompleteFake"
                                        className = "form-control text-left"
                                        // name = "password"
                                        placeholder = " ¿Hola, cuál es el modelo, número de parte o marca que buscabas?"
                                        autoComplete = {'off'}
                                        style = {{borderRight: "transparent",borderLeft: "transparent", height: "68.566px", display : "none"}}
                                        value = {itemsReducer.search} 
                                        />
                                    {/* Para ocultar que aparece la lista de predictivo */}
                    <input
                        id="dropdownAutoComplete"
                        className="form-control text-left"
                        name="password"
                        placeholder=" ¿Hola, cuál es el modelo, número de parte o marca que buscabas?"
                        autoComplete={'off'}
                        style={{ borderRight: "transparent", borderLeft: "transparent", height: "68.566px", border: "1px solid #ced4da" }}
                        onKeyDown={event => event.keyCode === 13 && this.search()}
                        value={itemsReducer.search}
                        onChange={this.onTextChanged} type="text"
                        list={itemsReducer.search.length > 1 && "suggestionList"} />
                    <datalist id="suggestionList">
                        {suggestions && suggestions.length > 0 && suggestions.map((item) => {
                            let name = Object.keys(item).find(name => name == filter[0] || name == filter[1]);
                            return (<option data-value={
                                // CodeBar
                                item.ItemCode + '' +
                                (item.ItemName != null ? ', ' + item.ItemName : '') + '' +
                                (item.Marca != null ? ', ' + item.Marca : '') + '' +
                                (item.Modelo != null ? ', ' + item.Modelo : '')
                            }>

                        { filter != [] && filter != undefined ?
                                                    (name == filter[0] || name == filter[1] ?  item[name] || '' : '' ) + ', ' + item.ItemName
                                                    :
                                                        item.ItemCode +''+
                                                        (item.ItemName != null && item.ItemName != '' ? ', '+item.ItemName : '') +''+
                                                        (item.Marca != null && item.Marca != '' ? ', '+item.Marca : '') +''+
                                                        (item.Modelo != null && item.Modelo != '' ? ', '+item.Modelo : '') 
                                                        }
                                                    </option>)
                                                }
                                            )}
                </datalist>
                {/* <span className="divisior"></span> */}
                <button className="btn buttonIconSearch" type="button" style={{borderColor: "#ced4da",color: "#666666", borderLeft: "transparent", color: "#ced4da"}} onClick={this.search}>                        
                    <img
                        src={config.icons.search}
                        alt="search"
                        style={{cursor: 'pointer', padding: 0, margin: 0, color: "#ced4da", width:'20px', height:'20px'}}
                        aria-hidden="true"
                    />
                </button>
                {/* <button className="btn buttonAvanzada" type="button" style={{borderColor: "#ced4da",color: "#666666", borderLeft: "transparent", color: "#ced4da"}} onClick={fillDataSearch}>                        
                    Avanzada
                </button> */}
                {/* <div className="input-group-prepend" onClick={this.search} style={{marginLeft: 5}}>
                    <span
                        className="input-group-text"
                        style={{
                            textAlign: 'center',
                            height: 30,
                            cursor: 'pointer',
                            border: 'none',
                            background: 'none',
                            paddingLeft: 1,
                            paddingRight: 1
                        }}>
                        <i
                            className={icon}
                            style={{marginLeft: 5, cursor: 'pointer', color: iconColor}}
                            aria-hidden="true"
                        />
                    </span>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH, value}),
        setIdCategory: value => dispatch({type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value}),
        setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        setLocation:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_LOCATION, value}),
        setItemsFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value }),
        setItemsCategoryFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_CATEGORY_FILTER, value }),
        setSearchFilter: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_FILTER, value}),
        setNewFilters: value => dispatch({ type: DISPATCH_ID.SET_NEW_FILTERS, value }),
        setPrevSearchVal: value => dispatch({ type: DISPATCH_ID.SET_PREV_SEARCH_VAL, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBarContentSearchItems)
