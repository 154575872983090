import React, { Component } from 'react';
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID, ROLES} from '../libs/utils/Const';
import {connect} from "react-redux";
import {ItemDetailsModal,ItemCotizacionModal} from "./index";
import CurrencyFormat from 'react-currency-format';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ItemSlider.css';
import './card.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import moment from 'moment';
import $ from "jquery";
import ReactHover, { Trigger, Hover } from "react-hover";

// definir las variables responsivas para el carrusel
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1920 },
        items: 4,
        partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
    },
    desktop2: {
        breakpoint: { max: 1920, min: 1200 },
        items: 3,
        partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1200, min: 992 },
      items: 2,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet1: {
      breakpoint: { max: 992, min: 767 },
      items: 2,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet2: {
        breakpoint: { max: 767, min: 464 },
        items: 1,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
      },
    mobile: {
      breakpoint: { max: 463, min: 0 },
      items: 1,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
}

class ItemSlider2 extends Component {

    constructor(props){
        super(props);
        this.state = {
            seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
            vendor: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'vendor')),
            itemSelect: '',
            renderStock: false,
            renderInfoDiscount: false,
        };
        this.mouseOverAddCart = this.mouseOverAddCart.bind(this);
        this.mouseOutAddCart = this.mouseOutAddCart.bind(this);
        this.iconMouse = [];     
        this.iconMouseOver = []; 
    }

    changeQuantity = (index, item, event) =>{
        const {sessionReducer: {role}, changeQuantity, notificationReducer: {showAlert},itemsReducer: {items2: itemsSearch}, shoppingCartReducer: { isDevolution }} = this.props; // Revisar variable items2 con item de changLocalQuantity
        const cliente = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')) ?? {};

        if(role === ROLES.CLIENT && cliente.CardCode === undefined){
            showAlert({type: 'warning', message: "Debes seleccionar un cliente para ingresar aqui", timeOut: 2000})
        }else{
            if(isDevolution){
                showAlert({ type: 'error', message: "Tienes articulos pendientes para devolver", timeOut: 3000 });
                return;
            }
            let newQuantity ;
            let onHand = item.OnHand;
            let OnHandPrincipal = item.OnHandPrincipal;       
            itemsSearch.map( itemFilter => {
                if (itemFilter.ItemCode === item.ItemCode) {
                   newQuantity =  Number(event.nativeEvent.target.value) || 1 ;
                   itemFilter.quantity = newQuantity;
                }
            });
            if(newQuantity){
                if(OnHandPrincipal >=  Number(item.quantity)  && onHand >= newQuantity){
                    showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });
    
                }else if( OnHandPrincipal <  Number(item.quantity)  && onHand >= newQuantity ){
                    showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });
                    showAlert({ type: 'warning', message: "Sugerencia cambiar a otro almacen.", timeOut: 4000 });
                }else{
                    showAlert({ type: 'success', message: "Articulo agregado al carrito", timeOut: 1000 });
                    showAlert({ type: 'warning', message: " Articulo sujeto a disponibilidad de proveedores.", timeOut: 4000 });
                }
                changeQuantity(index,item, newQuantity || 1, true); // add
            }
        }
    };

    changLocalQuantity = (index, item, event)=>{
        const {itemsReducer: {items2: itemsSearch}} = this.props;
        let newQuantity =  event.nativeEvent.target.value;
        itemsSearch.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                // if(newQuantity >= 1 ){
                    itemFilter.quantity = newQuantity;
                // }
            }
        });
        this.applyFilters(itemsSearch);
    }

    setSepecialPrice = (itemParameter) =>{
        let item = itemParameter;
        const {itemsReducer: {specialPrice}} = this.props;
        
        let CardCode = specialPrice.CardCode;
        let PriceList = specialPrice.ListNum && specialPrice.ListNum !== '' ? specialPrice.ListNum : specialPrice.priceList;
        
        // Special Prices Validation
        //########################################################################################################################
        let priority2 = "*" + PriceList;
        let formattedDocDate = moment().format('YYYY/MM/DD');

        let flagSN = true; // Socio de negocio
        let flagPriceList = true; // Lista de precios
        let flagPricePriority = true;
        let maxQuantity = 0; // Cantidad maxima a alegir
        let priceItem = -1;
        let discount = -1;
        let priceBeforeDisc= -1;

        item.QuantitySpecial = item.quantity;
        item.Price = -1;

        // Socio de negocios
        if(specialPrice.specialPrices.length > 0){
            specialPrice.specialPrices.map((itemPrice) => {
                if (item.ItemCode == itemPrice.ItemCode && itemPrice.CardCode == CardCode) {
                    if (itemPrice.children.length > 0) {
                        itemPrice.children.map((child) => {
                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);
                            
                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);

                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if(docDate >= inicialCantidad && docDate <= finalCantidad){
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if(child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if(maxQuantity != 0){
                                        flagSN = false;
                                    }
                                    if(flagSN){
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagSN = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagSN = false;
                                }
                            } else {
                                // General
                                if(flagSN){
                                    item.DiscountPercentSpecial = itemPrice.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice.Price);
                                    discount = parseFloat(itemPrice.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            } 
                        })
                    } else {                           
                        //General
                        item.DiscountPercentSpecial = itemPrice.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice.Price);
                        discount = parseFloat(itemPrice.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        }
        // Lista de inventario
        if(specialPrice.specialPricesList.length > 0 && flagPricePriority){
            specialPrice.specialPricesList.map((itemPrice2)  => {
                if(itemPrice2.ItemCode == item.ItemCode && itemPrice2.CardCode == priority2){
                    if (itemPrice2.children.length > 0) {
                        itemPrice2.children.map((child)=> {

                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);
                            
                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);
                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice2.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice2.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if(docDate >= inicialCantidad && docDate <= finalCantidad){
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if(child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if(maxQuantity != 0){
                                        flagPriceList = false;
                                    }
                                    if(flagPriceList){
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagPriceList = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagPriceList = false;
                                }
                            } else {
                                // General
                                if(flagPriceList){
                                    item.DiscountPercentSpecial = itemPrice2.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice2.Price);
                                    discount = parseFloat(itemPrice2.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            } 
                        })
                    } else {
                        // General
                        item.DiscountPercentSpecial = itemPrice2.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice2.Price);
                        discount = parseFloat(itemPrice2.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        } 
        //No tiene precio especial
        if(flagPricePriority){
            item.DiscountPercentSpecial = 0;
        }

        // Precios por descuentos especiales
        if(priceBeforeDisc != -1){
            item.Price = Number(priceItem);
            item.PriceBeforeDiscount = Number(priceBeforeDisc);
        }
        
        let priceTax = item.Price !== -1 ? Number(((item.Price * (16 / 100)) + item.Price).toFixed(2)) : -1;
        return priceTax;
    }

    // No aplica
    changeBackOrder= (item, addItem) => {
        const {itemsReducer : {deleteBackOrder, addBackOrder}} = this.props;
        if(addItem){
            addBackOrder({item, quantity: 1})
        }else{
            deleteBackOrder({item, deleteAll: false});
        }
    };
    
    applyFilters = data => {
        const {setItemsFilterSearch2} = this.props;
        setItemsFilterSearch2(data);
    };

    mouseOverAddCart = (index, flag, event)=>{
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "none";
            this.iconMouseOver[index].style.display = "block";
        }
    }

    mouseOutAddCart = (index, flag, event)=>{
        if(flag == 'green'){
            event.currentTarget.style.backgroundColor = "#89c33f";
            event.currentTarget.className = "btn btn-block botonAgregar"
        } else if(flag == 'yellow'){
            event.currentTarget.style.backgroundColor = "#efc964";
            event.currentTarget.className = "btn btn-block botonAgregarYellow"
        } else {
            event.currentTarget.style.backgroundColor = "#e27753";
            event.currentTarget.className = "btn btn-block botonAgregarAgotado"
        }
        // Íconos
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "block";
            this.iconMouseOver[index].style.display = "none";
        }
    }

    dejanosMensaje = (item) => {
        this.setState({
            itemSelect: item
        });
        setTimeout(() => {
            $('#itemCotizaModal').modal('show');
        }, 300);        
    };

    render() {
        const {itemsReducer : { items2, searchItemsFilter, updateFavorite, deleteShoppingCart, openItemDetails , whsGeneralName, whsGeneralType, getStockDetails, getInfoDiscount }, dashboard, changeBackOrder, sessionReducer,  sessionReducer: { user } } = this.props;
        const { seller,itemSelect, card, renderStock, renderInfoDiscount } = this.state;
        let valor = seller ? seller.U_FMB_Handel_Perfil : '0';
        let locationStock = 'sliderDash2';
        return (
            <div>
                <ItemCotizacionModal  
                    Usuario={user}
                    datos = {sessionReducer.role}
                    item= {itemSelect}
                /> 
                <ItemDetailsModal view={VIEW_NAME.ITEMS_VIEW}/>
                <div className="SliderItems" >
                    <Carousel autoPlay itemClass="carousel-item-padding-20-px" partialVisible={false} infinite={true} responsive={responsive} autoPlaySpeed={5000000} removeArrowOnDeviceType={["tablet", "mobile"]} >
                        {items2 && items2.map((item, index) => {
                            item.ItemName = item.ItemName || '';
                            let dashboardForTwoSliders = dashboard ? dashboard : '';
                            let imagesArray = item.U_Handel_ImagesArray || '';
                            imagesArray = imagesArray.split('|');
                            let imagenShow = item.PicturName ||  imagesArray[0] ? (config.BASE_URL + SERVICE_API.getImage + '/' + (item.PicturName ? item.PicturName :imagesArray[0]) ) : require('../images/noImage.png');
                            return (
                                <div id="ItemsSlider2" style={{ display:"flex", justifyContent:"center", maxWidth:"20rem", maxHeight:"45rem", position:"relative",paddingLeft:"1rem",paddingRight:"1rem", paddingBottom:'1rem',  border:"none !important"}} key={index}>
                                <div className="item card card-container-all" style={{ position: "relative", width: "380px", marginTop: "4px", minHeight: "450px", backgroundColor: config.itemsList.backgroundColor, border: "solid !important" }}>
                                        <div className="imageCard">
                                            <div style={{display: (!item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 ? 'none' : 'table')}}>
                                                <div className="font-weight-bold ribbon" style={{position: "absolute"}} onClick={() => openItemDetails(item)}>
                                                    <span>
                                                        <blink>{item.isDestacado ? 'DESTACADO' : 'PROMOCIÓN'}</blink>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-center container-img'>
                                                {item.Discount && item.Discount > 0 && item.FinalPrice && (item.U_web !== 0) ?
                                                    <div className='rounded' style={{ position: 'absolute', backgroundColor: 'red', color: 'white', top: '5px', right: '10px', padding: '4px', marginTop:'5px' }}>
                                                        {item.Discount + '% OFF'}
                                                    </div>
                                                    : <></>
                                                }
                                                {item.Discount && item.Discount > 0 && item.FinalPrice && (item.U_web !== 0) ?
                                                    <div className='rounded' style={{ position: 'absolute', top: '45px', right: '10px', padding: '4px', marginTop: '5px' }}>
                                                        <ReactHover options={true} >
                                                            <Trigger type="trigger">
                                                                <img
                                                                    src={config.icons.discountIcon}
                                                                    width={'40px'}
                                                                    height={'40px'}
                                                                    alt='Icono Descuento'
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={async (e) => { await getInfoDiscount(item.ItemCode, locationStock); await this.setState({ renderInfoDiscount: true }); }}
                                                                />
                                                            </Trigger>
                                                            {renderInfoDiscount && (item.flagInfoDiscount ? item.flagInfoDiscount : false) &&
                                                                <Hover type="hover"  >
                                                                    <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-90px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                        <p className="card-title text-left" style={{ fontSize: 13, color: 'black' }}>
                                                                            <span style={{ fontWeight: 'bold' }}> Tipo de Descuento: </span> <span>{item.infoDiscount.typeDiscount || ''}</span><br></br>
                                                                            <span style={{ fontWeight: 'bold' }}> Descuento: </span> <span>{item.infoDiscount.Discount || ''} %</span>
                                                                        </p>
                                                                        {item.infoDiscount.Quantities && item.infoDiscount.Quantities?.length > 0 ?
                                                                            <>
                                                                                <p className="card-title text-left" style={{ fontSize: 13, color: 'black' }}>
                                                                                    <span style={{ fontWeight: 'bold' }}> Información del descuento.</span>
                                                                                </p>
                                                                                {item.infoDiscount.Quantities.map((item, index) => (
                                                                                    <p key={index} className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'black' }}>
                                                                                        <span style={{ fontWeight: 'bold' }}>Cantidad Min: </span><span>{item.Amount}</span><br></br>
                                                                                        <span style={{ fontWeight: 'bold' }}>Descuento: </span><span>{`${item.Discount} %`}</span><br></br>
                                                                                        <span style={{ fontWeight: 'bold' }}>Precio </span><span>
                                                                                            <CurrencyFormat
                                                                                                value={Number(item.Price || 0) * 1.16}
                                                                                                displayType={'text'}
                                                                                                decimalScale={2}
                                                                                                thousandSeparator={true}
                                                                                                fixedDecimalScale={true}
                                                                                                prefix={'$ '}
                                                                                                suffix={' ' + config.general.currency}
                                                                                            >
                                                                                            </CurrencyFormat>
                                                                                        </span>
                                                                                    </p>
                                                                                ))}
                                                                            </>
                                                                            : <></>
                                                                        }
                                                                    </div>
                                                                </Hover>
                                                            }
                                                        </ReactHover>
                                                    </div>
                                                    : <></>}
                                                <img
                                                    onClick={() => openItemDetails(item)}
                                                    className="card-img-top cardImg"
                                                    style={{
                                                        height: "150px",
                                                        width:"150px", // Ajusta el ancho deseado para el modo lista
                                                        backgroundColor: "transparent",
                                                        borderTopRightRadius: "10px",
                                                        cursor: "pointer",
                                                        marginRight: "auto",
                                                        marginLeft: "auto",
                                                        padding: 0,
                                                        objectFit: "cover",
                                                    }}
                                                    //src={config.shoppingCartIcons.imagenDefecto}
                                                    src={imagenShow}
                                                    alt="Imagen del articulo"
                                                /> 
                                            </div>
                                               
                                             <div className="card-body" id={'idCardBody' + index} style={{ height: "fit-content", margin: 0, minHeight: "180px", color: config.itemsList.textColor/*margin: 0, padding: 0, color: config.itemsList.textColor*/ }}>
                                                <div>
                                                    <p className="card-subtitle text-left" style={{ fontSize: 20, marginLeft: 2, color: config.itemsList.textPrice2 }}>
                                                        {!(item.U_web === 1)
                                                            ? <h1 class="quote-h1">Solicite su cotización</h1>
                                                            :
                                                            (item.Discount && item.Discount > 0 && item.FinalPrice && (item.U_web !== 0) ?
                                                                <>
                                                                    <div className='row d-flex justify-content-start align-items-center'>
                                                                        <span style={{ color: 'red', fontSize: '12px' }}>Antes</span>
                                                                        <span className="font-weight-bold" style={{ fontSize: 14, color: "#666666", textDecoration: 'line-through', marginLeft: '5px' }} >
                                                                            <CurrencyFormat
                                                                                value={item.U_SubDepartamento === "PIS-05" && item.U_M2 ? item?.OriginalPriceTax / item.U_M2 : item?.OriginalPriceTax || 0}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={'$ '}
                                                                                suffix={' ' + item.currency}>
                                                                            </CurrencyFormat>
                                                                            {item.U_SubDepartamento === "PIS-05" && item.U_M2 ? <span style={{ marginLeft: '12px', color: '#89c33f' }}>m²</span> : <></>}
                                                                        </span>
                                                                    </div>
                                                                    <h1 class="price">
                                                                        <CurrencyFormat
                                                                            value={item.U_SubDepartamento === "PIS-05" && item.U_M2 ? item?.FinalPriceTax / item.U_M2 : item?.FinalPriceTax || 0}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2}
                                                                            prefix={'$ '}
                                                                            suffix={' ' + item.currency}>
                                                                        </CurrencyFormat>
                                                                        {item.U_SubDepartamento === "PIS-05" && item.U_M2 ? <span style={{ marginLeft: '12px', color: '#89c33f' }}>m²</span> : <></>}
                                                                    </h1>
                                                                </>
                                                                :
                                                                <>{
                                                                    (valor == '0' || !item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 || item.DiscountPercentSpecial == 0 ?
                                                                        <h1 className="price">
                                                                            <CurrencyFormat
                                                                                value={item.U_SubDepartamento === "PIS-05" && item.U_M2 ? item?.Price / item.U_M2 : item?.Price || 0}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={'$'}
                                                                                suffix={' ' + item.currency}>
                                                                            </CurrencyFormat>
                                                                            {item.U_SubDepartamento === "PIS-05" && item.U_M2 ? <span style={{ marginLeft: '12px', color: '#89c33f' }}>m²</span> : <></>}
                                                                        </h1>
                                                                        : <>
                                                                            <h1 className="price">
                                                                                <CurrencyFormat
                                                                                    value={item.PriceBeforeDiscount}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix={'$'}
                                                                                    suffix={' ' + item.currency}>
                                                                                </CurrencyFormat>
                                                                                <CurrencyFormat
                                                                                    value={item.Price}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix={'$'}
                                                                                    suffix={' ' + item.currency}>
                                                                                </CurrencyFormat>
                                                                                {seller ? seller.U_FMB_Handel_Perfil != '0' && item.DiscountPercentSpecial !== 0 ?
                                                                                    <div className="descuento col-md-4 align-middle text-right">
                                                                                        <span className="text-danger">{parseFloat(item.DiscountPercentSpecial).toFixed(2)}% OFF</span>
                                                                                    </div>
                                                                                    : "" : ""
                                                                                }
                                                                            </h1>
                                                                        </>
                                                                    )
                                                                }</>
                                                            )
                                                        }
                                                        {item.U_web === 1 &&
                                                            " " //+ item.currency
                                                        }
                                                    </p>
                                                </div>
                                                <div className="overflow-auto">
                                                    <p className="card-title text-left ItemName" style={{ padding: 1, fontSize: 13, fontWeight: 600, overflowWrap: 'break-word', maxWidth: '170px' }}>
                                                        {/* {item.ItemName ? (item.ItemName).length > 32 ? (item.ItemName).substring(0, 31) + " ... ": item.ItemName : " "} */}
                                                        {item.ItemName}
                                                    </p>
                                                </div>
                                                <div className='container-info'>
                                                    <div>
                                                        <h2 className='sku-h2'>SKU: {item.ItemCode}</h2> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ border: 'none', marginTop: "21px"/*borderTop: "transparent"*/ }}>
                                            <div className="row text-center" style={{ margin: 0, padding: 0 }}>

                                            {item.U_web === 1 && whsGeneralType === 1 ?
                                                <div className="col-12" style={{padding:0}}>
                                                    <div className="row text-center" style={{ margin: 0, padding: 0 }}>
                                                        {seller && seller.empID !== "1" ? 
                                                            <>
                                                                <div className="col-3" style={{padding:0}}>
                                                                    <input
                                                                        // disabled={!(!!parseInt(item.MaximoStock))}
                                                                        id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                        type="number"
                                                                        min="1"
                                                                        max={Number(item.OnHandPrincipal) - 1}
                                                                        // value={(item.quantity != 0)? ((parseInt(item.MaximoStock) >= item.quantity ) ? item.quantity : parseInt(item.MaximoStock)) : (parseInt(item.MaximoStock) === 0 ? 0 : 1 ) }
                                                                        value={ Number(item.quantity) <1 ? "" : Number(item.quantity) }
                                                                        // value={item.quantity ? Number(item.quantity).toFixed(0) : ''}
                                                                        className="form-control"
                                                                        name={'quantity' + item.ItemCode}
                                                                        placeholder="1"
                                                                        style={{textAlign: "left", height: 40.5, width: 65}}
                                                                        onFocus={(event) => event.target.select()}
                                                                        
                                                                        onChange={(event) => {this.changLocalQuantity(dashboard.toString() + index, item, event)}}
                                                                        //onBlur={(event) => {this.changeQuantity(index, item, event)}}
                                                                    //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                                    />
                                                                </div>
                                                                <div className="col-6 botonAgregar">
                                                                    <button style={{ textAlign: "center" }}
                                                                        type="button"
                                                                        readOnly={item.OnHandPrincipal === 0 || item.OnHandPrincipal === '' ? true : false}
                                                                        // className={item.flag === 'green' ? "btn btn-block botonAgregar" : "btn btn-block botonAgregarAgotado"}
                                                                        className= {item.OnHandPrincipal >=  Number((item.quantity) > 0 ? item.quantity : 1)  && Number(item.OnHandPrincipal) >= 1 ? "btn btn-block botonAgregar p-2"  : item.OnHand >=  Number((item.quantity) > 0 ? item.quantity : 1) ? "btn btn-block botonAgregarYellow p-2" : "btn btn-block botonAgregarAgotado p-2"}  
                                                                        // className= {item.OnHandPrincipal === 0 ? "btn btn-block botonAgregarAgotado" : item.flag === 'green' ? "btn btn-block botonAgregar": "btn btn-block botonAgregarYellow"}  
                                                                        value={(item.quantity ? Number(item.quantity) : '1')}
                                                                        onClick={(event)=>{this.changeQuantity(index, item, event)}} 
                                                                    >
                                                                    </button>
                                                                </div>                                                   
                                                            </>
                                                        :
                                                            <>
                                                                {/* {parseFloat(item.OnHand) > 0 && parseFloat(item.OnHandPrincipal) > 0 && ((seller && seller.empID === "1") || sessionReducer.role === ROLES.PUBLIC) ? */}
                                                                    <>
                                                                        <div className="col-3" style={{padding:0}}>
                                                                            <input
                                                                                // disabled={!(!!parseInt(item.MaximoStock))}
                                                                                id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                                type="number"
                                                                                min="1"
                                                                                max={Number(item.OnHandPrincipal) - 1}
                                                                                // value={(item.quantity != 0)? ((parseInt(item.MaximoStock) >= item.quantity ) ? item.quantity : parseInt(item.MaximoStock)) : (parseInt(item.MaximoStock) === 0 ? 0 : 1 ) }
                                                                                value={ Number(item.quantity) <1 ? "" : Number(item.quantity) }
                                                                                // value={item.quantity ? Number(item.quantity).toFixed(0) : ''}
                                                                                className="form-control"
                                                                                name={'quantity' + item.ItemCode}
                                                                                placeholder="1"
                                                                                style={{textAlign: "left", height: 40.5, width: 65}}
                                                                                onFocus={(event) => event.target.select()}
                                                                                
                                                                                onChange={(event) => {this.changLocalQuantity(dashboard.toString() + index, item, event)}}
                                                                                //onBlur={(event) => {this.changeQuantity(index, item, event)}}
                                                                            //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-6 botonAgregar">
                                                                            <button style={{ textAlign: "center" }}
                                                                                type="button"
                                                                                readOnly={item.OnHandPrincipal === 0 || item.OnHandPrincipal === '' ? true : false}
                                                                                // className={item.flag === 'green' ? "btn btn-block botonAgregar" : "btn btn-block botonAgregarAgotado"}
                                                                                className= {item.OnHandPrincipal >=  Number((item.quantity) > 0 ? item.quantity : 1)  && Number(item.OnHandPrincipal) >= 1 ? "btn btn-block botonAgregar p-2"  : item.OnHand >=  Number((item.quantity) > 0 ? item.quantity : 1) ? "btn btn-block botonAgregarYellow p-2" : "btn btn-block botonAgregarAgotado p-2"}  
                                                                                // className= {item.OnHandPrincipal === 0 ? "btn btn-block botonAgregarAgotado" : item.flag === 'green' ? "btn btn-block botonAgregar": "btn btn-block botonAgregarYellow"}  
                                                                                value={(item.quantity ? Number(item.quantity) : '1')}
                                                                                onClick={(event)=>{this.changeQuantity(index, item, event)}} 
                                                                            >
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                {/* :
                                                                    <div style={{ padding: 0 }}>
                                                                       <div className="btn-container">
                                                                           <div className="without-stock" style={{backgroundColor: config.navBar.iconColor}}>
                                                                               <h3 className="title-stock">No hay stock por ahora</h3>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                                } */}
                                                            </>
                                                        }
                                                        <div>
                                                            {/* {seller && seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ? */}
                                                                <div style={{ position: 'relative' }} >
                                                                    <ReactHover  options = { true } >
                                                                            <Trigger type="trigger">
                                                                                <img
                                                                                    src={config.icons.stock}
                                                                                    width={'40px'}
                                                                                    height={'40px'}
                                                                                    alt='Icono stock'
                                                                                    style={{ cursor: 'pointer' }} // Add this line to set the cursor style
                                                                                    onClick={async(e) => {await getStockDetails(item.ItemCode, locationStock); await this.setState({renderStock: true});}}
                                                                                />
                                                                            </Trigger>
                                                                            {renderStock && (item.flagStock ? item.flagStock : false)  &&
                                                                                <Hover type="hover"  >
                                                                                    {item.whsCodes && item.whsCodes.length > 0 ?
                                                                                        <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-135px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                                            <p className="card-title text-left" style={{ fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                <span style={{ fontWeight: 'bold' }}> Stock : </span> <span>{parseInt(item.OnHand) || '0'}</span>
                                                                                            </p>
                                                                                            {item.whsCodes.map((item, index) => (
                                                                                                <p key={index} className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                    <span style={{ fontWeight: 'bold' }}> Almacén {item.WhsName ? `'${item.WhsName}'` : `'N/A'`} : </span><span>{parseInt(item.Stock) || '0'}</span>
                                                                                                </p>
                                                                                            ))}
                                                                                        </div>
                                                                                        :
                                                                                        <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-135px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                                            <p className="card-title text-left" style={{ fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                <span style={{ fontWeight: 'bold' }}> Stock: </span> <span>{parseInt(item.OnHand) || '0'}</span>
                                                                                            </p>
                                                                                            <p className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                <span style={{ fontWeight: 'bold' }}> Almacén {whsGeneralName ? `'${whsGeneralName}'` : `'defecto'`} : </span><span>{parseInt(item.OnHandPrincipal) || '0'}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                </Hover>
                                                                            }
                                                                    </ReactHover>
                                                                </div>
                                                            {/* :
                                                                <>
                                                                    {((seller && seller.empID === "1") || sessionReducer.role === ROLES.PUBLIC)  && parseFloat(item.OnHand) > 0 && parseFloat(item.OnHandPrincipal) > 0 ?
                                                                        <div className="icoCarrito col-2 text-center align-middle" style={{ fontSize: 33, padding: 0, textAlign: "right" }}>
                                                                            <img ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() + index] = iconMouse} src={ Number(item.OnHandPrincipal) >= Number((item.quantity) > 0 ? item.quantity : 1) && Number(item.OnHandPrincipal) >= 1 ? config.shoppingCartIcons.carritoVerde :  Number(item.OnHand) >=  Number((item.quantity) > 0 ? item.quantity : 1)  ? config.shoppingCartIcons.carritoAmarillo : config.shoppingCartIcons.carritoAgotado } style={{ display: "block", cursor: "auto" }} />
                                                                        </div> 
                                                                    :''}
                                                                </>
                                                            } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                <>
                                                    {item.U_web === 1 ?

                                                        <div>
                                                            {/* {seller && seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ? */}
                                                                <div style={{ position: 'relative' }} >
                                                                    <ReactHover  options = { true } >
                                                                            <Trigger type="trigger">
                                                                                <img
                                                                                    src={config.icons.stock}
                                                                                    width={'40px'}
                                                                                    height={'40px'}
                                                                                    alt='Icono stock'
                                                                                    style={{ cursor: 'pointer' }} // Add this line to set the cursor style
                                                                                    onClick={async(e) => {await getStockDetails(item.ItemCode, locationStock); await this.setState({renderStock: true});}}
                                                                                />
                                                                            </Trigger>
                                                                            {renderStock && (item.flagStock ? item.flagStock : false)  &&
                                                                            <Hover type="hover"  >
                                                                                {item.whsCodes && item.whsCodes.length > 0 ?
                                                                                    <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15 , zIndex: '1000000', position: 'relative', top: '-135px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                                        <p className="card-title text-left" style={{ fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                            <span style={{ fontWeight: 'bold' }}> Stock : </span> <span>{parseInt(item.OnHand) || '0'}</span>
                                                                                        </p>
                                                                                        {item.whsCodes.map((item, index) => (
                                                                                            <p key={index} className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                                <span style={{ fontWeight: 'bold' }}> Almacén {item.WhsName ? `'${item.WhsName}'` : `'N/A'`} : </span><span>{parseInt(item.Stock) || '0'}</span>
                                                                                            </p>
                                                                                        ))}
                                                                                    </div>
                                                                                    :
                                                                                    <div style={{ background: '#efefef', padding: '8px 8px 10px 10px', borderRadius: 15, zIndex: '1000000', position: 'relative', top: '-135px', width: '205px', height: '105px', overflowY: 'scroll', marginLeft: '-110px', display: 'flex', flexDirection: 'column' }}>
                                                                                        <p className="card-title text-left" style={{ fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                            <span style={{ fontWeight: 'bold' }}> Stock: </span> <span>{parseInt(item.OnHand) || '0'}</span>
                                                                                        </p>
                                                                                        <p className="card-title text-left" style={{ paddingTop: 5, fontSize: 13, color: 'rgb(0 0 0 / 69%)' }}>
                                                                                            <span style={{ fontWeight: 'bold' }}> Almacén {whsGeneralName ? `'${whsGeneralName}'` : `'defecto'`} : </span><span>{parseInt(item.OnHandPrincipal) || '0'}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                }
                                                                            </Hover>
                                                                            }
                                                                    </ReactHover>
                                                                </div>
                                                                {/* :
                                                                <div className="icoCarrito col-2 text-center align-middle" style={{ fontSize: 33, padding: 0, textAlign: "right" }}>
                                                                    <img ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() + index] = iconMouse} src={ Number(item.OnHandPrincipal) >= Number((item.quantity) > 0 ? item.quantity : 1) && Number(item.OnHandPrincipal) >= 1 ? config.shoppingCartIcons.carritoVerde :  Number(item.OnHand) >=  Number((item.quantity) > 0 ? item.quantity : 1)  ? config.shoppingCartIcons.carritoAmarillo : config.shoppingCartIcons.carritoAgotado } style={{ display: "block", cursor: "auto" }} />
                                                                </div> 
                                                                } */}
                                                        </div>

                                                    :''}
                                                </>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUser: value => dispatch({type: DISPATCH_ID.LOGIN_SET_USER, value}),
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsFilterSearch2: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER2, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ItemSlider2);