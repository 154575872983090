import React, { Component } from 'react';

class AuthorizationModal extends Component{

    
    render(){
        const {apiValidation, funcionCrearDocumento} = this.props;
        
        return(
            <div className="modal" tabindex="-1" role="dialog" id = 'authorizationModal'>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Proceso de autorización</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div> 
                        <div className="modal-body">
                            <p>
                                Hemos detectado que la creación de este documento pasará por el proceso de autorización 
                                <strong> {apiValidation.nameCond}</strong>.
                            </p>
                            <p>
                                ¿Deseas que este documento se guarde como borrador?
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => funcionCrearDocumento('Si')} >  Si</button>
                            {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => funcionCrearDocumento('No')}>Ir a reportes</button> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}
export default AuthorizationModal;