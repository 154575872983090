
class History {

    constructor(history){
        this.history = history;
    }

    goHome(){
        this.history.push('/');
    }

    goLogin(){
        this.history.push('/login');
    }
    
    goRedZone(){
        this.history.push('/redZone');
    }

    goResetPoints(){
        this.history.push('/resetPoints');
    }

    goProfile(){
        this.history.push('/profile');
    }

    goOrders(){
        this.history.push('/Orders');
    }
    goBill(){
        this.history.push('/Bill');
    }

    goItems(){
        this.history.push('/items');
    }

    // goItemsDetails(descripcion, id, marca) {
    //     const cleanText = (text) => text ? text.replace(/[^a-zA-Z0-9]+/g, ' ').trim().replace(/\s+/g, '-') : '';
    //     descripcion = cleanText(descripcion) || 'descripcion';
    //     id = id || 'id';
    //     marca = cleanText(marca) || 'marca';

        
    //     id = encodeURIComponent(id);
    //     let arrayValue = id ? id.split("/") : [];
    //     if (arrayValue.length >= 1) {
    //         id = id.replace(/[/]/g, "~");
    //     }
    //     let params = `${descripcion}--${id}--${marca}`;
    //     this.history.push(`/itemDetail/${params}`);
    // }  Descomentar en b2c para permalinks

    //Borrar esta funcion cuando se descomente la de arriba
    goItemsDetails(ItemCode){
        this.history.push('/itemsDetails/'+ItemCode);
    } 
    goML(){
        this.history.push('/mercadoLibre');
    }

    goBackOrder(){
        this.history.push('/backOrder');
    }

    goShoppingCart(){
        this.history.push('/shoppingCart');
    }

    goAboutUs(){
        this.history.push('/aboutUs');
    }
    // 117
    goFreeAdvice(){
        this.history.push('/freeAdvice');
    }

    goContactUs(){
        this.history.push('/contactUs');
    }

    goSelectClient(){
        this.history.push('/selector');
    }
    goReports(){
        this.history.push('/reports');
    }

    goSelectAddress(){
        this.history.push('/selectAddress');
    }

    goValidateOrder(){
        this.history.push('/validateOrder');
    }

    goCreateOrder(){
        this.history.push('/createOrder');
    }

    goSelector(){
        this.history.push('/selector');
    }

    goMyProfile(){
        this.history.push('/myprofile')
    }

    goSubirArchivo(){
        this.history.push('/subirArchivo')
    }

    goSubirArchivoPadres(){
        this.history.push('/subirArchivoPadres')
    }
    goAutorizaciones(){
        this.history.push('/autorizaciones')
    }

    goCanalModerno(){
        this.history.push('/canalModerno')
    }

    goClaim(){
        this.history.push('/claim');
    }

    goPolitics(){
        this.history.push('/politics');
    }

    goQuestions(){
        this.history.push('/questions');
    }

    goItemsMulos(){
        this.history.push('/itemsMulos');
    }
    
    goItemsBlanca(){
        this.history.push('/itemsBlanca');
    }
    
    goItemsRoutlet(){
        this.history.push('/itemsRoutlet');
    }

    goTerms(){
        this.history.push('/terms');
    }

    goAddress(){
        this.history.push('/addAddress');
    }

    goEditAddress(){
        this.history.push('/editAddress');
    }

    goAccountData(){
        this.history.push('/accountData');
    }

    goSellingPolices(){
        this.history.push('/sellingPolices');
    }

    goRedAliado(){
        this.history.push('/redAliado');
    }

    goPrivacy(){
        this.history.push('/privacy');
    }

    goJob(){
        this.history.push('/job');
    }

    goSucursales(){
        this.history.push('/sucursales');
    }

    goDevolution(){
        this.history.push('/devolutionView');
    }

    goPaymentMethodView(){
        this.history.push('/paymentMethodView');
    }

    goSafeShopping(){
        this.history.push('/safeShoppig');
    }

    goAboutRedZone(){
        this.history.push('/aboutRedZone');
    }

    goAdmiNewsBlog(){
        this.history.push('/admiNewsBlog');
    }

    goNewsBlog(){
        this.history.push('/newsBlog');
    }

    goSpecialPrices(){
        this.history.push('/specialPrices');
    }

    goPromocionales(){
        this.history.push('/promocionales');
    }

    goCatalogos(){
        this.history.push('/catalogo');
    }

    goAnalitycs(){
        this.history.push('/analitycs');
    }

    goItemsRefacciones(){
        this.history.push('/itemsRefacciones');
    }

    goItemsFood(){
        this.history.push('/itemsFood');
    }

    goItemsPaking(){
        this.history.push('/itemsPaking');
    }

    goItemsFerreteria(){
        this.history.push('/itemsFerreteria');
    }
    
    goQuestionshipping(){
        this.history.push('/questionshipping');
    }

    goBoletin(){
        this.history.push('/boletin');
    }
    // Busqueda LITE
    goQuote(){
        this.history.push('/quote');
    }

    goGetPlantilla(){
        this.history.push('/platilla');
    }

    goApps(){
        this.history.push('/apps');
    }

    goAnalisis(){
        this.history.push('/Analisis');
    }

    goTimes(){
        this.history.push('/Times');
    }

    goDetails(){
        this.history.push('/Details');
    }

    goGeneral(){
        this.history.push('/General');
    }
}

export default History;