import React from 'react';
import ReactToPrint from 'react-to-print';
import ReportModal from './ReportModal';

 
class ExportReportPDF extends React.Component {
    render() {
      const {date,data,user,SubTotal,Total,seller, currentCart} = this.props;
      
      return (
        <div>
           <ReactToPrint
                content={() => this.componentRef}
                trigger={() =>  <label className="btn float-left impr mr-sm-2"><i class="fas fa-print" style={{color:'#f31010'}}></i> Imprimir PDF</label>}
            />
            <div style={{ display: "none" }}>
                <ReportModal ref={(response) => (this.componentRef = response)} date={date} data={data} user={user} SubTotal={SubTotal} Total={Total}  seller={seller} currentCart={currentCart}/>
                {/* <ReportModal ref={(response) => (this.componentRef = response)} data={orderID} /> */}

            </div>
        </div>
      );
    }
}
 
export default ExportReportPDF;