import React, { Component } from 'react';
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID, SERVICE_RESPONSE, ROLES } from '../libs/utils/Const';
import {connect} from "react-redux";
import { ItemDetailsModal } from "./index";
import CurrencyFormat from 'react-currency-format';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ItemSlider.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ApiClient } from '../libs/apiClient/ApiClient';
import moment from 'moment';

let settings;
let apiClient = ApiClient.getInstance();

// esto es para el nuevo carrousel que contendrá los items
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1920 },
        items: 4,
        partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
    },
    desktop2: {
        breakpoint: { max: 1920, min: 1200 },
        items: 3,
        partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1200, min: 992 },
      items: 2,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet1: {
        breakpoint: { max: 992, min: 767 },
        items: 2,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
      },
    tablet2: {
        breakpoint: { max: 767, min: 464 },
        items: 1,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
      },
    mobile: {
      breakpoint: { max: 463, min: 0 },
      items: 1,
      partialVisibilityGutter: 5 // this is needed to tell the amount of px that should be visible.
    }
}
  
class ItemSlider extends Component {

    constructor(props){
        super(props);
        this.state = {
        };
        this.mouseOverAddCart = this.mouseOverAddCart.bind(this);
        this.mouseOutAddCart = this.mouseOutAddCart.bind(this);
        this.iconMouse = [];     
        this.iconMouseOver = []; 
    }

    changeQuantity = (index, item, event) =>{
        const {changeQuantity, notificationReducer: {showAlert},itemsReducer: {items: itemsSearch}} = this.props; 
        
        let newQuantity;
        itemsSearch.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                newQuantity = itemFilter.quantity || '1';
            }
        });

        let onHand = item.OnHandPrincipal;

        if(Number(newQuantity) > Number(onHand)) {
            if (Number(onHand) !== 0 ) {
                showAlert({type: 'warning', message: "Se excede el número de articulos disponibles de este producto 02", timeOut: 2500});
            }
            newQuantity = onHand;
        } 

        if(!newQuantity){
                changeQuantity(index,item, newQuantity, false); //delete
            }else{
                changeQuantity(index,item, newQuantity, true); // add
            }
    };

    setSepecialPrice = (itemParameter) =>{
        let item = itemParameter;
        const {itemsReducer: {specialPrice}} = this.props;
        
        let CardCode = specialPrice.CardCode;
        let PriceList = specialPrice.ListNum && specialPrice.ListNum !== '' ? specialPrice.ListNum : specialPrice.priceList;
        
        // Special Prices Validation
        //########################################################################################################################
        let priority2 = "*" + PriceList;
        let formattedDocDate = moment().format('YYYY/MM/DD');

        let flagSN = true; // Socio de negocio
        let flagPriceList = true; // Lista de precios
        let flagPricePriority = true;
        let maxQuantity = 0; // Cantidad maxima a alegir
        let priceItem = -1;
        let discount = -1;
        let priceBeforeDisc= -1;

        item.QuantitySpecial = item.quantity;
        item.PriceTest = -1;

        // Socio de negocios
        if(specialPrice.specialPrices.length > 0){
            specialPrice.specialPrices.map((itemPrice) => {
                if (item.ItemCode == itemPrice.ItemCode && itemPrice.CardCode == CardCode) {
                    if (itemPrice.children.length > 0) {
                        itemPrice.children.map((child) => {
                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);
                            
                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);

                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if(docDate >= inicialCantidad && docDate <= finalCantidad){
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if(child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if(maxQuantity != 0){
                                        flagSN = false;
                                    }
                                    if(flagSN){
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagSN = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagSN = false;
                                }
                            } else {
                                // General
                                if(flagSN){
                                    item.DiscountPercentSpecial = itemPrice.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice.Price);
                                    discount = parseFloat(itemPrice.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            } 
                        })
                    } else {                           
                        //General
                        item.DiscountPercentSpecial = itemPrice.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice.Price);
                        discount = parseFloat(itemPrice.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        }
        // Lista de inventario
        if(specialPrice.specialPricesList.length > 0 && flagPricePriority){
            specialPrice.specialPricesList.map((itemPrice2)  => {
                if(itemPrice2.ItemCode == item.ItemCode && itemPrice2.CardCode == priority2){
                    if (itemPrice2.children.length > 0) {
                        itemPrice2.children.map((child)=> {

                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);
                            
                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);
                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice2.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice2.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if(docDate >= inicialCantidad && docDate <= finalCantidad){
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if(child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if(maxQuantity != 0){
                                        flagPriceList = false;
                                    }
                                    if(flagPriceList){
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagPriceList = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagPriceList = false;
                                }
                            } else {
                                // General
                                if(flagPriceList){
                                    item.DiscountPercentSpecial = itemPrice2.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice2.Price);
                                    discount = parseFloat(itemPrice2.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            } 
                        })
                    } else {
                        // General
                        item.DiscountPercentSpecial = itemPrice2.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice2.Price);
                        discount = parseFloat(itemPrice2.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        } 
        //No tiene precio especial
        if(flagPricePriority){
            item.DiscountPercentSpecial = 0;
        }

        // Precios por descuentos especiales
        if(priceBeforeDisc != -1){
            item.Price = Number(priceItem);
            item.PriceTest = Number(priceItem);            
            item.PriceBeforeDiscount = Number(priceBeforeDisc);
        }
        
        let price = item.PriceTest !== -1 ? Number(item.Price).toFixed(2) : -1;
        return price;
    }

    changLocalQuantity = (index, item, event)=>{
        const {shoppingCartReducer: {items},itemsReducer: {items: itemsSearch}} = this.props;
        let newQuantity =  event.nativeEvent.target.value;
        itemsSearch.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                itemFilter.quantity = newQuantity;
                let specialPrice = this.setSepecialPrice(itemFilter);
                if(specialPrice !== -1){
                    itemFilter.Price = specialPrice;
                }
            }
        });
        this.applyFilters(itemsSearch);
    }
    
    applyFilters = data => {
        const {setItemsFilterSearch} = this.props;
        // enableSpinner(true);
        setItemsFilterSearch(data);
        // enableSpinner(false);

        // $("#menuCategories").removeClass('open-menu-categories');
    };

    mouseOverAddCart = (index, flag, event)=>{
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "none";
            this.iconMouseOver[index].style.display = "block";
        }
    }

    mouseOutAddCart = (index, flag, event)=>{
        if(flag == 'green'){
            event.currentTarget.style.backgroundColor = "#89c33f";
            event.currentTarget.className = "btn btn-block botonAgregar"
        } else if(flag == 'yellow'){
            event.currentTarget.style.backgroundColor = "#efc964";
            event.currentTarget.className = "btn btn-block botonAgregarYellow"
        } else {
            event.currentTarget.style.backgroundColor = "#e27753";
            event.currentTarget.className = "btn btn-block botonAgregarAgotado"
        }
        // Íconos
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "block";
            this.iconMouseOver[index].style.display = "none";
        }
    }

    render() {
        const { items, changeBackOrder, updateFavorite, deleteShoppingCart, openDetails, dashboard } = this.props;
        const { addCartMessageOver } = this.state;
        let h1=items.length;

        return (
            <div>
                <ItemDetailsModal view={VIEW_NAME.ITEMS_VIEW} />
                <div className="SliderItems">
                <Carousel autoPlay partialVisible={false} responsive={responsive} autoPlaySpeed={5000} removeArrowOnDeviceType={["tablet", "mobile","tablet1","tablet2"]} >
                        {items && items.map((item, index) => {
                            item.ItemName = item.ItemName || '';
                            let dashboardForTwoSliders = dashboard ? dashboard : '';
                            return (
                                <div id="ItemsSlider" style={{ display:"relative", textAlign:"center", maxWidth:"27rem"}} key={(dashboard || '') + item.ItemCode + item.ItemName + item.Price}>
                                    <div className="item card" style={{width: "305px", height: "521.733px", borderRadius: ".8rem", backgroundColor: config.itemsList.backgroundColor }}>
                                        <div className="imageCard">
                                            <div style={{
                                                display: (!item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 ? 'none' : 'table'),                                                
                                            }}>
                                                <div className="font-weight-bold ribbon" onClick={() => openDetails(item)}><span><blink> {item.isDestacado ? 'DESTACADO' : 'PROMOCIÓN'} </blink></span></div>
                                            </div>
                                            <img
                                                onClick={() => openDetails(item)}
                                                className="card-img-top cardImg"
                                                // src={config.shoppingCartIcons.imagenDefecto}
                                                src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require("../images/noImage.png")}
                                                alt=""
                                            />
                                            <div className="card-body" style={{height:"fit-content",margin: 0, padding: "38px", color: config.itemsList.textColor}}>
                                                <div>
                                                    <p className="card-subtitle text-left" style={{ fontSize: 20, color: config.itemsList.textPrice2 }}>
                                                        {item.U_web === 0 || item.U_web === null
                                                            ?  "Solicite su cotización"
                                                    
                                                            
                                                           
                                                      
                                                            :(!item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 ? 
                                                                <CurrencyFormat
                                                                    value={item.Price}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={'$'}>
                                                                </CurrencyFormat>
                                                            :<>
                                                                <span className="precioPromocion row justify-content-start ml-md-2 pl-2">
                                                                    <CurrencyFormat
                                                                        value={item.PriceBeforeDiscount}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        prefix={'$'}>
                                                                    </CurrencyFormat>
                                                                </span>
                                                                <span className="precioPromocionItemList">
                                                                    <div className="preciosItemSlider row justify-content-start">
                                                                        <div className="pricePromoItemSlider col-md-auto ml-md-1" style = {{fontSize: 'xx-large'}}>
                                                                            <CurrencyFormat
                                                                                value={item.currency === 'USD' ? item.Price * item.RateUSD : item.Price}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={'$'}>
                                                                            </CurrencyFormat>
                                                                        </div>
                                                                        {item.DiscountPercentSpecial !== 0 &&
                                                                            <div className="descuento col-md-4 align-middle text-right">
                                                                                <span className = "text-danger">{parseFloat(item.DiscountPercentSpecial).toFixed(2)}% OFF</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </span>
                                                            </>
                                                        )
                                                    }
                                                    {item.U_web === 1 &&
                                                        " " //+ item.currency
                                                    }
                                                    </p>
                                                </div>
                                                {
                                                /*Nombre del articulo*/
                                                }
                                                <div className="overflow-auto ItemName">
                                                <p className="card-title text-left ItemName" style={{ padding: 1, fontSize: 13, fontWeight: 600, overflowWrap: 'break-word', maxWidth: '170px' }}>
                                                        {/*item.ItemName.length > 43 ? item.ItemName.substring(0, 42 ) + "..." : item.ItemName*/}
                                                        {/* {item.ItemName ? (item.ItemName).length > 32 ? (item.ItemName).substring(0, 31) + " ... ": item.ItemName : " "} */}
                                                        {item.ItemName}
                                                    </p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                    <p className="text-left " style={{marginLeft: -16,fontSize: 15, paddingBottom: 0, marginBottom: 0,}}><span style={{color:"#AFAFAF"}}>SKU: </span> <span className="font-weight-bold" style={{color: "#000000"}}>{item.ItemCode}</span></p>
                                                        {/* <h6 className="card-subtitle font-weight-bold text-left" style={{ fontSize: 16, margin: 2, color: config.itemsList.textPrice }}>
                                                            {item.U_web === 0 || item.U_web === null
                                                                ? "Solicite su cotización"
                                                                : <CurrencyFormat
                                                                    value={item.Price}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={'$ '}>
                                                                </CurrencyFormat>
                                                            }
                                                            {item.U_web === 1 &&
                                                                " " + item.currency
                                                            }
                                                        </h6> */}
                                                    </div>
                                                    <div className="col-3" style={{ padding: 0, margin: 0, }}>
                                                        <div onClick={() => updateFavorite(item)} style={{ padding: 0 }}>
                                                            {/*<i
                                                            className={config.icons.favorite}
                                                            aria-hidden="true"
                                                            style={{
                                                                cursor: 'pointer',
                                                                padding: 0,
                                                                margin: 0,
                                                                marginTop: 9,
                                                                fontSize: 30,
                                                                zIndex: 10,
                                                                color: item.favorite ? config.itemsList.iconColor : 'rgba(124, 124, 125, 0.5)',
                                                            }}
                                                        />*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{borderTop: "transparent"}}>
                                            <div className="row text-center" style={{margin: 0, padding: 0}}>
                                                {item.U_web === 0 || item.U_web === null ? 
                                                    <div className="col-12" style={{padding:0}}>
                                                          <button
                                                            className="btn btn-lg text-white"
                                                            type="button" 
                                                            style={{background: config.navBar.iconColor, fontSize: '1rem', fontWeight: 'bold' }}
                                                            >
                                                           SOLICITE SU COTIZACIÓN
                                                        </button>
                                                    
                                                    </div> : 
                                                    <div className="col-3 cantidad" style={{padding:0}}>
                                                        {item.Unidades === "Unidades" ?
                                                            <input
                                                                disabled={!(!!item.OnHandPrincipal)}
                                                                id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                type="number"
                                                                min="1"
                                                                value={item.quantity ? Number(item.quantity).toFixed(0) : ''}
                                                                className="form-control"
                                                                name={'quantity' + item.ItemCode}
                                                                placeholder="1"
                                                                style={{textAlign: "left", marginTop: 1, height: 44.5, width: 115}}
                                                                onChange={(event) => {this.changLocalQuantity(dashboard.toString() + index, item, event)}}
                                                                //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                            />
                                                            :<input
                                                                disabled={!(!!item.OnHandPrincipal)}
                                                                id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                type="number"
                                                                min="1"
                                                                value={item.quantity ? item.quantity : ''}
                                                                className="form-control"
                                                                name={'quantity' + item.ItemCode}
                                                                placeholder="1"
                                                                style={{textAlign: "left", height: 44.5}}
                                                                onChange={(event) => {this.changLocalQuantity(dashboard.toString() + index, item, event)}}
                                                                //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                            />
                                                        }
                                                    </div>
                                                }
                                                {/* {item.U_web === 0 || item.U_web === null ?
                                                    <div><label style={{textAlign: "center", fontSize: 16}}>Para cotización</label></div>:
                                                    <div className="col-6 botonAgregar">
                                                        <button style={{textAlign: "center"}} 
                                                            type="button" 
                                                            readOnly = {item.OnHandPrincipal === 0 || item.OnHandPrincipal === '' ? true : false} 
                                                            className= {item.OnHandPrincipal === 0 ? "btn btn-block botonAgregarAgotado" : item.flag === 'green' ? "btn btn-block botonAgregar": "btn btn-block botonAgregarYellow"}  
                                                            value={(item.quantity ? Number(item.quantity): '1')} 
                                                            onClick={(event)=>{this.changeQuantity(index, item, event)}} 
                                                            onMouseOver={(event)=>{this.mouseOverAddCart(dashboardForTwoSliders.toString() +index, item.flag, event)}} 
                                                            onMouseOut={(event)=>{this.mouseOutAddCart(dashboardForTwoSliders.toString() +index, item.flag, event)}}
                                                            >
                                                        </button>
                                                    </div>
                                                } */}
                                                {((item.U_web !== 0 && item.U_web !== null) && (item.OnHandPrincipal == 0 || item.OnHandPrincipal == '')) &&
                                                    <div className="icoCarrito col-3 text-center align-middle" style={{fontSize: 33,padding:0, textAlign: "right"}}>
                                                        <img ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() +index] = iconMouse} src={config.shoppingCartIcons.carritoAgotado} style={{display: "block", color: "red", cursor: "auto"}}/>
                                                        <img ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() +index] = iconMouseOver} src={config.shoppingCartIcons.camionAgotado} style={{display: "none", color: "red", cursor: "auto"}}/>
                                                    </div>
                                                } 
                                                {((item.U_web !== 0 && item.U_web !== null) && item.OnHandPrincipal > 0 && (item.flag === 'green' || item.flag === 'yellow')) &&
                                                    <div className="icoCarrito col-3 text-center align-middle" style={{fontSize: 33,padding:0, textAlign: "right"}}>
                                                        <img ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() +index] = iconMouse} src={item.flag === 'green' ? config.shoppingCartIcons.carritoVerde : config.shoppingCartIcons.carritoAmarillo} style={{display: "block", cursor: "auto"}}/>
                                                        <img ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() +index] = iconMouseOver} src={item.flag === 'green' ? config.shoppingCartIcons.camionVerde : config.shoppingCartIcons.camionAmarillo} style={{display: "none", cursor: "auto"}}/>
                                                    </div>
                                                }
                                                {/* {item.U_web === 1 &&
                                                    <div className="col-3 text-center align-middle iconosCarrito" style={{color: "red", fontSize: 33,padding:0, textAlign: "right"}}>
                                                        {item.quantity && <i className={config.icons.trash} style={{cursor: "pointer"}} onClick={() =>  deleteShoppingCart({item, deleteAll: false})}/>}
                                                    </div>
                                                } */}
                                            </div>
                                            <div className="row text-center" style={{ margin: 0, padding: 0 }}>
                                                {/* {item.U_web === 0 || item.U_web === null
                                                    ? item.wishlist === 1 &&
                                                    <div className="col-12" style={{ padding: 0 }}>
                                                        <label style={{ textAlign: "center", fontSize: 16 }}>para cotización</label>
                                                    </div>
                                                    : item.wishlist === 1 &&
                                                    <div className="col-10">
                                                        <label style={{ textAlign: "center", marginTop: 12, fontSize: 14 }}>Lista de deseos</label>
                                                    </div>
                                                } */}
                                                {item.U_web === 1 &&
                                                    item.wishlist === 1 &&
                                                    <div className="col-2" style={{ color: config.navBar.textColor2, fontSize: 20, padding: 0 }}>
                                                        {item.backOrder
                                                            ? <img className="backOrderFalse" src={config.icons.backOrderFalse} alt="backOrderFalse" style={{ width: '20px', height: '20px', marginTop: "7px", cursor: 'pointer', marginLeft: "7px" }} onClick={() => changeBackOrder(item, false)} />
                                                            : <img className="backOrderTrue" src={config.icons.backOrderTrue} alt="backOrderTrue" style={{ width: '20px', height: '20px', marginTop: "7px", cursor: 'pointer', marginLeft: "7px" }} onClick={() => changeBackOrder(item, true)} />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Carousel>                    
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsFilterSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value}),
        setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        searchByDashOption: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH_OPTION, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ItemSlider);

