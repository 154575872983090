import React, { Component } from 'react';
import {config} from "../libs/utils/Const";

class NavBarButton extends Component {
    render() {
        const { icon, iconColor, ruta, iconAbajo, menuHam} = this.props;
        return (
            <button className="navbar-toggler toggler-example ml-auto" 
                type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                aria-controls="navbarSupportedContent1" 
                aria-expanded="false" 
                aria-label="Toggle navigation" 
                style={{color: iconColor, width: "100%"}}
            >
                <div className="dark-blue-text">
                    
                    <img src={config.icons.userWhite} style={{width:'16px', height:'16px', cursor:'pointer'}} alt="icon" />
                    <img src={config.icons.flechaW} alt="iconAbajo" style={{width:'12px', height:'12px', marginLeft: "5px", cursor:'pointer'}} />
                </div>
                
            </button>
        );
    }
}

export default NavBarButton;