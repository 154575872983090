import React, { Component } from 'react';
import { NavBar } from "../components";
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID, SERVICE_RESPONSE, ROLES } from '../libs/utils/Const';
import {connect} from "react-redux";
import './EditAtributeItem.css';
import $ from 'jquery';
import 'react-multi-carousel/lib/styles.css';
import { ApiClient } from '../libs/apiClient/ApiClient';
import moment from 'moment';

let apiClient = ApiClient.getInstance();

class EditAtributeItem extends Component {

    constructor(props){
        super(props);
        this.state = {
            atributesItems :[],
            copyAtributesItems :[], // Guardar una copia de las props por si se aplica lógica de familia default
            manualUpdate: false,
        };
    }
    // componentWillUpdate(nextProps, nextState){
    //     if(nextProps.responseFam != this.props.responseFam){
    //         this.setState({
    //             atributesItems : this.props.responseFam,
    //         });
    //     }
        
    // }

    componentDidUpdate(prevProps) {
        const { responseFam } = this.props;
        const { copyAtributesItems } = this.state;
        if (
            prevProps.responseFam !== responseFam &&
            JSON.stringify(copyAtributesItems) !== JSON.stringify(responseFam)
        ) {
            this.setState({
                atributesItems: responseFam,
                copyAtributesItems: responseFam,
            });
        }
    }
    
    render () {
        const { atributesItems, } = this.state;
        let checkedA = true ;
        return (
        <div className="modal fade bd-example-modal-lg" id="AtributeEdit" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{minWidth:300 ,maxWidth: '80vw', margin: '1.75rem auto', borderRadius: 15}}>
                <div className="modal-content">
                    <div className="modal-header" style={{background: config.navBar.iconColor, color: '#FFFFFF'}}>
                        <h5 className="modal-title">Atributos unicos por items</h5>
                        <button type="button" style={{color:"#FFFFFF", opacity:1, margin:0, padding:0}} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-row" >
                                <div className="form-group col-12 row body-form-col" >
                                    {Object.keys(atributesItems).length > 0 && atributesItems.allAtributes.length > 0 &&
                                        atributesItems.allAtributes.map( (attribute , index) => {
                                            checkedA = false ;
                                            // console.log('con<atributesItems',atributesItems);
                                            if( atributesItems.atributeActive.length > 0 ){
                                                checkedA =  atributesItems.atributeActive.filter( ( campos => campos == attribute.column ) ).length > 0;
                                            }
                                            return(
                                                <div key={index} className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 row">

                                                    <input className='col-1 check-atributes' checked = {checkedA} type="checkbox" id={'Check'+attribute.code}
                                                    onClick={() => {this.changeCheck(attribute.column, attribute.code );}}
                                                    />
                                                    <div className = 'col-9'/* style={{ alingItems: 'center' }}*/ >
                                                        <label className='text-atribut' for={'Check'+attribute.code} > {attribute.name} </label>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="modal-footer col-12 row col-footer">

                                    <button type="button" className="btn "
                                    style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}}
                                    onClick = {() => { 
                                        $('#AtributeEdit').modal('hide') ;
                                    }}
                                    > Cancelar </button>

                                    <button type="button" className="btn " id='btn-family-default' 
                                    style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}}
                                    onClick = { () => { 
                                        // this.atributesDefault();
                                        this.defaultFamily();
                                    }}
                                    > Familia default </button>

                                    <button type="button" className="btn "
                                    style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}}
                                    onClick = {() => { 
                                        this.updateItem();
                                    }}
                                    > Guardar </button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    updateItem = async () => {
        const { atributesItems } = this.state;
        const { ItemCode, enableSpinner, notificationReducer: {showAlert, }, recargar , responseFam : { Family } } = this.props;

        let response = [];
        // console.log('con<atributesItems:',atributesItems);
        if( ItemCode ){
            if(atributesItems.atributeActive.length > 0){
                response = await apiClient.updateOnlyAttribut(ItemCode,atributesItems.atributeActive);
                recargar(Family,ItemCode);
            }else{
                response = await apiClient.deleteOnlyAttribut(ItemCode,);
                recargar(Family,ItemCode);
            }
            if(response.status == SERVICE_RESPONSE.SUCCESS){
                showAlert({ type: 'success', message: response.message });
                $('#AtributeEdit').modal('hide') ;
            }else{
                showAlert({ type: 'error', message: response.message });
            }
        }
    }
    
    atributesDefault = async () => {
        const { ItemCode, enableSpinner, notificationReducer: {showAlert, } , recargar ,  responseFam : { Family }} = this.props;
        const response = await apiClient.deleteOnlyAttribut(ItemCode,);

        if(response.status == SERVICE_RESPONSE.SUCCESS){
            showAlert({ type: 'success', message: response.message });
            $('#AtributeEdit').modal('hide') ;
            recargar(Family,ItemCode);
        }else{
            showAlert({ type: 'error', message: response.message });
        }
    }

    defaultFamily = async () => {
        const { atributesItems } = this.state;
        const { enableSpinner, notificationReducer: { showAlert } } = this.props;
        let copyAtributesItems = atributesItems;
        if (atributesItems?.Family) {
            enableSpinner(true);
            let dataFamily = { type: 4 }; // 1- Crear, 2-Actualizar, 3-Eliminar, 4-Obtener
            const response = await apiClient.updateFamilies(dataFamily);
            enableSpinner(false);
            if (response.status === SERVICE_RESPONSE.SUCCESS) {
                let family = response?.data?.find((attribute) => attribute.IdFamily === parseInt(atributesItems.Family));
                if (family) {
                    enableSpinner(true);
                    let existingAttributes = atributesItems?.atributeActive || []; // Atributos existentes
                    let familyAttributes = family.attributes || []; // Atributos de la familia
                    if (familyAttributes?.length === 0 || !familyAttributes) {
                        enableSpinner(false);
                        return showAlert({ type: 'info', message: `La familia no cuenta con atributos`, timeOut: 8000 });
                    }
                    //Buscar atributos de la familia
                    const mappedFamilyAttributes = familyAttributes?.map(attr => {
                        // Normalizamos ambos valores a mayúsculas y eliminamos diferencias como "Atributo"
                        const normalizedAttr = attr?.replace(/A_/g, '')?.toUpperCase();
                        const found = atributesItems?.allAtributes?.find(item =>
                            item?.code?.replace(/Atributo_/g, '')?.toUpperCase()?.includes(normalizedAttr)
                        );
                        return found ? found.column : null;
                    }).filter(Boolean); // Filtrar valores null

                    // Concatenar y eliminar duplicados
                    let uniqueAttributes = [...new Set([...existingAttributes, ...mappedFamilyAttributes])];

                    let updatedChecks = { ...atributesItems, atributeActive: uniqueAttributes };
                    this.setState({ atributesItems: updatedChecks, copyAtributesItems });
                    enableSpinner(false);
                    showAlert({ type: 'success', message: `Los atributos de la familia fueron asignados`, timeOut: 8000 });
                } else {
                    showAlert({ type: 'info', message: 'La familia no está registrada, se restablecieron los atributos', timeOut: 8000 });
                }
            } else {
                showAlert({ type: 'error', message: `Ocurrió un problema al intentar obtener los atributos de la familia`, timeOut: 8000 });
            }
        } else {
            showAlert({ type: 'info', message: 'No existe una familia seleccionada', timeOut: 8000 });
        }
    }
    
    changeCheck(column , code) {
        let { atributesItems } = this.state;
        let newAray = [];

        let check = $('#Check'+code).prop("checked");
        if(check){
            atributesItems.atributeActive.push(column);
        }else{
           for (let index = 0; index < atributesItems.atributeActive.length; index++) {
               const element = atributesItems.atributeActive[index];
               if(element != column){
                newAray.push(element);
               }
           }
           atributesItems.atributeActive = newAray;
        }
        
        this.setState({
            atributesItems
        });
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsFilterSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value}),
        setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        searchByDashOption: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH_OPTION, value}),
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditAtributeItem);

