import React from "react";
import moment from "moment";
import { config } from '../libs/utils/Const.js';

class AutoPrintFormat extends React.Component {
  render() {
    const { orders, doc } = this.props;
    // PDF Documentos de autorización
    let totalPrecioTotal = 0;
    return (
      <div style={{ pageBreakBefore: 'always', padding: "30px 50px" }}>
        <div className="d-flex flex-column" style={{ marginTop: "20px" }}>
          {/* Cabecera información */}
          <div className="d-flex flex-row">
            <div>
              <img
                style={{ width: "80%", height: "auto", maxWidth: 200 }}
                src={config.navBar.icon}
                alt="Logo"
              />
              <br />
              <div>
                <h2 class="mt-4 text-left font-weight-bold">
                  Perez de la Mora
                </h2>
                <h5 class="mb-2 text-left">Calle Ramon Corona 525, Ciudad Guzman Centro, C.P. 49000, Jalisco.</h5>
                <h5 class="text-left">ventas@perezdelamora.com</h5>
                <h5 className="text-left">Tel: (01 34) 1413-55-99  o  (01 34) 1410-53-37</h5>
              </div>
            </div>

            <div
              className="d-flex justify-content-end"
              style={{
                flexWrap: "wrap",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                content: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h2 style={{ fontWeight: "bold" }}>Documentos de autorización</h2>
              </div>

              {/* Tabla información de cliente */}
              <table style={{ width: "90%", borderCollapse: "collapse" }}>
                <thead>
                  <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                    <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                      Nº Cliente
                    </th>
                    <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                      Nombre
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        border: "0px solid #ddd",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                      className="font-weight-bold"
                    >
                      {orders ? orders.CardCode : "N/A"}
                    </td>
                    <td
                      style={{ border: "0px solid #ddd", textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      {orders ? orders.CardName : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Tabla información de N° documento, fecha y vendedor */}
              <table style={{ width: "90%", borderCollapse: "collapse" }}>
                <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Nº Documento
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Fecha
                  </th>
                  <th style={{ border: "0px solid #ddd", textAlign: "center" }}>
                    Vendedor
                  </th>
                </tr>
                <tr>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {orders ? orders.DocEntry : "N/A"}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc && doc.data && doc.data.header && doc.data.header.length > 0 ?
                      moment(doc.data.header[0].TaxDate).utc().format('DD-MM-YYYY') : "N/A"}
                  </td>
                  <td
                    style={{ border: "0px solid #ddd", textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    {doc?.data?.header?.[0]?.U_FMB_Handel_Creador
                      ? doc.data.header[0].U_FMB_Handel_Creador.split(",")[1]?.trim() ?? "N/A"
                      : "N/A"}
                  </td>
                </tr>
              </table>

            </div>
          </div>

          {/* Tabla info body */}
          <div className="mt-5 d-flex flex-column bd-highlight text-center">
            <table>
              <tr className="text-white text-center" style={{ backgroundColor: config.navBar.iconColor }}>
                <th>Cód. Fabricante</th>
                <th>Cód. SAP</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Precio con IVA</th>
                <th>Precio total con IVA</th>
              </tr>
              {!!doc &&
                doc.data.body.map((item, index) => {
                  const precioTotalItem = parseFloat(item.LineTotal);
                  totalPrecioTotal += precioTotalItem;
                  return (
                    <tr className='text-center' key={index}>
                      <th>{item.SuppCatNum ? item.SuppCatNum : 'N/A'}</th>
                      <th>{item.ItemCode ? item.ItemCode : '--'}</th>
                      <th>{item.ItemName ? item.ItemName : 'N/A'}</th>
                      <th>{item.Quantity ? parseInt(item.Quantity) : '--'}</th>
                      <th>{item.Price ? `$ ${parseFloat(item.Price).toFixed(2)} MXN` : '--'}</th>
                      <th>{item.Price ? `$ ${parseFloat(item.Price * 0.16).toFixed(2)} MXN` : '--'}</th>
                      <th>{item.Price ? `$ ${parseFloat(item.Price * 0.16 + parseFloat(item.Price)).toFixed(2)} MXN` : '--'}</th>
                    </tr>
                  );
                })}
            </table>
          </div>

          {/* Tabla total de total */}
          <div className="d-flex justify-content-end mt-5 text-center">
            <table className="font-weight-bold">
              <tr>
                <th
                  className="text-white p-2"
                  style={{ backgroundColor: config.navBar.iconColor }}
                >
                  Subtotal
                </th>
                <td> {totalPrecioTotal >=0 ? `$ ${totalPrecioTotal.toFixed(2)} MXN` : '--'} </td>
              </tr>
              <tr>
                <th
                  className="text-white"
                  style={{ backgroundColor: config.navBar.iconColor }}
                >
                  IVA 16%
                </th>
                <td> {totalPrecioTotal >=0 ? `$ ${(totalPrecioTotal * 0.16).toFixed(2)} MXN` : '--'} </td>
              </tr>
              <tr>
                <th
                  className="text-white"
                  style={{ backgroundColor: config.navBar.iconColor }}
                >
                  Total
                </th>
                <td>
                  {doc && doc.data && doc.data.header && doc.data.header.length > 0
                    ? `$ ${parseFloat(doc.data.header[0].DocTotal).toFixed(2)}  MXN` : "--"}
                </td>
              </tr>
            </table>
          </div>
          <br></br>
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              flexDirection: 'row',
            }}
          >
            <span className="font-weight-bold">Comentario:</span>
            <span style={{ marginLeft: '10px' }}>
              {doc?.data?.header?.[0]?.Comments || 'Sin comentario disponible'}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default AutoPrintFormat;