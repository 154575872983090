import React, { Component } from 'react';
import { NavBar } from "../components";
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID, SERVICE_RESPONSE, ROLES } from '../libs/utils/Const';
import { connect } from "react-redux";
import { ItemDetailsModal } from "./index";
import './Fathers.css';
import $ from 'jquery';
import 'react-multi-carousel/lib/styles.css';
import { ApiClient } from '../libs/apiClient/ApiClient';
import moment from 'moment';
import { FileUploader } from "react-drag-drop-files";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';

let apiClient = ApiClient.getInstance();

ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            'heading', '|',
            'alignment', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
            'link', '|',
            'bulletedList', 'numberedList', 'todoList',
            '-', // break point
            'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
            'code', 'codeBlock', '|',
            'insertTable', '|',
            'outdent', 'indent', '|',
            'uploadImage', 'blockQuote', '|',
            'undo', 'redo'

        ],
        shouldNotGroupWhenFull: true,
    },
    language: 'es'
};

class Fathers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // Father: {},
            // activesAtributes : [],
            // IMGEN : require('../images/noImage.png'),
            // F458 UX
            // isEditItemModeF: false,
            // isCleanDataModeF: false,
        };
    }



    render() {

        const { Familys, Father, activesAtributes, IMGEN, changeCheck, handleDrop
            , fillData, NewFather, SaveFather, clearAll, onChangeFather, isCleanDataMode, isEditItemMode, addChilds, childsFather,
            fillDataItem, removeChildsFather, changeOrderChilds, HandleCaracteristicasFather, HandleEspecificacionesFather} = this.props;

        // const {isCleanDataMode, isEditItemMode} = this.state;
        // const { Familys, data } = this.props;
        let checkedA = false;
        let namesAtributes = [];

        if(childsFather.length > 0){
            let numberAtributes = Object.keys(childsFather[0].Child);
    
            for (let index = 0; index < numberAtributes.length; index++) {
                const element = numberAtributes[index];
                if( (element).includes('Atributo_') ){
                    namesAtributes.push(element);
                }
            }
        }


        return (
            <div className="content-fluid row" >

                <form>
                    <div className="form-group row col-12">
                        <label className='col-md-6' style={{ fontWeight: "bold", marginTop: 10 }}>Información padres</label>
                        <div className='col-md-6 col-Childs' onClick={async () => { addChilds(Father.CODE) }} >
                                <img src={config.icons.backOrderTrue} alt="backOrderTrue" style={{ width: '15px', height: '15px'}} />
                            <div className=' text-Childs'>
                                Agregar hijos
                            </div>
                        </div>
                    </div>
                    <div className="form-row">

                        {/* <div className="form-group col-md-4">
                            <label >Codigo</label>
                            <input type="text"
                                value={(Father.CODE ? Father.CODE : '')}
                                className="form-control"
                                name="FatherCode"
                                id="FatherCode"
                                autoComplete="off"
                                disabled={isEditItemMode}
                                onKeyDown={event => event.keyCode === 13 && fillData(event.target.value)}
                                onChange={(event) => onChangeFather(event.target.value, 'CODE')}
                            />
                        </div> */}

                        <div className="form-group col-md-4">
                            <label >Nombre</label>
                            <input type="text"
                                value={(Father.NAME ? Father.NAME : "")}
                                className="form-control"
                                // name="SKU"
                                // id="SKU"
                                autoComplete="off"
                                onChange={(event) => onChangeFather(event.target.value, 'NAME')}
                            />
                        </div>
                        <div className="form-group col-md-4" hidden = {true}>
                            <label ></label>
                            <input type="text"
                            hidden = {true}
                            />
                        </div>

                        <div className="form-group col-md-4 " >
                            <label>Activo ecommerce</label>
                            <select
                                class='select-Cliente-Tipo  col-md-12 custom-select'
                                size='1'
                                name="AtivoEcommerce"
                                id="AtivoEcommerce"
                                value={(Father.ENABLED ? Father.ENABLED : "")}
                                onChange={(event) => onChangeFather(event.target.value, 'ENABLED')}
                            >
                                <option value="0" >Selecciona opcion</option>
                                <option value="1" >Si ver </option>
                                <option value="0" >No ver</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-lg-6" style={{ justifyContent: "center", alignItems: "center", textAlignLast: 'center', placeSelf: 'center' }} >
                            <label >Imagen : </label>
                            <div style={{ alignItems: 'center', background: 'white', height: '5rem' }} >
                                <FileUploader
                                    multiple={false}
                                    hoverTitle='Arrastrar archivos'
                                    handleChange={handleDrop}
                                    name="file"
                                    types={["JPEG", "PNG", "GIF", "JPG"]}>
                                    <div className='row col-12 photos' style={{ alignItems: 'center' }}>
                                        <i class="fa fa-upload col-2" aria-hidden="true"></i>
                                        <p className='col-10' style={{ fontSize: 12 }}>En este lugar suben los archivos</p>
                                    </div>
                                </FileUploader>
                            </div>
                        </div>

                        <div className="form-group col-lg-6" style={{ justifyContent: "center", alignItems: "center", textAlignLast: 'center', placeSelf: 'center' }} >
                            <div className="form-group row col-md-12" style={{ alignContent: 'center', border: '#dedede 2px solid' }}>
                                <img className="img-fluid crese" style={{ backgroundColor: 'white', maxHeight: 130 }} src={IMGEN} />
                            </div>
                        </div>

                    </div>

                    <div className="form-row">
                        {Familys.allAtributes && Familys.allAtributes.length > 0 &&

                            Familys.allAtributes.map((attribute, index) => {
                                checkedA = false;
                                checkedA = activesAtributes.filter( (campos => campos == attribute.column)).length > 0;
                                return (
                                    <div className='col-lg-4 row col-allAtributes'>
                                        <input className='col-1 check-atributes' for={'Check' + attribute.code} checked={(attribute.active != undefined ? attribute.active : checkedA)} type="checkbox" id={'Check' + attribute.code}
                                            onClick = {() => changeCheck(index, attribute.column, attribute.code)}
                                        />
                                        <div className='col-9' >
                                            <label className='text-atribut' for={'Check' + attribute.code} > {attribute.name} </label>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                        <br></br>
                        <div className="form-row">
                        <div className="form-group col-md-12">
                            {/* <label 
                            // hidden={!desabilitadoCreate}
                            >Descripcion: </label>
                            <textarea resize type="textarea" 
                            // disabled
                            // hidden={!desabilitadoCreate}
                            value={ ( Father.USERTEXT ? (Father.USERTEXT).toString() : "" ) } 
                            style={{height: '10rem', overflowY: 'Scroll'}} 
                            className="form-control " 
                            name="Descripcion" 
                            id="Descripcion"  
                            autoComplete="off" 
                            onChange={(event) => onChangeFather(event.target.value, 'USERTEXT')} 

                            /> */}
                            <fieldset>
                                <label className="control-label" htmlFor="content">Características</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={Father.USERTEXT || ''}
                                    onInit={editor=>{ }}
                                    onChange={HandleCaracteristicasFather}
                                    config={{
                                        toolbar: ['bold'],
                                        fontFamily: {
                                            options: ['Arial'],
                                            supportAllValues: false,
                                        },
                                        fontSize: {
                                            options: ['14'],
                                            default: '14'
                                        },
                                        removePlugins: ['Italic', 'Underline', 'Strikethrough', 'Subscript', 'Superscript', 'Font', 'FontSize', 'TextColor', 'BGColor'],  // Eliminar opciones innecesarias
                                    }}
                                />
                            </fieldset>
                        </div>
                    </div>
                    <br></br>
                    <div className="form-row">
                        <div className="form-group col-md-12"
                            // hidden={ !( atributeActive.filter( ( campos => campos == 'U_FMB_Atributo_Especificaciones' ) ).length > 0 )}
                            >
                            {/* <label 
                            >ESPECIFICACIONES: </label>
                            <textarea resize type="textarea" 
                            value={ ( Father.U_FMB_ATRIBUTO_ESPECIFICACIONES ? (Father.U_FMB_ATRIBUTO_ESPECIFICACIONES).toString() : "" ) } 
                            style={{height: '10rem', overflowY: 'Scroll'}} 
                            className="form-control " 
                            name="Especificaciones" 
                            id="Especificaciones"  
                            autoComplete="off" 
                            onChange={(event) => onChangeFather(event.target.value, 'U_FMB_ATRIBUTO_ESPECIFICACIONES')} 
                            /> */}
                            <fieldset>
                                <label className="control-label" htmlFor="content">Especificaciones</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={Father.U_FMB_ATRIBUTO_ESPECIFICACIONES || ''}
                                    onInit={editor=>{ }}
                                    onChange={HandleEspecificacionesFather}
                                    config={{
                                        toolbar: ['bold'],
                                        fontFamily: {
                                            options: ['Arial'],
                                            supportAllValues: false,
                                        },
                                        fontSize: {
                                            options: ['14'],
                                            default: '14'
                                        },
                                        removePlugins: ['Italic', 'Underline', 'Strikethrough', 'Subscript', 'Superscript', 'Font', 'FontSize', 'TextColor', 'BGColor'],  // Eliminar opciones innecesarias
                                    }}
                                />
                            </fieldset>
                        </div>
                    </div>

                    <div className="form-row row-Items">
                        {childsFather && childsFather.length > 0 &&

                            childsFather.map((childs, index) => {
                                let imagesArray = childs.Child.U_FMB_Handel_ImagesArray || '';
                                imagesArray = imagesArray.split('|');
                                return (
                                    <div className='col-md-3 row col-item'>
                                        
                                        <div className='col-item1 col-12'>
                                            <div className='col-12 row' >
                                                <img className='img-fluid img-child '
                                                    src={config.BASE_URL + SERVICE_API.getImage + '/' + (childs.Child.PicturName ? childs.Child.PicturName : childs.Child.U_FMB_Handel_ImagesArray ? imagesArray[0] : 'noImagexd.png')}
                                                    alt="Sample Image"
                                                    style={{ height: 75 }}
                                                    onClick={() => {
                                                        fillDataItem(childs.Child.ItemCode);
                                                        $('#nav-profile-tab-Father').removeClass('active');
                                                        $('#Father').removeClass('active');
                                                        $('#nav-profile-tab-GeneralDriver').addClass('active');
                                                        $('#DatosMaestrosGeneralDriver').addClass('active');
                                                    }}
                                                />
                                                <div className='CloseButton' onClick={() => {
                                                    removeChildsFather(  childs.Child , index );
                                                }}>
                                                    <img src={config.icons.times} alt="times" style={{ width: '20px', height: '20px'}} />
                                                </div>
                                            </div>
                                            <div className='col-12 row col-Name-Code'  
                                            onClick={() => {
                                                fillDataItem(childs.Child.ItemCode);
                                                $('#nav-profile-tab-Father').removeClass('active');
                                                $('#Father').removeClass('active');
                                                $('#nav-profile-tab-GeneralDriver').addClass('active');
                                                $('#DatosMaestrosGeneralDriver').addClass('active');
                                            }}
                                            >
                                                <span className='col-12 row' >{childs.Child.ItemCode}</span>
                                                <span className='col-12 row' >{childs.Child.ItemName}</span>
                                                <span className='col-12 row' > 
                                                {namesAtributes.length > 0  &&
                                                    namesAtributes.map( (name) => {
                                                        return(
                                                            <span className='col-12 row atributos-col-row' > { childs.Child[name] } </span>
                                                        )
                                                    })
                                                }
                                                </span>
                                            </div>
                                            <div className='col-12 row col-NumberChild' >
                                                <div className = 'col-12 col-NumberChild-input' >
                                                    <input type="text"className='col-12 input-NumberChild' id='NumberChild'
                                                    value = {childs.Child.U_FMB_Handel_Orden}
                                                    onChange = {async (event )=>{
                                                        changeOrderChilds(childs.Child.ItemCode,event.target.value)
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <br/>
                    <div className="row">
                        {isEditItemMode && <div className='col-md-3 Button-C-S-E' style={{marginTop:'10px'}}>
                            <button type="button" className="btn btn-dark col-md-10"
                             style={{backgroundColor: config.navBar.iconColor}}
                                onClick={() => { $('#DecisionModal').modal('show'); } }
                            >Guardar
                            </button>
                        </div>}

                        {isCleanDataMode && <div className='col-md-3 Button-C-S-E'  style={{marginTop:'10px'}}>
                            <button type="button" className="btn btn-dark col-md-10"
                             style={{backgroundColor: config.navBar.iconColor}}
                                onClick={() => clearAll()}
                            >Limpiar
                            </button>
                        </div>}

                        {!isEditItemMode &&
                            <div className='col-md-3 Button-C-S-E' style={{marginTop:'10px'}}>
                                <button type="button" className="btn btn-dark col-md-10"
                                 style={{backgroundColor: config.navBar.iconColor}}
                                    onClick={() => NewFather()}
                                >Crear nuevo
                                </button>
                            </div>}
                    </div>
                </form>
            </div>
        );
    }



}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
        setNextPage: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value }),
        searchByDashOption: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH_OPTION, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Fathers);

