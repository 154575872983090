import React, { Component } from 'react';
import { Session, ProfieldModel, DocumentModel } from "../../components";
import { DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE, VIEW_NAME, config, ROLES } from "../../libs/utils/Const";
import { ApiClient } from "../../libs/apiClient/ApiClient";
import { connect } from "react-redux";
import moment from 'moment';
import $ from 'jquery';
import TimesView from '../time/TimesView';
import GeneralView from '../general/GeneralView';
import DetallesView from '../details/DetallesView';
import { CSVLink, CSVDownload } from "react-csv";
import { Redirect } from 'react-router';
import * as XLSX from 'xlsx';
import './analisis.css';

let apiClient = ApiClient.getInstance();

class AnalisisViews extends Component {
    csvLink = React.createRef();
    constructor(props) {
        super(props);
        const f = new Date;
        const newDate = new Date(f.setMonth(f.getMonth() + +(-2)))
        this.state = {
            // orders: [],
            // order: {
            //     header: {},
            //     body: []
            // },
            // hoja: "",
            // dataCsv: [],
            // hojas: [],
            // file: false,
            // items: {
            //     header: {},
            //     body: []
            // },
            // usuario: '',
            // data: [],
            fechaInicio: '',
            fechaFinal: '',
            // overdueOne: [],
            // opcion: 0,
            // reportsData: [],
            fechamin: moment(newDate).format('YYYY-MM-DD'),
            fechamax: moment(new Date()).format('YYYY-MM-DD'),
            // isLoaded: false,
            // itemCode: '',
            // isDevolution: false,
            user: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')),
            // oneOrder: '',   // Numero de documento a buscar
            // itemCodeSearch: '',
            seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
            // print: [],
            // docsToPrint: [],
            // docsToPrintCopy: [],
            // filter: '',
            filterDocument: '',
            // returns2: [],
            // returnRequest: [],
            // param1: 'All',
            // totalsOne: 0,
            popularGeneralItems: {},
            partidasCabecera: {},
            isRender: '',
            warehouses: [],
            analyticWhs: 'ALL',
        };
    }

    async componentDidMount() {
        const { enableSpinner, sessionReducer } = this.props;
        enableSpinner(true);
        let response = await apiClient.getDataProfiel();
        try {
            let creatorUser = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
            this.setState({
                usuario: creatorUser
            });
        } catch (error) {

        }
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                order: response.data,
            })
        };
        // this.handleInputChange = this.handleInputChange.bind(this);
        let fechaInicio = moment(new Date()).format('YYYY-MM-DD');
        let fechaFinal = moment(new Date()).format('YYYY-MM-DD');
        this.setState({
            fechaInicio,
            fechaFinal
        });
        if (sessionReducer && sessionReducer.role === ROLES.PUBLIC) {
            return <Redirect to="/" />;
        }
        let resWarehouses = await apiClient.getAnalyticsWhs();
        if (resWarehouses && resWarehouses.status === SERVICE_RESPONSE.SUCCESS) {
            await this.setState({ warehouses: resWarehouses.data || [] });
        }
        //Obtener la información de la sección por defecto (Pedidos)
        await this.onClickGetData('A2');
    };

    // async handleInputChange(event) {
    //     const target = event.target
    //     const value = target.type === 'checkbox' ? target.checked : target.value
    //     const name = target.name
    //     const this2 = this
    //     this.setState({
    //         [name]: value
    //     })
    //     let hojas = []
    //     if (name === 'file') {
    //         let reader = new FileReader()
    //         reader.readAsArrayBuffer(target.files[0])
    //         reader.onloadend = (e) => {
    //             var data = new Uint8Array(e.target.result);
    //             var workbook = XLSX.read(data, { type: 'array' });
    //             workbook.SheetNames.forEach(function (sheetName) {
    //                 // Here is your object
    //                 var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
    //                 hojas.push({
    //                     data: XL_row_object,
    //                     sheetName
    //                 })
    //             });
    //             this2.setState({
    //                 selectedFileDocument: target.files[0],
    //                 hojas
    //             });
    //             if (hojas[0]) {
    //                 this.openDetail(hojas[0]);
    //             }
    //         }
    //     }
    // };

    // openDetail = async docEntry => {
    //     const { enableSpinner, notificationReducer: { showAlert } } = this.props;
    //     enableSpinner(true);
    //     let response = await apiClient.getDataDocuemt(docEntry);
    //     enableSpinner(false);

    //     if (response.status === SERVICE_RESPONSE.SUCCESS) {
    //         this.setState({
    //             items: response.data,
    //         });
    //         $('#docuementModal').modal('show');
    //         $("#file").val(null);
    //         return;
    //     }

    //     showAlert({ type: 'error', message: response.message })
    // };

    // openOrder = () => {
    //     const { enableSpinner, notificationReducer: { showAlert } } = this.props;
    //     const { user } = this.state;
    //     enableSpinner(true);

    //     if (user !== null) {
    //         $('#dataProfiel').modal('show');
    //     } else {
    //         showAlert({ type: 'warning', message: "No hay un cliente seleccionado" })
    //     }

    //     enableSpinner(false);
    //     return;
    // };

    onClickGetData = async (opcion) => {
        let fechaInicio = moment(new Date()).format('YYYY-MM-DD')
        let fechaFinal = moment(new Date()).format('YYYY-MM-DD')
        await this.setState({
            fechaInicio,
            fechaFinal,
            data: [],
            isLoaded: false,
            isRender: opcion,
        });

        if (opcion === 'A2') {
            const newDate = new Date();
            newDate.setMonth(newDate.getMonth() - 1); // Cambiar a 1 mes atrás la fecha minima
            await this.setState({
                fechamin: moment(newDate).format('YYYY-MM-DD')
            });
        } else {
            const newDate = new Date();
            // newDate.setDate(newDate.getDate() - 1); // Cambiar a 1 día atrás la fecha minima
            newDate.setMonth(newDate.getMonth() - 2); // Cambiar a 2 meses atrás la fecha minima
            await this.setState({
                fechamin: moment(newDate).format('YYYY-MM-DD'),
                fechaInicio: fechaInicio
            });
        }
        setTimeout(async () => {
            await this.getData(opcion);
        }, 200);
    };

    getData = async (opcion, filter) => {
        const { enableSpinner, notificationReducer: { showAlert } } = this.props;
        const { fechaInicio, fechaFinal, fechamin, filterDocument, analyticWhs } = this.state;
        let response = [];
        // Activa el spinner
        enableSpinner(true);

        //     // Reinicia ciertos estados
        //     this.setState({
        //         docsToPrint: [],
        //         docsToPrintCopy: [],
        //         print: []
        //     });

        //     if (opcion === 1) { // Oferta de venta
        //         response = await apiClient.getQuotation(!this.state.isLoaded ? fechamin : fechaInicio, fechaFinal);
        //     } else if (opcion === 2) { // Pedidos
        //         response = await apiClient.getOrders(!this.state.isLoaded ? fechamin : fechaInicio, fechaFinal);
        //     }
        if (opcion === 'A1') { // Tiempos
            let resultsA1 = [];
            let flag = 0; // Bandera auxiliar para errores.

            if (!filterDocument) { //No existe filtro
                const MAX_CONCURRENT_REQUESTS = 5; // Número máximo de peticiones simultáneas
                let promises = [];
                let step1 = 0;
                let step2 = 800;
                while (step2 <= 2400 && flag !== 3) {

                    let dataAnalytics = {
                        tableType: 'AnalyticT1',
                        date: fechaInicio, // Only 1 day
                        minTime: step1,
                        maxTime: step2,
                        filter: '',
                        analyticWhs,
                    };

                    const requestPromise = async () => { //Promesa de petición
                        try {
                            const analyticsResponse = await apiClient.getAnalyticsTables(dataAnalytics);
                            if (analyticsResponse.status === SERVICE_RESPONSE.SUCCESS && analyticsResponse.data.length) {
                                resultsA1 = resultsA1.concat(analyticsResponse.data);
                            } else {
                                if (analyticsResponse.status === !SERVICE_RESPONSE.SUCCESS) {
                                    flag++;
                                }
                            }
                        } catch (error) {
                            // console.error('Error fetching analytics tables:', error);
                            flag++;
                        }
                    };

                    promises.push(requestPromise()); //Agregar para despues ejecutar las promesas

                    if (promises.length >= MAX_CONCURRENT_REQUESTS) {
                        await Promise.all(promises);
                        promises = [];
                    }
                    //Actualizar tiempos
                    step1 = step2;
                    step2 = this.incrementTime(step2, 30); // Each 30 mn
                    // step2 = this.incrementTime(step2, 60); // Each hour
                }

                enableSpinner(true);
                if (promises.length > 0) { //Esperar a que las promesas se resuelvan
                    await Promise.all(promises);
                }
                enableSpinner(false);
                response.status = resultsA1?.length ? 1 : flag === 0 ? 1 : 0;
                response.data = resultsA1;

            } else { //Cuando existe filtro
                let dataAnalytics = {
                    tableType: 'AnalyticT1',
                    date: fechaInicio, // Only 1 day
                    minTime: '',
                    maxTime: '',
                    filter: filterDocument,
                    analyticWhs,
                };
                enableSpinner(true);
                response = await apiClient.getAnalyticsTables(dataAnalytics);
                enableSpinner(false);
            }
        } else if (opcion === 'A2') { // General

            let dataAnalytics = {
                tableType: 'AnalyticT2',
                initialDate: !this.state.isLoaded ? fechamin : fechaInicio,
                finalDate: fechaFinal,
                filter: filterDocument || '',
                analyticWhs,
            }
            enableSpinner(true);
            let analyticsResponse = await apiClient.getAnalyticsTables(dataAnalytics);
            response.status = analyticsResponse.status;
            response.data = analyticsResponse && analyticsResponse?.data && analyticsResponse?.data?.dataGeneralItems ? analyticsResponse.data.dataGeneralItems : [];
            enableSpinner(false);

            await this.setState({
                popularGeneralItems: response?.data?.length && analyticsResponse && analyticsResponse?.data && analyticsResponse?.data?.popularItems ? analyticsResponse.data.popularItems : {}
            })

        } else if (opcion === 'A3') { // Partidas
            let resultsA3 = [];
            let flag = 0; // Bandera auxiliar para errores.
            let partidasCabecera = {
                General: { opened: 0, closed: 0 },
                OQUT: { opened: 0, closed: 0 },
                ORDR: { opened: 0, closed: 0 },
                ODLN: { opened: 0, closed: 0 },
                OINV: { opened: 0, closed: 0 }
            };
            let dataAnalytics = {
                tableType: 'AnalyticT3',
                initialDate: !this.state.isLoaded ? fechamin : fechaInicio,
                finalDate: fechaFinal,
                filter: filterDocument || '',
            };

            const analyticsResponse = await apiClient.getAnalyticsTables(dataAnalytics);
            if (analyticsResponse.status === SERVICE_RESPONSE.SUCCESS && analyticsResponse.data.length) {
                resultsA3 = resultsA3.concat(analyticsResponse.data);
            } else {
                if (analyticsResponse.status === SERVICE_RESPONSE.ERROR) {
                    flag++;
                }
            }

            // Calcular partidasCabecera después de recibir todos los datos
            enableSpinner(true);
            resultsA3.forEach(partida => {
                partidasCabecera.General.opened += partida.Opened;
                partidasCabecera.General.closed += partida.Closed;

                switch (partida.ObjType) {
                    case '23':
                        partidasCabecera.OQUT.opened += partida.Opened;
                        partidasCabecera.OQUT.closed += partida.Closed;
                        break;
                    case '17':
                        partidasCabecera.ORDR.opened += partida.Opened;
                        partidasCabecera.ORDR.closed += partida.Closed;
                        break;
                    case '15':
                        partidasCabecera.ODLN.opened += partida.Opened;
                        partidasCabecera.ODLN.closed += partida.Closed;
                        break;
                    case '13':
                        partidasCabecera.OINV.opened += partida.Opened;
                        partidasCabecera.OINV.closed += partida.Closed;
                        break;
                    default:
                        break;
                }
            });
            enableSpinner(false);
            response.status = resultsA3?.length ? 1 : flag === 0 ? 1 : 0;
            response.data = resultsA3;

            await this.setState({
                partidasCabecera: resultsA3?.length ? partidasCabecera : {}
            })
        }

        enableSpinner(false);

        if (response?.status !== SERVICE_RESPONSE.SUCCESS) {
            showAlert({ type: 'error', message: 'Ocurrió un error al consultar la Información (Analytics C-E1)' });
            return;
        }

        if (!Array.isArray(response?.data)) {
            showAlert({ type: 'error', message: 'Ocurrió un error al consultar la Información (Analytics C-E2)' });
            return;
        }

        if (response?.data?.length === 0) {
            showAlert({ type: 'info', message: 'No hay Información por mostrar' });
            this.setState({ data: [] });
            return;
        }


        await this.setState({
            data: response?.data || []
        });
    }

    // Actualiza valor de no. orden conF
    inputDocNum = event => {
        let DocNum = event.nativeEvent.target.value;
        this.setState({
            oneOrder: DocNum
        });
    }
    // openClose = event => {
    //     this.setState({
    //         param1: event
    //     });
    // }

    inputItemCode = event => {
        let itemCodeSelect = event.nativeEvent.target.value;
        this.setState({
            itemCodeSearch: itemCodeSelect
        });
    }

    handleInputDateInicio = event => {
        const { notificationReducer: { showAlert } } = this.props;
        const selectedDate = event.nativeEvent.target.value;
        const endDate = this.state.fechaFinal;

        if (selectedDate < this.state.fechamin) {
            // showAlert({type: 'warning', message: 'La fecha de inicio no puede ser menor que la fecha mínima permitida.'});
            showAlert({ type: 'warning', message: 'La fecha de inicio mínima permitida es ' + moment(this.state.fechamin).format('DD/MM/YY') });

        } else if (endDate && selectedDate > endDate) {
            showAlert({ type: 'warning', message: 'La fecha de inicio no puede ser mayor que la fecha final.' });
        } else {
            this.setState({
                isLoaded: true,
                fechaInicio: selectedDate,
            });
        }
    }

    handleInputDateFinal = event => {
        const { notificationReducer: { showAlert } } = this.props;
        const selectedDate = event.nativeEvent.target.value;
        const startDate = this.state.fechaInicio;

        if (selectedDate < this.state.fechamin) {
            showAlert({ type: 'warning', message: 'La fecha final no puede ser menor que la fecha mínima permitida.' });

        } else if (startDate && selectedDate < startDate) {
            showAlert({ type: 'warning', message: 'La fecha final no puede ser menor que la fecha de inicio.' });

        } else if (selectedDate > this.state.fechamax) {
            showAlert({ type: 'warning', message: 'La fecha final máxima permitida es ' + moment(this.state.fechamax).format('DD/MM/YY') });

        } else {
            this.setState({
                fechaFinal: selectedDate,
            });
        }
    }

    handleInputFilter = event => {
        this.setState({
            filter: event.nativeEvent.target.value,
            filterDocument: event.nativeEvent.target.value
        });
    }

    // generateCSV = async () => {
    //     let datacsv = [["", "DESCRIPCIÓN", "UNIDADES SOLICITADAS", "CODIGO EAN", "PTV-RINTI", "desde"],
    //     ["", "DESCRIPCIÓN", "UNIDADES SOLICITADAS", "CODIGO EAN", "PTV-RINTI", "desde"]]

    //     this.setState({ dataCsv: datacsv })

    // };

    // Devolutions = async (valor) => {
    //     const { enableSpinner, notificationReducer: { showAlert } } = this.props;
    //     const { fechaInicio, fechaFinal, fechamin, itemCode } = this.state;

    //     if (valor === 1) {
    //         this.setState({
    //             data: [],
    //             isDevolution: true
    //         });
    //     } else if (valor === 2) {

    //         let response = await apiClient.searchBillByKey(!this.state.isLoaded ? fechamin : fechaInicio, fechaFinal, itemCode);

    //         if (response.status != SERVICE_RESPONSE.SUCCESS) {
    //             showAlert({ type: 'error', message: 'Ocurrió un error al consultar la Información 2' });
    //             return;
    //         }

    //         if (!Array.isArray(response.data)) {
    //             showAlert({ type: 'error', message: 'Ocurrió un error al consultar la Información 3' });
    //         }

    //         if (response.data.length === 0) {
    //             showAlert({ type: 'info', message: 'No hay Información por mostrar' });
    //             return;
    //         }

    //         setTimeout(async () => {
    //             this.setState({
    //                 data: response.data || [],
    //             });
    //         }, 500);
    //     }
    // }

    // updateItemCode = async (itemCode) => {
    //     const { enableSpinner, notificationReducer: { showAlert } } = this.props;
    //     const { fechaInicio, fechaFinal, fechamin } = this.state;

    //     await this.setState({
    //         itemCode: itemCode,
    //     });

    //     this.Devolutions(2);
    // }

    // changLocalQuantity = async (index, item, event) => {
    //     const { notificationReducer: { showAlert } } = this.props;
    //     const { data } = this.state;
    //     let newQuantity = event.nativeEvent.target.value;
    //     if (parseInt(newQuantity) > parseInt(item.OpenQty)) {
    //         newQuantity = parseInt(item.OpenQty);
    //         data[index].newQuantity = newQuantity;

    //         await this.setState({
    //             data,
    //         });
    //         showAlert({ type: 'warning', message: 'Solo puedes devolver la cantidad de articulos facturada.', timeOut: 0 });
    //         return;
    //     } else {
    //         if (newQuantity <= parseInt(item.OpenQty) && parseInt(newQuantity) > 0) {
    //             data[index].newQuantity = newQuantity;
    //             await this.setState({
    //                 data,
    //             });
    //         }
    //     }
    // };

    // changeDocsToPrint = async (docEntry) => {
    //     const { docsToPrint } = this.state;
    //     const index = docsToPrint.indexOf(docEntry);

    //     if (index === -1) {
    //         this.setState({
    //             docsToPrint: [...docsToPrint, docEntry]
    //         });
    //     } else {
    //         const updatedDocsToPrint = docsToPrint.slice();
    //         updatedDocsToPrint.splice(index, 1);
    //         this.setState({
    //             docsToPrint: updatedDocsToPrint
    //         });
    //     }
    // }

    // changeDocsToPrintCopy = async (docEntry) => {
    //     const { docsToPrintCopy } = this.state;
    //     const index = docsToPrintCopy.indexOf(docEntry);

    //     if (index === -1) {
    //         this.setState({
    //             docsToPrintCopy: [...docsToPrintCopy, docEntry]
    //         });
    //     } else {
    //         const updatedDocsToPrint = docsToPrintCopy.slice();
    //         updatedDocsToPrint.splice(index, 1);
    //         this.setState({
    //             docsToPrintCopy: updatedDocsToPrint
    //         });
    //     }
    // }

    incrementTime(time, minutesToAdd) {
        let hours = Math.floor(time / 100);
        let minutes = time % 100;

        minutes += minutesToAdd;

        while (minutes >= 60) {
            hours += 1;
            minutes -= 60;
        }

        return hours * 100 + minutes;
    }

    handleSelectedFilter = async (option, event) => {
        await this.setState({
            analyticWhs: event.nativeEvent.target.value,
        });
        await this.onClickGetData(option);
    }

    render() {
        const { history, sessionReducer } = this.props;
        const { isRender, isLoaded, fechaInicio, fechaFinal, fechamin, fechamax, data, popularGeneralItems, filterDocument, warehouses, analyticWhs, partidasCabecera } = this.state;
        if (sessionReducer && sessionReducer.role === ROLES.PUBLIC) {
            return <Redirect to="/" />;
        } else {
        return (
            <div className="content-fluid reports" style={{ marginTop: 0, paddingBottom: 20, paddingRight: 0, backgroundColor: config.Back.backgroundColor }}>
                <Session history={history} view={VIEW_NAME.ANALYTICS_VIEW} />
                {/* <ProfieldModel order={order} /> */}
                {/* <DocumentModel order={items} /> */}
                {/* <CSVLink
                    data={dataCsv}
                    filename={"plantilla.csv"}
                    className="hidden"
                    ref={this.csvLink}
                    target="_blank" /> */}

                <div className="content-fluid mx-3" >
                    <div className="row">
                        <div className="col-md-12 MargenSuperior">
                            <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>Análisis</h2>
                        </div>
                    </div>
                    <div className="row p-2">
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="nav nav-tabs" id="myTab" role="tablist" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <li className="nav-item">
                                    <a className="nav-link navbarReportes" style={{ color: '#F07C00' }} id="Tiempos-tab" data-toggle="tab" href="#Tiempos" role="tab" aria-controls="Tiempos" onClick={async (event) => { await this.onClickGetData('A1'); await this.setState({ filterDocument: '', analyticWhs: 'ALL' }) }} aria-selected="false">Tiempos</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link navbarReportes active" style={{ color: '#F07C00' }} id="General-tab" data-toggle="tab" href="#General" role="tab" aria-controls="General" onClick={async (event) => { await this.onClickGetData('A2'); await this.setState({ filterDocument: '', popularGeneralItems: {}, analyticWhs: 'ALL' }) }} aria-selected="true">General</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link navbarReportes" style={{ color: '#F07C00' }} id="Partidas-tab" data-toggle="tab" href="#Partidas" role="tab" aria-controls="Partidas" onClick={async (event) => { await this.onClickGetData('A3'); await this.setState({ filterDocument: '', partidasCabecera: {}, analyticWhs: 'ALL' }) }} aria-selected="false">Partidas</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade " id="Tiempos" role="tabpanel" aria-labelledby="Tiempos-tab">
                                    {isRender === 'A1' ?
                                        <TimesView
                                            times={data}
                                            getData={this.getData}
                                            handleInputDateInicio={this.handleInputDateInicio}
                                            handleInputDateFinal={this.handleInputDateFinal}
                                            fechaInicio={fechaInicio}
                                            fechaFinal={fechaFinal}
                                            fechamin={fechamin}
                                            fechamax={fechamax}
                                            isLoaded={isLoaded}
                                            handleInputFilter={this.handleInputFilter}
                                            filter={filterDocument}
                                            warehouses={warehouses}
                                            analyticWhs={analyticWhs}
                                            handleSelectedFilter={this.handleSelectedFilter}
                                        // docsToPrint={docsToPrint}
                                        // changeDocsToPrint={this.changeDocsToPrint}
                                        // inputDocNum={this.inputDocNum}
                                        // openClose={this.openClose}
                                        // oneOrder={oneOrder}
                                        />
                                        : <></>}
                                </div>
                                <div className="tab-pane fade show active" id="General" role="tabpanel" aria-labelledby="General-tab">
                                    {isRender === 'A2' ?
                                        <GeneralView
                                            generalItems={data}
                                            popularGeneralItems={popularGeneralItems}
                                            getData={this.getData}
                                            handleInputDateInicio={this.handleInputDateInicio}
                                            handleInputDateFinal={this.handleInputDateFinal}
                                            fechaInicio={fechaInicio}
                                            fechaFinal={fechaFinal}
                                            fechamin={fechamin}
                                            fechamax={fechamax}
                                            isLoaded={isLoaded}
                                            handleInputFilter={this.handleInputFilter}
                                            filter={filterDocument}
                                            warehouses={warehouses}
                                            analyticWhs={analyticWhs}
                                            handleSelectedFilter={this.handleSelectedFilter}
                                        // docsToPrint={docsToPrint}
                                        // changeDocsToPrint={this.changeDocsToPrint}
                                        />
                                        : <></>}
                                </div>
                                <div className="tab-pane fade " id="Partidas" role="tabpanel" aria-labelledby="Partidas-tab">
                                    {isRender === 'A3' ?
                                        <DetallesView
                                            partidas={data}
                                            partidasCabecera={partidasCabecera}
                                            getData={this.getData}
                                            handleInputDateInicio={this.handleInputDateInicio}
                                            handleInputDateFinal={this.handleInputDateFinal}
                                            fechaInicio={fechaInicio}
                                            fechaFinal={fechaFinal}
                                            fechamin={fechamin}
                                            fechamax={fechamax}
                                            isLoaded={isLoaded}
                                            handleInputFilter={this.handleInputFilter}
                                            filter={filterDocument}
                                        // docsToPrint={docsToPrint}
                                        // changeDocsToPrint={this.changeDocsToPrint}
                                        />
                                        : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        }
    }
}
const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalisisViews);