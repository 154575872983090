import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { config, DISPATCH_ID, SERVICE_RESPONSE, ROLES } from '../libs/utils/Const';
import { DecisionModal } from "../components";
import { connect } from "react-redux";
import $ from 'jquery';
import 'react-multi-carousel/lib/styles.css';
import { ApiClient } from '../libs/apiClient/ApiClient';

let apiClient = ApiClient.getInstance();

class AdmFamilies extends Component {

    constructor(props) {
        super(props);
        this.state = {
            families: [],
            familySelect: '',
            editMode: false,
            deleteMode: false,
            atributesItems: [], // Aquí cargas tus atributos con Code y Name
            atributeActive: [], // Para manejar los seleccionados
            familyEdit: {},
            nameFamily: '',
        };
    }

    componentDidMount = async () => {
        const { enableSpinner } = this.props;
        enableSpinner(true);
        const response = await apiClient.getAllAttributes();
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                atributesItems: response.data ?? [],
                atributeActive: [],
            });
        }
        await this.fillFamilyData();
    }

    fillFamilyData = async () => {
        const { enableSpinner } = this.props;
        enableSpinner(true);
        let dataFamily = { type: 4 }; // 1- Crear, 2-Actualizar, 3-Eliminar, 4-Obtener
        const response = await apiClient.updateFamilies(dataFamily);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                families: response.data ?? []
            });
            let e = { target: { name: 'familySelect', value: '' } }
            await this.onChangeValue(e);
        }
    }

    onChangeValue(event) {
        const { name, value } = event.target;
        const { families } = this.state;
        this.setState({
            [name]: value,
        });

        if (name === "familySelect") {
            if (value) { // Cuando se selecciona una familia verificar atributos activos
                let family = families?.find((attribute) => attribute.Code === parseInt(value));
                if (family) {
                    this.setState({
                        atributeActive: family.attributes ?? [], // Actualiza los atributos activos de la familia
                        familyEdit: family ?? {},
                        nameFamily: family.Name ?? '',
                    });
                }
            } else {
                this.setState({ // Si no hay familia seleccionada, limpia los atributos activos
                    atributeActive: [],
                    editMode: false,
                    deleteMode: false,
                });
            }
        }
    }

    changeCheck(code) {
        const { atributeActive } = this.state;

        const isChecked = atributeActive?.includes(code); // Determinar si el atributo ya está en la lista activa

        let updatedActive;
        if (isChecked) {
            updatedActive = atributeActive.filter(attr => attr !== code); // Si está activo, lo quitamos de la lista
        } else {
            updatedActive = [...atributeActive, code]; // Si no está activo, lo añadimos
        }

        this.setState({
            atributeActive: updatedActive
        });
    }

    SaveFamily = async () => {
        const { notificationReducer: { showAlert }, recargar, enableSpinner } = this.props;
        const { nameFamily, atributeActive, editMode, deleteMode, familyEdit } = this.state;

        if (!nameFamily && editMode) return showAlert({ type: 'warning', message: 'Ingrese el nombre de la familia', timeOut: 5000 });

        if (atributeActive?.length === 0 && editMode) return showAlert({ type: 'warning', message: 'Seleccione al menos un atributo para la familia', timeOut: 5000 });

        if ((familyEdit && editMode) || deleteMode) { // Actualizar o eliminar la familia
            if (editMode) {
                familyEdit.attributes = atributeActive;
                familyEdit.Name = nameFamily;
            }

            enableSpinner(true);
            let dataFamily = { familyEdit, type: deleteMode ? 3 : 2 }; // 1- Crear, 2-Actualizar, 3-Eliminar, 4-Obtener
            let result = await apiClient.updateFamilies(dataFamily);
            enableSpinner(false);
            if (result.status == SERVICE_RESPONSE.SUCCESS) {
                enableSpinner(true);
                await this.fillFamilyData();
                await recargar();
                enableSpinner(false);
                showAlert({ type: 'success', message: result.message, timeOut: 8000 });
            } else {
                showAlert({ type: 'error', message: result.message, timeOut: 8000 });
            }
        }
    }

    resetInfo = async () => {
        this.setState({
            editMode: false,
            deleteMode: false,
        });
        let e = { target: { name: 'familySelect', value: '' } }
        await this.onChangeValue(e);
    }

    deleteQuestion = () => {
        window.scrollTo(0, 0);
        Swal.fire({
            title: 'Eliminar Familia',
            text: "¿Estás seguro de eliminar la familia?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: config.navBar.iconColor,
            confirmButtonText: 'Sí, eliminar'
        }).then(async (result) => {
            if (result.value) { //Eliminar
                await this.onChangeValue({ target: { name: 'deleteMode', value: true } });
                await this.SaveFamily();
            } else { // No eliminar
                await this.resetInfo();
            }
        });
    }

    render() {
        const { } = this.props;
        const { families, familySelect, atributesItems, editMode, nameFamily } = this.state;

        return (
            <div className="content-fluid row" >
                <div className='col-12 m-0 p-0 showList' id='showFamiliesList'>
                    <div className='row m-0 p-0 my-3'>
                        <h5>Administración de Familias</h5>
                    </div>
                    <div className='m-0 p-0 mb-4 mt-3'>
                        <select
                            class='custom-select'
                            name="familySelect"
                            id="familySelect"
                            value={familySelect}
                            onChange={(event) => !editMode && this.onChangeValue(event)}
                            style={{ marginBottom: !familySelect ? '8rem' : '0px' }}
                            disabled={editMode ? true : false}
                        >
                            <option value="" >Selecciona una opción</option>
                            {families?.length > 0 &&
                                families?.map((family, index) => {
                                    return (<option key={index} value={family.Code} >{family.Name}</option>)
                                })
                            }
                        </select>
                    </div>
                    {familySelect &&
                        <div className='m-0 p-0 mb-4 mt-3'>
                            <div className="form-group">
                                <label style={{ fontWeight: "bold" }}>Información de la familia</label>
                            </div>
                            {/* Nombre de la familia*/}
                            <div className="form-row" style={{ padding: "0px 50px 0px 50px" }}>
                                <div className="form-group col-md-12" style={{ display: "flex" }}>
                                    <input type="text"
                                        id='id-nameFamily'
                                        name='nameFamily'
                                        value={nameFamily ?? ''}
                                        placeholder='Nombre de la Familia'
                                        // onKeyDown={event => event.keyCode === 13 && this.SaveFamily()}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(event) => editMode && this.onChangeValue(event)}
                                        disabled={!editMode ? true : false}
                                        maxLength={100}
                                    />
                                </div>
                            </div>

                            {/* Atributos de la familia*/}
                            <div className="form-row" >
                                <div className="form-group col-12 row body-form-col" >
                                    {atributesItems.length > 0 &&
                                        atributesItems.map((attribute, index) => {
                                            const isChecked = this.state.atributeActive.includes(attribute.Code);
                                            return (
                                                <div key={index} className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 row">
                                                    <input
                                                        className="col-1 check-atributes"
                                                        type="checkbox"
                                                        id={'Check' + attribute.Code}
                                                        checked={isChecked} // Controlado por el estado
                                                        onChange={() => editMode && this.changeCheck(attribute.Code)} // Cambiar estado
                                                        maxLength={30}
                                                        disabled={!editMode ? true : false}
                                                    />
                                                    <div className="col-9">
                                                        <label className="text-atribut" htmlFor={'Check' + attribute.Code}>
                                                            {attribute.Name}
                                                        </label>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    }

                    <div className="mt-4 row d-flex justify-content-center align-items-center">
                        {familySelect &&
                            <>
                                <button type="button" className="btn btn-danger"
                                    style={{ fontWeight: "bold", color: '#FFFFFF' }}
                                    onClick={() => this.deleteQuestion()} > Eliminar </button>
                                {editMode &&
                                    <button type="button" className="btn ml-5"
                                        style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF' }}
                                        onClick={() => { this.resetInfo() }} > Cancelar </button>
                                }
                                <button type="button" className="btn ml-3"
                                    style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF' }}
                                    onClick={(event) => { editMode ? this.SaveFamily(event) : this.onChangeValue({ target: { name: 'editMode', value: true } }) }}> {editMode ? 'Guardar' : 'Editar'} </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdmFamilies);