import React, { Component } from 'react';
import { ApiClient } from "../../libs/apiClient/ApiClient";
import { connect } from "react-redux";
import moment from 'moment';
import { DISPATCH_ID, config } from "../../libs/utils/Const";
import $ from 'jquery';
import ExportReportGeneral from '../../components/ExportReportGeneral';
import 'datatables.net-bs4';  // Asegúrate de que esta importación sea necesaria para tu uso específico
import * as XLSX from "xlsx";

const apiClient = ApiClient.getInstance();

class DetallesView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            partidas: [],
            order: {
                header: {},
                body: []
            },
            typeCondition: 0,
            tableToExcel: [],
            guia: []
        };
        this.table = null;
    }

    async componentDidMount() {
        let user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser'));
        this.setState({
            typeCondition: user ? user.GroupNum : 0
        });
        this.fillDataDetails();


    }

    fillDataDetails = () => {
        this.table = $('#tablaDetalles').DataTable({
            "paging": true,
            "info": false,
            "searching": false,
            //"bDestroy": true,	
            "order": [[0, 'desc']],
            "language": {
                "lengthMenu": "Mostrar _MENU_ registros por página",
                "zeroRecords": "No se encontraron registros",
                "info": "Mostrando página _PAGE_ de _PAGES_",
                "infoEmpty": "No existen registros",
                "infoFiltered": "(filtrado de _MAX_ entradas)",
                "loadingRecords": "Buscando...",
                "processing": "Procesando...",
                "search": "Buscar:",
                "paginate": {
                    "first": "Primero",
                    "last": "Último",
                    "next": "Siguiente",
                    "previous": "Anterior"
                }
            }
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.partidas != nextProps.partidas) {
            this.table.destroy();
            this.table = null;
        }
        return true;
    }

    componentDidUpdate() {
        if (this.table == null) {
            this.fillDataDetails();
        }
    }

    // openOrder = async docEntry => {
    //     const { enableSpinner, notificationReducer: { showAlert } } = this.props;
    //     enableSpinner(true);
    //     let response = await apiClient.getDataQuotation(docEntry);
    //     enableSpinner(false);

    //     if (response.status === SERVICE_RESPONSE.SUCCESS) {
    //         this.setState({
    //             order: response.data,
    //             guia: response.data.statusGuia || [],
    //         });

    //         $('#quotationModal').modal('show');
    //         return;
    //     }

    //     showAlert({ type: 'error', message: "Aviso: " + response.message })
    // };

    exportToExcel = () => {
        const { partidas, partidasCabecera, notificationReducer: { showAlert } } = this.props;

        if (partidas && partidas.length > 0 && partidasCabecera) {
            const tableData = partidas.map(document => ({
                "No. Documento": document.DocNum || '',
                "Tipo de documento": this.DocumentName(document.ObjType),
                "Partidas": document.Lines ?? 0,
                "Partidas abiertas": document.Opened ?? 0,
                "Partidas cerradas": document.Closed ?? 0,
                "Estado del documento": this.DocumentStatus(document.Status),
            }));

            const popularItemsData = [
                {
                    "Total Partidas Abiertas": partidasCabecera?.General?.opened || 0,
                    "Total Partidas Cerradas": partidasCabecera?.General?.closed || 0,
                    "Total Partidas Abiertas en Cotizaciones": partidasCabecera?.OQUT?.opened || 0,
                    "Total Partidas Abiertas en Pedidos": partidasCabecera?.ORDR?.opened || 0,
                    "Total Partidas Abiertas en Entregas": partidasCabecera?.ODLN?.opened || 0,
                    "Total Partidas Abiertas en Facturas": partidasCabecera?.OINV?.opened || 0,
                    "Total Partidas Cerradas en Cotizaciones": partidasCabecera?.OQUT?.closed || 0,
                    "Total Partidas Cerradas en Pedidos": partidasCabecera?.ORDR?.closed || 0,
                    "Total Partidas Cerradas en Entregas": partidasCabecera?.ODLN?.closed || 0,
                    "Total Partidas Cerradas en Facturas": partidasCabecera?.OINV?.closed || 0,
                }
            ];

            const worksheetGeneral = XLSX.utils.json_to_sheet(tableData);
            const worksheetPopular = XLSX.utils.json_to_sheet(popularItemsData);

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, worksheetGeneral, "Partidas");
            XLSX.utils.book_append_sheet(wb, worksheetPopular, "Partidas-Resumen"); // Segunda hoja

            // Guardar el archivo
            XLSX.writeFile(wb, "Análisis-Partidas.xlsx");
        } else {
            // Manejar caso donde no hay datos para exportar
            showAlert({ type: 'info', message: 'No se ha podido generar el archivo porque no se encontraron resultados para su búsqueda' });
        }
    }

    DocumentStatus(status, isCancelled) {
        let result = '';
        status = isCancelled === 'Y' ? 'Y' : status;
        switch (status) {
            case 'O':
                result = "Abierto";
                break;
            case 'C':
                result = "Cerrado";
                break;
            case 'Y':
                result = "Cancelado";
                break;
            default:
                result = '--';
                break;
        }
        return result;
    };

    DocumentName(type) {
        let result = '';
        switch (type) {
            case "23":
                result = "Cotización";
                break;
            case "17":
                result = "Pedido";
                break;
            case "15":
                result = "Entrega";
                break;
            case "13":
                result = "Factura";
                break;
            default:
                result = 'N/A';
                break;
        }
        return result;
    };

    render() {
        const { order, typeCondition, tableToExcel, guia } = this.state;
        const { partidas, getData, handleInputDateInicio, handleInputDateFinal, fechaFinal, fechaInicio, fechamin, fechamax, isLoaded, configReducer: { currency }, enableSpinner, docsToPrint, changeDocsToPrint, partidasCabecera, filter, handleInputFilter } = this.props;

        return (
            <div className="content-fluid" style={{ backgroundColor: config.Back.backgroundColor }}>
                <div className="row text-center" style={{ marginBottom: 16, marginTop: 16 }}>
                    <div className="col-md-12">
                        <h3 style={{ fontWeight: "bolder", fontSize: "1rem", color: "black" }}>PARTIDAS</h3>
                    </div>
                </div>
                <div className="row text-center" style={{ marginBottom: 16, marginTop: 16 }}>
                    {/* <div className="col-md-3">
                        <div>
                            <div class="search" style={{ justifyContent: "center", display: 'flex' }}>
                                <input
                                    id="inputSearch"
                                    className="form-control col-md-6 searchTerm"
                                    type="text"
                                    placeholder="Filtro"
                                    value={filter}
                                    // disabled={docsToPrint?.length !== 0}
                                    onChange={(event) => handleInputFilter(event)}
                                    // onKeyDown={event => event.keyCode === 13 && filter.trim() !== '' && getData('A3', filter)}
                                    onKeyDown={event => event.keyCode === 13 && getData('A3', filter)}
                                />
                                <button
                                    type="button"
                                    className="searchButton"
                                    // onClick={() => filter.trim() !== '' && getData('A3', filter)} disabled={docsToPrint.length !== 0}
                                    onClick={() => getData('A3', filter)}
                                    // disabled={docsToPrint?.length !== 0}
                                    style={{ backgroundColor: config.navBar.menuCategoriesBackgroundHover }}
                                >
                                    <i style={{ fontSize: "1.3rem", color: 'white' }} className={config.icons.search} />
                                </button>
                            </div>
                        </div>
                    </div> */}
                    <div className=" row col-md-4">
                        <h4 className="pr-2">Desde:</h4>
                        <input
                            id="fechaInicio"
                            type="date"
                            className="form-control col-md-6"
                            name="fechauno"
                            min={fechamin}
                            max={fechamax}
                            value={!isLoaded ? fechamin : fechaInicio}
                            onChange={(event) => handleInputDateInicio(event)}
                        />
                    </div>
                    <div className="row col-md-4 pb-3">
                        <h4 className="pr-2">Hasta:</h4>
                        <input
                            id="FechaFin"
                            type="date"
                            className="form-control col-md-6"
                            name="fechados"
                            min={fechamin}
                            max={fechamax}
                            value={fechaFinal}
                            onChange={(event) => handleInputDateFinal(event)}
                        />
                    </div>
                    <div className="col-auto pb-2">
                        <button
                            onClick={() => getData('A3')}
                            className="btn botonResumen"
                            style={{
                                backgroundColor: config.navBar.iconColor,
                                color: config.navBar.textColor2,
                                fontWeight: "bold",
                            }}
                        >
                            Ver Partidas
                        </button>
                    </div>
                </div>
                {/* Total de Partidas*/}
                <div className="row text-center" style={{ marginTop: '15px' }}>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Total Partidas Abiertas</th>
                                    <td>{partidasCabecera?.General?.opened || 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Total Partidas Cerradas</th>
                                    <td>{partidasCabecera?.General?.closed || 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* Total Partidas Abiertas de cada documento*/}
                <div className="row text-center" style={{ marginTop: '15px' }}>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Total Partidas Abiertas en Cotizaciones</th>
                                    <td>{partidasCabecera?.OQUT?.opened || 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Total Partidas Abiertas en Pedidos</th>
                                    <td>{partidasCabecera?.ORDR?.opened || 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Total Partidas Abiertas en Entregas</th>
                                    <td>{partidasCabecera?.ODLN?.opened || 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Total Partidas Abiertas en Facturas</th>
                                    <td>{partidasCabecera?.OINV?.opened || 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* Total Partidas Cerradas de cada documento */}
                <div className="row text-center mt-2">
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Total Partidas Cerradas en Cotizaciones</th>
                                    <td>{partidasCabecera?.OQUT?.closed || 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Total Partidas Cerradas en Pedidos</th>
                                    <td>{partidasCabecera?.ORDR?.closed || 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Total Partidas Cerradas en Entregas</th>
                                    <td>{partidasCabecera?.ODLN?.closed || 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th scope="row">Total Partidas Cerradas en Facturas</th>
                                    <td>{partidasCabecera?.OINV?.closed || 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="ml-1">
                    {/* {docsToPrint.length > 0 &&
                        <ExportReportGeneral
                            orders={partidas}
                            data={docsToPrint}
                            typeDocs={"quotations"}
                            enableSpinner={enableSpinner}
                        />
                    } */}
                </div>
                <div className="row">
                    <div style={{ cursor: 'pointer' }}>
                        <button onClick={this.exportToExcel} className="btn btn-success">
                            Exportar a Excel
                        </button>
                    </div>
                    <div className="col-md-12 table-responsive tableReports">
                        <table id="tablaDetalles" className="table table-striped scrolltable">
                            <thead style={{ textAlign: "-webkit-center" }}>
                                <tr style={{ backgroundColor: config.shoppingList.summaryList, color: "white", fontSize: "15px", cursor: 'pointer' }}>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}></th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>No. Documento</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Tipo de documento</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Partidas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Partidas abiertas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Partidas cerradas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Estado del documento</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {partidas && partidas?.length ?
                                    partidas.map((document, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope="col-lg-auto row" style={{ verticalAlign: "middle" }}>
                                                    {/* <div class="form-check" style={{ minWidth: "70px" }}>
                                                    <input class="form-check-input" type="checkbox" value={document.DocEntry} onChange={(event) => changeDocsToPrint(event.nativeEvent.target.value)} id="partidasToPrint" />
                                                </div> */}
                                                </th>
                                                <th scope="col-lg-auto row text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{document.DocNum || ''}</th>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{this.DocumentName(document.ObjType)}</td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{document.Lines ?? 0}</td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{document.Opened ?? 0}</td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{document.Closed ?? 0}</td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{this.DocumentStatus(document.Status, document.Cancelled)}</td>
                                            </tr>
                                        );
                                    })
                                    : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DetallesView);