import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import {
    NavBarLogo,
    NavBarShoppingCartIcon,
    NavbarSearchIcon,
    WarehouseModal,
    NavBarContentSearchItems,
    NavBarButton,
    LoginModal,
    LoginRegisterModal,
    MenuCategories,
    DejanosMensajeModal,
    MenuNavbar,
    SideBar,
    MenuMarcas,
    CategoriesMenu,
    ProfieldModel
} from "./index";
import { VIEW_NAME, config, ROLES, DISPATCH_ID, SERVICE_RESPONSE} from '../libs/utils/Const';
import { Modal } from './index';
import $ from "jquery";
import './navBar.css';
import { ApiClient } from "../libs/apiClient/ApiClient";
import moment from 'moment';
import './SideBar.css';

let modal = new Modal();
let apiClient = ApiClient.getInstance();
let responseFlete = { ItemCode: '', ItemName: '', Price: '0', PriceList: '0', PurchaseLimit: '' };

class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            marcas: [],
            aparatos: [],
            refacciones: [],
            fabricantes: [],
            materiales: [],
            boxes: [],
            itemsRegalos: [],
            itemsRegalosSelect: [],
            businessPartnerInfo: {},
            itemsNotificacion: [],
            seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
            vendor: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'Vendor')),
            cliente: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')),
            order: {
                header: {},
                body: []
            },
        };
    }
    //Evento para capturar los valores de los campos
    handelChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value
        });
    };

    async componentDidMount() {
        // this.fillDataSearch();
        const {enableSpinner} = this.props;
        enableSpinner(true);
        let response = await apiClient.getDataProfiel();
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                order: response.data,
            })
        };
    }

    toggleMenuUser = () => {
        $("#menu-user-button").click(function () {
            $(".menu-user").toggle();
            $(".menu-user-responsive").focus();
        });
    }

    renderName = () => {
        const { configReducer, sessionReducer: { user } } = this.props;
        const { seller, vendor } = this.state;
        if (seller !== null) {
            return (
                <div className='row'>
                    <span className="ml-2 orig" style={{ color: config.navBar.textColor }}>{seller.firstName} {seller.lastName}</span>
                    <span className="orig" style={{ color: config.navBar.textColor, fontSize: '14px', marginLeft: `${user?.CardName && user?.CardName.length >15 ? '90px' : '20px'}`}}>{user.CardName}</span>
                </div>
            );
        } else {
            return (
                <span className="ml-2" style={{ color: config.navBar.textColor }}>{user.CardName}</span>
            );
        }
    }

    iconUser = (priceList) => {
        let result = '';
        switch (priceList) {
            // case 4:
            //     result = config.Avatar.bronze1;
            //     break;
            // case 5:
            //     result = config.Avatar.silver1;
            //     break;
            // case 6:
            //     result = config.Avatar.gold1;
            //     break;
            // case 7:
            //     result = config.Avatar.platinum1;
            //     break;
            // case 9:
            //     result = config.Avatar.diamond1;
            //     break;
            // case 13:
            //     result = config.Avatar.mercado1;
            //     break;
            default:
                result = config.Avatar.red1;
                break;
        }
        return result
    }

    // nivel = (priceList)=>{
    //     let result = '';
    //     switch (priceList) {
    //         case 4:
    //             result = 'Bronze';
    //             break;
    //         case 5:
    //             result = 'Silver';
    //             break;
    //         case 6:
    //             result = 'Gold';
    //             break;
    //         case 7:
    //             result = 'Platinum';
    //             break;
    //         case 9:
    //             result = 'Diamond';
    //             break;
    //         case 13:
    //             result = 'Filial';
    //             break;
    //         default:
    //             result = '';
    //             break;
    //     }
    //     return result
    // }

    accountInfo = () => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        const { user } = this.state;
        enableSpinner(true);

        if (user !== null) {
            $('#dataProfiel').modal('show');
        } else {
            showAlert({ type: 'warning', message: "No hay un cliente seleccionado" })
        }

        enableSpinner(false);
        return;
    };

    renderClientOptionFirstNavBar = () => {
        const { configReducer, sessionReducer: { user } } = this.props;
        const { seller, order} = this.state;
        return (
            <><ProfieldModel order={order}/>
            <ul className="navbar-nav miCuenta d-md-flex ml-4" style={{ maxWidth: 200 }}>

                <li className="nav-item">
                    <span className=" row" style={{ display: "flex", alignItems: "center" }}>
                        {/* <i id="IconUser" className={config.icons.user} aria-hidden="true" style={{ color: config.navBar.iconColor2, fontSize: 25 }} /> */}
                        <img className="img-fluid" src={this.iconUser(user.listNum)} style={{ color: config.navBar.iconColor2, height: "36px", marginLeft:'-36px', width:'36px' }} />
                        <a className="nav-link dropdown-toggle" style={{ display: "flex", alignItems: "center" }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", maxWidth: 200 }}>
                        <span className="marq" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {this.renderName()}
                        </span>
                        </div>
                        </a>
                        {/* <div className="dropdown"> */}
                        {/* Toggle clientes*/}
                        <div className="dropdown-menu menu-optios" aria-labelledby="dropdownMenuButton">
                            {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ?
                                <a className="dropdown-item clic-menu" onClick={() => { configReducer.history.goSelectClient(); }}>Mis clientes</a>
                                : "" : ""
                            }
                            {/* {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ?
                                <a className="dropdown-item clic-menu" onClick={() => { this.accountInfo(); }}>Información de la cuenta</a>
                                : "" : ""
                            } */}
                            {/* Toggle correos del boletin*/}
                            {seller ? seller.U_FMB_Handel_Perfil !== '0' && seller.U_FMB_Handel_Perfil !== 5 && seller.U_FMB_Handel_Boletin === 1 ? 
                                    <a className="dropdown-item menuMio" style={{ color: "#C55930 !important" }} onClick={() => { configReducer.history.goBoletin(); }}>Ver correos del boletín</a>
                                    :
                                    (seller.U_FMB_Handel_Perfil === 3 ? 
                                        <a className="dropdown-item menuMio" style={{ color: "#C55930 !important" }} onClick={() => { configReducer.history.goBoletin(); }}>Ver correos del boletín</a>
                                        : '')
                                : ''
                            }

                            {/* Toggle Analitics*/}
                            {/* {seller ? seller.U_FMB_Handel_Analitycs == 1 ?
                                <a className="dropdown-item" onClick={() => { configReducer.history.goAnalitycs(); }}>Analitycs</a>
                                : "" : ""
                            } */}
                            {/* Toggle bonoficaciones*/}
                            {/* {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 && seller.U_FMB_Bonificaciones === "1" ?
                                <a className="dropdown-item menuMio" onClick={() => { configReducer.history.goPromocionales(); }}>Bonificaciones</a>
                                : "" : ""
                            } */}
                            {/* {Object.entries(user).length !== 0 && } */}

                            {/* Toggle subir archivo OC*/}
                            {config.modules.subirArchivoExcel && seller ? seller.U_FMB_Handel_Perfil != 5 && Object.entries(user).length !== 0 ?
                                <Link to='/subirArchivo' className="dropdown-item" >Subir archivo OC</Link>
                                : "" : ""
                            }
                            {/* Toggle mis reportes*/}
                            {seller ? seller.U_FMB_Handel_Perfil != 5 && Object.entries(user).length !== 0 ?
                                <Link to='/reports' className="dropdown-item" >Mis reportes</Link>
                                : "" : ""
                            }
                            {/* Toggle mis articulos*/}
                            {seller && (seller.U_FMB_Handel_Perfil === 3 || seller.U_FMB_Handel_Perfil === '3') ?
                                <Link to='/RegisterItems' className="dropdown-item" >Articulos</Link>
                                : ""
                            }
                            {seller && seller.U_FMB_Handel_Perfil === 3 ?
                                <Link to='/subirArchivoPadres' className="dropdown-item" >Subir Plantilla Padres</Link>
                                : ""
                            }
                            {seller && seller.U_FMB_Handel_Perfil === 3 ?
                               <Link to='/Analisis' className="dropdown-item" >Análisis</Link>
                                : ""
                            }
                             
                            {/* <a className="dropdown-item" onClick={() => { configReducer.history.goOrders(); }}>Mis pedidos</a> */}
                            {/* Toggle Lista de deseos*/}
                            {user.wishlist === 1 &&
                                <a className="dropdown-item" onClick={() => { configReducer.history.goBackOrder(); }}>Lista de deseos</a>}
                            {/* {user.U_FMB_Handel_Admin === '1' &&
                                <a className="dropdown-item" onClick={() => { configReducer.history.goML(); }}>Admin ML</a>} */}
                            {/* {user.banners === "on" && */}
                            {/* Toggle lista de deseos*/}
                            {seller ? seller.U_FMB_Banner == 1 ?
                                <Link to='/adminBanners' className="dropdown-item" >Adm. Banners</Link>
                                : "" : ""
                            }
                            {/* seller ? seller.U_FMB_AdmBlogNoticia == 1 ? */}
                            {/* Toggle Adm. Blog de noticias*/}
                            {seller ? seller.U_FMB_AdmBlogNoticia == 1 ?
                                <Link to='/admiNewsBlog' className="dropdown-item" >Adm. Blog de noticias</Link>
                                : "" : ""
                            }
                            {/* Toggle Autorizaciones */}
                            {seller ? seller.U_FMB_Handel_Perfil === 5
                                ?
                                <a className="dropdown-item" onClick={() => { configReducer.history.goAutorizaciones(); }}>Autorizaciones</a>
                                : "" : ""
                            }
                            {/* goMyProfile es el perfil de Pete, goProfile es de Missa */}
                            {/* Toggle Mi perfil*/}
                            {seller ? seller.U_FMB_Handel_Perfil != 5 && Object.entries(user).length !== 0 ?
                                <a className="dropdown-item" onClick={() => { configReducer.history.goProfile(); }}>Mi Perfil</a>
                                : "" : ""
                            }
                            {/* <Link to='/reports' className="dropdown-item"  style={{ color: config.navBar.textColor }}>Mis reportes</Link> */}
                            {/* Toggle Adm. Banners*/}
                            {user.banners === "on" &&
                                <Link to='/adminBanners' className="dropdown-item " style={{ color: config.navBar.textColor }}>Adm. Banners</Link>
                            }
                            {/* Toggle periodo de puntos*/}
                            {user.U_FMB_Handel_Admin === '1' && config.modules.points &&
                                <a className="dropdown-item" onClick={() => { configReducer.history.goResetPoints(); }}>Periodo de puntos</a>}
                            {/* Toggle Precios especiales*/}
                            {/* {user.U_FMB_Handel_Admin === '1' &&
                                <a className="dropdown-item m-item" style={{ color: config.navBar.textColor }} onClick={() => { configReducer.history.goSpecialPrices(); }}>Precios especiales</a>} */}
                            {/* Toggle cerrar sesion*/}
                            {/* <a className="dropdown-item" onClick={() => { configReducer.history.goProfile(); }}>Mi Perfil</a> */}
                            <a className="dropdown-item m-item" style={{ color: config.navBar.textColor }} onClick={() => { this.logOut(); }}>Cerrar Sesión</a>
                        </div>
                    </span>
                    {/* </div> */}
                </li>
            </ul>
            </>
        )
    };

    logOut = async () => {
        const { configReducer, setBusiness, setToken, setUser, setRememberUser, setRole, shoppingCartReducer: { getShoppingCart }, sessionReducer: { user } } = this.props;
        const { seller, vendor } = this.state;
        let today = moment().format('YYYY-MM-DD');
        let time = moment().format('h:mm:ss a');
        let data = [];
        if (seller && seller.U_FMB_Handel_Perfil == 2) {
            data = {
                CardCode: seller.salesPrson,
                CardName: seller.lastName + ' ' + seller.firstName,
                Date: today,
                Time: time,
                TypeUser: 'Vendedor',
                Email: seller.email,
                IP: '192.168.0.139',
                Business: 'PerezDeLaMora',
                Session: 0
            }
        } else if (seller.U_FMB_Handel_Perfil == 0) {
            data = {
                CardCode: user.CardCode,
                CardName: user.CardName,
                Date: today,
                Time: time,
                TypeUser: 'Cliente',
                Email: user.U_FMB_Handel_Email,
                Business: 'PerezDeLaMora',
                Session: 0
            }
        }
        // if( Object.keys(data).length > 0){
        //await apiClient.sendData(data);
        // }
        await apiClient.logOut();
        // ---------------------------------------------------------------------------------------------
        localStorage.removeItem(config.general.localStorageNamed + 'Role');
        localStorage.removeItem(config.general.localStorageNamed + 'Token');
        localStorage.removeItem(config.general.localStorageNamed + 'CurrentUser');
        localStorage.removeItem(config.general.localStorageNamed + 'PartnerUser');
        localStorage.removeItem(config.general.localStorageNamed + 'RememberUser');
        localStorage.removeItem(config.general.localStorageNamed + 'Vendor');
        localStorage.clear();
        configReducer.history.goHome();

        setRole(ROLES.PUBLIC);
        setBusiness(config.general.business);
        setToken(undefined);
        setUser({});
        setRememberUser(false);

        setTimeout(async () => {
            await getShoppingCart(true);
        }, 300);

        window.location.reload(); // Recargar la página
    };

    // Evento onClick boton de WhatsApp
    btnWhats = () => {
        const $btnAbrir = document.getElementById("abrir-ventana");
        $btnAbrir.addEventListener("click", (e) => {
            window.open("#");
        });
    }
    // Fin evento de boton WhatsApp

    renderPublicOptionFirstNavBar = () => {
        const { configReducer: { history } } = this.props;
        return (
            <ul className="navbar-nav d-none d-md-flex">
                <li className="nav-item" >
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <h4 className="font-weigth-bold mr-sm-2 miCuenta" style={{ color: config.navBar.textColor }} onClick={() => { /*modal.loginModal('show')*/ history.goLogin(); }}><img src={config.Avatar.red1} alt="avatar" style={{ width: "38px" }} /> Inicio de sesión</h4>
                    </a>
                </li>
                {/* <li className="nav-item">
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <span onClick={() => { modal.loginRegisterModal('show') }}>Registrarse</span>
                    </a>
                </li> */}
            </ul>
        )
    };

    warehouseModal = () => {
        setTimeout(() => {
            $('#warehouseModal').modal('show');
        }, 200);
    };

    renderFirstNavBar = () => {
        const { sessionReducer, configReducer } = this.props;
        const { seller, vendor, cliente } = this.state;
        return (
            <div style={{ zIndex: 10 }}>
                {/* Boton FLotante WhatsApp */}
                <div class="burb" id="abrir-ventana" /*onClick={() => {this.btnWhats();}}*/>
                    <a href="https://api.whatsapp.com/send?phone=%2B523411462554&data=ARDOhG-s1vyySkxPvdEJNF5pdW-mylipLTriPyPousn9zCwTnCTWsHBJLCeYL7wAHP9XuOH6bL6Kg7MsM0OVOsL6KSICDm-woPe9wIRN3YKsFWucnnDeTm1HI-uJkIQ5XqYWgs3onqkyIAlFztc0sdUFgg&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR2hYqsDWyyXVpmrfMn_ADOOd7QSqwtEtzU8mqyEj9geVz65YiWUIesMIDc" target="_blank">
                        <img className="img-fluid" width='auto' src={config.BarraTelefono.Whats} style={{ maxWidth: '80%' }} alt="WhatsApp"></img>
                    </a>
                </div>
                <div id="navContact" className="row justify-content-end p-2" style={{ backgroundColor: config.navBar.iconColor, fontSize: '1.2rem' }}>
                    {/* <div className="col-auto">
                        <a href= {config.footer.info.linkPhoneWhats} target="_blank">
                            <img className="img-fluid" width='25px' src={config.BarraTelefono.Whats} style={{ color: config.footer.iconColor, padding: 1 }}></img>&nbsp;
                            <span style={{color:"white"}}>{config.footer.info.phoneWhats}</span>
                        </a>
                    </div>  */}

                    {/* <div className="col-auto">
                        <a onClick={() => { configReducer.history.goSucursales(); }} target="_blank">
                            <img className="img-fluid" width='25px' src={config.BarraTelefono.IconUbicacion} style={{ color: config.footer.iconColor, padding: 1 }}></img>&nbsp;
                            <span style={{color:"white"}}>Una sucursal cerca de ti</span>
                        </a>
                    </div> */}
                </div>
                <nav id="navFirst" className="​navbar navbar-expand-sm primerNav" style={{ border: "none", background: config.navBar.backgroundColor }} >
                    <ul className="navbar-nav elements">
                        <li className="nav-link menu-categories-button text-center" style={{ marginLeft: "auto!important", color: config.navBar.textColor, cursor: "pointer" }}>
                            <img className='ml-3 pr-3 d-none d-sm-inline d-lg-none' src={config.navBar.menu} style={{ width: "55px", cursor: "pointer" }}  data-target='#offCanvasCategories' onClick={''} data-toggle='modal'/>
                            <img className='ml-3 pr-3 d-sm-none' src={config.navBar.menu} style={{ width: "37px", cursor: "pointer" }}  data-target='#offCanvasCategories' onClick={''} data-toggle='modal'/>   
                            {config.modules.hamburgesaCategorias === true &&
                                <img id="buttonMenu" src={config.navBar.menu} className="Img-fluid" style={{ color: config.navBar.iconColorNavBar}} />
                            }
                            <div className="navbar-brand" >
                                <NavBarLogo />
                            </div>
                        </li>
                        <li className="nav-item searchItems d-md-block d-none" style={{ textAlign: "center" }}>
                            <NavBarContentSearchItems className="nav justify-content-center" icon={config.icons.search} iconColor={config.navBar.iconColor} textColor={config.navBar.backgroundColor} fillDataSearch={this.fillDataSearch} />

                        </li>
                        <li className="nav-item text-center d-md-none d-lg-none searchAdvance" >
                            {/* <span className="buttonSearchAdvanced" > <img src={config.navBar.iconSearch} onClick={() => this.fillDataSearch()} className="Img-fluid"/> </span>  */}
                            {/* <span className="d-md-none" ><img src={config.navBar.searchAdvanced} className="Img-fluid" onClick={() => { modal.searchModal('show') }}/></span> */}
                        </li>
                        <li className="shopp nav-item mr-sm-auto d-md-flex arregloNavBar" id="navbarFirst" style={{ marginRight: "auto!", alignItems: "center" }}>

                            <span>
                                <span>{sessionReducer.role === ROLES.PUBLIC ? this.renderPublicOptionFirstNavBar() : this.renderClientOptionFirstNavBar()}</span>
                            </span>
                            <span className="">
                                <NavbarSearchIcon
                                    icon={config.icons.search}
                                    iconColor={config.navBar.iconColor}
                                    textColor={config.navBar.textColor}
                                />
                                <NavBarShoppingCartIcon
                                    icon={config.icons.shoppingCart}
                                    iconColor={config.navBar.iconColor2}
                                    textColor={config.navBar.textColor}
                                />
                            </span>
                            {/* Busqueda LITE */}
                            {/* {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ? 
                                <span className="ml-2" style={{alignSelf:"center"}}>
                                    <span className="shooping">
                                        <img src={config.navBar.quote} className="Img-fluid"  onClick={() => configReducer.history.goQuote()} />
                                    </span>
                                </span> 
                                : "" : ""
                            } */}
                            {/* {seller && seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 &&  */}
                            {/* <span className="shooping">
                                {sessionReducer.role === ROLES.PUBLIC || (seller && seller.empID === "1" && cliente && cliente.U_FMB_Handel_ATV === 0) ? null : (
                                    <img style={{ marginLeft: 15 }} src={config.sucursalView.iconSucursal} className="Img-fluid" onClick={() => this.warehouseModal()} />
                                )}
                            </span> */}
                            {/* } */}
                        </li>
                    </ul>
                </nav>
                {/* <div className="fixed-top navbar navbar-expand-sm navbar-dark" style={{ border: 'none', padding: 0, height: 45, background: config.navBar.backgroundColor, marginTop: 30 }}>
                    <ul className="navbar-nav " style={{ paddingLeft: 16, paddingBottom: 16 }}>
                        <li className="nav-link menu-categories-button" style={{ color: config.navBar.textColor, cursor: "pointer" }}>
                            <i className={config.icons.menu} style={{ color: config.navBar.iconColor, fontsize: 15 }} />
                        </li>
                    </ul>
                    <NavBarLogo />
                    <ul className="navbar-nav  col-md-4 d-sm-block d-none  " style={{ paddingBottom: 5, marginLeft: 230, textAlign: "center" }}>
                        <li>
                            <NavBarContentSearchItems className="nav justify-content-center" icon={config.icons.search} iconColor={config.navBar.iconColor} textColor={config.navBar.backgroundColor} />
                        </li>
                    </ul>
                    <div className="row" >
                        <div className="col-md-12">
                            <button type="button"
                                className="btn btn-primary btn-sm btn-block"
                                style={{ fontsize: 25, background: config.navBar.iconColor, color: "white" }}
                                onClick={() => { modal.searchModal('show') }}>
                                Busqueda avanzada
                            </button>
                        </div>
                    </div>
                    <div className="navbar-nav ml-sm-auto d-md-flex" id="navbarFirst">
                        <span className="mr-2 p-1">
                            <span>{sessionReducer.role === ROLES.PUBLIC ? this.renderPublicOptionFirstNavBar() : this.renderClientOptionFirstNavBar()}</span>
                        </span>
                        <span className="p-1 mr-3">
                            <NavbarSearchIcon
                                icon={config.icons.search}
                                iconColor={config.navBar.iconColor}
                                textColor={config.navBar.textColor}
                            />&nbsp;
                        <NavBarShoppingCartIcon
                                icon={config.icons.shoppingCart}
                                iconColor={config.navBar.iconColor}
                                textColor={config.navBar.textColor}
                            />
                        </span>
                    </div>
                </div> */}
            </div >
        );
    };

    renderPublicOptionMenu = () => {
        const { configReducer: { history } } = this.props;
        return (
            <ul className="navbar-nav mr-auto" >
                <li className="nav-item" >
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <h4 className="font-weigth-bold" style={{ color: config.navBar.textColor2 }} onClick={() => { /*modal.loginModal('show')*/ history.goLogin(); }}>
                        <img alt="Modificar" src={config.icons.circleUser} style={{ width: '16px', height: '16px', marginRight: '5px' }} /> Inicio de sesión </h4>
                    </a>
                </li>
            </ul>
        )
    };

    searchModal = () => {
        const { marcas, aparatos, refacciones, fabricantes, materiales } = this.state;
        return (
            <div className="modal fade" id="searchModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: "center" }}>
                <div className="modal-dialog" role="document" style={{ margin: "1.75rem auto" }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: '#0060aa' }}>
                            <h5 className="modal-title " style={{ color: config.navBar.textColor2 }}>Búsqueda</h5>
                            <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3">
                            <form onSubmit={this.handelSubmit} method="post" encType="text/plain" className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>Marca</h4>
                                        <select name="marca" placeholder="Selecciona una marca" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona una marca</option>
                                            {marcas &&
                                                marcas.map(marca => {
                                                    return <option value={marca.Code} key={marca.Code}>{marca.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Aparato</h4>
                                        <select name="aparato" placeholder="Selecciona un aparato" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona un aparato</option>
                                            {aparatos &&
                                                aparatos.map(aparato => {
                                                    return <option value={aparato.Code} key={aparato.Code}>{aparato.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Refacción</h4>
                                        <select name="refaccion" placeholder="Selecciona una refaccion" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona una refacción</option>
                                            {refacciones &&
                                                refacciones.map(refaccion => {
                                                    return <option value={refaccion.Code} key={refaccion.Code}>{refaccion.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <h4>Fabricante</h4>
                                        <select name="fabricante" placeholder="Selecciona un fabricante" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona un fabricante</option>
                                            {fabricantes &&
                                                fabricantes.map(fabricante => {
                                                    return <option value={fabricante.Code} key={fabricante.Code}>{fabricante.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div> */}
                                    <div className="col-md-12">
                                        <h4>Material</h4>
                                        <select name="material" placeholder="Selecciona un material" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona un material</option>
                                            {materiales &&
                                                materiales.map(material => {
                                                    return <option value={material.Code} key={material.Code}>{material.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <h4>Nombre</h4>
                                        <input type="text" className="form-control" placeholder="Nombre(s)" name="nombre" onChange={this.handelChange} />
                                    </div> */}
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <input type="submit" value="Buscar" className="btn mb-2 btn-block "
                                                style={{ fontsize: 25, background: '#0060aa', color: "white", marginTop: 15 }} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    fillDataSearch = async () => {
        const { enableSpinner } = this.props;
        //Busqueda de valores para los desplegables
        enableSpinner(true);
        await apiClient.getFiltros().then(result => {
            this.setState({
                marcas: result.data.marcas || [],
                aparatos: result.data.aparatos || [],
                refacciones: result.data.refacciones || [],
                fabricantes: result.data.fabricantes || [],
                materiales: result.data.materiales || [],
            });
            setTimeout(() => {
                modal.searchModal('show');
            }, 300);

        });
        enableSpinner(false);

    };

    handelSubmit = async (e) => {
        //Prevenimos un recargo de pagina
        e.preventDefault();
        //Definimos las variables que estan en el state
        const { marca, marcas, aparato, refaccion, fabricante, material, nombre } = this.state;
        const { enableSpinner, setItemsSearch, configReducer: { history }, setItemsFilterSearch, sessionReducer } = this.props;
        //Validamos que los campos esten llenos

        //Preparamos los datos para pasarlos al back
        let busqueda = {
            contenido: {
                marca: marca,
                aparato: aparato,
                refaccion: refaccion,
                fabricante: fabricante,
                material: material,
                nombre: nombre,
            }
        };
        enableSpinner(true);
        await apiClient.searchAdvance(busqueda).then(result => {
            modal.searchModal('hide');
            enableSpinner(false);
            setItemsSearch(result.data);
            setItemsFilterSearch(result.data);
            // -----------------------------------
            let today = moment().format('YYYY-MM-DD');
            let time = moment().format('h:mm:ss a');
            let data = {
                cve_user: sessionReducer.user.CardCode || '',
                search: marca + ' ' + aparato + ' ' + refaccion + ' ' + fabricante + ' ' + material + ' ' + nombre,
                date: today,
                time: time,
                origin: 'busqueda',
                num_results: result.data.length,
            }
            // await apiClient.Search(data);
            // -----------------------------------
            history.goItems();
            return;
        });
        //Validamos la respuesta del Back
    };

    renderClientOptionMenu = () => {
        const { configReducer, history, sessionReducer: { user } } = this.props;
        const { seller } = this.state;
        return (
            <ul className="navbar-nav mr-auto">
                {/* <li className="nav-item">
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <i className={config.icons.user} style={{ color: config.navBar.iconColor }} aria-hidden="true" />
                        <strong><span style={{color: config.navBar.textColor2}}>{user.CardName}</span></strong>
                    </a>
                </li> */}
                <li className="nav-item" onClick={() => { configReducer.history.goProfile(); }}>
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <strong><span style={{ color: config.navBar.textColor2 }}>{user.CardName}</span></strong>
                    </a>
                </li>
                {/* <li className="nav-item" onClick={() => { configReducer.history.goProfile(); }}>
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <span style={{color: config.navBar.textColor2}}>Nivel: {this.nivel(user.listNum)}</span>
                    </a>
                </li> */}
                {/*Dropdown de la parte responsiva */}
                <li className="nav-item" onClick={() => { configReducer.history.goProfile(); }}>
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <span style={{ color: config.navBar.textColor3 }}>Mi Perfil</span>
                    </a>
                </li>
                {/* Dropdown de Mis clientes */}
                {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goSelectClient(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor3 }}>Mis clientes</span>
                        </a>
                    </li>
                    : "" : ""
                }
                {/* {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ?
                    <li className="nav-item" onClick={() => { this.accountInfo(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor3 }}>Información de la cuenta</span>
                        </a>
                    </li>
                    : "" : ""
                } */}
                {/* Dropdown de Analitycs */}
                {/* {seller ? seller.U_FMB_Handel_Analitycs == 1 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goAnalitycs(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span  style={{color: config.navBar.textColor2}}>Analitycs</span>
                        </a>
                    </li>
                    : "" : ""
                } */}
                {/* Dropdown de Ver correos del boletin */}
                {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 && seller.U_FMB_Handel_Boletin === 1 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goBoletin(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor3 }}>Ver correos del boletín </span>
                        </a>
                    </li>
                    :
                    (seller.U_FMB_Handel_Perfil === 3 ?
                        <li className="nav-item" onClick={() => { configReducer.history.goBoletin(); }}>
                            <a className="nav-link" style={{ color: config.navBar.textColor }}>
                                <span style={{ color: config.navBar.textColor3 }}>Ver correos del boletín </span>
                            </a>
                        </li>
                    : "") :""
                }
                {/* Dropdown de bonificaciones */}
                {/* {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 && seller.U_FMB_Bonificaciones === "1" ?
                    <li className="nav-item" onClick={() => { configReducer.history.goPromocionales(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span  style={{color: config.navBar.textColor2}}>Bonificaciones</span>
                        </a>
                    </li>
                    : "" : ""
                } */}
                {/* Dropdown de Subir archivo OC*/}
                {config.modules.subirArchivoExcel && seller ? seller.U_FMB_Handel_Perfil != 5 && Object.entries(user).length !== 0 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goSubirArchivo(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor3 }}>Subir archivo OC</span>
                        </a>
                    </li>
                    : "" : ""
                }
                {/* Dropdown de Mis reportes*/}
                {seller ? seller.U_FMB_Handel_Perfil != 5 && Object.entries(user).length !== 0 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goReports(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor3 }}>Mis reportes</span>
                        </a>
                    </li>
                    : "" : ""
                }
                {/* {user.banners === "on" && */}
                {/* Dropdown de Adm. Banners */}
                {seller ? seller.U_FMB_Handel_Perfil != 5 && Object.entries(user).length !== 0 ?
                    <li className="nav-item">
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span>
                                <Link to='/adminBanners' style={{ color: config.navBar.textColor3 }} >Adm. Banners</Link>
                            </span>
                        </a>
                    </li>
                    : "" : ""
                }
                {/* Dropdown de Admin Articulos */}
                {seller && (seller.U_FMB_Handel_Perfil === 3 || seller.U_FMB_Handel_Perfil === '3') ?
                    <li className="nav-item" /*onClick={() => { configReducer.history.goAutorizaciones(); }}*/>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span>
                                <Link to='/RegisterItems' style={{ color: config.navBar.textColor3 }} >Articulos</Link>
                            </span>
                        </a>
                    </li>
                    : ""
                }
                {seller && seller.U_FMB_Handel_Perfil === 3 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goSubirArchivoPadres(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor3 }}>Subir Plantilla Padres</span>
                        </a>
                    </li>
                    : ""
                }
                {seller && seller.U_FMB_Handel_Perfil === 3 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goAnalisis(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor3 }}>Análisis</span>
                        </a>
                    </li>
                    : ""
                }
                {/* Dropdown de Adm. Blog de noticias */}
                {/* seller ? seller.U_FMB_AdmBlogNoticia == 1 ? */}
                {seller ? seller.U_FMB_AdmBlogNoticia == 1 ?
                    <li className="nav-item">
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span>
                                <Link to='/admiNewsBlog' style={{ color: config.navBar.textColor3 }} >Adm. Blog de noticias</Link>
                            </span>
                        </a>
                    </li>
                    : "" : ""
                }
                {/* Dropdown de Autorizaciones */}
                {seller ? seller.U_FMB_Handel_Perfil === 5
                    ?
                    <li className="nav-item" onClick={() => { configReducer.history.goAutorizaciones(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor3 }}>Autorizaciones</span>
                        </a>
                    </li>
                    : "" : ""
                }
                {/* {seller ? seller.U_FMB_Handel_Perfil != 5 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goProfile(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span  style={{color: config.navBar.textColor2}}>Mi Perfil</span>
                        </a>
                    </li>
                    : "" : ""
                } */}

                {/* <li className="nav-item" onClick={() => { this.logOut(); }}>
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <span  style={{color: config.navBar.textColor2}}>Cerrar Sesión</span>
                    </a>
                </li> */}

              
                
            </ul>
        )
    };

    dejanosMensaje = async () => {
        setTimeout(() => {
            $('#dejanosMensajeModal').modal('show');
        }, 300);
    };

    renderSecondNavBar = () => {
        const { configReducer: { history, idFamilyFilter, idCategoriesMenu }, sessionReducer: { user }, sessionReducer } = this.props;

        return (
            <div style={{ width: "100%", zIndex: 90, background: "transparent", position: 'relative' }}>
                {/* NavBar solo visible en nmediano */}
                <div className="d-block d-lg-none" style={{ width: "100%", height: 45, background: config.navBar.backgroundColor }}>
                    <nav className="navbar navbar-expand-lg navbar-two" style={{ background: config.navBar.iconColor, zIndex: 94 }}>
                        <NavBarButton iconColor={config.navBar.iconColor2} icon={config.icons.userBlack} iconAbajo={config.icons.arrowDown} menuHam={config.icons.barsMenu} ruta={this.iconUser(user.listNum)} style={{ fontsize: 25 }} />
                        <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                            {/* <div id="desplegable" className="collapse dropdown-menu_category m-drop" aria-labelledby="navbarDropdown" style={{maxHeight:365, padding:0, position:'absolute', background:'white'}}>
                                {idCategoriesMenu && idCategoriesMenu.length > 0 && <MenuNavbar /> }
                            </div> */}
                            {sessionReducer.role === ROLES.PUBLIC ? this.renderPublicOptionMenu() : this.renderClientOptionMenu()}
                            <ul className="navbar-nav mr-auto">
                                {/* <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} onClick={() => this.mostrarCategoria('1','Refacciones')}>Departamentos </li> */}
                                {/* <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} aria-controls = 'navbarSupportedContent1' data-toggle="collapse" data-target="#navbarSupportedContent1" >
                                    <span data-toggle="collapse" href="#desplegable" aria-expanded="false" aria-controls="desplegable" > Departamentos </span>  
                                </li> */}
                                {/* <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} onClick={() => this.mostrarCategoria('3','Paking')}>Marcas</li> */}
                                <li className="nav-link nav-item text-white" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" style={{ cursor: "pointer", color: config.navBar.iconColor2 }} onClick={() => this.handleViews("featured")}>Destacados</li>
                                <li className="nav-link nav-item tex-white" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" style={{ cursor: "pointer", color: config.navBar.iconColor2 }} onClick={() => history.goNewsBlog()}>Soluciones + <span className="ideas Cchange" style={{ fontSize: '1.5rem' }} >Ideas</span> </li>
                                {/* <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} onClick={this.dejanosMensaje}>COTIZA AQUÍ</li> */}
                                {/* {config.aboutUs.active &&
                                    <li className="nav-link nav-item text-white" style={{ cursor: "pointer" }} onClick={() => history.goAboutUs()}>
                                        <span> ¿QUIÉNES SOMOS?</span>
                                    
                                    </li>} */}
                                {/* {config.contactUs.active &&
                                    <li className="nav-link nav-item text-white" style={{  cursor: "pointer" }} onClick={() => history.goContactUs()}>
                                        <span> CONTÁCTANOS </span>
                                    </li>} */}
                                {config.questions.active &&
                                    <li className="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => history.goQuestions()}>
                                        <span> Preguntas frecuentes </span>
                                    </li>}
                                {config.claim.active &&
                                    <li className="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => history.goClaim()}>
                                        <span>Contáctanos</span>
                                    </li>}
                                {config.termPage.active &&
                                    <li className="nav-link" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => history.goPolitics()}>
                                        <span> Términos de uso </span>
                                    </li>}
                                {/* Dropdown de Cerrar sesion */}
                                {sessionReducer.role !== ROLES.PUBLIC && (
                                    <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" onClick={() => { this.logOut(); }}>
                                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                                            <span className="dropdown-menu-item" style={{ color: config.navBar.textColor3 }}>Cerrar Sesión</span>
                                        </a>
                                    </li> )
                                }
                            </ul>
                        </div>
                    </nav>
                </div>
                {/* NavBar solo visible en largo */}
                <div className="d-12 d-none d-lg-flex" style={{ width: "100%", background: config.navBar.backgroundColor }}>
                    <nav className="navbar navbar-expand-md navbar-light navbar-two" style={{ width: "100%", backgroundColor: "#fff" }}>
                        <div className="d-none d-lg-block col-md-12">
                            <ul className="navbar-nav justify-content-md-center">
                                <li className="nav-link col-auto text-navbar mt-2" style={{ color: config.navBar.textCategorieColorHover, cursor: "pointer", maxHeight: "36px", marginBottom: "8px", borderRight: "2px solid #122759" }}>
                                    <span data-toggle="collapse" href="#desplegable" aria-expanded="false" aria-controls="desplegable">Departamentos</span>
                                </li>
                                <li className="nav-link col-auto text-navbar mt-2" style={{ color: config.navBar.textCategorieColorHover, cursor: "pointer", maxHeight: "36px", marginBottom: "8px", borderRight: "2px solid #122759" }} onClick={async () => { await this.handleViews("featured") }}>
                                    {/* <img src={config.NavarIconos.iconThree} className="Img-fluid " ></img> */}
                                    <span>Destacados</span>
                                </li>
                                <li className="nav-link col-auto text-navbar mt-2" style={{ color: config.navBar.textCategorieColorHover, cursor: "pointer", maxHeight: "36px", marginBottom: "8px", borderRight: "2px solid #122759" }}>
                                    {/* <img src={config.NavarIconos.iconTwo} className="Img-fluid" ></img> */}
                                    <span data-toggle="collapse" href="#desplegableMarcas" aria-expanded="false" aria-controls="desplegableMarcas" >Marcas</span>
                                </li>
                                <li className="nav-link col-autotext-navbar mt-2 Cchange" style={{ color: config.navBar.textCategorieColorHover, cursor: "pointer", maxHeight: "36px", marginBottom: "8px", borderRight: "2px solid #122759" }} onClick={() => history.goNewsBlog()}>
                                    {/* <img src={config.NavarIconos.iconTwo} className="Img-fluid" ></img> */}
                                    <span> Soluciones + <span className="ideas" style={{ fontSize: '1.5rem' }} >Ideas</span></span>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>

                <div id="desplegable" className="collapse dropdown-menu_category m-drop" aria-labelledby="navbarDropdown" style={{ zIndex: '1000000', maxHeight: 305, padding: 0, position: 'absolute', /*border : '2px solid red',*/ background: 'white' }}>
                    {idCategoriesMenu && idCategoriesMenu.length > 0 && <MenuNavbar />}
                </div>

                <div id="desplegableMarcas" className="collapse dropdown-menu_category m-drop" aria-labelledby="navbarDropdown" style={{ zIndex: '1000000', maxHeight: 305, padding: 0, position: 'absolute', /*border : '2px solid red',*/ background: 'white' }}>
                    {<MenuMarcas />}
                </div>
            </div>

        );
    };

    mostrarCategoria = (valor, seccion) => {
        const { setCategory, setIdCategory, setLocation, setNameCategory, itemsReducer: { searchByCategories }, setNextPage } = this.props;
        setIdCategory(valor);
        setNextPage(0);
        setNameCategory(seccion);
        setLocation('menuCategories');
        searchByCategories(valor, 0, seccion);
    };

    mostrarCategoria = async (valor, seccion) => {
        const { setCategory, setIdCategory, setLocation, setNameCategory, itemsReducer: { searchByCategories }, setNextPage, setItemsFilters, setItemsCategoryFilters } = this.props;
        setIdCategory(valor);
        setNextPage(0);
        setNameCategory(seccion);
        await setLocation('menuCategories');
        await setItemsFilters({});
        let dataFilterCat = {
            cateogry: valor,
            page: 0,
            view: seccion,
            subCategory: ''
        }
        await setItemsCategoryFilters(dataFilterCat);
        await searchByCategories(valor, 0, seccion);
    };

    handleViewsRes = async (opcion, page = 0) => {
        const { itemsReducer, setIdCategory, setLocation, configReducer: { history }, setItemsFilters, setItemsCategoryFilters } = this.props;
        setIdCategory(null);
        await setLocation(opcion);
        await setItemsFilters({});
        await setItemsCategoryFilters({});
        await itemsReducer.searchByKey(0, opcion);
    };

    handleViews = async (opcion) => {
        const { itemsReducer:{ searchByKey }, setLocation, setNewFilters } = this.props;
        await setLocation(null); // Necesario para volver a solicitar filtros de búsqueda nueva
        await setLocation(opcion);
        await setNewFilters({});
        await searchByKey();
    };

    handleOutletZone = async () => {
        const { configReducer: { history } } = this.props;
        const { itemsReducer, setIdCategory, setLocation } = this.props;
        setIdCategory(null);
        setLocation('outletView');
        await itemsReducer.searchByKey(0, 'outletView');
    }
    handleWhiteZone = async () => {
        const { configReducer: { history } } = this.props;
        const { itemsReducer, setIdCategory, setLocation } = this.props;
        setIdCategory(null);
        setLocation('whiteView');
        await itemsReducer.searchByKey(0, 'whiteView');
    }
    handleRedPolarZone = async () => {
        const { configReducer: { history } } = this.props;
        const { itemsReducer, setIdCategory, setLocation } = this.props;
        setIdCategory(null);
        setLocation('redView');
        await itemsReducer.searchByKey(0, 'redView');
    }

    openNav() {
        document.getElementById("offcanvasRight").style.width = "250px";
        document.getElementById("root").style.paddingLeft = "250px";
    }

    closeNav() {
        document.getElementById("offcanvasRight").style.width = "0";
        document.getElementById("root").style.padingLeft = "0";
    }

    changeQuantity = async (index, item, newQuantity, addItem) => {
        const { enableSpinner, setShoppingCart, itemsReducer: { addShoppingCart, deleteShoppingCart }, shoppingCartReducer: { items } } = this.props;

        items.map(itemFilter => {
            if (item.ItemCode === itemFilter.ItemCode) {
                itemFilter.quantity = newQuantity;
            }
        });

        setShoppingCart(items);

        if (!newQuantity) return;
        if (addItem) {
            addShoppingCart({ item, quantity: (newQuantity) })
        }
        // setTimeout(async () => {
        //     let shoppingCart = [];     
        //     let response = await apiClient.getShoppingCart(items);

        //     if (response.status === SERVICE_RESPONSE.SUCCESS) {                    
        //         shoppingCart = response.data.shoppingCart;                    
        //     } else {
        //         return;
        //     }    
        //     setShoppingCart(shoppingCart);
        // }, 150); 
    };

    refeshState = (array, Notificacion) => {
        const { history, shoppingCartReducer: { items }, itemsReducer: { addShoppingCart, deleteShoppingCart } } = this.props;
        const { itemsRegalosSelect } = this.state;

        let itemsPromo = [];
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if (element.itemDisparador === item.ItemCode && element.tipoSeleccion === 1) {
                    if (element.tipoDisparador === 2) {
                        let result = itemsPromo.find(itemDisp => (itemDisp.itemDisparador !== item.ItemCode));
                        if (!result) {
                            element.bonificacion.cantidad *= element.homeItemQuantity;
                            itemsPromo.push(element);
                        }
                    } else if (element.tipoDisparador === 1) {
                        element.bonificacion.cantidad *= element.homeItemQuantity;
                        itemsPromo.push(element);
                    }

                }
            }
        }

        if (itemsPromo.length > 0) {
            let boxes = [];
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if (element.tipoSeleccion === 2) {
                    let result = boxes.find(item => (item.itemDisparador === element.itemDisparador));
                    if (!result) {
                        boxes.push(element);
                    }
                }
            }
            for (let index = 0; index < itemsRegalosSelect.length; index++) {
                const element = itemsRegalosSelect[index];
                for (let index = 0; index < boxes.length; index++) {
                    const box = boxes[index];
                    if (element.itemDisparador === box.itemDisparador && element.idDisparador === box.idDisparador) {
                        boxes.splice(index, 1);
                    }
                }
            }
            this.setState({
                itemsRegalos: itemsPromo,
                itemPromociones: array,
                selectorType: 1,
                boxes: boxes,
                itemsRegalosSelect: [],
                itemsNotificacion: Notificacion

            });

        } else {
            let boxes = [];
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if (element.tipoSeleccion === 2) {
                    let result = boxes.find(item => (item.itemDisparador === element.itemDisparador));
                    if (!result) {
                        boxes.push(element);
                    }
                }
            }
            for (let index = 0; index < itemsRegalosSelect.length; index++) {
                const element = itemsRegalosSelect[index];
                for (let index = 0; index < boxes.length; index++) {
                    const box = boxes[index];
                    if (element.itemDisparador === box.itemDisparador && element.idDisparador === box.idDisparador) {
                        boxes.splice(index, 1);
                    }
                }
            }

            this.setState({
                itemsRegalos: itemsPromo,
                itemPromociones: array,
                boxes: boxes,
                itemsRegalosSelect: [],
                itemsNotificacion: Notificacion,

            });
        }
    };
    addItem = async (item, event) => {
        const { itemsReducer: { items: itemsSearch, addShoppingCart, deleteShoppingCart }, setItemsFilterSearch, setShoppingCart, notificationReducer: { showAlert } } = this.props;
        let newQuantity;
        let itemsLite = [];
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                newQuantity = itemFilter.quantity || '1';
                itemsLite = itemFilter;
            }
        });

        await addShoppingCart({ item, quantity: (newQuantity || '1') });
        // setShoppingCart(itemsLite);
        // setShoppingCart ([ itemsLite ])
        this.getShoppingCart();

    }
    render() {
        const { itemsReducer: { deleteShoppingCart, addShoppingCart }, sessionReducer: { user }, sessionReducer, shoppingCartReducer: { items, backOrder, isDevolution } } = this.props;
        const { itemPromociones, Limit, Codigo, CardName, itemsRegalos, itemsBonificacion, selectorType, itemsRegalosSelect, boxes, itemsNotificacion, businessPartnerInfo, pointsInfo, activePoints, isAddShopping } = this.state;
        return (
            <div>
                {/* <MenuCategories /> */}
                <WarehouseModal />
                <LoginRegisterModal />
                <LoginModal />
                <DejanosMensajeModal
                    Usuario={user}
                    datos={sessionReducer.role}
                ></DejanosMensajeModal>
                {this.searchModal()}
                <div style={{ background: config.navBar.backgroundColor, zIndex: 1020 }}>
                    {this.renderFirstNavBar()}
                    {this.renderSecondNavBar()}
                    <SideBar
                        view={VIEW_NAME.SHOPPING_CART_VIEW}
                        data={items}
                        deleteItem={deleteShoppingCart}
                        changeQuantity={this.changeQuantity}
                        add={addShoppingCart}
                        sendTo={this.sendToBackOrder}
                        // businessPartnerInfo={businessPartnerInfo}
                        refeshState={this.refeshState}
                        // itemPromociones = {itemPromociones}
                        selectItemsPromo={this.selectItemsPromo}
                        // itemsRegalos = {itemsRegalos}
                        // itemsRegalosSelect = {itemsRegalosSelect}
                        deleteItemPromo={this.deleteItemPromo}
                        // boxes = {boxes}
                        itemsNotificacion={itemsNotificacion}
                        isDevolution={isDevolution}
                    />
                </div>
                <CategoriesMenu />
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),

        setIdCategory: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value }),
        setNameCategory: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_NAME_CATEGORY, value }),
        setRole: value => dispatch({ type: DISPATCH_ID.SESSION_SET_ROLE, value }),
        addNotificationReference: value => dispatch({ type: DISPATCH_ID.NOTIFICATION_SET_ALERT_REFERENCE, value }),
        setBusiness: value => dispatch({ type: DISPATCH_ID.SESSION_SET_BUSINESS_ID, value }),
        setToken: value => dispatch({ type: DISPATCH_ID.SESSION_SET_TOKEN, value }),
        setUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_USER, value }),
        setRememberUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_REMEMBER_USER, value }),
        setLogOutReference: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_LOG_OUT_REFERENCE, value }),
        setNextPage: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value }),
        setLocation: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_LOCATION, value }),
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),
        setItemsFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value }),
        setItemsCategoryFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_CATEGORY_FILTER, value }),
        setNewFilters: value => dispatch({ type: DISPATCH_ID.SET_NEW_FILTERS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBar);
