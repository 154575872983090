import { DISPATCH_ID } from '../../libs/utils/Const';

export const ItemsReducer = (state = { items: [], itemsFilter: [],items2: [], itemsFilter2: [], tags: [], itemDetails: {}, fieldsFilter:[], 
    search: '', searchFilter: '',category: '', nameCategory: null, searchItemsFilter: '', searchByKey: null, searchByCategories: null,searchByDash: null, 
    openItemDetails: null, updateFavorite: null, addShoppingCart:null, addBackOrder: null, deleteBackOrder:null, deleteShoppingCart: null, 
    TotalPage: 0, nextPage: 0, idCategory: null,location:null, auxLocation: null, searchByDashOption:null, specialPrice:null, itemsCategories :{},
    itemsAutoComplete :[], searchCategories: [],uniqueFilter : {},uniqueCategoryFilter:{},items1: [],items2: [], itemsFilter1: [], itemsFilter2: [], whsGeneral: null, 
    whsGeneralName: null, whsGeneralType: null, searchItemsFilter1: '',searchItemsFilter2: '', searchLite: [], openItemEdid: null, viewCategory:null, getStockDetails: null, getInfoDiscount: null,
    NewFilters: {}, prevSearchVal: {}, flagGetFilters: false, }, action) => {
    switch (action.type) {
        case DISPATCH_ID.ITEMS_SET_ITEMS:
            return Object.assign({}, state, { items: action.value, searchItemsFilter: state.search || state.category });
        case DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER:
            return Object.assign({}, state, { itemsFilter: action.value });
        case DISPATCH_ID.ITEMS_SET_ITEMS1:
            return Object.assign({}, state, { items1: action.value, searchItemsFilter1: state.search || state.category });
        case DISPATCH_ID.ITEMS_SET_ITEMS2:
            return Object.assign({}, state, { items2: action.value, searchItemsFilter2: state.search || state.category });
        case DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER1:
            return Object.assign({}, state, { itemsFilter1: action.value });
        case DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER2:
            return Object.assign({}, state, { itemsFilter2: action.value });
        case DISPATCH_ID.ITEMS_SET_ITEM_DETAILS:
            return Object.assign({}, state, { itemDetails: action.value });
        case DISPATCH_ID.ITEMS_SET_SEARCH:
            return Object.assign({}, state, { search: action.value, category: '' });
        case DISPATCH_ID.ITEMS_SET_SEARCH_FILTER:
            return Object.assign({}, state, { searchFilter: action.value });
        case DISPATCH_ID.ITEMS_SET_CATEGORY:
            return Object.assign({}, state, { category: action.value, search: '' });
        case DISPATCH_ID.ITEMS_SET_NAME_CATEGORY:
            return Object.assign({}, state, { nameCategory: action.value });
        case DISPATCH_ID.ITEMS_SAVE_TAGS:
            return Object.assign({}, state, { tags: action.value });
        case DISPATCH_ID.ITEMS_OPEN_ITEM_DETAILS_REFERENCE:
            return Object.assign({}, state, { openItemDetails: action.value });
        case DISPATCH_ID.ITEMS_UPDATE_FAVORITE_REFERENCE:
            return Object.assign({}, state, { updateFavorite: action.value });
        case DISPATCH_ID.ITEMS_ADD_SHOPPING_CART_REFERENCE:
            return Object.assign({}, state, { addShoppingCart: action.value });
        case DISPATCH_ID.ITEMS_ADD_BACK_ORDER_REFERENCE:
            return Object.assign({}, state, { addBackOrder: action.value });
        case DISPATCH_ID.ITEMS_DELETE_BACK_ORDER_REFERENCE:
            return Object.assign({}, state, { deleteBackOrder: action.value });
        case DISPATCH_ID.ITEMS_DELETE_SHOPPING_CART_REFERENCE:
            return Object.assign({}, state, { deleteShoppingCart: action.value });
        case DISPATCH_ID.ITEMS_SET_SEARCH_BY_KEY_REFERENCE:
            return Object.assign({}, state, { searchByKey: action.value });
        case DISPATCH_ID.ITEMS_SET_SEARCH_BY_CATEGORIES_REFERENCE:
            return Object.assign({}, state, { searchByCategories: action.value });
        case DISPATCH_ID.ITEMS_SET_SEARCH_BY_CATEGORIES_HOME_REFERENCE:
            return Object.assign({}, state, { searchByCategoriesHome: action.value });
        case DISPATCH_ID.ITEMS_SET_SEARCH_BY_CATEGORIES_HOME_REFERENCE2:
            return Object.assign({}, state, { searchByCategoriesHome2: action.value });
        case DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH:
            return Object.assign({}, state, { searchByDash: action.value});
        case DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH_OPTION:
            return Object.assign({}, state, { searchByDashOption: action.value});
        case DISPATCH_ID.ITEMS_SET_TOTALROWS:
            return Object.assign({}, state, { TotalPage: action.value });
        case DISPATCH_ID.ITEMS_SET_NEXTPAGE:
            return Object.assign({}, state, { nextPage: action.value });
        case DISPATCH_ID.ITEMS_SET_IDCATEGORY:
            return Object.assign({}, state, { idCategory: action.value });
        case DISPATCH_ID.ITEMS_SET_LOCATION:
            return Object.assign({}, state, { location: action.value });
        case DISPATCH_ID.ITEMS_SET_AUX_LOCATION:
            return Object.assign({}, state, { auxLocation: action.value });
        case DISPATCH_ID.ITEMS_SET_CATEGORIES:
            return Object.assign({}, state, { itemsCategories: action.value });
        case DISPATCH_ID.ITEMS_SET_AUTO_COMPLETE:
            return Object.assign({}, state, { itemsAutoComplete: action.value });
        case DISPATCH_ID.CONFIG_SET_FIELDSFILTER:
            return Object.assign({}, state, { fieldsFilter: action.value });
        case DISPATCH_ID.ITEMS_SET_SEARCH_CATEGORY:
            return Object.assign({}, state, { searchCategories: action.value });
        case DISPATCH_ID.ITEMS_SPECIAL_PRICES:
            return Object.assign({}, state, { specialPrice: action.value });
        case DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER:
            return Object.assign({}, state, { uniqueFilter: action.value });
        case DISPATCH_ID.ITEMS_SET_UNIQUE_CATEGORY_FILTER:
            return Object.assign({}, state, { uniqueCategoryFilter: action.value });
        case DISPATCH_ID.ITEMS_SET_SEARCH_LITE:
            return Object.assign({}, state, { searchLite: action.value });
        case DISPATCH_ID.ITEMS_SET_WHSGENERAL:
            return Object.assign({}, state, { whsGeneral: action.value });
        case DISPATCH_ID.ITEMS_SET_WHSGENERAL_NAME:
            return Object.assign({}, state, { whsGeneralName: action.value });
        case DISPATCH_ID.ITEMS_SET_WHSGENERAL_TYPE:
            return Object.assign({}, state, { whsGeneralType: action.value });
        case DISPATCH_ID.ITEMS_OPEN_ITEM_DETAILS_EDIT:
            return Object.assign({}, state, { openItemEdid: action.value });
        case DISPATCH_ID.ITEM_INFO_DISCOUNT:
            return Object.assign({}, state, { getInfoDiscount: action.value });
        case DISPATCH_ID.ITEMS_CLEAN_REDUCER:
            return Object.assign({}, state, { items: [], itemsFilter: [],fieldsFilter: [], tags: [], itemDetails: {}, search: '', searchFilter: '',category: '', 
            nameCategory: '', searchItemsFilter: '', searchByDashOption: null, searchByKey:null,searchByDash:null, searchByCategories: null, 
            openItemDetails: null, updateFavorite: null, addShoppingCart:null, addBackOrder: null, deleteBackOrder:null, deleteShoppingCart: null , 
            specialPrice: null, idCategory: null, searchLite: [], whsGeneral: null, whsGeneralName: null, whsGeneralType: null, openItemEdid :null,
            NewFilters: {},  prevSearchVal: {}, auxLocation: null, flagGetFilters: false });
        case DISPATCH_ID.ITEMS_SET_VIEWCATEGORY:
            return Object.assign({}, state, { viewCategory: action.value });
        case DISPATCH_ID.ITEM_STOCK_REFRENCE:
            return Object.assign({}, state, { getStockDetails: action.value });
        case DISPATCH_ID.SET_NEW_FILTERS:
            return Object.assign({}, state, { NewFilters: action.value });
        case DISPATCH_ID.SET_PREV_SEARCH_VAL:
            return Object.assign({}, state, { prevSearchVal: action.value });
        case DISPATCH_ID.SET_FLAG_GET_FILTERS:
            return Object.assign({}, state, { flagGetFilters: action.value });
        default:
            return state;
    }
};
