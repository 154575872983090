import React, { Component } from 'react';
import { connect } from "react-redux";
import { config, ROLES, DISPATCH_ID } from '../libs/utils/Const';
import $ from "jquery";
import { ApiClient } from "../libs/apiClient/ApiClient";
import moment from 'moment';
import ReactDOM from 'react-dom';
import './MenuNavbar.css';


class MenuMarcas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMarca: null
        };
    }
    searchMarcaRes = async (valor) => {
        const {setCategory,setIdCategory, setLocation,itemsReducer: {searchByCategories},setNextPage, itemsReducer, setItemsFilters, setItemsCategoryFilters } = this.props;
        setIdCategory(valor);
        setNextPage(0);
        await setLocation('menuCategories');
        await setItemsFilters({});
        let dataFilterCat = {
            cateogry: valor,
            page: 0,
            view: 'marca',
            subCategory: ''
        }
        await setItemsCategoryFilters(dataFilterCat);
        await searchByCategories(valor,0,'marca');
    };

    searchMarca = async (val) => {
        const { itemsReducer:{ searchByKey }, setLocation, setNewFilters } = this.props;
        await setLocation(null); // Necesario para volver a solicitar filtros de búsqueda nueva
        await setLocation('brandMenu');
        await setNewFilters({brand: val});
        await searchByKey();
    };

    handleCategoryHover = (marca) => {
        this.setState({ selectedMarca: marca });
    };

    render() {
        const { configReducer: { history, idMarcasMenu }, sessionReducer: { user }, sessionReducer } = this.props;
        return (
            <div>
                <div data-toggle="collapse" href="#desplegableMarcas" aria-expanded="false" aria-controls="desplegableMarcas"
                    style={{ width: '150vw', height: '150vh', background: 'transparemt', zIndex: '-1', position: 'absolute', top: '-173px'}}>
                </div>

                <div className='collapse' id='Categorys' style={{ display: "flex", padding: 0 }}>

                    {/* ############## REGION DE CATEGORIAS (NIVEL 1) ############## */}
                    <div id="Drop-Familia" style={{ background: config.navBar.headerCards, cursor: 'pointer', color: 'white', fontWeight: 'bold' }}>
                        <div className='designScroll Departamentos-lvl-1'>
                            {idMarcasMenu.length > 0 && idMarcasMenu.map(marcasL => {
                                return (
                                    <div className={`category ${this.state.selectedMarca === marcasL ? 'selected' : ''}`}>
                                        <span
                                            className='nav-link'
                                            style={{ fontSize: "0.8rem", height: '3rem' }}
                                            onClick={() => this.searchMarca(marcasL.Code)}
                                            onMouseOver={() => this.handleCategoryHover(marcasL)}
                                        >
                                            {marcasL.U_name}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),

        setRole: value => dispatch({ type: DISPATCH_ID.SESSION_SET_ROLE, value }),
        addNotificationReference: value => dispatch({ type: DISPATCH_ID.NOTIFICATION_SET_ALERT_REFERENCE, value }),
        setBusiness: value => dispatch({ type: DISPATCH_ID.SESSION_SET_BUSINESS_ID, value }),
        setToken: value => dispatch({ type: DISPATCH_ID.SESSION_SET_TOKEN, value }),
        setUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_USER, value }),
        setRememberUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_REMEMBER_USER, value }),
        setLogOutReference: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_LOG_OUT_REFERENCE, value }),
        setIdCategory: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value }),
        setNextPage: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value }),
        setLocation: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_LOCATION, value }),
        setView: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_VIEWCATEGORY, value }),
        setItemsFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value }),
        setItemsCategoryFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_CATEGORY_FILTER, value }),
        setNewFilters: value => dispatch({ type: DISPATCH_ID.SET_NEW_FILTERS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuMarcas);