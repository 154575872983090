import React, { Component } from 'react';
import { config, ROLES, DISPATCH_ID, SERVICE_API } from '../libs/utils/Const';
import { connect } from "react-redux";
import './Footer.css';
import packageJson from '../../package.json';
import { PreguntasModal } from "./index";
import AppsView from '../views/apps/AppsView';
import $ from "jquery";

global.appVersion = packageJson.version;

class Footer extends Component {
    getYear = () => {
        let today = new Date();
        return today.getFullYear() || '';
    };

    render() {
        const { configReducer: { history }, sessionReducer: { user }, sessionReducer } = this.props;
        return (
            <footer className="page-footer font-small pt-5" style={{backgroundColor: config.footer.backgroundColor}}>
                <div className="text-center text-md-left webFooter">
                    <div className="row" id='footer-container'style={{ maxWidth: '100%' }}>
                        {/* Logo de perez */}

                        <div className="col-md-3 text-center p-0" style={{marginRight:'20px'}}>
                            <div className='desk-just'>
                            <a href=" " target="_blank">
                            <img src={config.footer.icon} className="img-fluid" style={{width:'250px'}} />
                            </a>
                            <br/>
                            <p>
                                {config.footer.socialMedia2.map((red, index) => {
                                    return (red.visibility &&
                                        <a class="btn btn-floating m-1" href={red.linkref} target="_black" key={index}>
                                            <img className="img-fluid" width='25px' src={red.icon} />
                                        </a>
                                    )
                                })}
                            </p>
                            <a href="http://fmbsolutions.mx/" target="_blank">
                                <img src={config.footer.power} className="img-fluid" width='120px' style={{ marginRight: '0rem',maxWidth: '25%',marginTop: '23px', minWidth: '95px'}}/>
                            </a>
                        </div>
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        {/* Información de la empresa */}
                        <div className="col-md-2 text-left p-0" style={{marginRight:'20px'}}>
                            <h5 className="font-weight-bold text-uppercase mb-4" style={{color: config.footer.textColor}}>Empresa</h5>
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goAboutUs()}> ¿Quienes Somos? </span>
                                </a>
                            </p>
                            
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goContactUs()}> Contacto </span>
                                </a>
                            </p>
                            {config.modules.BolsaTrabajo && 
                                <p>
                                    <a>
                                        <span style={{ color: config.footer.textColor }} onClick={() => history.goJob()}> Bolsa de trabajo </span>
                                    </a>
                                </p>
                            }
                            {config.modules.Devoluciones && 
                                <p>
                                    <a>
                                        <span style={{ color: config.footer.textColor }} onClick={() => history.goDevolution()}> Devoluciones </span>
                                    </a>
                                </p>
                            }
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goNewsBlog()}> Soluciones + ideas </span>
                                </a>
                            </p>
                            {config.modules.footerRedAliado && 
                                <p>
                                    <a>
                                        <span style={{ color: config.footer.textColor }} onClick={() => history.goRedAliado()}> Aliados </span>
                                    </a>
                                </p>
                            }
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goGetPlantilla()}> Descargar plantilla </span>
                                </a>
                            </p>
                            {sessionReducer.role !== ROLES.PUBLIC && (
                                <p>
                                    <a onClick={() => history.goApps()}>
                                        <span style={{ color: config.footer.textColor }}>Descargar aplicaciones</span>
                                    </a>
                                </p>
                            )}
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        {/* Información legal */}
                        <div className="col-md-2 text-left p-0 " style={{marginRight:'20px'}}>
                        <h5 className="font-weight-bold text-uppercase mb-4" style={{color: config.footer.textColor}}>Preguntas</h5>
                        <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goQuestionshipping()}> Información de envío</span>
                                </a>
                            </p>
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goQuestions()}> Preguntas frecuentes</span>
                                </a>
                            </p>
                            {/* <h5 className="font-weight-bold text-uppercase mb-4" style={{color: config.footer.textColor}}>Legales</h5> */}
                            {config.pdf.VERterminosCondiciones &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.terminosCondiciones}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}>Términos y condiciones </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasVentas &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasEnviosGarantias}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}>Políticas de envíos y garantías </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasGarantia &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasGarantia}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}> Políticas de garantía </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasUsoAvisoPrivacidad &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasUsoAvisoPrivacidad}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }} > Políticas de uso y aviso de privacidad </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERterminosCondiciones &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasEnviosGarantias}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}>Términos de envío </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasVentas &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasGarantia}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}>Políticas de garantía y Devoluciones </span>
                                    </a>
                                </p>
                            }
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        {/* Ubicaccion de la empresa */}
                        <div className="col-md-2 text-left p-0" style={{marginRight:'20px'}}>
                            <h5 className="font-weight-bold text-uppercase mb-4" style={{color: config.footer.textColor}}>Contacto</h5>
                            <p>
                                <a>
                                    <img className="img-fluid" src={config.footer.info.phoneIcon} style={{ color: config.footer.iconColor, padding: 1,  width:'18px', height:'18px' }}></img>&nbsp;
                                    <span style={{ color: config.footer.textColor }}>{config.footer.info.phone}</span>
                                </a>
                            </p>
                            <p>
                                <a style={{color: config.footer.colorP}}>
                                    <img className="img-fluid" src={config.footer.info.emailIcon} style={{ color: config.footer.iconColor, padding: 1, width:'18px', height:'18px'  }}></img>&nbsp;
                                    <span onClick={() => $('#ClarificationModal').modal('show')} style={{ color: config.footer.textColor }}>{config.footer.info.email}</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="text-center text-md-left responsivoFooter" style={{marginRight: 20}}>
                    <div className="row">
                        {/* Logo de perez */}
                        <div className="col-md-2 text-center" style={{paddingTop: "36px !important"}}>
                            <a href="#" target="_blank">
                                <img src={config.footer.icon} className="img-fluid" style={{width:'250px'}} />
                            </a>
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        {/* Información de la empresa */}
                        <div className="col-md-2">
                            <h5 className="font-weight-bold text-uppercase mb-4" style={{color: config.footer.textColor}}>Empresa</h5>
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goAboutUs()}> ¿Quienes Somos? </span>
                                </a>
                            </p>
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goContactUs()}> Contacto </span>
                                </a>
                            </p>
                            {config.modules.BolsaTrabajo && 
                                <p>
                                    <a>
                                        <span style={{ color: config.footer.textColor }} onClick={() => history.goJob()}> Bolsa de trabajo </span>
                                    </a>
                                </p>
                            }
                            {config.modules.Devoluciones && 
                                <p>
                                    <a>
                                        <span style={{ color: config.footer.textColor }} onClick={() => history.goDevolution()}> Devoluciones </span>
                                    </a>
                                </p>
                            }
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goNewsBlog()}> Soluciones + ideas </span>
                                </a>
                            </p>
                            {config.modules.footerRedAliado && 
                                <p>
                                    <a>
                                        <span style={{ color: config.footer.textColor }} onClick={() => history.goRedAliado()}> Aliados </span>
                                    </a>
                                </p>
                            }
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goGetPlantilla()}> Descargar plantilla </span>
                                </a>
                            </p>
                            {sessionReducer.role !== ROLES.PUBLIC && (
                                <p>
                                    <a onClick={() => history.goApps()}>
                                        <span style={{ color: config.footer.textColor }}>Descargar aplicaciones</span>
                                    </a>
                                </p>
                            )}
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        {/* Información legal */}
                        <div className="col-md-2">
                            <h5 className="font-weight-bold text-uppercase mb-4" style={{color: config.footer.textColor}}>Preguntas</h5>
                        <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goQuestionshipping()}> Información de envío</span>
                                </a>
                            </p>
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goQuestions()}> Preguntas frecuentes</span>
                                </a>
                            </p>
                            {/* <h5 className="font-weight-bold text-uppercase mb-4" style={{color: config.footer.textColor}}>Legales</h5> */}
                            {config.pdf.VERterminosCondiciones &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.terminosCondiciones}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}>Términos y condiciones </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasVentas &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasEnviosGarantias}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}>Políticas de envíos y garantías </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasGarantia &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasGarantia}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}> Políticas de garantía </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasUsoAvisoPrivacidad &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasUsoAvisoPrivacidad}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }} > Políticas de uso y aviso de privacidad </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERterminosCondiciones &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasEnviosGarantias}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}>Términos de envío </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasVentas &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasGarantia}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}>Políticas de garantía y Devoluciones </span>
                                    </a>
                                </p>
                            }
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        {/* Ubicaccion de la empresa */}
                        <div className="col-md-2">
                            <h5 className="font-weight-bold text-uppercase mb-4" style={{color: config.footer.textColor}}>Contacto</h5>
                            <p>
                                <a>
                                    <img className="img-fluid"src={config.footer.info.phoneIcon} style={{ color: config.footer.iconColor, padding: 1 , width:'20px', height:'20px'}}></img>&nbsp;
                                    <span style={{ color: config.footer.textColor }}>{config.footer.info.phone}</span>
                                </a>
                            </p>
                            <p>
                                <a style={{color: config.footer.colorP}}>
                                    <img className="img-fluid" src={config.footer.info.emailIcon} style={{ color: config.footer.iconColor, padding: 1 , width:'20px', height:'20px'}}></img>&nbsp;
                                    <span onClick={() => $('#ClarificationModal').modal('show')} style={{ color: config.footer.textColor }}>{config.footer.info.email}</span>
                                </a>
                            </p>
                            
                            {/*Redes sociales */}
                           <p className="">
                                { config.footer.socialMedia2.map( (red, index) => {
                                    return(red.visibility &&
                                        <a class="btn btn-floating m-1-Icon" href={red.linkref} target="_black" key={index}>
                                            <img className="img-fluid" width='30px' src={red.icon}/>
                                        </a>
                                    )
                                })}
                            </p>
                            {/* <p>
                                <a>
                                    <img className="img-fluid" width='5%' height='5%' src={config.footer.info.hoursIcon} style={{ color: config.footer.iconColor, padding: 1 }}></img>&nbsp;
                                    <span style={{ color: config.footer.textColor }}>{config.footer.info.hours}</span>
                                </a>
                            </p> */}
                            
                        </div>
                        <hr className="clearfix w-100 d-md-none"></hr>
                        {/* Logo de la empresa */}
                        <div className="col-md-2 text-center" style = {{marginTop: '3%', paddingTop:"1.2rem"}}>
                            {/* <p style={{ color: config.footer.textColor }} >DESARROLLADO POR</p> */}
                            <a href="http://fmbsolutions.mx/" target="_blank">
                                <img src={config.footer.power} className="img-fluid" style = {{marginBottom: "10%", width:'150px'}} />
                            </a>
                        </div>
                        <hr className="clearfix w-100 d-md-none" />

                    </div>
                </div>
                {/* Inofrmacion de la version */}
                <div className="footer-copyright text-center py-4">
                
                    <a href="http://fmbsolutions.mx/" target="_blank">
                        <span style={{ color: config.footer.textColor }}>Todos los derechos reservados ©</span>
                        <br></br>
                        <span  style={{ color: config.footer.textColor }}>{this.getYear()} &middot; Versión &middot; {global.appVersion}</span>
                    </a>
                </div>
                <PreguntasModal title={'Contáctanos ante cualquier Duda o Pregunta!'} />
            </footer>
        );
    }
}
const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);