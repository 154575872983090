import React, {Component} from 'react';
import Async from 'react-async';
import { Link } from "react-router-dom";
import {config} from "../libs/utils/Const";
import './CarouselDashboard.css';

class CarouselDashboard extends Component {

    constructor(props){
        super(props);
        const api = config.BASE_URL;
        const assets = config.ASSETS;
        this.state = {
            urlapi: api,
            assets: assets,
            banners: []
        };
    }
    
    // We'll request user data from this API
    loadActiveBanners = () => fetch(`${this.state.urlapi}/getbanners`, { method: 'GET', mode: 'cors', cache: 'default' })
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json());

    renderCarouselImage = (imagePath, index) => {
        return (<div key={index + 'q'} className={"carousel-item" + (!index ? ' active' : '')} style={{backgroundColor: 'rgba(248,249,250,0.6)'}}>
            <img className="img-fluid" src={imagePath} alt="First slide"/>
        </div>);
    };

    renderIndicator = (imagePath, index) => (
        <li key={index + 'w'} data-target="#carouselDashboard" data-slide-to={index}  className={!index ? ' active':''}/>
    );

    render() {
        let images = config.carousel.images;
        const { assets } = this.state;
        return (
            <div className="carousel slide" /*style={{marginTop: 0}}*/>
                <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-ride="carousel">
                <Async promiseFn={this.loadActiveBanners}>
                    <Async.Loading>Cargando...</Async.Loading>
                    <Async.Fulfilled>
                    {data => {
                        return (
                        <ol className="carousel-indicators">
                            {data.map((rows, index) => (
                            <li data-target="#carouselExampleCaptions" data-slide-to={index} className={index === 0 ? 'active' : ''} key={index}></li>
                            ))
                            }
                        </ol>
                        )
                    }}
                    </Async.Fulfilled>
                    <Async.Rejected>
                    {error => `Something went wrong: ${error.message}`}
                    </Async.Rejected>
                </Async>
                <Async promiseFn={this.loadActiveBanners}>
                    <Async.Loading>Cargando...</Async.Loading>
                    <Async.Fulfilled>
                    {data => {
                        return (
                        <div className="carousel-inner">
                            {data.map((rows, index) =>
                         
                            (
                                
                                <div className={'carousel-item ' + (index === 0 ? 'active' : '')} key={index}>
                                    <img src={assets + 'banners/' + rows.image} className="d-block w-100" alt={rows.image} />
                                   
                                    {/* <div className="carousel-caption d-none d-md-block"> */}
                                        {/* <h5>{rows.title}</h5> */}
                                        {/* {(() => {
                                        if (rows.url.length > 5) {
                                            return <a className='btn' style={{color: config.navBar.textColor, backgroundColor: config.navBar.backgroundColor}} href={rows.url} target="_blank" rel="noopener noreferrer">Leer más</a>;
                                        } else {
                                            return <Link className="btn" style={{color: config.navBar.textColor, backgroundColor: config.navBar.backgroundColor}} to={'/postbanner/' + rows.id + '/' + rows.slug}>Leer más</Link>;
                                        }
                                        })()} */}
                                    {/* </div> */}
                                </div>
                            )
                            )
                            }
                        </div>
                        )
                    }}
                    </Async.Fulfilled>
                    <Async.Rejected>
                    {error => `Something went wrong: ${error.message}`}
                    </Async.Rejected>
                </Async>
                <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
                </div>

            </div>
        );
    }
}

export default CarouselDashboard;