import React, { Component } from 'react';
import { connect } from "react-redux";
import { Dropdown } from 'react-bootstrap';
import { config, DISPATCH_ID } from '../libs/utils/Const';
import ReactDOM from 'react-dom';
import './MenuNavbar.css';

class CategoriesMenu extends Component {
    // Función para buscar artículos con las categorías especificadas
    subCategorySearch = async (valor, section, subCategory = null) => {
        const { setIdCategory, setLocation, itemsReducer: { searchByCategories }, setNextPage, setItemsFilters, setItemsCategoryFilters } = this.props;
        setIdCategory(valor);
        setNextPage(0);
        await setLocation('menuCategories');
        await setItemsFilters({});
        let dataFilterCat = {
            cateogry: valor,
            page: 0,
            view: section,
            subCategory: subCategory
        }
        await setItemsCategoryFilters(dataFilterCat);
        await searchByCategories(valor, 0, section, subCategory);
    };

    searchMarcaRes = async (valor) => {
        const {setCategory,setIdCategory, setLocation,itemsReducer: {searchByCategories},setNextPage, itemsReducer, setItemsFilters, setItemsCategoryFilters} = this.props;
        setIdCategory(valor);
        setNextPage(0);
        await setLocation('menuCategories');
        await setItemsFilters({});
        let dataFilterCat = {
            cateogry: valor,
            page: 0,
            view: 'marca',
            subCategory: ''
        }
        await setItemsCategoryFilters(dataFilterCat);
        await searchByCategories(valor,0,'marca');
    };

    searchMarca = async (val) => {
        const { itemsReducer:{ searchByKey }, setLocation, setNewFilters } = this.props;
        await setLocation(null); // Necesario para volver a solicitar filtros de búsqueda nueva
        await setLocation('brandMenu');
        await setNewFilters({brand: val});
        await searchByKey();
    };

    // Región para pintar las subcategories (Nivel 1)
    renderSubCategories1 = (category, index1) => {
        // Se buscan los elementos HTML de la categoría padre
        const dropdownIcon = document.getElementById('dropIcon' + index1);
        const divSSubCat = document.getElementById('Category' + index1);
        const divSSubCat2 = category?.categoriesL2?.map((categoriesL, index2) => { 
            if(categoriesL?.categoriesL3.length > 0) {
                return (
                <>
                    <div class="row p-2 container-menu">
                        <div class="col-9 pr-0">
                            <span class="d-block" type="button" data-dismiss="modal" aria-label="Close" style={{cursor: 'pointer'}} onClick={() => this.subCategorySearch(categoriesL.Code, "productos", category)} >{this.capitalizeFirstLetter(categoriesL.U_name)}</span>
                        </div>
                        <div class="col-3 text-right" onClick={() => this.renderSubCategories2(categoriesL, index1, index2)} >
                            <img id={"dropIcon" + index1 + "-" + index2} src={config.navBar.dropdownMore} style={{ width: "15px", cursor: "pointer" }}/>
                        </div>
                    </div>
                    <hr className='m-0' />
                    <div class="pl-4" id={"subCat"+ index1 + "-" + index2} style={{display:'none'}}></div>
                </>
                )
            } else {
                return (
                    <>
                        <div className='container-menu p-2 ml-3'>
                            <span class="d-block" type="button" data-dismiss="modal" aria-label="Close" style={{cursor: 'pointer'}} onClick={() => this.subCategorySearch(category.Code, "productos")}>{this.capitalizeFirstLetter(categoriesL.U_name)}</span>
                        </div>
                        <hr className='m-0' />
                    </>
                    
                )
            }
        });
        ReactDOM.render(divSSubCat2, divSSubCat);
        // Dependiendo el estado anterior se abre o se cierra la sub-categoría
        if (divSSubCat.style.display === 'none') { 
            divSSubCat.style.display = "block";
            dropdownIcon.src = config.navBar.dropdownHide;
        } else {
            divSSubCat.style.display = "none";
            dropdownIcon.src = config.navBar.dropdownMore;
        }
    }

    // Región para pintar las subcategories (Nivel 2)
    renderSubCategories2 = (categoriesL, index1, index2) => {
        // Se buscan los elementos HTML de la categoría padre
        const dropdownIcon = document.getElementById('dropIcon' + index1 + "-" + index2);
        const divSubCat = document.getElementById('subCat'+ index1 + "-" + index2);
        const divSubCat2 = categoriesL?.categoriesL3?.map((categorieL3) => { 
            return (
                <>
                    <div className='p-2 container-menu'>
                        <span class="d-block" type="button" data-dismiss="modal" aria-label="Close" style={{cursor: 'pointer'}} onClick={() => this.subCategorySearch(categorieL3.U_name,"productos2", categorieL3.U_name)}>{this.capitalizeFirstLetter(categorieL3.U_name)}</span>
                    </div>
                    <hr className='m-0' />
                </>
            )
        });
        ReactDOM.render(divSubCat2, divSubCat);
        // Dependiendo el estado anterior se abre o se cierra la sub-categoría
        if (divSubCat.style.display === 'none') { 
            divSubCat.style.display = "block";
            dropdownIcon.src = config.navBar.dropdownHide;
        } else {
            divSubCat.style.display = "none";
            dropdownIcon.src = config.navBar.dropdownMore;
        }
    }    

    // Cambiar texto a Primera mayúscula y resto minúscula
    capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    // Pintar el menú
    render() {
        const { configReducer: { idMarcasMenu, idCategoriesMenu } } = this.props;
        return (
            <div class="modal left fade shadow" id="offCanvasCategories" tabindex="1" role="dialog" aria-labelledby="offcanvas" data-backdrop="true" style={{position: 'fixed', zIndex: '2024', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
            <div class="modal-dialog menuModal" role="document" style={{ position: "fixed", background: 'white' ,margin: "auto", maxWidth: "300px", height: "100%", left: 0, marginLeft: "0px", boxShadow: "0 3px 6px 1px var(--ck-color-shadow-drop-active)", width: "100%" }}>
                <div class="modal-content" style={{ height: "100%", overflowY: "auto", border: 'none', borderRadius: 0 }}>
                    <div class="modal-header-close black-bar-header mt-2">
                        <div class="container-fluid p-0">
                            <div class="row p-0">
                                <div class="col-9">
                                    <img className = "img-fluid" src={config.navBar.icon} style={{maxWidth: 250, maxHeight: 95}} alt="logo-empresa"/>
                                </div>
                                <div class="col-3 align-items-end">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='menu-categoris' style={{marginTop: '20px'}}>
                        <Dropdown className='container p-0'>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                <img className = "img-fluid" src={config.icons.tools} style={{maxWidth: 20, maxHeight: 20}} alt="logo-empresa"/> Departamentos
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div class="m-2 pl-3">
                                    {idCategoriesMenu?.map((category, index) => {
                                        if(category?.categoriesL2.length > 0){
                                            return (
                                                <>
                                                    <div class="row p-2 container-menu">
                                                        <div class="col-9 pr-0">
                                                            <span class="d-block" type="button" data-dismiss="modal" aria-label="Close" style={{cursor: 'pointer'}} onClick={() => this.subCategorySearch(category.Code, "productos")}>{this.capitalizeFirstLetter(category.U_name)}</span> 
                                                        </div>
                                                        <div class="col-3 text-right" onClick={()=> this.renderSubCategories1(category,index)} >
                                                            <img id={"dropIcon" + index} src={config.navBar.dropdownMore} style={{ width: "15px", cursor: "pointer" }}/>
                                                        </div>
                                                    </div>
                                                    <hr class="m-0"/>
                                                    <div class="pl-3" id={"Category"+index} style={{display: 'none'}}> </div>
                                                </>
                                            );
                                        }else{
                                            return (
                                                <>
                                                    <div class="row p-2 container-menu">
                                                        <div class="col-9 pr-0">
                                                            <span class="d-block" type="button" data-dismiss="modal" aria-label="Close" style={{cursor: 'pointer'}} onClick={() => this.subCategorySearch(category.Code, "productos")}>{this.capitalizeFirstLetter(category.U_name)}</span> 
                                                        </div>
                                                    </div>
                                                    <hr class="m-0"/>
                                                    <div class="pl-3" id={"Category"+index} style={{display: 'none'}}> </div>
                                                </>
                                            );
                                        } 
                                    })}
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div style={{marginTop: '10px'}}>
                        <Dropdown className='container p-0'>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                <img className = "img-fluid" src={config.icons.tool} style={{maxWidth: 20, maxHeight: 20}} alt="logo-empresa"/> Marcas
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div class="m-2" style={{width: '263px'}}>
                                    {idMarcasMenu?.map((marcasL, index) => {
                                        return (
                                            <>
                                                <div class="p-2 container-menu">
                                                    <div class="col-9 pr-0">
                                                        <span class="d-block" type="button" data-dismiss="modal" aria-label="Close" style={{cursor: 'pointer'}} onClick={() => this.searchMarca(marcasL.Code)}>{this.capitalizeFirstLetter(marcasL.U_name)}</span> 
                                                    </div>
                                                </div>
                                                <hr className='m-0'/>
                                                <div class="pl-3" id={"Category"+index} style={{display: 'none'}}> </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

const mapStateToProps = store => {
    return {
       itemsReducer: store.ItemsReducer,
       sessionReducer: store.SessionReducer,
       configReducer: store.ConfigReducer,
       shoppingCartReducer: store.ShoppingCartReducer,
       notificationReducer: store.NotificationReducer,
    };
 };
 
const mapDispatchToProps = dispatch => {
    return {
       enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
       setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
       setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }), 
       setRole: value => dispatch({ type: DISPATCH_ID.SESSION_SET_ROLE, value }),
       addNotificationReference: value => dispatch({ type: DISPATCH_ID.NOTIFICATION_SET_ALERT_REFERENCE, value }),
       setBusiness: value => dispatch({ type: DISPATCH_ID.SESSION_SET_BUSINESS_ID, value }),
       setToken: value => dispatch({ type: DISPATCH_ID.SESSION_SET_TOKEN, value }),
       setUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_USER, value }),
       setRememberUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_REMEMBER_USER, value }),
       setLogOutReference: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_LOG_OUT_REFERENCE, value }),
       setIdCategory: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value }),
       setNextPage: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value }),
       setLocation: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_LOCATION, value }),
       setItemsFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value }),
       setItemsCategoryFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_CATEGORY_FILTER, value }),
       setNewFilters: value => dispatch({ type: DISPATCH_ID.SET_NEW_FILTERS, value }),
    };
};
 
 export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoriesMenu);