import React, {Component} from 'react';
import {config, SERVICE_API,DISPATCH_ID} from "../libs/utils/Const";
import {ApiClient} from "../libs/apiClient/ApiClient";
import { ProgressBar } from "./index";
import $ from 'jquery';
import {connect} from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { PayPalButton } from "react-paypal-button-v2";

let paypalOptions = {
    clientId: config.paymentMethod.paypal.clienId,
    intent: 'capture',
    commit: true,
    currency : 'MXN',
};

let  buttonStyles = {
    layout: 'horizontal',
    shape: 'rect',
    color: 'blue'
};

let apiClient = ApiClient.getInstance();

class BillDetailModal extends Component {

    state = {
        paymentMethod: '',
        seleccion: '',
    }

    async addToShopingCart(){
        //Los PROPS los consigues de abajo
        const {order,notificationReducer: {showAlert},configReducer,enableSpinner} = this.props;
        //Lista de los productos del documento

        const bill = []; 

        for(var indice = 0; indice < order.body.length; indice++) {
            const arreglo = order.body[indice];
            let esDuplicado = false;
            for(var i = 0; i < bill.length; i++) {

                if (bill[i].ItemCode === arreglo.ItemCode) {
                    bill[i].Quantity += parseInt(arreglo.Quantity);
                    esDuplicado = true;
                    break;
                }
            }
            if (!esDuplicado) {
                bill.push(arreglo);
            }
        }

        let items = [];
        //Evadir el Articulo de envio
        bill.map((item) => {
            if(item.ItemCode !== "MANIOBRAS" && item.ItemCode !== "MANIOBRAS II"){
                items.push({ItemCode: item.ItemCode, quantity: parseInt(item.Quantity)});
            }
        });
        //Lista sin el Envio o Traslado
        //Comineza a pasarse al carro
        enableSpinner(true);
        //agregar al carro directo con sql
        let responsesd = await apiClient.updateShoppingCartLocal(items);
        if(responsesd.status === 1){
            $('#billModal').modal('hide');
            showAlert({type: 'success', message: ' Agregado a carrito correctamente ', timeOut: 0});
            configReducer.history.goShoppingCart();
        }
        enableSpinner(false);
    }

    renderPaymentMethod = (total = 999999 ) => {
        const { notificationReducer: {showAlert}} = this.props;
        
        let total1 = parseFloat(total).toFixed(2);
        if (total) return <div>
            <PayPalButton
                options={paypalOptions}
                style={buttonStyles}
                amount={total1}
                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                onSuccess={(details, data) => {
                    this.sendOrder();
                    showAlert({type: 'success', message: 'Se esta procesando tu pago'})

                }}
                onError={(details) => {
                    showAlert({type: 'error', message: 'Ha ocurrido un inconveniente con su pago contante a su asesor de ventas'})
                }}
                onCancel={(details, data) => {
                    showAlert({type: 'warning', message: 'Se ha cerrado la página o bien has cancelado el pago'})

                }}
            />
        </div>
    };

    // Creación del pago
    sendOrder = async () => {
        const { seleccion } = this.state
        // Obtenermos datos para el pago y la navegación
        const {order,enableSpinner, notificationReducer: {showAlert},configReducer} = this.props;

        // Cargamos el usuario
        let CurrentUser = localStorage.getItem(config.general.localStorageNamed + 'CurrentUser');
        CurrentUser = JSON.parse(CurrentUser);

        // Preparemos los datos del pagp
        let data = {
            CardCode: CurrentUser.CardCode,
            DocEntry: order.header.DocEntry,
            DocTotal: order.header.DocTotal,
            objType: '24',
            seleccion: seleccion,
        }

        // Hacemos la petición del pago y esperar la respuesta 
        await apiClient.createPayment(data).then( (response) => {
            enableSpinner(false);
            if(response.status === 1){
                showAlert({type: 'success', message: response.message , timeOut: 8000});
            }else{
                showAlert({type: 'error', message: response.message , timeOut: 8000});
            }
            $('#paymentModal').modal('hide');
            $('#billModal').modal('hide');
            configReducer.history.goHome();
        });
    }

    openPayment = () => {
        $('#paymentModal').modal('show');
    };

    CloseModalTicket = () =>{
        $('#paymentModal').modal('hide');
    }

    selectOption = event => {
        this.setState({
            seleccion: event.nativeEvent.target.value || '',
        })
    };

    render() {
        const {order, guia,configReducer:{currency}} = this.props;
        const { seleccion } = this.state;

        return (
            <div className="modal fade" id="billModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{border: "none", textAlign: 'center'}}>
                <div className="modal-dialog modal-xl" role="document" style={{margin: '1.75rem auto', maxWidth:'90%'}}>
                    <div className="modal-content" style={{border:"1px solid #00000073"}}>
                        <div className="modal-header" style={{background: config.navBar.iconColor}}>
                            <h5 className="modal-title" style={{color: config.navBar.textColor2}}>Productos de la factura</h5>
                            <button type="button" style={{color: config.navBar.textColor2}} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3" style={{maxHeight:'700px', overflowY:'scroll'}}>
                            <ProgressBar guiaStatus = {guia} currentDocInfo = {Array.isArray(order.header) ? order.header[0] : order.header} type = {'OINV'}/>
                            <h3 className="text-center font-weight-bold">Detalle del documento</h3> 
                            <div>
                                <div className="text-left card" style={{ border: 'none'}}>
                                <div className="row" style={{ padding: 0, textAlign: 'center' }}>
                                    <div className="col-sm-12" style={{paddingLeft:0, paddingRight:0}}>
                                        <div className="container-fluid p-0">
                                                <div className="row font-weight-bold">
                                                    <div className="col-md-12 table-responsive" style={{overflowY:'scroll'}}>
                                                        <table className="table">
                                                            <thead>
                                                                <tr style={{backgroundColor: config.navBar.iconColor, color: "white"}}>
                                                                    <th className="text-center" style={{ whiteSpace: 'nowrap'}} scope="col-lg-auto">Clave</th>
                                                                    <th className="text-center" scope="col" style={{ whiteSpace: 'normal', width: '40%' }}>Nombre del Artículo</th>
                                                                    <th className="text-center" style={{ whiteSpace: 'nowrap'}} scope="col-lg-auto">Cantidad</th>
                                                                    <th className="text-center" style={{ whiteSpace: 'nowrap'}} scope="col-lg-auto">Precio <br/>sin IVA</th>
                                                                    <th className="text-center" style={{ whiteSpace: 'nowrap'}} scope="col-lg-auto">% <br/>descuento</th>
                                                                    <th className="text-center" style={{ whiteSpace: 'nowrap'}} scope="col-lg-auto">Precio con<br/> descuento</th>
                                                                    <th className="text-center" style={{ whiteSpace: 'nowrap' }} scope="col-lg-auto">Subtotal <br/>de línea</th>
                                                                    <th className="text-center" style={{ whiteSpace: 'nowrap'}} scope="col-lg-auto">Línea <br/>total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {order.body.map(item => {
                                                                    return (
                                                                        <tr key={item.ItemCode}>
                                                                            <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}} > {item.ItemCode} </td>
                                                                            <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '40%', padding: '0.3rem', fontSize: '15px' }}>{item.Dscription}</td>
                                                                            <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}} > {parseInt(item.Quantity)} </td>
                                                                            <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}} >
                                                                                <CurrencyFormat 
                                                                                    value={item.PriceBeforeDiscount}
                                                                                    displayType={'text'} 
                                                                                    thousandSeparator={true} 
                                                                                    fixedDecimalScale={true} 
                                                                                    decimalScale={2} 
                                                                                    prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod+' '}
                                                                                    suffix = {' ' + item.Currency}>
                                                                                </CurrencyFormat>   
                                                                            </td>
                                                                            <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}}>{parseInt(item.DiscPrcnt)}%</td>
                                                                            <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}}>
                                                                                <CurrencyFormat 
                                                                                    value={item.PriceBeforeDiscount - (item.PriceBeforeDiscount * (item.DiscPrcnt /100))} 
                                                                                    displayType={'text'} 
                                                                                    thousandSeparator={true} 
                                                                                    fixedDecimalScale={true} 
                                                                                    decimalScale={2} 
                                                                                    prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod+' '}
                                                                                    suffix = {' ' + item.Currency}
                                                                                    >
                                                                                </CurrencyFormat>   
                                                                            </td>
                                                                            <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}}>
                                                                                <CurrencyFormat 
                                                                                    value={(item.PriceBeforeDiscount - (item.PriceBeforeDiscount * (item.DiscPrcnt / 100))) * item.Quantity}
                                                                                    displayType={'text'} 
                                                                                    thousandSeparator={true} 
                                                                                    fixedDecimalScale={true} 
                                                                                    decimalScale={2} 
                                                                                    prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod + ' '}
                                                                                    suffix={' ' + item.Currency}>
                                                                                </CurrencyFormat>   
                                                                            </td>
                                                                            <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap'}} >
                                                                                <CurrencyFormat 
                                                                                   value={item.LineTotal * 1.16}
                                                                                    displayType={'text'} 
                                                                                    thousandSeparator={true} 
                                                                                    fixedDecimalScale={true} 
                                                                                    decimalScale={2} 
                                                                                    prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod+' '}
                                                                                    suffix = {' ' + currency.MainCurncy}>
                                                                                </CurrencyFormat>
                                                                            </td>                                                       
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3 className="text-center font-weight-bold mt-4">Totales</h3>
                            <div className="text-left card" style={{ border: 'none' }}>
                                <div className="row" style={{ padding: 10, textAlign: 'center' }}>
                                    <div className="col-sm-12">
                                        <div className="container p-0">
                                            <div className="row font-weight-bold">
                                                <div className="col-md-12 table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                                                                <th className="text-center" scope="col-lg-auto">Subtotal</th>
                                                                <th className="text-center" scope="col-lg-auto">IVA 16%</th>
                                                                <th className="text-center" scope="col-lg-auto">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-center">
                                                                    <CurrencyFormat
                                                                        value={order.body.reduce((acc, item) => acc + (item.PriceBeforeDiscount - (item.PriceBeforeDiscount * (item.DiscPrcnt / 100))) * item.Quantity, 0).toFixed(2)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        prefix={currency.DocCurrCod + ' '}
                                                                        suffix={' ' + config.general.currency}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <CurrencyFormat
                                                                        value={order.body.reduce((acc, item) => acc + (item.PriceBeforeDiscount - (item.PriceBeforeDiscount * (item.DiscPrcnt / 100))) * item.Quantity * 0.16, 0).toFixed(2)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        prefix={currency.DocCurrCod + ' '}
                                                                        suffix={' ' + config.general.currency}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <CurrencyFormat
                                                                        value={parseFloat(order.header.DocTotal).toFixed(2)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        prefix={currency.DocCurrCod + ' '}
                                                                        suffix={' ' + config.general.currency}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div style={{
                                marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                flexDirection: 'row'
                            }}>
                                <span className="font-weight-bold">Comentario:</span>
                                <span style={{ marginLeft: '10px' }}>{order?.header?.Comments || 'Sin comentario disponible'}</span>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* { order.header.DocStatus === "O" &&
                                <button type="button" className="btn text-white"  style={{background: "#FFC300"}} onClick={()=> this.openPayment()}>
                                    Pagar ahora
                                </button>
                            } */}
                            {/* <div className="btn text-white" data-dismiss="modal" style={{ background: config.navBar.iconColor }}>
                                <CurrencyFormat
                                    value={parseFloat(order.header.DocTotal).toFixed(2)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={currency.DocCurrCod + ' '}
                                    suffix={' ' + config.general.currency}
                                />
                            </div> */}
                            <button type="button" className="btn text-white"  style={{background: "#86C03F"}} onClick={()=> this.addToShopingCart()}>
                            <img src={config.icons.shoppingCartW}  alt="Agregar al carrito" style={{ width: '15px', height: '15px', marginRight: '5px' }}/>
                                &nbsp; Agregar al carrito
                            </button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
                {/* Modal de pago */}
                <div className="modal fade" id="paymentModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{border: "none", textAlign: 'center'}}>
                    <div className="modal-dialog modal-xl" role="document" style={{margin: '1.75rem auto'}}>
                        <div className="modal-content">
                            <div className="modal-header" style={{background: config.navBar.iconColor}}>
                                <h5 className="modal-title" style={{color: config.navBar.textColor2}}>Pago en Paypal</h5>
                                <button type="button" style={{color: config.navBar.textColor2}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{backgroundColor: '#E1F0FF'}}>
                                <div className="row justify-content-md-center my-3">
                                    <div className="col-md-6">
                                        <h2 className="card-title"  onClick={this.selectOption}>
                                            <input type="radio" value='04' name="paymentmethod" style={{marginRight: 5}}/>
                                            Tarjeta de crédito
                                        </h2>
                                    </div>
                                    <div className="col-md-6">
                                        <h2 className="card-title"  onClick={this.selectOption}>
                                            <input type="radio" value='28' name="paymentmethod" style={{marginRight: 5}}/>
                                            Tarjeta de débito
                                        </h2>
                                    </div>
                                </div>
                                { seleccion !== '' &&
                                    <div className="row justify-content-md-center my-4" >
                                        <div className="col-md-6">
                                            <div className="buttonPaymentMethod">
                                                {this.renderPaymentMethod(order.header.DocTotal)}                                    
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer" style={{backgroundColor: '#E1F0FF'}}>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setItemDetailsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value}),
        setItemsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEMS, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BillDetailModal);
