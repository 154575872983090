import React, { Component } from 'react';
import { SERVICE_RESPONSE, DISPATCH_ID, config, ROLES} from "../libs/utils/Const";
import $, { isNumeric } from 'jquery';
import {ApiClient} from "../libs/apiClient/ApiClient";
import {connect} from "react-redux";
let apiClient = ApiClient.getInstance();
// 117
class WarehouseModal extends Component{

    constructor(props) {
        super(props);
        this.state = {
            whsGeneralCode: '',
            whsGeneralName: '',
            // whsGeneralCode: '01',
            // whsGeneralName: 'Por Defecto',
            wshList: [],
            
        };
    };

    componentDidMount = async () => {
        this.fillOWHS();
    }

    fillOWHS = async () => {
        let response = await apiClient.getOWHSuUser();
        this.setState({
            wshList: response.data,
        })
    }

    // ########## REGION Cambiar almacen localmente (Visualmente) ##########
    changeLocalWhs = async (event) => {
        const {whsGeneralCode, whsGeneralName} = this.state;
        let newWsh = event.nativeEvent.target.value.split(",");
        this.setState({
            whsGeneralCode: newWsh[0],
            whsGeneralName: newWsh[1],
        })
    };
    //  ########## FIN REGION ##########

    // ########## REGION Cambiar almacen de manera general ##########
    changeWhs = async (event) => {
        const {setWhsGeneral, setWhsGeneralName, notificationReducer: {showAlert}, shoppingCartReducer: { items }, configReducer: {history},sessionReducer: { user },setRole,setToken,setUserSession,setRememberUser,enableSpinner} = this.props;
        const {whsGeneralCode, whsGeneralName} = this.state;

        if(this.state.whsGeneralCode.trim() === ''){
            showAlert({type: 'warning', message: "Selecciona un Almacen primero"});
        } else if (items.length > 0) {
            showAlert({type: 'warning', message: "Para cambiar de almacén no debe tener articulos en el carrito de compras."});
        } else{
            let button = document.querySelector('.selectOWHS');
            setWhsGeneral(whsGeneralCode);
            setWhsGeneralName(whsGeneralName);
            let usuario = {
                email: user.CardCode,
                password: user.U_Handel_Password,
                whs: whsGeneralCode,
            };
            let vendedor = {
                salesPerson: "0",
                firstName: "Vendedor",
                lastName: "Desde cliente"
            };
            
            let response = await apiClient.login(usuario);
            if (response.status === SERVICE_RESPONSE.SUCCESS) {
                showAlert({type: 'success', message: "Almacen actualizado", setTimeout:2000});
                let user = response.data.user;
                let token = response.data.token;
                let remember = true;
                
                localStorage.setItem(config.general.localStorageNamed + 'Token', JSON.stringify(token));
                localStorage.setItem(config.general.localStorageNamed + 'Role', ROLES.CLIENT);
                localStorage.setItem(config.general.localStorageNamed + 'CurrentUser', JSON.stringify(user));
                localStorage.setItem(config.general.localStorageNamed + 'Vendor', JSON.stringify(vendedor));
                localStorage.setItem(config.general.localStorageNamed + 'RememberUser', remember );
    
                setRole(ROLES.CLIENT);
                setToken(token);
                setUserSession(user);
                setRememberUser(remember);
    
                let responsesd = await apiClient.updateShoppingCartLocal(items);
    
                localStorage.removeItem(config.general.localStorageNamed + 'shoppingCart');
    
                // setTimeout(async ()=> {
                //     history.goHome();
                // },50);
            }
            
            // await apiClient.UpdateWOHSBack(whsGeneralCode);
            setTimeout(()=> {
                $('#warehouseModal').modal('hide');
                // history.goQuote();  // redirecciona a Items e inmediatamente al home al cambiar de almacen (Solucion practica para cuando estas en home)
                history.goHome();   // redirecciona al home al cambiar de almacen
                window.location.reload();
            }, 50);

            //   setTimeout(()=> {
            //     enableSpinner(false);
            // },6000);
        }
    };
    //  ########## FIN REGION ##########

    render() {
        const {wshList, whsGeneralCode, whsGeneralName} = this.state;
        const {itemsReducer, sessionReducer:{user}} = this.props;
        // this.fillOWHS();
        return (
            <div className="modal fade bd-example-modal-lg" id="warehouseModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{minWidth:300 ,maxWidth: 600, margin: '1.75rem auto', borderRadius: 15}}>
                    <div className="modal-content">

                        <div className="modal-header" style={{background: config.navBar.iconColor, color: '#FFFFFF'}}>
                            <h5 className="modal-title">SELECCIÓN DE ALMACÉN</h5>
                            <button type="button" style={{color:"#FFFFFF", opacity:1, margin:0, padding:0}} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label style={{fontWeight: "bold"}}>ELIJA EL ALMACÉN DEL CUAL SE CONSULTARAN LOS ARTICULOS</label>
                                </div>
                                <div className="form-row" style={{padding: "0px 50px 0px 50px"}}>
                                    <div className="form-group col-md-12" style={{display:"flex"}}>
                                        <label htmlFor="articulo" style={{alignSelf:"end", marginRight:15}}>ALMACENES</label>
                                        <select
                                            name="WareHouse"
                                            className="form-control text-left"
                                            onChange={(event) => { this.changeLocalWhs(event) }}
                                            value={[whsGeneralCode, whsGeneralName]}
                                            style={{ textAlign: 'center', height: 30, padding: 0 }}>
                                            <option  > Seleccione almacen</option>
                                            {wshList!== undefined && wshList.length > 0 &&
                                                wshList.map((whs) => {
                                                    if(user.wareHouse == whs.WhsCode){    
                                                        return <option value={[whs.WhsCode, whs.WhsName]} > {`${whs.WhsName} - Por defecto`}</option>
                                                    }else{
                                                        return <option value={[whs.WhsCode, whs.WhsName]} >{whs.WhsName}</option>
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                </form>
                        </div>
                        <div className="modal-footer">
                            <label htmlFor="cantidad" style={{marginRight:"auto"}}>ALMACEN SELECCIONADO {itemsReducer.whsGeneralName || 'POR DEFECTO: CEDIS'}</label>
                            <button type="button" className="btn selectOWHS" id = 'selectOWHS'  style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}} onClick={this.changeWhs}> Seleccionar </button>
                        </div>
                    </div>
                </div>
            </div>
        );  
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        sessionReducer: store.SessionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setWhsGeneral: value => dispatch({type: DISPATCH_ID.ITEMS_SET_WHSGENERAL, value}),
        setWhsGeneralName: value => dispatch({type: DISPATCH_ID.ITEMS_SET_WHSGENERAL_NAME, value}),
        setRole: value => dispatch({type: DISPATCH_ID.SESSION_SET_ROLE, value}),
        setToken: value => dispatch({type: DISPATCH_ID.SESSION_SET_TOKEN, value}),
        setRememberUser: value =>  dispatch({type: DISPATCH_ID.SESSION_SET_REMEMBER_USER, value}),
        setUserSession: value => dispatch({type: DISPATCH_ID.SESSION_SET_USER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WarehouseModal);