import React, { Component } from 'react';
import { SERVICE_RESPONSE, DISPATCH_ID, config, ROLES} from "../libs/utils/Const";
import $, { isNumeric } from 'jquery';
import {ApiClient} from "../libs/apiClient/ApiClient";
import {connect} from "react-redux";
let apiClient = ApiClient.getInstance();
// 117
class ListAttribut extends Component{

    constructor(props) {
        super(props);
        this.state = {
            AttributeCode:'',
            ListAttribute : [],
            EditeAttributeBool : false,
            NewAttributeBool : false,
            NameAttrinute : '',
        };
    };

    componentDidMount = () => {
        const {NewListAttribute} = this.props;
    }

    render() {
        const  { ListAttribute, NewAttributeBool, NameAttrinute, EditeAttributeBool } = this.state;
        const {NewListAttribute} = this.props;
        return (
            <div className="modal fade bd-example-modal-lg" id="ListAttribut" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{minWidth:300 ,maxWidth: 600, margin: '1.75rem auto', borderRadius: 15}}>
                    <div className="modal-content">

                        <div className="modal-header" style={{background: config.navBar.iconColor, color: '#FFFFFF'}}>
                            <h5 className="modal-title">Lista de opciones</h5>
                            <button type="button" style={{color:"#FFFFFF", opacity:1, margin:0, padding:0}} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label style={{fontWeight: "bold"}}>SELECCIONE PROPIEDAD</label>
                                </div>
                                {/* Componente de agregar*/}
                                <div className="form-row" style={{padding: "0px 50px 0px 50px"}}>
                                <div className="form-group col-md-12" style={{display:"flex"}}>
                                        <label htmlFor="nombre"  >Nombre </label>
                                        <input type="text"
                                        id='atributoNombre'
                                        value={NameAttrinute ? NameAttrinute : ''}
                                        onKeyDown = {event => event.keyCode === 13 && this.SaveAttribute()}
                                        className="form-control" 
                                        autoComplete="off" 
                                        onChange={(event) => this.onChangeName(event.target.value)}
                                    />
                                    </div>
                                </div>
                                </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn" //disabled={!NewAttributeBool && !EditeAttributeBool} 
                            style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}} 
                              onClick={this.SaveAttribute}> Guardar </button>
                            {/* <button type="button" className="btn" style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}}
                            > Editar </button> */}
                        </div>
                    </div>
                </div>
            </div>
        );  
    }

    onChangeName(newName){
        const  { NameAttrinute } = this.state;
        if(NameAttrinute != newName){
            this.setState({
                NameAttrinute : newName,
            });
        }
        
    }
    SaveAttribute = async () =>{
        const {notificationReducer: {showAlert}, Table, recargar, fathers} = this.props;
        const  { ListAttribute, EditeAttributeBool, NewAttributeBool, NameAttrinute, AttributeCode } = this.state;
        // return;
            if(NameAttrinute){
                let result = await apiClient.NewListAttribute(Table , NameAttrinute, fathers);
                if(result.status == SERVICE_RESPONSE.SUCCESS){
                    showAlert({type: 'success', message: result.message, timeOut: 5000});
                    recargar();
                    setTimeout(() => {
                        $('#ListAttribut').modal('hide') ;
                    }, 200);
                }else{
                    showAlert({type: 'warning', message: result.message, timeOut: 5000});
                }
            }
        // }
    }
}
   

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        sessionReducer: store.SessionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setWhsGeneral: value => dispatch({type: DISPATCH_ID.ITEMS_SET_WHSGENERAL, value}),
        setWhsGeneralName: value => dispatch({type: DISPATCH_ID.ITEMS_SET_WHSGENERAL_NAME, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListAttribut);