/*  Constantes del Proyecto */

//FMB
//import {default as configDefault} from '../empresas/fmbSolutions';

//JACUZZI
//import { default as configDefault } from '../empresas/jacuzzi';

//CIG
//import { default as configDefault } from '../empresas/cig';

//RedHogar
// import { default as configDefault } from '../empresas/redHogar';

//Casa instrumental
// import { default as configDefault } from '../empresas/casa';

//Diasa
// import { default as configDefault } from '../empresas/diasa';

//IRCO
// import { default as configDefault } from '../empresas/irco';

//PEREZ DE LA MORA
import { default as configDefault } from '../empresas/perez';


export const config = configDefault;

export const licence = '2025-01-16';

export const FORMAT_NUMBER = {
    separador: ',', // separador para los miles
    sepDecimal: '.', // separador para los decimales
    formatear: function (num) {
        num += '';
        let splitStr = num.split('.');
        let splitLeft = splitStr[0];
        let splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        let regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
            splitLeft = splitLeft.replace(regx, "$1" + this.separador + "$2");
        }
        return this.simbol + splitLeft + splitRight;
    },
    new: function (num, simbol) {
        this.simbol = simbol || '';
        if (typeof num == 'undefined') {
            num = 0;
        }
        if (isNaN(num)) {
            return num;
        }
        return this.formatear(Number(num).toFixed(2));
    },
};

/* ===================== ACTIONS REDUCERS DISPATCH ==================== */

export const VIEW_NAME = {
    DASHBOARD_VIEW: 'dashboardView',
    LOGIN_VIEW: 'LoginView',
    RED_ZONE_VIEW: 'RedCompensasZoneView',
    TERMS_VIEW: 'TermsView',
    RED_ALIADO_VIEW: 'RedAliadoView',
    RESET_POINTS_VIEW: 'ResetPointsView',
    ITEMS_VIEW: 'itemsView',
    PRIVACY_VIEW: 'PrivacyView',
    ITEMS_DETAILS_VIEW: 'itemsDetailsView',
    SHOPPING_CART_VIEW: 'shoppingCartView',
    BACK_ORDER_VIEW: 'backOrderView',
    PROFILE_VIEW: 'profileView',
    ORDERS_VIEW: 'ordersView',
    CONTACT_US_VIEW: 'contactUsView',
    ABOUT_US_VIEW: 'aboutUsView',
    APPS_US_VIEW:'AppsView',
    CLAIM_US_VIEW: 'claim',
    CATALOGOS_US_VIEW: 'catalogos',
    POLITICS_US_VIEW: 'politics',
    QUESTIONS_US_VIEW: 'questions',
    SELECT_ADDRESS_VIEW: 'selectAddressView',
    EDIT_ADDRESS_VIEW: 'EditAddressView',
    VALIDATE_ORDER_VIEW: 'validateOrderView',
    CREATE_ORDER_VIEW: 'createOrderView',
    PROMO_VIEW: 'PromocionalesView',
    AUTORIZA_VIEW: 'AutorizacionesView',
    CANALMODERNO_VIEW: 'MiddlewareView',
    ITEMS_POLAR_VIEW: 'ItemsPolarView',
    ITEMS_BLANCA_VIEW: 'ItemsBlancaView',
    ITEMS_ROUTLET_VIEW: 'ItemsRoutletView',
    ITEMS_REFACCIONES_VIEW: 'ItemsRefaccionesView',
    ITEMS_FOOD_VIEW: 'ItemsFoodView',
    ITEMS_PAKING_VIEW: 'ItemsPakingView',
    ITEMS_FERRETERIA_VIEW: 'ItemsFerreteriaView',
    ADD_ADRESS_VIEW: 'AddAddress',
    ACCOUNT_DATA_VIEW: 'AccountData',
    JOB_VIEW: 'job',
    ABOUT_Red_VIEW: 'aboutRedView',
    SAFE_SHOPPING_VIEW: 'safeShoppingView',
    PAYMENT_METHOD_VIEW: 'paymentMethodView',
    DEVOLUTION_VIEW: 'devolutionView',
    SPECIAL_PRICES_VIEW: 'specialPricesView',
    NEW_BLOG_VIEW: 'newBlogView',
    SUCURSALES_VIEW: 'SucursalView',
    QUESTIONSHIPPING_US_VIEW: 'questionshipping',
    FREE_ADVICE: 'freeAdvice',
    ANALYTICS_VIEW: 'analisisView',
};

export const DISPATCH_ID = {
    /* Config */
    CONFIG_SET_SPINNER: 'CONFIG_SET_SPINNER',
    CONFIG_SET_HISTORY_REFERENCE: 'CONFIG_SET_HISTORY_REFERENCE',
    CONFIG_SET_LOG_OUT_REFERENCE: 'CONFIG_SET_LOG_OUT_REFERENCE',
    CONFIG_SET_CURRENCY: 'CONFIG_SET_CURRENCY',
    CONFIG_SET_BEFORE_UNLOAD_REFERENCE: 'CONFIG_SET_BEFORE_UNLOAD_REFERENCE',
    CONFIG_CLEAN_REDUCER: 'CONFIG_CLEAN_REDUCER',
    CONFIG_SET_PACKAGE: 'CONFIG_SET_PACKAGE',
    CONFIG_SET_PRELOAD_COMPONENTS:'CONFIG_SET_PRELOAD_COMPONENTS',
    CONFIG_SET_VIEW_NAVBAR:'CONFIG_SET_VIEW_NAVBAR',
    /* 117 */
    CONFIG_SET_ID_FAMILY_FILTERS: 'CONFIG_SET_ID_FAMILY_FILTERS',
    CONFIG_SET_ID_CATEGORY_FILTERS: 'CONFIG_SET_ID_CATEGORY_FILTERS',
    CONFIG_SET_FIELDSFILTER: 'CONFIG_SET_FIELDSFILTER',

    // ######### REGION MENU NAVBAR ############
    CONFIG_SET_CATEGORIES_MENU: 'CONFIG_SET_CATEGORIES_MENU',
    CONFIG_SET_MARCAS_MENU: 'CONFIG_SET_MARCAS_MENU',
    // ######### FIN REGION ############


    /* Notification */
    NOTIFICATION_SET_ALERT_REFERENCE: 'NOTIFICATION_SET_ALERT_REFERENCE',

    /* login */
    LOGIN_SET_USER: 'LOGIN_SET_USER',
    LOGIN_SET_NAME: 'LOGIN_SET_NAME',
    LOGIN_SET_RFC: 'LOGIN_SET_RFC',
    LOGIN_SET_PHONE: 'LOGIN_SET_PHONE',
    LOGIN_SET_PHONE_2: 'LOGIN_SET_PHONE_2',
    LOGIN_SET_PASSWORD: 'LOGIN_SET_PASSWORD',
    LOGIN_SET_VALIDATE_PASSWORD: 'LOGIN_SET_VALIDATE_PASSWORD',
    LOGIN_SET_ADDRESS: 'LOGIN_SET_ADDRESS',
    LOGIN_SET_STREET: 'LOGIN_SET_STREET',
    LOGIN_SET_SUBURB: 'LOGIN_SET_SUBURB',
    LOGIN_SET_CITY: 'LOGIN_SET_CITY',
    LOGIN_SET_CP: 'LOGIN_SET_CP',
    LOGIN_SET_COUNTRY: 'LOGIN_SET_COUNTRY',
    LOGIN_SET_STATE: 'LOGIN_SET_STATE',
    LOGIN_CLEAN_REDUCER: 'LOGIN_CLEAN_REDUCER',

    LOGIN_SET_CFDI: 'LOGIN_SET_CFDI',
    LOGIN_SET_BLOCK: 'LOGIN_SET_BLOCK',

    TRANSBANK_VALUE: 'TRANSBANK_VALUE',
    TRANSBANK_ACTION: 'TRANSBANK_ACTION',
    TRANSBANK_NAME: 'TRANSBANK_NAME',

    /* Data to billing */
    LOGIN_SET_ADDRESS_BILL: 'LOGIN_SET_ADDRESS_BILL',
    LOGIN_SET_STREET_BILL: 'LOGIN_SET_STREET_BILL',
    LOGIN_SET_SUBURB_BILL: 'LOGIN_SET_SUBURB_BILL',
    LOGIN_SET_CITY_BILL: 'LOGIN_SET_CITY_BILL',
    LOGIN_SET_CP_BILL: 'LOGIN_SET_CP_BILL',
    LOGIN_SET_COUNTRY_BILL: 'LOGIN_SET_COUNTRY_BILL',
    LOGIN_SET_STATE_BILL: 'LOGIN_SET_STATE_BILL',

    /* Session */
    SESSION_SET_ROLE: 'SESSION_SET_ROLE',
    SESSION_SET_USER: 'SESSION_SET_USER',
    SESSION_SET_ADDRESSES: 'SESSION_SET_ADDRESSES',
    SESSION_SET_PACKAGE: 'SESSION_SET_PACKAGE',
    SESSION_SET_TOKEN: 'SESSION_SET_TOKEN',
    SESSION_SET_REMEMBER_USER: 'SESSION_SET_REMEMBER_USER',
    SESSION_SET_BUSINESS_ID: 'SESSION_SET_BUSINESS_ID',
    SESSION_CLEAN_REDUCER: 'SESSION_CLEAN_REDUCER',

    /* ItemsReducer */
    ITEMS_SET_ITEMS: 'ITEMS_SET_ITEMS',
    ITEMS_SAVE_ITEMS_FILTER: 'ITEMS_SAVE_ITEMS_FILTER',
    ITEMS_SET_ITEMS2: 'ITEMS_SET_ITEMS2',
    ITEMS_SAVE_ITEMS_FILTER2: 'ITEMS_SAVE_ITEMS_FILTER2',
    ITEMS_SET_ITEM_DETAILS: 'ITEMS_SET_ITEM_DETAILS',
    ITEMS_SET_SEARCH: 'ITEMS_SET_SEARCH',
    ITEMS_SET_SEARCH_FILTER: 'ITEMS_SET_SEARCH_FILTER',
    ITEMS_SET_PAGINA: 'ITEMS_SET_PAGINA',
    ITEMS_SET_CATEGORY: 'ITEMS_SET_CATEGORY',
    ITEMS_SET_NAME_CATEGORY: 'ITEMS_SET_NAME_CATEGORY',
    ITEMS_SAVE_TAGS: 'ITEMS_SAVE_TAGS',
    ITEMS_OPEN_ITEM_DETAILS_REFERENCE: 'ITEMS_OPEN_ITEM_DETAILS_REFERENCE',
    ITEMS_UPDATE_FAVORITE_REFERENCE: 'ITEMS_UPDATE_FAVORITE_REFERENCE',
    ITEMS_ADD_SHOPPING_CART_REFERENCE: 'ITEMS_ADD_SHOPPING_CART_REFERENCE',
    ITEMS_ADD_BACK_ORDER_REFERENCE: 'ITEMS_ADD_BACK_ORDER_REFERENCE',
    ITEMS_DELETE_BACK_ORDER_REFERENCE: 'ITEMS_DELETE_BACK_ORDER_REFERENCE',
    ITEMS_DELETE_SHOPPING_CART_REFERENCE: 'ITEMS_DELETE_SHOPPING_CART_REFERENCE',
    ITEMS_SET_SEARCH_BY_KEY_REFERENCE: 'ITEMS_SET_SEARCH_BY_KEY_REFERENCE',
    ITEMS_SET_SEARCH_BY_KEY_PAGINACION_REFERENCE: 'ITEMS_SET_SEARCH_BY_KEY_PAGINACION_REFERENCE',
    ITEMS_SET_SEARCH_BY_CATEGORIES_REFERENCE: 'ITEMS_SET_SEARCH_BY_CATEGORIES_REFERENCE',
    ITEMS_SET_SEARCH_BY_CATEGORIES_HOME_REFERENCE: 'ITEMS_SET_SEARCH_BY_CATEGORIES_HOME_REFERENCE',
    ITEMS_SET_SEARCH_BY_CATEGORIES_HOME_REFERENCE2: 'ITEMS_SET_SEARCH_BY_CATEGORIES_HOME_REFERENCE2',
    ITEMS_SET_ITEMS1: 'ITEMS_SET_ITEMS1',
    ITEMS_SET_ITEMS2: 'ITEMS_SET_ITEMS2',
    ITEMS_SAVE_ITEMS_FILTER1: 'ITEMS_SAVE_ITEMS_FILTER1',
    ITEMS_SAVE_ITEMS_FILTER2: 'ITEMS_SAVE_ITEMS_FILTER2',
    ITEMS_SET_SEARCH_BY_DASH: 'ITEMS_SET_SEARCH_BY_DASH',
    ITEMS_SET_SEARCH_BY_DASH_OPTION : 'ITEMS_SET_SEARCH_BY_DASH_OPTION',
    ITEMS_CLEAN_REDUCER: 'ITEMS_CLEAN_REDUCER',
    ITEMS_SET_IDCATEGORY: 'ITEMS_SET_IDCATEGORY',
    ITEMS_SET_CATEGORIES: 'ITEMS_SET_CATEGORIES',
    ITEMS_SET_UNIQUE_FILTER: 'ITEMS_SET_UNIQUE_FILTER',
    ITEMS_SET_UNIQUE_CATEGORY_FILTER: 'ITEMS_SET_UNIQUE_CATEGORY_FILTER',
    ITEMS_SET_AUTO_COMPLETE:'ITEMS_SET_AUTO_COMPLETE',
    ITEMS_SET_SEARCH_CATEGORY:'ITEMS_SET_SEARCH_CATEGORY',
    ITEMS_SPECIAL_PRICES: 'ITEMS_SPECIAL_PRICES',
    ITEMS_SET_WHSGENERAL: 'ITEMS_SET_WHSGENERAL',
    ITEMS_SET_WHSGENERAL_NAME: 'ITEMS_SET_WHSGENERAL_NAME',
    ITEMS_SET_WHSGENERAL_TYPE: 'ITEMS_SET_WHSGENERAL_TYPE',
    ITEMS_OPEN_ITEM_DETAILS_EDIT: 'ITEMS_OPEN_ITEM_DETAILS_EDIT',
    
    // Paginacion 
    ITEMS_SET_TOTALROWS: 'ITEMS_SET_TOTALROWS',
    ITEMS_SET_NEXTPAGE: 'ITEMS_SET_NEXTPAGE',
    ITEMS_SET_LOCATION: 'ITEMS_SET_LOCATION',
    ITEMS_SET_AUX_LOCATION: 'ITEMS_SET_AUX_LOCATION',

    /* ShoppingCart */
    SHOPPING_CART_SAVE_CART: 'SHOPPING_CART_SAVE_CART',
    SHOPPING_CART_SAVE_BACK_ORDER: 'SHOPPING_CART_SAVE_BACK_ORDER',
    SHOPPING_CART_GET_SHOPPING_CART_REFERENCE: 'SHOPPING_CART_GET_SHOPPING_CART_REFERENCE',
    SHOPPING_CART_ITEMS_GIFT: 'SHOPPING_CART_ITEMS_GIFT',
    ITEMS_DEVOLUTION: 'ITEMS_DEVOLUTION',
    ITEMS_SET_SEARCH_LITE: 'ITEMS_SET_SEARCH_LITE',
    // Vista para pagina articulos por categoria
    ITEMS_SET_VIEWCATEGORY: 'ITEMS_SET_VIEWCATEGORY',

    ITEM_STOCK_REFRENCE: 'ITEM_STOCK_REFRENCE',
    ITEM_INFO_DISCOUNT: 'ITEM_INFO_DISCOUNT',
    
    // Guardar nueva version de filtros
    SET_NEW_FILTERS: 'SET_NEW_FILTERS',
    SET_PREV_SEARCH_VAL: 'SET_PREV_SEARCH_VAL',
    SET_FLAG_GET_FILTERS: 'SET_FLAG_GET_FILTERS',
};

export const SERVICE_API = {
    login: '/users/login',
    getBusinessPartnerInfo: '/users',
    loginTwoSteps: '/users/loginTwoSteps',
    loginTwoStepsMail: '/users/loginTwoStepsMail',
    subscribeUnsubscribe: '/newsletter/subscribeUnsubscribe',
    insertResetPoints: '/points/insertResetPoints',
    verifySubscription: '/newsletter',
    loginTwoSteps: '/users/loginTwoSteps',
    // loginTwoStepsMail: '/users/loginTwoStepsMail',
    resetPoints: '/points/resetPoints',
    register: '/users/create',
    validate: '/users/validate',
    updatePartner: '/users/updatePartner',
    updateAddresses: '/users/updateAddresses',
    sendJobMail: '/users/sendJobMail',
    sendPDFEmail: '/users/sendPDFEmail',
    getProfile: '/profile/',
    getCategories: '/categories/',
    getImage: '/item/getImage',
    getQR: '/getQR',
    getFile: '/item/getFile',
    searchByKey: '/items/searchByKey',
    searchByKeySpeed: '/items/searchByKeySpeed',
    searchByCategory: '/items/searchByCategory',
    openItemDetails: '/item',
    updateFavorite: '/favorite/update',
    updateShoppingCart: '/shoppingCart/update',
    updateBackOrder: '/backOrder/update',
    updateShoppingCarLocal: '/shoppingCart/updatelocal',
    deleteShoppingCart: '/shoppingCart/delete',
    deleteBackOrder: '/backOrder/delete',
    getShoppingCart: '/shoppingCart',
    createDocument: '/createDocument',
    AuthorizationModels: '/AuthorizationModels',
    SaveFileOV: '/saveFileOV',
    notifyOrders: '/users/UserNoite',
    getTaxEnvio: '/getTaxEnvio',
    getImageCategories: '/categories/getImage',
    getPolitics: '/categories/getPolitics',
    getBillspdf:'/bills/getBillspdf',
    getBillsxml:'/bills/getBillsxml',

    getItemsData:'/itemArticulo',
    getAttributesItems:'/itemArticulo/getAttributesItems',
    getFamilys:'/itemArticulo/getFamilys',
    getAllAttributes: '/itemArticulo/getAllAttributes',
    updateFamilies: '/itemArticulo/updateFamilies',
    updateFamilys:'/itemArticulo/updateFamilys',
    getCarruseles: '/getCarruseles',
    
    updateOnlyAttribut:'/itemArticulo/updateOnlyAttribut',
    deleteOnlyAttribut:'/itemArticulo/deleteOnlyAttribut',

    searchByKeyNew:'/itemArticulo/searchByKeyNew',
    getExistenceChild:'/itemArticulo/getExistenceChild',
    FatherToChilds:'/itemArticulo/FatherToChilds',
    
    newFather:'/itemArticulo/newFather',
    getFather:'/itemArticulo/getFather',
    updateFather:'/itemArticulo/updateFather',

    searchByKeyReturn:'/returns/searchByKeyReturn',

    getValuesTables:'/itemArticulo/getValuesTables',

    getFamily:'/itemArticulo/getFamily',
    getAttribute:'/itemArticulo/getAttribute',
    getListAttribute:'/itemArticulo/getListAttribute',
    UpdateOrderChilds:'/itemArticulo/UpdateOrderChilds',
    
    NewListAttribute: '/itemArticulo/NewListAttribute' ,
    
    
    // NewFamily: '/itemArticulo/NewFamily' ,
    // NewAttribute: '/itemArticulo/NewAttribute' ,

    // NewAttrubuteFamiy: '/itemArticulo/NewAttrubuteFamiy',
    
    // UpdateFamily: '/itemArticulo/UpdateFamily' ,
    // UpdateAttribute: '/itemArticulo/UpdateAttribute' ,
    // UpdateAttributeList: '/itemArticulo/UpdateAttributeList' ,

    // AddAttribute:'/itemArticulo/AddAttribute',

    UpdateItem: '/itemArticulo/UpdateItem',
    NewItem: '/itemArticulo/NewItem',

    getChildrens: '/itemArticulo/getChildrens',
    getChildrensEdit: '/itemArticulo/getChildrensEdit',

    removeShopping: '/removeShopping',
    getCountries: '/catalog/countries',
    getStates: '/catalog/states',
    getCP: '/catalog/cp',
    getCFDI: '/catalog/cfdi',

    getRegions: '/getRegions',
    getCities: '/getCities',
    getComunas: '/getComunas',

    getProductsHome: '/getProductsHome',
    getCategoriesHome: '/getCategoriesHome',
    getBannerHome:'/getBannerHome',

    getCFDI: '/catalog/cfdi',
    getInfoSelectAddress: '/catalog/infoSelectAddress',

    getPayment: '/transbank',

    getOrders: '/orders',
    getOrdersSeller: '/ordersSeller',
    getOrder: '/order',
    getOrdersDetails: '/order',
    getOneOrder: '/getOneOrder',
    getGeneralOrdersView: '/GeneralOrdersView',
    getFlete: '/item_flete',

    getDescuento: '/catalogs/getDescuento',

    getBillings: '/bill',
    getDataBillings: '/dataBill',
    getInvoices:'/getInvoices',
    getHistory: '/getHistory',
    getPDF: '/bills/getPDF/',
    getXML: '/bills/getXML/',
    getReturns: '/returns',
    searchBillByKey: '/returns/searchBillByKey',

    getProdutcsDashBoard: '/items/dashBoard/',

    getBanners:'/admin/getbanners',

    getQuotation: '/quotations',
    getDataQuotation: '/dataQuotation',
    createQuotation:'/createQuotations',

    getDelivery: '/deliverys',
    getDataDelivery: '/dataDelivery',
    createDelivery: '/createDelivery',

    getSaveds: '/saveds',
    getDataProduct: '/saved',
    getDataDocument: '/docuemntList',
    createSavedCart: '/createSavedCart',

    getPreliminarys: '/preliminarys',
    getDataPreliminarys: '/dataPreliminarys',
    createPreliminar: '/createPreliminary',
    getFiltros : '/filtros',
    getCurrency: '/Currency',
    getMarcas:'/getMarcas',
    getAparatos:'/getAparato',
    getRefaccion:'/getRefaccion',
    getFabricante:'/getFabricante',
    getMaterial:'/getMaterial',
    getItemsAdvance:'/searchAdvance',
    getProductsEspecial:'/getProductsEspecial',
    ProductsEspecial:'/ProductsEspecial',

    getCollections: '/collections',

    getOverdueOne: '/overdueOne',
    getOverdueTwo: '/overdueTwo',
    getOverdueThree: '/overdueThree',
    getOverdueFour: '/overdueFour',
    getOverdueFive: '/overdueFive',
    getOverdueSix: '/overdueSix',

    getDataProfiled: '/dataProfiled',

    getDataReumen: '/getDataResumen',

    sendClaim: '/claimReports',
    mercadoUpdateItem : '',

    PostItemMercadoLibre: '/PostItemMercadoLibre',
    searchItemsMl: '/getItemToML',
    searchPublishedItemsML: '/getPublishedItemsML',
    darValor: '/DarValor',

    updateItemML: '/UpdateItemML',
    postearItem: '/postPublication',

    getInfoCoupon: '/getCoupon',
    seller: '/seller/login',
    listClient: '/seller/listClient',
    searchClient: '/seller/searchClient',
    searchItems:'/Items',
    getPromo:'/Promocionales',
    getPromocion : '/getPromocion',
    getPromocionDisparador :'/getPromocionDisparador',
    getConditions : '/getConditions',
    getBonificacion: '/catalogs/getBonificacion',
    tipoDisparadorVol : '/tipoDisparadorvol',
    tipoDisparadorMonto : '/tipoDisparadormonto',
    getPriceList : '/catalog/getPriceList',
    getListPrice:'/itemArticulo/getListPriceByItemCode',
    //update Carrito guardado
    updateCart : '/updateCart',
    insertPromo : '/InsertPromo',
    updatePromo :'/UpdatePromo',
    aprobarPromo : '/AprobarPromo',
    activarPromo : '/ActivarPromo',
    //Update Partner
    detailsprofile: '/detailsprofile',
    //Autorizaciones
    getAutorizaciones : '/Autorizaciones',
    getAutorizacionesCanalModerno : '/AutorizacionesCanalModerno',
    getCreateCanalModerno : '/getCreateCanalModerno',
    createAutorization : '/createAutorization',
    rejectedAutorization : '/rejectedAutorization',
    getDatailsAuto: '/detailAuto',
    getDatailsAutoCanalModerno: '/detailAutoCanalModerno',
    loginousr : '/users/loginousr',
    updateAuthorization : '/updateAuthorization',
    //CANAL MODERNO
    validateMiddleware: '/validateProcess',
    middleware: '/process/',
    //canalModerno : '/canalModerno',
    //Conditions promocionales
    searchConditions : '/searchConditions',
    getPromocionales:'/Promo',
    getPackageStrore: '/catalog/packageStore',
    jobTypes: '/jobTypes',

    sendRaiting: '/raiting',
    getRaiting: '/getRaiting',
    AutoComplete: '/AutoComplete',
    sendData: '/sendData',

    sendRequestCardMail: '/users/sendRequestCardMail',

    getValidationSpecialPrices: '/getValidationSpecialPrices',
    setSpecialPricesStatus: '/updateSPStatus',

    getSpecialPrice: '/SpecialPrice',
    sendMessage : '/sendMessage',
    getAnalytics: '/Analitycs',
    Search: '/Search',
    getAllEmails: '/getAllEmails',
    createPayment: '/createPayment',
    getWshCode: '/catalogs/validateWhsCode',
    logOut: '/logOut',

    /*Almacenes*/
    getAllOWHS: '/catalog/getAllOWHS',
    getOWHSuUser: '/profile/getOWHSuUser',
    UpdateWOHSBack : '/profile/UpdateWOHSBack',
    /*Almacenes*/
    getCreditNotes: '/CreditNotes/getCreditNotes',
    getCreditNotesDetails: '/CreditNotes/getCreditNotesDetails',
    getDevolutionDetails: '/getDevolutionDetails',
    // Validar si existe U_Clave
    existClave: '/existClave',

    // Aclaraciones
    getReturns2: '/Returns/getReturns2',

    // Solicitud de devolución
    getReturnRequest: '/ReturnRequest/getReturnRequest',
    getReturnRequestDetails: '/ReturnRequest/getReturnRequestDetails',
    getRelationDocuments: '/catalogs/getRelationDocuments',

    // traer el stock de todos los almacenes
    getStockDetails: '/getStock/',
    sendFathers: '/sendFathers',

    //Analytics Tables
    getAnalyticsTables: '/getAnalyticsTables',
    getAnalyticsWhs: '/getAnalyticsWhs',

    //Consultar información de descuento
    getInfoDiscount: '/getInfoDiscount/',
};

export const SERVICE_RESPONSE = {
    SUCCESS: 1,
    EXPIRED_SESSION: -1,
    ERROR: 0,
};

export const ROLES = {
    PUBLIC: 'PUBLIC',
    CLIENT: 'CLIENT',
};

export const OBJ_TYPE = {
    ORDER: '23',
};