import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import {DISPATCH_ID, config} from "../libs/utils/Const";
import "./NavbarSearchIcon.css";
import {SearchItemsActions} from './index';


class NavbarSearchIcon extends Component {

    componentDidMount() {
        $(".ico-nav-shearch").click(function(){
            $(".form-buscar").toggle();
            $(".input-search-responsive").focus();
        });
    }

    search = async () => {
        const { itemsReducer: { searchByKey, search }, setPrevSearchVal, setLocation, setSearch, setNewFilters } = this.props;
        await setLocation(null); // Necesario para volver a solicitar filtros de búsqueda nueva
        await setLocation('searchGral');
        await setNewFilters({});
        await setPrevSearchVal({prevSearch: search});
        await searchByKey();
        $(".input-search-responsive").blur();
        setSearch(''); 
    };

    render() {
        const {itemsReducer, setSearch, icon, iconColor} = this.props;
        let elem = document.getElementById('search');
        return (
            <span>
            {/* <SearchItemsActions forwardRef={(ref) => this.searchItems = ref}/> */}
                <img
                    src={config.icons.search}
                    className="ico-nav-shearch d-md-none"
                    alt="navegación"
                    width="18"
                    height="18"
                />
            <span className="form-buscar" style={{display:'none' }}>
                <div className='for-buscar input-group pl-2 pr-2 d-md-none' style={{ zIndex: "2021", position: "absolute", left: "0vw", top: 90 }}>
                    <input type="text"
                        className="form-control text-center"
                        placeholder="Buscar"
                        value={itemsReducer.search}
                        onChange={(event) => setSearch(event.target.value)}
                        onKeyDown={event => event.keyCode === 13 && this.search() }
                        //onBlur={ () => $(".form-buscar").toggle()}
                    />
                    <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2" onClick={()=>(this.search() && $(".form-buscar").toggle())}>
                        <img src={config.icons.search} alt="search" style={{ width: '15px', height: '15px'}} />
                        </span>
                    </div>
                </div>
            </span>

        </span>

        );
    };

}


const mapStateToProps = store => {
    return {
        configReducer: store.ConfigReducer,
        itemsReducer: store.ItemsReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH, value}),
        setLocation:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_LOCATION, value}),
        setItemsFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value }),
        setItemsCategoryFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_CATEGORY_FILTER, value }),
        setSearchFilter: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_FILTER, value}),
        setNewFilters: value => dispatch({ type: DISPATCH_ID.SET_NEW_FILTERS, value }),
        setPrevSearchVal: value => dispatch({ type: DISPATCH_ID.SET_PREV_SEARCH_VAL, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavbarSearchIcon)

