import React from "react";
import moment from "moment";
import { config, SERVICE_API } from "../libs/utils/Const";
class NumeroLetra {
  Unidades = (num) => {
    switch (num) {
      case 1: return "UN";
      case 2: return "DOS";
      case 3: return "TRES";
      case 4: return "CUATRO";
      case 5: return "CINCO";
      case 6: return "SEIS";
      case 7: return "SIETE";
      case 8: return "OCHO";
      case 9: return "NUEVE";
      default: return "";
    }
  }

  Decenas = (num) => {
    let decena = Math.floor(Number(num) / 10);
    let unidad = Number(num) - (decena * 10);

    switch (decena) {
      case 1:
        switch (unidad) {
          case 0: return "DIEZ";
          case 1: return "ONCE";
          case 2: return "DOCE";
          case 3: return "TRECE";
          case 4: return "CATORCE";
          case 5: return "QUINCE";
          default: return "DIECI" + this.Unidades(unidad);
        }
      case 2:
        switch (unidad) {
          case 0: return "VEINTE";
          default: return "VEINTI" + this.Unidades(unidad);
        }
      case 3: return this.DecenasY("TREINTA", unidad);
      case 4: return this.DecenasY("CUARENTA", unidad);
      case 5: return this.DecenasY("CINCUENTA", unidad);
      case 6: return this.DecenasY("SESENTA", unidad);
      case 7: return this.DecenasY("SETENTA", unidad);
      case 8: return this.DecenasY("OCHENTA", unidad);
      case 9: return this.DecenasY("NOVENTA", unidad);
      case 0: return this.Unidades(unidad);
    }
  }

  DecenasY = (strSin, numUnidades) => {
    if (numUnidades > 0) return strSin + " Y " + this.Unidades(numUnidades)
    return strSin;
  }

  Centenas = (num) => {
    let centenas = Math.floor(Number(num) / 100);
    let decenas = Number(num) - (centenas * 100);

    switch (centenas) {
      case 1:
        if (decenas > 0) return "CIENTO " + this.Decenas(decenas);
        return "CIEN";
      case 2: return "DOSCIENTOS " + this.Decenas(decenas);
      case 3: return "TRESCIENTOS " + this.Decenas(decenas);
      case 4: return "CUATROCIENTOS " + this.Decenas(decenas);
      case 5: return "QUINIENTOS " + this.Decenas(decenas);
      case 6: return "SEISCIENTOS " + this.Decenas(decenas);
      case 7: return "SETECIENTOS " + this.Decenas(decenas);
      case 8: return "OCHOCIENTOS " + this.Decenas(decenas);
      case 9: return "NOVECIENTOS " + this.Decenas(decenas);
      default: return this.Decenas(decenas);
    }
  }

  Seccion = (num, divisor, strSingular, strPlural) => {
    let cientos = Math.floor(Number(num) / divisor)
    let resto = Number(num) - (cientos * divisor)
    let letras = "";

    if (cientos > 0) {
      if (cientos > 1) letras = this.Centenas(cientos) + " " + strPlural;
      else letras = strSingular;
    }
    if (resto > 0) letras += "";

    return letras;
  }

  Miles = (num) => {
    let divisor = 1000;
    let cientos = Math.floor(Number(num) / divisor)
    let resto = Number(num) - (cientos * divisor)

    let strMiles = this.Seccion(num, divisor, "UN MIL", "MIL");
    let strCentenas = this.Centenas(resto);

    if (strMiles === "") return strCentenas;
    return strMiles + " " + strCentenas;
  }

  Millones = (num) => {
    let divisor = 1000000;
    let cientos = Math.floor(Number(num) / divisor)
    let resto = Number(num) - (cientos * divisor)

    let strMillones = this.Seccion(Number(num), divisor, "UN MILLON ", "MILLONES ");
    let strMiles = this.Miles(resto);

    if (strMillones === "") return strMiles;
    return strMillones + " " + strMiles;
  }

  NumeroALetras = (moneda, num,) => {
    let that = this;
    let monedaSingular = "";
    let monedaPlural = "";

    if (moneda === "MXN") {
      monedaPlural = "PESOS";
      monedaSingular = "PESO";
    }

    if (moneda === "USD") {
      monedaPlural = "DOLARES";
      monedaSingular = "DOLAR";
    }

    if (moneda === "EUR") {
      monedaPlural = "EUROS";
      monedaSingular = "EURO";
    }

    if (moneda === "CAD") {
      monedaPlural = "DOLARES CANADIENSES";
      monedaSingular = "DOLAR CANADIENSE";
    }

    let data = {
      numero: Number(num),
      enteros: Math.floor(Number(num)),
      centavos: (((Math.round(Number(num) * 100)) - (Math.floor(Number(num)) * 100))),
      letrasCentavos: "",
      letrasMonedaPlural: monedaPlural,//"PESOS", "Dólares", "Bolíletes", "etcs"
      letrasMonedaSingular: monedaSingular, //"PESO", "Dólar", "Bolilet", "etc"
      letrasMonedaCentavoPlural: "/100",
      letrasMonedaCentavoSingular: "/100"
    };

    data.letrasCentavos = " " + (function () {
      if (data.centavos === 1)
        return that.Millones(0) + data.centavos + data.letrasMonedaCentavoSingular;
      else
        return that.Millones(0) + data.centavos + data.letrasMonedaCentavoPlural;
    })();
    ;

    if (data.enteros === 0)
      return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
    if (data.enteros === 1)
      return this.Millones(data.enteros) + " " + data.letrasMonedaSingular + " " + data.letrasCentavos;
    else
      return this.Millones(data.enteros) + " " + data.letrasMonedaPlural + " " + data.letrasCentavos;

  }
}

class CollectPrintFormat extends React.Component {
  render() {
    const { collect } = this.props;
    const xml_data = collect?.U_FMB_XMLSELLADO;
    let cfdiCaComplemento = "";
    let valid_xml = false;
    let cadenaQR = "https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?&id=";
    valid_xml = xml_data?.hasOwnProperty("_attributes");
    if (valid_xml) {
      cfdiCaComplemento =
        "||" + xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.Version +
        "|" + xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.UUID +
        "|" + xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.FechaTimbrado +
        "|" + xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.RfcProvCertif +
        "||" + xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.SelloCFD +
        "|" + xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.NoCertificadoSAT + "||";

      cadenaQR +=
        xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.UUID + `&re=` +
        xml_data?.cfdiEmisor._attributes?.Rfc + `&rr=` +
        xml_data?.cfdiReceptor?._attributes?.Rfc + `&tt=` +
        xml_data?._attributes?.Total + `&fe=` +
        xml_data?._attributes?.Sello?.substring(xml_data?._attributes?.Sello?.length - 8, xml_data?._attributes?.Sello?.length);
    }

    const qrImageData = this.getQRImageBase64(cadenaQR);
    let sumSubTotal = 0;

    let numeroLetra = new NumeroLetra();
    let totalLetra = numeroLetra.NumeroALetras(collect?.DocCur || "MXN", Number(valid_xml ? xml_data?._attributes?.Total : 0));
    let invoices = collect?.paymenInfo || [];
    let auxComplementos = valid_xml ? (xml_data?.cfdiComplemento?.["pago20:Pagos"]?.["pago20:Pago"]?.["pago20:DoctoRelacionado"] || []) : [];
    auxComplementos = Array.isArray(auxComplementos) ? auxComplementos : [auxComplementos];
    const paymentDate = moment(collect?.TrsfrDate || collect?.DocDate);
    const paymentMethod = valid_xml ? (xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"].length > 0 ? xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"][0]._attributes?.FormaDePagoP : xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"]._attributes?.FormaDePagoP || "01") : collect?.PaymentMethod;
    const paymentAmount = Number(valid_xml ? (xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"].length > 0 ? xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"][0]._attributes?.Monto : xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"]._attributes?.Monto) : collect?.DocTotal || 0);

    const items = [{
      Quantity: 1,
      U_FMB_CLAVE_SAT: "84111506",
      U_FMB_UM_SAT: "ACT",
      Dscription: "Pago",
      U_FMB_TIPOFACTOR: "Tasa",
      unidad: "SER",
      Descuento: 0,
      Impuesto: 0,
      subTotal: 0,
      Price: 0,
      importeTraslado: 0,
    }];

    //PDF Cobranzas
    return (
      <div style={{ pageBreakBefore: "always", padding: "50px 100px" }}>
        <div className="d-flex flex-column">
          {/* Cabecera información */}
          <div className="row">
            <div className="col-3">
              <img style={{ width: "90%", height: "auto", maxWidth: 250 }} src={config.navBar.icon} alt="Logo" />
              <p className="text-center">
                PEREZ DE LA MORA S.A DE C.V <br /> <br />
                <strong> {valid_xml ? (xml_data?.cfdiEmisor._attributes?.Rfc || "") : "PMO0102023K2"}</strong>
              </p>
              <p style={{ fontSize: 10 }}>
                <strong> Régimen Fiscal: </strong >{valid_xml ? (xml_data?.cfdiEmisor?._attributes?.RegimenFiscal || "") : "601"} - {this.getRegimenFiscal(valid_xml ? (xml_data?.cfdiEmisor?._attributes?.RegimenFiscal || "") : "601")} <br />
                <span className="text-left">
                  <strong> Teléfono(s): </strong> 341 413 5599
                </span>
              </p>
            </div>
            <div className="col-9">
              <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "right" }}>
                <thead>
                  <tr>
                    <th className="text-right" style={{ fontSize: 10 }}> {valid_xml ? "CFDI de Pago" : "Documento de Pago"} </th>
                  </tr>
                </thead>
              </table>

              {/* Tabla información de cliente */}
              <table style={{ width: "100%", borderCollapse: "collapse", fontSize: 10 }}>
                <thead style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <tr>
                    <th className="text-center"> Serie </th>
                    <th className="text-center"> Folio </th>
                    <th className="text-center"> Número de Certificado </th>
                    <th className="text-center"> Fecha Emisión </th>
                    <th className="text-center"> Lugar de Expedición </th>
                  </tr>
                </thead>
                <tbody style={{ border: "1px solid" }}>
                  <tr style={{ fontSize: 10 }}>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?._attributes?.Serie || "") : collect?.SeriesName} </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?._attributes?.Folio || "") : collect?.DocNum} </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.NoCertificadoSAT || "") : "--"} </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?._attributes?.Fecha || "") : "--"} </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?._attributes?.LugarExpedicion || "") : "--"} </td>
                  </tr>
                </tbody>
              </table>
              <table style={{ width: "100%", borderCollapse: "collapse", fontSize: 10 }}>
                <thead style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <tr>
                    <th className="text-center"> Folio Fiscal </th>
                    <th className="text-center"> Certificado SAT </th>
                    <th className="text-center"> Fecha y Hora de Certificación </th>
                  </tr>
                </thead>
                <tbody style={{ border: "1px solid" }}>
                  <tr style={{ fontSize: 10 }}>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.UUID || "") : "--"} </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.NoCertificadoSAT || "") : "--"} </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.FechaTimbrado || "") : "--"} </td>
                  </tr>
                </tbody>
              </table>
              <table style={{ width: "100%", borderCollapse: "collapse", fontSize: 10 }}>
                <thead style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <tr>
                    <th className="text-center"> Cliente </th>
                    <th className="text-center"> Nombre del Cliente </th>
                    <th className="text-center"> Régimen Fiscal </th>
                    <th className="text-center"> Uso CFDI </th>
                  </tr>
                </thead>
                <tbody style={{ border: "1px solid" }}>
                  <tr style={{ fontSize: 10 }}>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {collect?.CardCode || ""} </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?.cfdiReceptor?._attributes?.Nombre || "") : collect?.CardCode} </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?.cfdiReceptor?._attributes?.RegimenFiscalReceptor || "") : ""} ‐ {this.getRegimenFiscal(xml_data?.cfdiReceptor?._attributes?.RegimenFiscalReceptor || "")} </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?.cfdiReceptor?._attributes?.UsoCFDI || "CP01") : ".."} - {valid_xml ? this.getUsoCFDI(xml_data?.cfdiReceptor?._attributes?.UsoCFDI || "CP01") : "--"} </td>
                  </tr>
                </tbody>
              </table>
              <table style={{ width: "100%", borderCollapse: "collapse", fontSize: 10 }}>
                <thead style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <tr>
                    <th className="text-center"> Tipo Documento </th>
                    <th className="text-center"> R.F.C. </th>
                    <th className="text-center"> Moneda </th>
                    <th className="text-center"> No. Doc </th>
                  </tr>
                </thead>
                <tbody style={{ border: "1px solid" }}>
                  <tr style={{ fontSize: 10 }}>
                    <td className="text-center" style={{ padding: "2px 5px" }}> Complemento de pago </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?.cfdiReceptor?._attributes?.Rfc || "") : collect.LicTradNum }  </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {valid_xml ? (xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"].length > 0 ? xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"][0]._attributes?.MonedaP : xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"]._attributes?.MonedaP) : collect.DocCur} </td>
                    <td className="text-center" style={{ padding: "2px 5px" }}> {collect?.DocNum} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* Cabecera información */}
          <div className="row">
            <div className="col-12">
              <table style={{ width: "100%", fontSize: 10 }} className="text-center mt-3">
                <thead style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <tr><th> DOMICILIO DE FACTURACIÓN </th></tr>
                </thead>
                <tbody style={{ border: "1px solid" }}>
                  <tr style={{ fontSize: 10 }}><td style={{ padding: "2px 5px" }}> {collect?.Address || "SIN DIRECCIÓN"} </td></tr>
                </tbody>
              </table>

              <table style={{ width: "100%", fontSize: 10 }} className="mt-3">
                <thead style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                  <tr>
                    <th className="text-center"> Cantidad </th>
                    <th className="text-center"> Unidad de medida </th>
                    <th className="text-center"> Código SAT </th>
                    <th className="text-center"> Código </th>
                    <th className="text-center"> Descripción </th>
                    <th className="text-center"> Precio U </th>
                    <th className="text-center"> Importe </th>
                  </tr>
                </thead>
                <tbody style={{ border: "1px solid" }}>
                  {items.map(item => {
                    let importe = (item.Quantity * item.Price);
                    let descuento = (parseFloat(item.Descuento) / 100) * parseFloat(importe);
                    let subTotal = importe - descuento;
                    return (
                      <tr style={{ fontSize: 10 }}>
                        <td className="text-center" style={{ padding: "2px 5px" }}> {Number(item.Quantity).toLocaleString("en-GB")} </td>
                        <td className="text-center" style={{ padding: "2px 5px" }}> {item.unidad || ""} </td>
                        <td className="text-center" style={{ padding: "2px 5px" }}> {item.U_FMB_UM_SAT} </td>
                        <td className="text-center" style={{ padding: "2px 5px" }}> -- </td>
                        <td className="text-center" style={{ padding: "2px 5px" }}> {item.Dscription} </td>
                        <td className="text-right" style={{ padding: "2px 5px" }}> {Number(item.Price).toLocaleString("en-US", { style: "currency", currency: "USD" })} </td>
                        <td className="text-right" style={{ padding: "2px 5px" }}> {Number(subTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })} </td>
                      </tr>);
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div class="row mt-2 p-0">
                <div class="col-9 p-0">
                  <p style={{ fontSize: 10 }}>
                    <span style={{ fontSize: 10 }}><strong> Comentarios: </strong></span> <br />
                    {totalLetra} {valid_xml ? (xml_data?._attributes?.Moneda || "") : (collect.DocCur || "")}
                  </p>
                </div>
                <div class="col-3 p-0 d-flex justify-content-end align-items-end">
                  <table>
                    <tbody>
                      <tr>
                        <td class="text-right" style={{ fontSize: 10, padding: "2px 5px" }}><strong> Subtotal </strong></td>
                        <td class="text-right" style={{ fontSize: 10, borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", padding: "2px 5px 2px 15px" }}>
                          {Number(Number(xml_data?._attributes?.SubTotal || sumSubTotal || 0).toFixed(2)).toLocaleString("en-US", { style: "currency", currency: "USD" })}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-right" style={{ fontSize: 10, padding: "2px 5px" }}><strong> Total </strong></td>
                        <td class="text-right" style={{ fontSize: 10, borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid", padding: "2px 5px 2px 15px" }}>
                          {Number(Number(xml_data?._attributes?.Total || 0).toFixed(2)).toLocaleString("en-US", { style: "currency", currency: "USD" })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row mt-2 p-0">
                <div class="col-12 p-0">
                  <p style={{ fontSize: 10 }}>
                    <span style={{ fontSize: 10 }}><strong> Detalles del pago: </strong></span> <br />
                    Forma de pago: {paymentMethod} - {this.getFormaPago(paymentMethod)} <br />
                    Banco: {collect?.BankCode || ""} <br />
                    Fecha: {paymentDate.isValid() ? paymentDate.format("DD/MM/YYYY") : "--/--/----"} <br />
                    Referencia: {collect?.TrsfrRef || ""} <br />
                  </p>
                </div>
              </div>

              <table style={{ width: "100%", fontSize: 10 }} className="mt-3">
                <thead style={{ backgroundColor: config.navBar.iconColor, color: "white", fontSize: 10 }}>
                  <tr>
                    <th class="text-center" colspan="4"> Complemento de Pago </th>
                  </tr>
                </thead>
                <tbody style={{ border: "1px solid" }}>
                  <tr style={{ fontSize: 10 }}>
                    <td class="text-left" style={{ padding: "2px 5px" }}><strong> Fecha de Pago: </strong> {valid_xml ? (xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"].length > 0 ? xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"][0]._attributes?.FechaPago : xml_data?.cfdiComplemento["pago20:Pagos"]["pago20:Pago"]._attributes?.FechaPago) : (paymentDate.isValid() ? paymentDate.format("DD/MM/YYYY[T]HH:mm:ss") : "--/--/----T--:--:--")} </td>
                    <td class="text-left" style={{ padding: "2px 5px" }}><strong> Forma de Pago: </strong> {paymentMethod} </td>
                    <td class="text-center" style={{ padding: "2px 5px" }}><strong> Monto: </strong> {paymentAmount.toFixed(2)} </td>
                    <td class="text-center" style={{ padding: "2px 5px" }}><strong> Número de Operación: </strong> {collect?.TransId} </td>
                  </tr>
                </tbody>
              </table>

              <table style={{ width: "100%", fontSize: 10 }} className="mt-3">
                <thead style={{ backgroundColor: config.navBar.iconColor, color: "white", fontSize: 10 }}>
                  <tr>
                    <th class="text-center" colspan="7"> Documentos Relacionados </th>
                  </tr>
                  <tr>
                    <th class="text-center"> Folio </th>
                    <th class="text-center"> Serie </th>
                    <th class="text-center"> Parcialidad </th>
                    <th class="text-center"> Saldo Anterior </th>
                    <th class="text-center"> Importe Pagado </th>
                    <th class="text-center"> Saldo Insoluto </th>
                    <th class="text-center"> Id Documento </th>
                  </tr>
                </thead>
                <tbody style={{ border: "1px solid" }}>
                  {auxComplementos.length > 0 ?
                    auxComplementos.map((DoctoRelacionadoObj) => {
                      const DoctoRelacionado = DoctoRelacionadoObj._attributes ?? null;
                      let Impuestos = DoctoRelacionadoObj["pago20:ImpuestosDR"]?.["pago20:TrasladosDR"]?.["pago20:TrasladoDR"] ?? []
                      Impuestos = Array.isArray(Impuestos) ? Impuestos : [Impuestos]
                      return (
                        <>
                          <tr style={{ fontSize: 10 }}>
                            <td class="text-center" style={{ padding: "2px 5px" }}> {DoctoRelacionado?.Folio ?? ""} </td>
                            <td class="text-center" style={{ padding: "2px 5px" }}> {DoctoRelacionado?.Serie ?? ""} </td>
                            <td class="text-center" style={{ padding: "2px 5px" }}> {DoctoRelacionado?.NumParcialidad ?? ""} </td>
                            <td class="text-center" style={{ padding: "2px 5px" }}> {DoctoRelacionado?.ImpSaldoAnt ?? ""} </td>
                            <td class="text-center" style={{ padding: "2px 5px" }}> {DoctoRelacionado?.ImpPagado ?? ""} </td>
                            <td class="text-center" style={{ padding: "2px 5px" }}> {(DoctoRelacionado?.ImpSaldoInsoluto ?? "")} </td>
                            <td class="text-center" style={{ padding: "2px 5px" }}> {DoctoRelacionado?.IdDocumento ?? ""} </td>
                          </tr>
                          {Impuestos.map((Impuesto2) => {
                            const taxAttributes = Impuesto2._attributes ?? null;
                            return (
                              <tr style={{ fontSize: 10 }}>
                                <td class="text-center" style={{ padding: "2px 5px" }}> </td>
                                <td class="text-center" style={{ padding: "2px 5px" }}> </td>
                                <td class="text-center" style={{ padding: "2px 5px" }}> BaseDR: {taxAttributes?.BaseDR ?? ""} </td>
                                <td class="text-center" style={{ padding: "2px 5px" }}> ImporteDR: {taxAttributes?.ImporteDR ?? ""} </td>
                                <td class="text-center" style={{ padding: "2px 5px" }}> ImpuestoDR: {taxAttributes?.ImpuestoDR ?? ""} </td>
                                <td class="text-center" style={{ padding: "2px 5px" }}> TasaOCuotaDR: {taxAttributes?.TasaOCuotaDR ?? ""} </td>
                                <td class="text-center" style={{ padding: "2px 5px" }}> TipoFactorDR: {taxAttributes?.TipoFactorDR ?? ""} </td>
                              </tr>
                            );
                          })}
                        </>);
                    }) :
                    invoices.map((doc) =>
                      <tr style={{ fontSize: 10 }}>
                        <td class="text-center" style={{ padding: "2px 5px" }}> {doc.DocNumFactura} </td>
                        <td class="text-center" style={{ padding: "2px 5px" }}> {doc.SeriesName} </td>
                        <td class="text-center" style={{ padding: "2px 5px" }}> {doc.parcialidad} </td>
                        <td class="text-center" style={{ padding: "2px 5px" }}> -- </td>
                        <td class="text-center" style={{ padding: "2px 5px" }}> {doc.SumApplied} </td>
                        <td class="text-center" style={{ padding: "2px 5px" }}> -- </td>
                        <td class="text-center" style={{ padding: "2px 5px" }}> {doc.U_UDF_UUID} </td>
                      </tr>)
                  }
                </tbody>
              </table>

              <table style={{ width: "100%", fontSize: 10 }} className="mt-3">
                <tbody style={{ border: "1px solid" }}>
                  <tr>
                    <td className="text-center" style={{ padding: "5px 25px" }} colSpan="3"><strong> Este documento es una representación impresa de un CFDI versión 4.0 </strong></td>
                  </tr>
                  <tr>
                    <td className="text-left" style={{ padding: "5px 25px" }} colSpan="2">
                      <strong> Sello digital autorizado: </strong>
                      <p style={{ fontSize: 10, maxWidth: "120px"}}>{(xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.SelloCFD || "").replace(/\+/g, "+ ")} </p>
                    </td>
                    <td style={{ padding: "5px 25px" }} rowSpan="2" className="text-right">
                      <img src={qrImageData} style={{ maxWidth: "120px", height: "auto", display: "inline-block" }} alt="QR Code" />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left" style={{ padding: "5px 25px" }} colSpan="2">
                      <strong> Sello digital SAT: </strong>
                      <p style={{ fontSize: 10, maxWidth: "120px" }}> {(xml_data?.cfdiComplemento?.tfdTimbreFiscalDigital?._attributes?.SelloSAT || "").replace(/\+/g, "+ ")} </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left" style={{ padding: "5px 25px" }} colSpan="3">
                      <strong> Cadena original: </strong>
                      <p style={{ fontSize: 10, maxWidth: "120px" }}>{(cfdiCaComplemento || "").replace(/\+/g, "+ ")}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div >
    );
  }

  getRegimenFiscal = (regimen) => {
    let descripcion = "";

    switch (regimen) {
      case "601":
        descripcion = "General de Ley Personas Morales";
        break;
      case "603":
        descripcion = "Personas Morales con Fines no Lucrativos";
        break;
      case "605":
        descripcion = "Sueldos y Salarios e Ingresos Asimilados a Salarios";
        break;
      case "606":
        descripcion = "Arrendamiento";
        break;
      case "607":
        descripcion = "Régimen de Enajenación o Adquisición de Bienes";
        break;
      case "608":
        descripcion = "Demás ingresos";
        break;
      case "610":
        descripcion = "Residentes en el Extranjero sin Establecimiento Permanente en México";
        break;
      case "611":
        descripcion = "Ingresos por Dividendos (socios y accionistas)";
        break;
      case "612":
        descripcion = "Personas Físicas con Actividades Empresariales y Profesionales";
        break;
      case "614":
        descripcion = "Ingresos por intereses";
        break;
      case "615":
        descripcion = "Régimen de los ingresos por obtención de premios";
        break;
      case "616":
        descripcion = "Sin obligaciones fiscales";
        break;
      case "620":
        descripcion = "Sociedades Cooperativas de Producción que optan por diferir sus ingresos";
        break;
      case "621":
        descripcion = "Incorporación Fiscal";
        break;
      case "622":
        descripcion = "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras";
        break;
      case "623":
        descripcion = "Opcional para Grupos de Sociedades";
        break;
      case "624":
        descripcion = "Coordinados";
        break;
      case "625":
        descripcion = "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas";
        break;
      case "626":
        descripcion = "Régimen Simplificado de Confianza";
        break;
      default:
        descripcion = "";
    }

    return descripcion;
  }

  getUsoCFDI = (UsoCFDI) => {
    let descripcion = "";

    switch (UsoCFDI) {
      case "G01":
        descripcion = "Adquisición de mercancías";
        break;
      case "G02":
        descripcion = "Devoluciones, descuentos o bonificaciones";
        break;
      case "G03":
        descripcion = "Gastos en general";
        break;
      case "I01":
        descripcion = "Construcciones";
        break;
      case "I02":
        descripcion = "Mobiliario y equipo de oficina por inversiones";
        break;
      case "I03":
        descripcion = "Equipo de transporte";
        break;
      case "I04":
        descripcion = "Equipo de computo y accesorios";
        break;
      case "I05":
        descripcion = "Dados, troqueles, moldes, matrices y herramental";
        break;
      case "I06":
        descripcion = "Comunicaciones telefónicas";
        break;
      case "I07":
        descripcion = "Comunicaciones satelitales";
        break;
      case "I08":
        descripcion = "Otra maquinaria y equipo";
        break;
      case "D01":
        descripcion = "Honorarios médicos, dentales y gastos hospitalarios";
        break;
      case "D02":
        descripcion = "Gastos médicos por incapacidad o discapacidad";
        break;
      case "D03":
        descripcion = "Gastos funerales";
        break;
      case "D04":
        descripcion = "Donativos";
        break;
      case "D05":
        descripcion = "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)";
        break;
      case "D06":
        descripcion = "Aportaciones voluntarias al SAR";
        break;
      case "D07":
        descripcion = "Primas por seguros de gastos médicos";
        break;
      case "D08":
        descripcion = "Gastos de transportación escolar obligatoria";
        break;
      case "D09":
        descripcion = "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones";
        break;
      case "D10":
        descripcion = "Pagos por servicios educativos (colegiaturas)";
        break;
      case "S01":
        descripcion = "Sin efectos fiscales";
        break;
      case "CP01":
        descripcion = "Pagos";
        break;
      case "CN01":
        descripcion = "Nómina";
        break;
      default:
        descripcion = "";
    }

    return descripcion;
  }

  getFormaPago = (FormaPago) => {
    let descripcion = "";

    switch (FormaPago) {
      case "01":
        descripcion = "Efectivo";
        break;
      case "02":
        descripcion = "Cheque nominativo";
        break;
      case "03":
        descripcion = "Transferencia electrónica de fondos";
        break;
      case "04":
        descripcion = "Tarjeta de crédito";
        break;
      case "05":
        descripcion = "Monedero electrónico";
        break;
      case "06":
        descripcion = "Dinero electrónico";
        break;
      case "08":
        descripcion = "Vales de despensa";
        break;
      case "12":
        descripcion = "Dación en pago";
        break;
      case "13":
        descripcion = "Pago por subrogación";
        break;
      case "14":
        descripcion = "Pago por consignación";
        break;
      case "15":
        descripcion = "Condonación";
        break;
      case "17":
        descripcion = "Compensación";
        break;
      case "23":
        descripcion = "Novación";
        break;
      case "24":
        descripcion = "Confusión";
        break;
      case "25":
        descripcion = "Remisión de deuda";
        break;
      case "26":
        descripcion = "Prescripción o caducidad";
        break;
      case "27":
        descripcion = "A satisfacción del acreedor";
        break;
      case "28":
        descripcion = "Tarjeta de débito";
        break;
      case "29":
        descripcion = "Tarjeta de servicios";
        break;
      case "30":
        descripcion = "Aplicación de anticipos";
        break;
      case "31":
        descripcion = "Intermediario pagos";
        break;
      case "99":
        descripcion = "Por definir";
        break;
      default:
        descripcion = "";
    }

    return descripcion;
  }

  getQRImageBase64 = (cadena) => {
    const qrImage = require("qr-image");
    const qrBuffer = qrImage.imageSync(cadena, { type: "png" });
    return `data:image/png;base64,${qrBuffer.toString("base64")}`;
  };
}

export default CollectPrintFormat;