import React, { Component } from 'react';
import {ItemDetailsModal, ItemsList, NavBar, Session, ShoppingCartList, CommentsModal, ItemsPromoModal} from "../components";
import { VIEW_NAME, ROLES, OBJ_TYPE, DISPATCH_ID, config, SERVICE_API,SERVICE_RESPONSE } from "../libs/utils/Const";
import {connect} from "react-redux";
import {ApiClient} from "../libs/apiClient/ApiClient";
import CurrencyFormat from 'react-currency-format';
import './SideBar.css';

import $ from 'jquery';

import { parseJSON } from 'jquery';
// import './shoppingCart.css'

let apiClient = ApiClient.getInstance();
// Definicion de un arreglo para el producto Flete
let responseFlete = {ItemCode:'',ItemName:'',Price:'0',PriceList:'0',PurchaseLimit:''};
// let modal = new Modal();
let discPrcnt = 0;


class SideBar extends Component {
    
    state = {
        promo: [],
        itemPromo:[],
        promociones:[],
        // whsCodes:[],
        seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
    }

    componentDidMount = async () => {
        $(document).ready(function(){
            $('[data-toggle="popover"]').popover();
        });
        // await this.cargarDatos();
    };

    cargarDatos = async () => {
        let user = localStorage.getItem(config.general.localStorageNamed + 'CurrentUser');
        user = JSON.parse(user) || {};
        let datos ={
            CardCode : user.CardCode,
            Canal : user.U_SYP_RICO_CCANAL || '',
            Region : user.U_SYP_RICO_CREGION || '',
            SubCanal : user.U_SYP_RICO_CSBCAN || ''
        }
        let newdatas = [] // await apiClient.getPromo(datos);
        if(newdatas.status !== 1){
           return;
        }
        if(newdatas.data.length > 0){
            this.setState({
                promociones: newdatas.data,
            });
            // await this.validateDisparador();
        }
        this.forceUpdate();
    };

    validateDisparador = async () => {
        const {promociones} = this.state;
        // const {enableSpinner, shoppingCartReducer: {items}} = this.props;
        const {enableSpinner, data ,refeshState} = this.props;
       //let data = items;
        let arrayPromoVol = [];
        let NotificacionIems = [];
        let itemsNotificacion = [];
        //##########---VALIDA ARTICULOS POR TIPO DE DISPARADOR----###################
        for (let index = 0; index < promociones.length; index++) {//----promociones
            const promo = promociones[index];
            let arrayItems = [];
            for (let index = 0; index < promo.length; index++) {//----Articulos por promoción
                const disparador = promo[index];
                if (disparador.fkTipoDisparador === 1) {  //---VOLUMEN--------------------------------
                    for (let index = 0; index < data.length; index++) {//----Articulos por promoción
                        const item = data[index];
                        if (item.ItemCode === disparador.idProducto) {
                            arrayItems.push(disparador);
                        }
                    };
                }else if (disparador.fkTipoDisparador === 2) { //--------------------------------MONTO--------------------------------

                }
            }
            if(arrayItems.length > 0){
                arrayPromoVol.push(arrayItems);

            }
        }
        if(arrayPromoVol.length > 0){
            let itemsPromo = [];

            let bonifNoti =[];
            for (let index = 0; index < arrayPromoVol.length; index++) {
                const promociones = arrayPromoVol[index];
                for (let index = 0; index < promociones.length; index++) {
                    const item = promociones[index];
                    itemsPromo.push(item);
                }
            }
            enableSpinner(true);
            bonifNoti = await apiClient.getBonificacion(itemsPromo);
            enableSpinner(false);
            for (let index = 0; index < itemsPromo.length; index++) {
                const element = itemsPromo[index];
                 const result = NotificacionIems.find(itemDisp => itemDisp.idProducto === element.idProducto);
                 if(!result){
                    NotificacionIems.push(element);
                 }
            }


            if(bonifNoti.data.length > 0){
                for (let index = 0; index < itemsPromo.length; index++) {
                    const item = itemsPromo[index];
                    let notificacion ={
                        itemDisparador: item.idProducto,
                        tipoVenta: item.fkSubTipo,
                        bonificacion : [],
                        idDisparador: item.disparador,
                        itemQuantity: item.cantidad,
                    }
                    for (let index = 0; index < bonifNoti.data.length; index++) {
                        const element = bonifNoti.data[index];
                        let itemsTemp = [];
                        for (let index = 0; index < element.length; index++) {
                            const bonificacion = element[index];
                            if(item.disparador === bonificacion.disparador && item.relacion === bonificacion.relacion){
                                itemsTemp.push(bonificacion);
                            }
                        }

                       let items = [];
                        let registrados = [];
                        for (let index = 0; index < itemsTemp.length; index++) {
                            const element = itemsTemp[index];
                             let include = registrados.includes(element.indexPack);
                             if(!include){
                                let arrayTemp = itemsTemp.filter(item => (item.indexPack === element.indexPack));
                                registrados.push(element.indexPack);
                                items.push(arrayTemp);
                             }


                        }   
                        
                        if(itemsTemp.length > 0){
                            notificacion.bonificacion = items;
                        }

                    }

                    itemsNotificacion.push(notificacion);
                }



                
                for (let index = 0; index < NotificacionIems.length; index++) {
                    const element = NotificacionIems[index];
                    let notificacion =[];
                    for (let index = 0; index < itemsNotificacion.length; index++) {
                        const promo = itemsNotificacion[index];
                        if(element.idProducto === promo.itemDisparador){
                            notificacion.push(promo);
                        }
                    }
                    element.notificacion = notificacion;
                }
                // if(filters.length > 0){
                //     NotificacionIems.push(filters);
                // }
            }
        }
        //##########---DISPARADOR TIPO VOLUMEN VALIDA SUBTIPO----###################
        let promoECD = [];
        let promoEDC = [];
        for (let index = 0; index < arrayPromoVol.length; index++) {
            const itemsPromo = arrayPromoVol[index];
            for (let index = 0; index < itemsPromo.length; index++) {
                const item = itemsPromo[index];
                if (item.fkSubTipo === 1) {  //---En la compra de--------------------------------
                    promoECD.push(itemsPromo);
                    break;
                } else if (item.fkSubTipo === 2) {//---En la compra decualquera de--------------------------------
                    promoEDC.push(itemsPromo);
                    break;
                }
            }
        }


        let itemsPromo = [];
         //##########--- EN LA COMPRA DE, VALIDA STOCK----###################
        for (let index = 0; index < promoECD.length; index++) {
            const promo = promoECD[index];
            for (let index = 0; index < promo.length; index++) {
                const element = promo[index];
                for (let index = 0; index < data.length; index++) {
                    const item = data[index];
                    if((parseInt(item.quantity,10) >= element.cantidad) && (item.ItemCode === element.idProducto)){
                        element.newQuantity = parseInt(parseInt(item.quantity,10) / element.cantidad);
                        itemsPromo.push(element);
                    }
                }
            }
        }
         //##########--- EN LA COMPRA DE CUALQUIERA DE, VALIDA STOCK  ----###################
        for (let index = 0; index < promoEDC.length; index++) {
            const promo = promoEDC[index];
            let sumTotal = 0;
            for (let index = 0; index < promo.length; index++) {
                const element = promo[index];

                for (let index = 0; index < data.length; index++) {
                    const item = data[index];

                     if(item.ItemCode === element.idProducto){
                        sumTotal+=parseInt(item.quantity,10);
                     }

                }
            }
            if(sumTotal >= promo[0].cantidad){
                for (let index = 0; index < promo.length; index++) {
                    const elementPromo = promo[index];
                    elementPromo.newQuantity = parseInt(sumTotal / promo[0].cantidad);
                    itemsPromo.push(elementPromo);
                }
            }
        }

        let arrayRelacional = [];
        let itemsBonificacion;
        if(itemsPromo.length > 0){
            enableSpinner(true);
                itemsBonificacion = await apiClient.getBonificacion(itemsPromo);
            enableSpinner(false);
            if(itemsBonificacion.data.length > 0){
                for (let index = 0; index < itemsPromo.length; index++) {
                    const itemsList = itemsPromo[index];
                    for (let index = 0; index < itemsBonificacion.data.length; index++) {
                        const bonificacionList = itemsBonificacion.data[index];

                        for (let index = 0; index < bonificacionList.length; index++) {
                            const element = bonificacionList[index];
                            if(itemsList.bonificacion === element.bonificacion && itemsList.relacion === element.relacion){
                                let promo ={
                                    itemDisparador: itemsList.idProducto,
                                    tipoDisparador: itemsList.fkSubTipo,
                                    bonificacion : element,
                                    tipoSeleccion: element.idTipoSeleccion,
                                    use : false,
                                    idDisparador: element.disparador,
                                    itemQuantity: itemsList.cantidad ,
                                    homeItemQuantity: itemsList.newQuantity,
                                }
                                arrayRelacional.push(promo);
                            }
                        }

                    }
                }

            }
        }
        refeshState(arrayRelacional,NotificacionIems);
    };

    changeQuantity = async (index, item, event) => {
        const { changeQuantity, sessionReducer: {user}, shoppingCartReducer: { items }, setShoppingCart , notificationReducer: { showAlert } } = this.props;
        let newQuantity = event.nativeEvent.target.value;

        let whsUser = item.WhsList.filter(item => item.WhsCode === user.wareHouse);
        whsUser.map(whs => {
            if (newQuantity < whs.OnHand){
                item.WhsCode = whs.WhsCode;
                item.OnHandPrincipal = whs.OnHand;
                setShoppingCart(item);
            }
        });
        changeQuantity(index, item, newQuantity || 1, true); // add
        this.validateDisparador();
    };


    //BOTON AGREGAR AL CARRITO

    changLocalQuantity = (index, item, event) => {
        const { shoppingCartReducer: { items } ,sessionReducer: {user}, setShoppingCart , notificationReducer: { showAlert }} = this.props;
        //const {whsCodes} = this.state;
        let newQuantity = event.nativeEvent.target.value;
        let onHand = parseInt(item.OnHand);

        items.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                //if(newQuantity >= 1 || newQuantity == '' ){
                    itemFilter.quantity = newQuantity || 1;
                // }
            }
        });
            
            // Validacion para regresar a almacen original al bajar el onHand(Stock) principal #Ulises-UPP
            // item.WhsList.map(whs => {
            //     //Compara que el item con la lista de almacenes sea la correcta
            //     if (whs.ItemCode === item.ItemCode){
            //         //Compara que el codigo del almacen de la lista con el codigo del almacen del usuario sea el mismo
            //         if(whs.WhsCode === user.wareHouse){
            //             //compara que sea mayor o igualbtn text-white btn-lg div-aplicaBtn--button el stock del almacen principal que la cantidad requerida
            //             if( parseInt(whs.OnHand) >= parseInt(item.quantity)){
            //                 item.WhsCode = whs.WhsCode;
            //                 item.OnHandPrincipal = whs.OnHand;
            //             }
            //         }
            // }
            // });
            // color success
            // document.getElementById(item.ItemCode).style.backgroundColor = "#28a745";
            // document.getElementById(item.ItemCode).style.borderColor = "#28a745";
        setShoppingCart(items);
        // }
        // else {
        //      return;
        // }
    }
    
    //Evento para capturar los valores de los campos de almacen
    changeLocalWhs = async (index, item, event) => {
        const { changeQuantity, shoppingCartReducer: { items }, setShoppingCart , notificationReducer: { showAlert } } = this.props;
        let newWsh = event.nativeEvent.target.value.split(",");
        item.WhsCode = newWsh[0];
        item.OnHandPrincipal = parseInt(newWsh[1]);
        changeQuantity(index, item, item.quantity, true); // add
    };

    changeWhs = async (index, item) => {
        const {data, sessionReducer: {user}, changeQuantity, shoppingCartReducer: { items }, setShoppingCart , notificationReducer: { showAlert } ,enableSpinner} = this.props;
        let responseWhs = await apiClient.getWshCode(item.ItemCode);
        if(responseWhs.status === 0){
            return;
        }
        if(responseWhs.data.length === 0){
            return;
        }
        data.map(items => {
            if(items.ItemCode === item.ItemCode){
                items.WhsList = responseWhs.data.filter( 
                    Whs=> (
                        parseInt(Whs.OnHand) >= parseInt(item.quantity) || user.wareHouse == Whs.WhsCode
                    )
                );
                
                items.WhsList.map(
                    almacen => {
                        (almacen.WhsCode == item.WhsCode) ? item.OnHandPrincipal = almacen.OnHand : item.OnHandPrincipal = item.OnHandPrincipal
                    }
                );
            
            }
        });
        setShoppingCart(data);
    };


    validateQuantity = (index, item, e) => {
        if (!e.target.value) {
            let event = {
                nativeEvent: {
                    target: {
                        value: 1,
                    }
                }
            };
            this.changeQuantity(index, item, event);
        }
    };

    validateStock = item => {
        if ((item.quantity > parseInt(item.OnHand)) && (!!item.OnHand || parseInt(item.OnHand) === 0) && !item.BE) {            
            return (
                <div className='col-12' style={{ color: 'red', fontSize: 15, padding: 0 }}>
                    Articulo sujeto a disponibilidad de proveedores.
                </div>
            )
        } else if ((item.quantity > parseInt(item.OnHandPrincipal)) && (!!item.OnHandPrincipal || parseInt(item.OnHandPrincipal) === 0) && !item.BE) {            
            return (
                <div className='col-12' style={{ color: 'red', fontSize: 15, padding: 0 }}>
                    Se recomienda comprar desde otro almacen.
                </div>
            )
        }
    };

    validateStockBonificacion = item => {
        if ((item.cantidad > item.Stock) && !!item.Stock) {
            return (
                <div className='col-12 font-weight-bold ' style={{ color: 'red', fontSize: 15, padding: 0 }}>
                    El artículo no tiene stock para la bonificación.
                </div>
            )
        }
    };

    deleteItemLocal = async ({ item = null, deleteAll = null })=>{
        const {deleteItem} = this.props;
        await deleteItem({ item, deleteAll: false });
        this.validateDisparador();
    };

    handleCloseModal = () => {
        $('#offcanvasRight').modal('hide'); // Cerrar el modal usando jQuery
    }

    
    render() {
        const { data, view,deleteItemPromo, sessionReducer: {user }, itemsReducer: { addShoppingCart, deleteShoppingCart, openItemDetails }, shoppingCartReducer: { items }, itemPromociones, selectItemsPromo, itemsRegalos, itemsRegalosSelect, itemDisparador, boxes, itemsNotificacion, businessPartnerInfo, configReducer: { currency, history }, isDevolution } = this.props;
        const {seller} = this.state;
        // const {shoppingCartReducer: { items,backOrder,isDevolution }, itemsReducer: {addShoppingCart, deleteShoppingCart, searchLite}, configReducer:{currency, history}} = this.props;
        // let seller = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
        return (
            <div class="modal right fade"  style={{backgroundColor:'rgba(0, 0, 0, 0.424)'}} id="offcanvasRight" tabindex="-1" role="dialog" aria-labelledby="offcanvasRight" data-backdrop="true">
                
                <div class="modal-dialog" role="document" style= {{position: "fixed", margin: "auto", maxWidth: "320px", height: "100%", right: 0,marginRight:"-15px"}}>
                    <div class="modal-content" style={{height: "100%", overflowY: "auto",  paddingRight: "15px", borderRadius: 0, }}>
                        <div id="navContact" class="row justify-content-end p-2" style={{backgroundColor: config.navBar.iconColor, fontSize: "1.2rem"}}></div>
                        <div class="modal-header" style={{backgroundColor:'#F07C00'}}>
                        
                            <h4 className='ml-2' style={{color:'white'}}>Carrito de compras</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div class="modal-body scrollCart">
                            {!!items && items.map((item, index) => {      
                                const { ItemName, ItemCode, Discount, PicturName, PriceBeforeDisc, FinalPrice, flagM2, Currency, OnHandPrincipal, quantity } = item; 
                                const flagDiscount = Boolean(Discount && Discount > 0);                 
                                return (
                                    <div key={index} className='card' style={{ border: 'none', marginTop:"1rem"}}>                                            
                                        <div className='row'>
                                            <div className='col-4' style={{ marginTop: "auto", marginBottom: "auto" }}>
                                                <img onClick={() => { this.handleCloseModal(); openItemDetails(item); }} className="img-fluid"
                                                    style={{
                                                        backgroundColor: 'white',
                                                        width: "auto",
                                                        height: "auto",
                                                        cursor: 'pointer'
                                                    }}
                                                    src={PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + PicturName) : require('../images/noImage.png')}
                                                    alt=""
                                                />
                                            </div>
                                            <div className='col-8'>
                                                <div className='row'>
                                                    <div className='text-left' style={{ color: "#808080", fontSize: 12}}>
                                                        {ItemName}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-7' style={{justifyContent: "left", marginLeft: -30 }}> 
                                                        <input
                                                            // disabled={!(!!OnHandPrincipal)}
                                                            id={'input-quantity-' + ItemCode+ index}
                                                            type="number" 
                                                            min='1'
                                                            max={Number(OnHandPrincipal) -1}
                                                            disabled = {isDevolution ? true : false} 
                                                            // value={ Number(quantity) <1 ? "" : Number(quantity) }
                                                            value={ Number(quantity) ? Number(quantity) : "" }
                                                            className="form-control mb-1 p-0" name={'quantity' + ItemCode}
                                                            placeholder={Number(quantity) === 0 ? '0' : "1"}
                                                            style={{ borderRadius: 8, borderColor: "#666666", color: "#666666", fontSize:10, textAlign: 'center' }}
                                                            onChange={(event) => { this.changLocalQuantity(index, item, event) }}
                                                            onBlur={(event) => { this.changeQuantity(index, item, event) }}
                                                            onFocus={(event) => event.target.select()}
                                                        />
                                                    </div>
                                                    <div className='col-5' style={{justifyContent: "right"}}>
                                                        {quantity &&
                                                            <img 
                                                                src={config.icons.trashRed}
                                                                alt="Eliminar" 
                                                                style={{ cursor: 'pointer', color: '#DF7654', width: '16px', height: '16px' }} 
                                                                onClick={() => this.deleteItemLocal({ item, deleteAll: false })} 
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12' style={{ marginTop: "auto", marginBottom: "auto" }}>
                                                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                                                    {flagDiscount && (
                                                        <>
                                                            <strong style={{ color: 'red', fontSize: '12px', fontWeight:'bolder' }}>
                                                                {Discount + ' % OFF'}
                                                            </strong>
                                                            <strong style={{ color: 'red', fontSize: '12px', marginLeft:'28px' }}>
                                                                Antes
                                                                <span style={{ textDecoration: 'line-through', color: 'black', marginLeft: '5px' }}>
                                                                    <CurrencyFormat
                                                                        value={PriceBeforeDisc || 0}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        prefix={currency.MainCurncy !== Currency ? '$' : currency.DocCurrCod}
                                                                        suffix={' ' + (Currency || config.general.currency)}>
                                                                    </CurrencyFormat>
                                                                    {flagM2 && <span style={{ marginLeft: '12px', color: '#89c33f' }}>m²</span>}
                                                                </span>
                                                            </strong>
                                                        </>
                                                    )}
                                                    <div className="row d-flex justify-content-center align-items-center">
                                                        <strong>
                                                            <CurrencyFormat
                                                                value={FinalPrice || 0}                                                              
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                prefix={currency.MainCurncy !== Currency ? '$' : currency.DocCurrCod}
                                                                suffix={' ' + (Currency || config.general.currency)}>
                                                            </CurrencyFormat>
                                                            {flagM2 && <span style={{ marginLeft: '12px', color: '#89c33f' }}>m²</span>}
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}                            
                        </div>
                        <button className="btn btn-primary m-3" style={{bottom:"50px", backgroundColor:"#122759", border:"#122759"}} onClick={() => history.goShoppingCart()} data-dismiss="modal">Ir al carrito</button>
                        {!!items.length &&
                            <button className="btn btn-orange" style={{marginBottom:"1rem", marginLeft:"1rem", marginRight:"1rem", backgroundColor:"white", border:"#122759", color:"#122759"}} onClick={() => deleteShoppingCart({ item: {}, deleteAll: true })}>Vaciar carrito</button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state,props) => {
    return {
        itemsReducer: state.ItemsReducer,
        sessionReducer: state.SessionReducer,
        configReducer: state.ConfigReducer,
        notificationReducer: state.NotificationReducer,
        shoppingCartReducer: state.ShoppingCartReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setGetShoppingCartReference: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_GET_SHOPPING_CART_REFERENCE, value}),
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsGift: value => dispatch(
            {
                type: DISPATCH_ID.SHOPPING_CART_ITEMS_GIFT, 
                value
            }
            ),
        setSearchLite : value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_LITE, value}),
        setSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH, value}),
        setIdCategory: value => dispatch({type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value}),
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SideBar);