import React, { Component } from 'react';
import { config, SERVICE_API, DISPATCH_ID } from "../libs/utils/Const";
import CurrencyFormat from 'react-currency-format';
import { ApiClient } from "../libs/apiClient/ApiClient";
import { ProgressBar } from "./index";
import $ from 'jquery';
import { connect } from 'react-redux';
let apiClient = ApiClient.getInstance();

class OverdueModal extends Component {

    async addToShopingCart() {
        //Los PROPS los consigues de abajo
        const { data, notificationReducer: { showAlert }, configReducer, enableSpinner } = this.props;
        //Lista de los productos del documento
        const bill = [];
        // Merge data from bill and data array
        for (let indice = 0; indice < data.length; indice++) {
            const arreglo = data[indice];
            let esDuplicado = false;
            for (let i = 0; i < bill.length; i++) {
                if (bill[i].ItemCode === arreglo.ItemCode) {
                    bill[i].Quantity += parseInt(arreglo.Quantity);
                    esDuplicado = true;
                    break;
                }
            }
            if (!esDuplicado) {
                bill.push(arreglo);
            }
        }
        let items = [];
        //Evadir el Articulo de envio
        data.map(item => {
            if (item.ItemCode !== "MANIOBRAS" && item.ItemCode !== "MANIOBRAS II") {
                items.push({ ItemCode: item.ItemCode, quantity: parseInt(item.Quantity) });
            }
        });
        //Lista sin el Envio o Traslado
        //Comineza a pasarse al carro
        enableSpinner(true);
        //agregar al carro directo con sql
        let responsesd = await apiClient.updateShoppingCartLocal(items);
        if (responsesd.status === 1) {
            $('#OverDueModal').modal('hide');
            showAlert({ type: 'success', message: ' Agregado a carrito correctamente ', timeOut: 0 });
            configReducer.history.goShoppingCart();
        }
        enableSpinner(false);
    }

    render() {
        const { data, configReducer: { currency }, guia, selectedInvoice } = this.props;
        return (
            <div className="modal fade" id="OverDueModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: 'center' }}>
                <div className="modal-dialog modal-xl" role="document" style={{ margin: '1.75rem auto' }}>
                    <div className="modal-content" style={{ border: "1px solid #00000073" }}>
                        <div className="modal-header" style={{ background: config.navBar.iconColor }}>
                            <h5 className="modal-title" style={{ color: config.navBar.textColor2 }}>Productos de la Factura</h5>
                            <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3" style={{ maxHeight: '600px', overflowY: 'scroll' }}>
                            <ProgressBar guiaStatus={guia} currentDocInfo={selectedInvoice} type={'OINV'} />
                            <h3 className="text-center font-weight-bold">Detalle del documento</h3>
                            <div className="text-left card" style={{ border: 'none' }}>
                                <div className="row" style={{ padding: 10, textAlign: 'center' }}>
                                    <div className="col-sm-12">
                                        <div className="container p-0">
                                            <div className="row font-weight-bold">
                                                <div className="col-md-12 table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr style={{ backgroundColor: config.navBar.iconColor, color: "white" }}>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap' }} >Clave</th>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap' }} >Nombre del Artículo</th>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap' }} >Cantidad</th>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap' }} >Precio</th>
                                                                <th className="text-center" style={{ whiteSpace: 'nowrap' }} scope="col-lg-auto">Precio con Descuento</th>
                                                                <th className="text-center" scope="col-lg-auto" style={{ whiteSpace: 'nowrap' }} >Precio Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map(item => {
                                                                return (
                                                                    <tr key={item.ItemCode}>
                                                                        <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap' }} > {item.ItemCode} </td>
                                                                        <td className="col-lg-auto text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '170px' }}>{item.Dscription}</td>
                                                                        <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap' }} > {parseInt(item.Quantity)} </td>
                                                                        <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap' }}>
                                                                            <CurrencyFormat
                                                                                value={item.Price}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={currency.MainCurncy !== item.Currency ? '' : currency.DocCurrCod + ' '}
                                                                                suffix={' ' + item.Currency}>
                                                                            </CurrencyFormat>
                                                                        </td>
                                                                        <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap' }} >
                                                                            <CurrencyFormat
                                                                                value={item.Price - (item.Price * (item.DiscPrcnt / 100))}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={currency.MainCurncy !== item.Currency ? '' : currency.DocCurrCod + ' '}
                                                                                suffix={' ' + item.Currency}
                                                                            >
                                                                            </CurrencyFormat>
                                                                        </td>
                                                                        <td className="col-lg-auto text-center" style={{ whiteSpace: 'nowrap' }}>
                                                                            <CurrencyFormat
                                                                                value={item.LineTotal}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={currency.MainCurncy !== item.Currency ? '' : currency.DocCurrCod + ' '}
                                                                                suffix={' ' + currency.MainCurncy}>
                                                                            </CurrencyFormat>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            flexDirection: 'row'
                        }}>
                            <span className="font-weight-bold">Comentario:</span>
                            <span style={{ marginLeft: '10px' }}> {data[0]?.Comments || 'Sin comentario disponible'}</span>
                        </div>
                        <div className="modal-footer">
                            {/* Renderiza el InvoiceTotal una vez */}
                            {selectedInvoice && (
                                <div className="btn text-white" style={{ background: config.navBar.iconColor }}>
                                    <CurrencyFormat
                                        value={selectedInvoice.DocTotal}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        prefix={currency.DocCurrCod + ' '}
                                        suffix={' ' + config.general.currency}
                                    />
                                </div>
                            )}
                            <button type="button" className="btn text-white" style={{ background: "#86C03F" }} onClick={() => this.addToShopingCart()}>
                                <img src={config.icons.shoppingCartW} alt="Agregar al carrito" style={{ width: '15px', height: '15px', marginRight: '5px' }}/>
                                &nbsp; Agregar al carrito
                            </button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemDetailsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OverdueModal);