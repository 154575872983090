import React, { Component } from 'react';
import { Session, OrderDetailsModal } from "../../components";
import { DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE, VIEW_NAME, config } from "../../libs/utils/Const";
import { ApiClient } from "../../libs/apiClient/ApiClient";
import { connect } from "react-redux";
import moment from 'moment';
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';
import ExportReportGeneral from '../../components/ExportReportGeneral';
import * as XLSX from "xlsx";
require('datatables.net-bs4');

const apiClient = ApiClient.getInstance();

class TimesView extends Component {
    constructor(props) {
        super(props);
        const f = new Date;
        const newDate = new Date(f.setMonth(f.getMonth() + +(-11)))
        this.state = {
            orders: [],
            order: {
                header: {},
                body: []
            },
            fechaInicio: '',
            fechaFinal: '',
            fechamin: moment(newDate).format('YYYY-MM-DD'),
            fechamax: moment(new Date()).format('YYYY-MM-DD'),
            isLoaded: false,
            guia: [],
            invoices: '',
        };
        this.table = null;
    }

    // Se Manda a traer la funcion de definicion de tabla
    componentDidMount() {
        let fechaInicio = moment(new Date()).format('YYYY-MM-DD')
        let fechaFinal = moment(new Date()).format('YYYY-MM-DD')
        this.setState({
            fechaInicio,
            fechaFinal
        });
        this.fillDataTimes();
    }
    // Se llena la tabla con la configuración
    fillDataTimes = () => {
        if ($.fn.DataTable) {
            this.table = $('#tablaTimes').DataTable({
                "paging": true,
                "info": false,
                "searching": false,
                //"bDestroy": true,	
                "order": [[0, 'desc']],
                "language": {
                    "lengthMenu": "Mostrar _MENU_ registros por página",
                    "zeroRecords": "No se encontraron registros",
                    "info": "Mostrando página _PAGE_ de _PAGES_",
                    "infoEmpty": "No existen registros",
                    "infoFiltered": "(filtrado de _MAX_ entradas)",
                    "loadingRecords": "Buscando...",
                    "processing": "Procesando...",
                    "search": "Buscar:",
                    "paginate": {
                        "first": "Primero",
                        "last": "Último",
                        "next": "Siguiente",
                        "previous": "Anterior"
                    }
                }
            });
        } else {
            console.error('tablaTimes>>jQuery or DataTables not loaded');
        }
    }
    // Se vacia la tabla
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.times != nextProps.times) {
            this.table.destroy();
            this.table = null;
        }
        return true;
    }
    // se llena la tabla
    componentDidUpdate() {
        if (this.table == null) {
            this.fillDataTimes();
        }
    }


    //Evento para capturar los valores de los campos
    handelChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value
        });
    };

    // openOrder = async docEntry => {
    //     const { enableSpinner, notificationReducer: { showAlert } } = this.props;
    //     enableSpinner(true);
    //     let response = await apiClient.getOrder(docEntry);
    //     enableSpinner(false);

    //     if (response.status === SERVICE_RESPONSE.SUCCESS) {
    //         this.setState({
    //             order: response.data,
    //             guia: response.data.statusGuia || [],
    //             invoices: response.data.Invoices || '',
    //         });

    //         $('#orderDetailsModal').modal('show');
    //         return;
    //     }
    //     showAlert({ type: 'error', message: response.message })
    // };

    /*handleInputDateInicio = event => {
        const {notificationReducer: {showAlert}} = this.props;
        const selectedDate = event.nativeEvent.target.value;
        const endDate = this.state.fechaFinal;
    
        if (selectedDate < this.state.fechamin) {
            showAlert({type: 'warning', message: 'La fecha de inicio no puede ser menor que la fecha mínima permitida.'});
            
        } else if (endDate && selectedDate > endDate) {
            showAlert({type: 'warning', message: 'La fecha de inicio no puede ser mayor que la fecha final.'});
        } else {
            this.setState({
                isLoaded: true,
                fechaInicio: selectedDate,
            });
        }
    }
    
    handleInputDateFinal = event => {
        const {notificationReducer: {showAlert}} = this.props;
        const selectedDate = event.nativeEvent.target.value;
        const startDate = this.state.fechaInicio;
    
        if (selectedDate < this.state.fechamin) {
            showAlert({type: 'warning', message: 'La fecha final no puede ser menor que la fecha mínima permitida.'});
           
        } else if (startDate && selectedDate < startDate) {
            showAlert({type: 'warning', message: 'La fecha final no puede ser menor que la fecha de inicio.'});
           
        } else if (selectedDate > this.state.fechamax) {
            showAlert({type: 'warning', message: 'La fecha final no puede ser mayor que la fecha máxima permitida.'});
            
        } else {
            this.setState({
                fechaFinal: selectedDate,
            });
        }
    }*/

    // docChangeName(status) {
    //     let result = '';
    //     switch (status) {
    //         case 'O':
    //             result = "Abierto";
    //             break;
    //         case 'C':
    //             result = "Cerrado";
    //             break;
    //         default:
    //             break;
    //     }
    //     return result;
    // };

    // docChangeNameFMB(target, base) {
    //     let result = '';
    //     let response = target != -1 ? target : base

    //     switch (response) {
    //         case 23:
    //             result = "Sin atender";
    //             break;
    //         case 17:
    //             result = "Atendido";
    //             break;
    //         case 15:
    //             result = "Surtido";
    //             break;
    //         case 13:
    //             result = "Facturado";
    //             break;
    //         default:
    //             result = 'Atendido';
    //             break;
    //     }
    //     return result;
    // };
    getStatusDoc = (guiaStatus, section, type, currentDocInfo) => {

        let firstStateDoc = guiaStatus && guiaStatus.length > 0 ? guiaStatus.find(guiaState => (guiaState.Legend === 'ACEPTADO')) : null;
        let secondStateDoc = guiaStatus && guiaStatus.length > 0 ? guiaStatus.find(guiaState => (guiaState.Legend === 'PREPARANDO')) : null;
        let threeStateDoc = guiaStatus && guiaStatus.length > 0 ? guiaStatus.find(guiaState => (guiaState.Legend === 'EMPAQUETANDO')) : null;
        let fourStateDoc = guiaStatus && guiaStatus.length > 0 ? guiaStatus.find(guiaState => (guiaState.Legend === 'FACTURADO')) : null;

        let typeDocument = '';
        switch (currentDocInfo.ObjType) {
            case '23': typeDocument = 'OQUT'; break;
            case '17': typeDocument = 'ORDR'; break;
            case '15': typeDocument = 'ODLN'; break;
            case '13': typeDocument = 'OINV'; break;
            default: break;
        }

        const formatDate = (date) => moment(date).utc().format('DD-MM-YYYY');
        const getDocNum = (doc) => doc ? '#' + doc.DocNum : '';

        let result = {
            date: '--',
            docNum: ''
        };

        if (section === 'ACEPTADO') {
            result.date = (firstStateDoc ? formatDate(firstStateDoc.DocDate) : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'OQUT' ? formatDate(currentDocInfo.DocDate) : '--');
            result.time = (firstStateDoc ? firstStateDoc.DocTimeFormatted : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'OQUT' ? currentDocInfo.DocTimeFormatted : '--');
            result.docNum = (firstStateDoc ? getDocNum(firstStateDoc) : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'OQUT' ? getDocNum(currentDocInfo) : typeDocument !== 'OQUT' ? 'Sin orígen' : '');
        } else if (section === 'PREPARANDO') {
            result.date = (secondStateDoc ? formatDate(secondStateDoc.DocDate) : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'ORDR' ? formatDate(currentDocInfo.DocDate) : '--');
            result.time = (secondStateDoc ? secondStateDoc.DocTimeFormatted : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'ORDR' ? currentDocInfo.DocTimeFormatted : '--');
            result.docNum = (secondStateDoc ? getDocNum(secondStateDoc) : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'ORDR' ? getDocNum(currentDocInfo) : typeDocument === 'OQUT' ? 'Sin procesar' : 'Sin orígen');
        } else if (section === 'EMPAQUETANDO') {
            result.date = (threeStateDoc ? formatDate(threeStateDoc.DocDate) : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'ODLN' ? formatDate(currentDocInfo.DocDate) : '--');
            result.time = (threeStateDoc ? threeStateDoc.DocTimeFormatted : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'ODLN' ? currentDocInfo.DocTimeFormatted : '--');
            result.docNum = (threeStateDoc ? getDocNum(threeStateDoc) : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'ODLN' ? getDocNum(currentDocInfo) : typeDocument === 'OQUT' || typeDocument === 'ORDR' ? 'Sin procesar' : 'Sin orígen');
        } else if (section === 'FACTURADO') {
            result.date = (fourStateDoc ? formatDate(fourStateDoc.DocDate) : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'OINV' ? formatDate(currentDocInfo.DocDate) : '--');
            result.time = (fourStateDoc ? fourStateDoc.DocTimeFormatted : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'OINV' ? currentDocInfo.DocTimeFormatted : '--');
            result.docNum = (fourStateDoc ? getDocNum(fourStateDoc) : guiaStatus && (guiaStatus.length === 0 || !guiaStatus.DocNum) && typeDocument === 'OINV' ? getDocNum(currentDocInfo) : typeDocument === 'OQUT' || typeDocument === 'ORDR' || typeDocument === 'ODLN' ? 'Sin procesar' : 'Sin orígen');
        }
        return result;
    };

    exportToExcel = () => {
        const { times, notificationReducer: { showAlert } } = this.props;

        if (times && times.length) {
            const tableData = times.map((time, index) => {
                const { Document, StatusGuia } = time;
                const aceptadoStatus = this.getStatusDoc(StatusGuia, 'ACEPTADO', 'OQUT', Document);
                const preparandoStatus = this.getStatusDoc(StatusGuia, 'PREPARANDO', 'ORDR', Document);
                const empaquetandoStatus = this.getStatusDoc(StatusGuia, 'EMPAQUETANDO', 'ODLN', Document);
                const facturadoStatus = this.getStatusDoc(StatusGuia, 'FACTURADO', 'OINV', Document);

                const calculateElapsedHours = (start, end) => {
                    if (!start || !end || !start.time || !start.date || !end.date || !end.time) return '--';

                    const startDateTime = moment(`${start.date} ${start.time}`, 'DD-MM-YYYY HH:mm:ss', true);
                    const endDateTime = moment(`${end.date} ${end.time}`, 'DD-MM-YYYY HH:mm:ss', true);

                    if (!startDateTime.isValid() || !endDateTime.isValid()) return '--';

                    const duration = moment.duration(endDateTime.diff(startDateTime));
                    const hours = Math.floor(duration.asHours());
                    const minutes = duration.minutes();

                    if (isNaN(hours) || isNaN(minutes) || hours < 0 || minutes < 0) return '--';

                    return `${hours} h ${minutes} m`;
                };

                const elapsedHoursOQUTToORDR = calculateElapsedHours(aceptadoStatus, preparandoStatus);
                const elapsedHoursORDRToODLN = calculateElapsedHours(preparandoStatus, empaquetandoStatus);
                const elapsedHoursODLNToOINV = calculateElapsedHours(empaquetandoStatus, facturadoStatus);

                return {
                    "Vendedor": Document?.SlpName || '',
                    "Socio de Negocios": Document?.CardName || '',
                    "Cotización": aceptadoStatus.docNum || '',
                    "Fecha de creación (Cotización)": aceptadoStatus.date || '',
                    "Hora de creación (Cotización)": aceptadoStatus.time || '--',
                    "Horas Transcurridas (Cotización a Pedido)": elapsedHoursOQUTToORDR,
                    "Pedidos": preparandoStatus.docNum || '',
                    "Fecha de creación (Pedido)": preparandoStatus.date || '',
                    "Hora de creación (Pedido)": preparandoStatus.time || '--',
                    "Horas Transcurridas (Pedido a Entrega)": elapsedHoursORDRToODLN,
                    "Entregas": empaquetandoStatus.docNum || '',
                    "Fecha de creación (Entrega)": empaquetandoStatus.date || '',
                    "Hora de creación (Entrega)": empaquetandoStatus.time || '--',
                    "Horas Transcurridas (Entrega a Factura)": elapsedHoursODLNToOINV,
                    "Facturas": facturadoStatus.docNum || '',
                    "Fecha de creación (Factura)": facturadoStatus.date || '',
                    "Hora de creación (Factura)": facturadoStatus.time || '--',
                };
            });

            const worksheetGeneral = XLSX.utils.json_to_sheet(tableData);

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, worksheetGeneral, "Tiempos");

            // Guardar el archivo
            XLSX.writeFile(wb, "Análisis-Tiempos.xlsx");
        } else {
            // Manejar caso donde no hay datos para exportar
            showAlert({ type: 'info', message: 'No se ha podido generar el archivo porque no se encontraron resultados para su búsqueda' });
        }
    };


    render() {
        const { order, guia, invoices } = this.state;
        const { configReducer: { currency }, times, getData, enableSpinner, docsToPrint, changeDocsToPrint, oneOrder, inputDocNum, isLoaded, handleInputDateInicio, openClose, handleInputDateFinal, param1, fechaInicio, fechaFinal, fechamin, fechamax, filter, handleInputFilter, warehouses, analyticWhs, handleSelectedFilter} = this.props;
        return (
            <div className="content-fluid" style={{ backgroundColor: config.Back.backgroundColor }}>
                <div className="row text-center" style={{ marginBottom: 16, marginTop: 16 }}>
                    <div className="col-md-12 pb-2">
                        <h3 style={{ fontWeight: "bolder", fontSize: "1rem", color: "black" }}>TIEMPOS TRANSCURRIDOS POR ÁREAS</h3>
                    </div>
                </div>
                <div className="row text-center" style={{ marginBottom: 16, marginTop: 16 }}>
                    <div className="col-md-3">
                        <div>
                            <div class="search" style={{ justifyContent: "center", display: 'flex' }}>
                                <input
                                id="inputSearch"
                                className="form-control col-md-6 searchTerm"
                                type="text"
                                placeholder="Vendedor o Socio"
                                value={filter}
                                // disabled={docsToPrint?.length !== 0}
                                onChange={(event) => handleInputFilter(event)}
                                // onKeyDown={event => event.keyCode === 13 && oneOrder.trim() !== '' && getData(12)}
                                onKeyDown={event => event.keyCode === 13 && getData('A1', filter)}
                                />
                                <button
                                    type="button"
                                    className="searchButton"
                                    // onClick={() => oneOrder.trim() !== '' && getData(12)} 
                                    onClick={() => getData('A1', filter)}
                                    // disabled={docsToPrint.length !== 0}
                                    style={{backgroundColor: config.navBar.menuCategoriesBackgroundHover}}
                                >
                                    <img alt="searchWhite" style={{ width:'16px', height:'16px'}} src={config.icons.searchWhite} />
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* {warehouses ?
                        <div className=" row col-md-3">
                            <select name="warehouses" placeholder="Filtrar por almacén" value={analyticWhs} className="form-control text-left" onChange={async (event) => await handleSelectedFilter('A1', event)} style={{ textAlign: 'center', height: 30, padding: 0 }}>
                                <option value="ALL">Todos los almacenes</option>
                                {
                                    warehouses.map((whs, index) => (
                                        <option value={whs.WhsCode} key={index}>{whs.WhsName}</option>
                                    ))}
                            </select>
                        </div>
                        : <></>} */}
                    <div className="col-md-1 mt-2 pb-2">
                        <div>
                            <div class="search" style={{ justifyContent: "center", display: 'flex' }}>
                                {/* <select 
                                id="documents" 
                                name="documents" 
                                onChange={async (event) => { await openClose(event.target.value); await getData(2) }} 
                                >
                                    <option value="All">Todos</option>
                                    <option value="O">Abiertos</option>
                                    <option value="C">Cerrados</option>
                                </select> */}
                            </div>
                        </div>
                    </div>
                    <div className=" row col-md-3">
                        {/* <h4 className="pr-2">Desde:</h4> */}
                        <h4 className="pr-2">Fecha:</h4>
                        <input
                            id="fechaInicio"
                            type="date"
                            className="form-control col-md-6"
                            name="fechauno"
                            min={fechamin}
                            max={fechamax}
                        value={fechaInicio}
                        onChange={(event) => handleInputDateInicio(event)} 
                        />
                    </div>
                    {/* <div className="row col-md-3 pb-3">
                        <h4 className="pr-2">Hasta:</h4>
                        <input
                            id="FechaFin"
                            type="date"
                            className="form-control col-md-6"
                            name="fechados"
                            min={fechamin}
                            max={fechamax}
                            value={fechaFinal}
                        onChange={(event) => handleInputDateFinal(event)} 
                        />
                    </div> */}
                    <div className="col-auto pb-2">
                        <button
                            onClick={() => getData('A1')}
                            className="btn botonResumen"
                            style={{
                                backgroundColor: config.navBar.iconColor,
                                color: config.navBar.textColor2,
                                fontWeight: "bold",
                            }}
                        >
                            Ver Tiempos
                        </button>
                    </div>
                </div>
                <div className="ml-1">
                    {/* {(docsToPrint && docsToPrint.length > 0) &&
                        <ExportReportGeneral
                            orders={orders}
                            data={docsToPrint}
                            typeDocs={"orders"}
                            enableSpinner={enableSpinner}
                        />
                    } */}
                </div>
                <div className="row">
                    <div style={{ cursor: 'pointer' }}>
                        <button onClick={this.exportToExcel} className="btn btn-success">
                            Exportar a Excel
                        </button>
                    </div>
                    <div className="col-md-12 table-responsive tableReports">
                        <table id="tablaTimes" className="table table-striped scrolltable">
                            <thead style={{ textAlign: "-webkit-center" }}>
                                <tr style={{ backgroundColor: config.shoppingList.summaryList, color: "white", fontSize: "15px" }}>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}></th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Vendedor</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Socio de Negocios</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Cotización</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Fecha de creación</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Hora de creación</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Horas Transcurridas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Pedidos</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Fecha de creación</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Hora de creación</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Horas Transcurridas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Entregas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Fecha de creación</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Hora de creación</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Horas Transcurridas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Facturas</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Fecha de creación</th>
                                    <th scope="col-lg-auto" style={{ verticalAlign: "middle" }}>Hora de creación</th>
                                    {/* <th scope="col-lg-auto">ORDN</th>
                                    <th scope="col-lg-auto">DocDate</th>
                                    <th scope="col-lg-auto">Totales</th> */}
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {times && times.length ? times.map((time, index) => {
                                    const { Document, StatusGuia } = time;
                                    const aceptadoStatus = this.getStatusDoc(StatusGuia, 'ACEPTADO', 'OQUT', Document);
                                    const preparandoStatus = this.getStatusDoc(StatusGuia, 'PREPARANDO', 'ORDR', Document);
                                    const empaquetandoStatus = this.getStatusDoc(StatusGuia, 'EMPAQUETANDO', 'ODLN', Document);
                                    const facturadoStatus = this.getStatusDoc(StatusGuia, 'FACTURADO', 'OINV', Document);

                                    const calculateElapsedHours = (start, end) => {
                                        if (!start || !end || !start.time || !start.date || !end.date || !end.time) return '--';

                                        const startDateTime = moment(`${start.date} ${start.time}`, 'DD-MM-YYYY HH:mm:ss', true);
                                        const endDateTime = moment(`${end.date} ${end.time}`, 'DD-MM-YYYY HH:mm:ss', true);

                                        if (!startDateTime.isValid() || !endDateTime.isValid()) return '--';

                                        const duration = moment.duration(endDateTime.diff(startDateTime));
                                        const hours = Math.floor(duration.asHours());
                                        const minutes = duration.minutes();

                                        if (isNaN(hours) || isNaN(minutes) || hours < 0 || minutes < 0) return '--';

                                        return `${hours} h ${minutes} m`;
                                    };

                                    let elapsedHoursOQUTToORDR = calculateElapsedHours(aceptadoStatus, preparandoStatus);
                                    let elapsedHoursORDRToODLN = calculateElapsedHours(preparandoStatus, empaquetandoStatus);
                                    let elapsedHoursODLNToOINV = calculateElapsedHours(empaquetandoStatus, facturadoStatus);

                                    return (
                                        <tr key={index}>
                                            <th scope="col-lg-auto row" style={{ verticalAlign: "middle" }}></th>
                                            <th scope="col-lg-auto row text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{Document?.SlpName || ''}</th>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{Document?.CardName || ''}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{aceptadoStatus.docNum || ''}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{aceptadoStatus.date || ''}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{aceptadoStatus.time || '--'}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{elapsedHoursOQUTToORDR}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{preparandoStatus.docNum || ''}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{preparandoStatus.date || ''}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{preparandoStatus.time || '--'}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{elapsedHoursORDRToODLN}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{empaquetandoStatus.docNum || ''}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{empaquetandoStatus.date || ''}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{empaquetandoStatus.time || '--'}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{elapsedHoursODLNToOINV}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{facturadoStatus.docNum || ''}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{facturadoStatus.date || ''}</td>
                                            <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{facturadoStatus.time || '--'}</td>
                                            {/* <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{''}</td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{''}</td>
                                                <td className="col-lg-auto text-center" style={{ fontSize: "15px", verticalAlign: "middle" }}>{''}</td> */}
                                        </tr>
                                    );
                                }) : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimesView);