import React, { Component } from 'react';
import { ApiClient } from "../libs/apiClient/ApiClient";
import { config, DISPATCH_ID, ROLES, SERVICE_API, SERVICE_RESPONSE } from "../libs/utils/Const";
import { connect } from "react-redux";
import Modal from './Modal';
import $ from 'jquery';
import moment from 'moment';

let modal = new Modal();

let apiClient = ApiClient.getInstance();

class SearchItemsActions extends Component {

    componentDidMount() {
        const { setSearchItemsByKeyReference, setSearchItemsByCategoriesReference, updateFavoriteReference, addShoppingCartReference, addBackOrderReference,
            deleteShoppingCartReference, deleteBackOrderReference, openItemDetailsReference, openItemDetailsEdit, setStockDetailsReference, setInfoDiscountReference } = this.props;
        setSearchItemsByKeyReference(this.searchItemsByKey);
        updateFavoriteReference(this.updateFavorite);
        addShoppingCartReference(this.addShoppingCart);
        addBackOrderReference(this.addBackOrder);
        deleteBackOrderReference(this.deleteBackOrder);
        deleteShoppingCartReference(this.deleteShoppingCart);
        openItemDetailsReference(this.openItemDetails);
        setSearchItemsByCategoriesReference(this.searchItemsByCategories);
        openItemDetailsEdit(this.openItemDetailsEdit);
        this.searchAutoComplete();
        this.getSpecialPrice();
        this.searchItemsByCategoriesHome2();
        setStockDetailsReference(this.getStockDetails);
        setInfoDiscountReference(this.getInfoDiscount);
    }

    async componentDidUpdate(prevProps) {
        const { itemsReducer: { location, flagGetFilters }, setFlagGetFilters } = this.props;
        if (prevProps.itemsReducer.location !== location && !flagGetFilters) {  // Volver a solicitar los filtros para la nueva búsqueda
            await setFlagGetFilters(true);
        }
    }

    getSpecialPrice = async () => {
        const { sessionReducer: { role }, specialPrice, notificationReducer: { showAlert }, configReducer: { history }, enableSpinner } = this.props;
        let response = await apiClient.getSpecialPrice();
        response = response.data ? response.data : [];
        specialPrice(response);
    }

    searchAutoComplete = async () => {
        const {
            setItemsAutoComplete,
            setSearchCategory,
            setTags,
            setCurrency,
            setPackage,
            setIdFamilyFilters,
            setIdCategoryFilters,
            setfieldsFilter,
            setWhsGeneral,
            setWhsGeneralName,
            setWhsGeneralType,
            setIdCategoriesMenu,
            setIdMarcasMenu// REGION MENU NAVBAR
        } = this.props;
        let packageFilter = [];
        let suggestions = [];
        let searchCategory = [];
        let currency = {};
        let fieldsFilter = [];
        let categories = localStorage.getItem(config.general.localStorageNamed + 'categories');
        categories = JSON.parse(categories);
        setTags(categories || []);

        let apiResponse = await apiClient.AutoComplete();
        if (apiResponse.status === 0) {
            return;
        }



        // Debntro de data esta los articulos
        suggestions = apiResponse.data.data;
        setItemsAutoComplete(suggestions);

        // Dentro de searchCategory estan las categorias
        searchCategory = apiResponse.data.searchCategory;
        setTags(searchCategory);

        // Dentro de currency estan las monedas
        currency = apiResponse.data.currency;
        setCurrency(currency);

        // Dentro de fieldsFilter estan las opciones de filtro
        fieldsFilter = apiResponse.data.fieldsFilter;
        setfieldsFilter(fieldsFilter);

        //Dentro de packageFilter estan las paqueterias
        packageFilter = apiResponse.data.setPackage;
        setPackage(packageFilter);


        setIdFamilyFilters(apiResponse.data.idFamilyFilter);
        setIdCategoryFilters(apiResponse.data.idCategoryFilter);

        // ########## MENU NAVBAR REDUX
        setIdCategoriesMenu(apiResponse.data.resultIdLevel1);
        setIdMarcasMenu(apiResponse.data.resultIdLevelMarca);
    }

    searchItemsByKeyRes = async (page = 0, view = null) => {
        const { sessionReducer: { role }, itemsReducer, itemsReducer: { whsGeneral, location }, setItemsCategories, setItemsFilters, setItemsSearch, setTotalRows, notificationReducer: { showAlert }, configReducer: { history }, enableSpinner, sessionReducer, setSearchLite, setItemsCategoryFilters } = this.props;
        let key = 'FindAll';
        let uniqueFilter = '';
        let user = {};
        let whs = '';
        const cliente = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')) ?? {};
        // try {
        //     user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
        //     whs = user.U_FMB_Handel_ALMA || '01';
        // } catch (error) {
        //     whs = '01'
        // }
        // if(view === 'redView' || view === 'whiteView' || view === 'outletView' || view === 'vitrinaView'){
        //     key = 'FindAll';
        // } else {
        // Validate click on search icon
        // if(searchBar === 1){
        if (role === ROLES.CLIENT && cliente.CardCode === undefined){
            showAlert({type: 'warning', message: "Debes seleccionar un cliente para ingresar aqui", timeOut: 2000});
        }else{
            if (itemsReducer.search && itemsReducer.search !== '') {

                key = itemsReducer.search;
            }else{
                if(itemsReducer.searchFilter && itemsReducer.searchFilter !== '' && itemsReducer.location === 'navBar'){
                    key = itemsReducer.searchFilter; //Filtro de texto de la barra de busqueda interno guardado
                }
            }
            // }            
            // }
            if (itemsReducer.uniqueFilter) {
                uniqueFilter = Object.entries(itemsReducer.uniqueFilter).length !== 0 ? itemsReducer.uniqueFilter : '';
            }
            let localShoppingCart = undefined;
            if (role === ROLES.PUBLIC) {
                localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
                localShoppingCart = JSON.parse(localShoppingCart) || [];
            }
            //comenar por si falla
            if (view === "Lite") {
                uniqueFilter =
                    { property: 'searchLite', value: '1' };
            }
    
            whs = whsGeneral || whs;
    
            enableSpinner(true);
            let response = null;
            let search = {
                key: key,
                localShoppingCart: localShoppingCart,
                whs: whs,
                page: page,
                uniqueFilter: uniqueFilter,
                view: view,
            }
            // response = await apiClient.searchByKey(key, localShoppingCart, whs, page, uniqueFilter, view/*, categorias, priceMax, priceMin, marca, fabrica, aparato, refaccion, stock */);
    
            response = await apiClient.searchByKey(search);
            // let response2 = await apiClient.searchByKeySpeed(search);
            enableSpinner(false);
            if (response.status === SERVICE_RESPONSE.SUCCESS) {
                setItemsSearch(response.data.results);
                setTotalRows(response.data.totalRows);
                setItemsCategories(response.data.allCategories);
                this.applyFilters(response.data.results);
                // setItemsFilters({});
                await setItemsCategoryFilters({}); //Limpiar filtros guardados de busqueda de categorias
                if (view === "Lite") {
                    setSearchLite(response.data.results)
                }
    
                let today = moment().format('YYYY-MM-DD');
                let time = moment().format('h:mm:ss a');
                let data = {
                    cve_user: sessionReducer.user ? sessionReducer.user.CardCode : 'Desconocido',
                    search: key,
                    date: today,
                    time: time,
                    origin: 'Busqueda',
                    num_results: response.data.results.length,
                }
                // await apiClient.Search(data);
                if (view === 'Refacciones') {
                    history.goItemsRefacciones();
                } else if (view === 'Food') {
                    history.goItemsFood();
                } else if (view === 'Paking') {
                    history.goItemsPaking();
                } else if (view === 'Ferreteria') {
                    history.goItemsFerreteria();
                } else if (view === 'Mulos') {
                    history.goItemsMulos();
                } else if (view === 'Lite') {
                    history.goShoppingCart();
                } else {
                    history.goItems();
                }
                return;
            }
            showAlert({ type: 'error', message: response.message, timeOut: 0 });
        }
    };

    searchItemsByKey = async (page = 0) => {
        const { sessionReducer: { role }, itemsReducer: { whsGeneral, location, search, NewFilters, prevSearchVal, flagGetFilters }, setNewFilters, setFlagGetFilters } = this.props;
        const { setItemsCategories, setItemsSearch, setTotalRows, notificationReducer: { showAlert }, configReducer: { history }, enableSpinner, setSearchLite } = this.props;
        const cliente = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')) ?? {};
        let key = search || '';

        if (role === ROLES.CLIENT && !cliente.CardCode){
            return showAlert({type: 'warning', message: "Debes seleccionar un cliente para ingresar aquí", timeOut: 2000});
        }

        enableSpinner(true);
        
        if (location === 'searchGral') key = prevSearchVal.prevSearch;
           
        const data = {
            key,
            whs: whsGeneral || '',
            page: page || 0,
            NewFilters,
            view: location,
            role,
            flagGetFilters,
        };

        const response = await apiClient.searchByKey(data);

        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            if (response.data.results.length < 1) {
                if (location === 'searchLite') showAlert({ type: 'info', message: 'No se encontraron resultados con su búsqueda', timeOut: 0 });
                setNewFilters({});                
            }

            if (location !== "searchLite") {
                setItemsSearch(response.data.results);
                setTotalRows(response.data.totalRows);
                this.applyFilters(response.data.results);
                if (flagGetFilters) { // Guardar filtros nuevos
                    setItemsCategories(response.data.allCategories);
                }
            } else {
                setSearchLite(response.data.results)
            }

            if (location === 'searchLite') {
                history.goShoppingCart();
            } else {
                history.goItems();
            }

            await setFlagGetFilters(false); //No volver a solicitar filtros
        } else {
            setNewFilters({});
            await setFlagGetFilters(true); // Volver a solicitar filtros
            showAlert({ type: 'error', message: response.message, timeOut: 0 });
        }

        enableSpinner(false);
    };

    searchItemsByCategories = async (category, page = 0, view) => {
        const { sessionReducer: { role }, itemsReducer,itemsReducer: { nextPage, whsGeneral, nameCategory, idCategory }, setItemsCategories, setItemsSearch, setTotalRows, notificationReducer: { showAlert }, configReducer: { history }, enableSpinner, sessionReducer } = this.props;
        let whs = '';
        let user = {};
        let uniqueFilter = ''
        let localShoppingCart = undefined;
        let cliente = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')) ?? {};
        if(role === ROLES.CLIENT && cliente.CardCode === undefined){
            showAlert({type: 'warning', message: "Debes seleccionar un cliente para ingresar aqui", timeOut: 2000});
        }else{
            if (role === ROLES.PUBLIC) {
                localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
                localShoppingCart = JSON.parse(localShoppingCart) || [];
            }
            let nameCategory1 = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'NameCategory'));
            enableSpinner(true);
            await setItemsSearch([]);
            view = (view) ? view : nameCategory; // eliminanda pero si falla busqueda de categorias o hamburguesa puede ser esto //si falla es mi culpa 
            // view = nameCategory;
    
            whs = whsGeneral || whs;

            if (itemsReducer.uniqueFilter) {
                uniqueFilter = Object.entries(itemsReducer.uniqueFilter).length !== 0 ? itemsReducer.uniqueFilter : 'nouniqueFilter';
            }

            uniqueFilter = uniqueFilter ? JSON.stringify(uniqueFilter) : 'nouniqueFilter';
            let response = await apiClient.searchByCategory(category, localShoppingCart, whs, page, view, uniqueFilter);
            enableSpinner(false);
            if (response.status === SERVICE_RESPONSE.SUCCESS) {
                setItemsSearch(response.data.results);
                setTotalRows(response.data.totalRows);
                setItemsCategories(response.data.allCategories);
                this.applyFilters(response.data.results);
                let today = moment().format('YYYY-MM-DD');
                let time = moment().format('h:mm:ss a');
                let data = {
                    cve_user: sessionReducer.user ? sessionReducer.user.CardCode : 'Desconocido',
                    search: nameCategory1,
                    date: today,
                    time: time,
                    origin: 'Categorias',
                    num_results: response.data.results.length,
                }
                // await apiClient.Search(data);
                setTimeout(() => {
                    if (view === 'Refacciones') {
                        history.goItemsRefacciones();
                    } else if (view === 'Food') {
                        history.goItemsFood();
                    } else if (view === 'Paking') {
                        history.goItemsPaking();
                    } else if (view === 'Ferreteria') {
                        history.goItemsFerreteria();
                    } else if (view === 'Mulos') {
                        history.goItemsMulos();
                    } else if (view === 'Lite') {
                        history.goQuote();
                    } else {
                        history.goItems();
                    }
    
                }, 500);
    
                return;
            }
            showAlert({ type: 'error', message: response.message, timeOut: 0 });
        }
    };

    applyFilters = data => {
        const { setItemsFilterSearch, enableSpinner } = this.props;
        // enableSpinner(true);
        setItemsFilterSearch(data);
        // enableSpinner(false);

        $("#menuCategories").removeClass('open-menu-categories');
    };

    searchItemsByCategoriesHome2 = async category => {
        const { itemsReducer: { whsGeneral }, sessionReducer: { role, user }, setItemsSearch1, setItemsSearch2, notificationReducer: { showAlert }, enableSpinner, setWhsGeneral, setWhsGeneralName, setWhsGeneralType } = this.props;

        let localShoppingCart = undefined;
        let whs = '';
        let userP = {};
        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }
        try {
            userP = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
            whs = userP.U_FMB_Handel_ALMA || '';
        } catch (error) {
            whs = '001'
        }
        enableSpinner(true);

        let busqueda2 = {
            contenido: {
                opcion: "nuevosproductos",
                limit: 20
            }
        };

        whs = whsGeneral || whs;
        await apiClient.ProductsEspecial(busqueda2, localShoppingCart, whs, 0).then(result => {
            setItemsSearch1(result.data.responseBody);
            this.applyFilters1(result.data.responseBody);
            setItemsSearch2(result.data.responseBody2);
            this.applyFilters2(result.data.responseBody2);
        });
        let responseOWHS = await apiClient.getOWHSuUser();

        let whsDefecto = responseOWHS.data.find(element => element.WhsCode === 'CD001-01')

        let whsResponse = user.whs || user.wareHouse || whsDefecto.WhsCode;
        let whsGeneralName = responseOWHS.data.find(element => element.WhsCode === whsResponse);

        if (whsGeneralName && whsResponse) {
            setWhsGeneral(whsResponse);
            setWhsGeneralName(whsGeneralName.WhsName);
            setWhsGeneralType(whsGeneralName.TAlmacen);
        }

        enableSpinner(false);
    };

    applyFilters1 = data => {
        const { setItemsFilterSearch1, enableSpinner } = this.props;
        enableSpinner(true);
        setItemsFilterSearch1(data);
        enableSpinner(false);

        $("#menuCategories").removeClass('open-menu-categories');
    };

    applyFilters2 = data => {
        const { setItemsFilterSearch2, enableSpinner } = this.props;
        enableSpinner(true);
        setItemsFilterSearch2(data);
        enableSpinner(false);

        $("#menuCategories").removeClass('open-menu-categories');
    };

    updateFavorite = async item => {
        const { sessionReducer: { role }, itemsReducer: { items }, notificationReducer: { showAlert }, enableSpinner } = this.props;


        if (role === ROLES.PUBLIC) {
            await this.needToCreateAccount();
            return;
        }
        enableSpinner(true);
        let response = await apiClient.updateFavorite(item);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 0 });
            return;
        }
        item.favorite = response.data.value; // change item details

        items.filter(itemFilter => {
            if (item.ItemCode === itemFilter.ItemCode) {
                itemFilter.favorite = response.data.value;
            }
        });

        this.applyFilters(items);

    };

    needToCreateAccount = () => {
        modal.loginModal('show');
    };

    addShoppingCart = async data => {
        const { enableSpinner, itemsReducer: { items: itemsSearch }, sessionReducer: { role }, shoppingCartReducer: { items }, notificationReducer: { showAlert }, setShoppingCart } = this.props;
        //item.U_FMB_Handel_Show100
        // if(Number(data.quantity) >= 100){            
        //     let flagAlert100 = false;
        //     itemsSearch.map(itemFilter => {                
        //         if (itemFilter.ItemCode === data.item.ItemCode) {
        //             // Validación para agregar más de 100 piezas
        //             if(itemFilter.U_FMB_Handel_Show100 == 1){
        //                 data.quantity = itemFilter.OnHandPrincipal >= data.quantity ? data.quantity : itemFilter.OnHandPrincipal;
        //             } else {
        //                 data.quantity = itemFilter.OnHandPrincipal >= 100 ? "100" : itemFilter.OnHandPrincipal;
        //                 flagAlert100 = true;
        //             }
        //         }
        //     });
        //     if(flagAlert100 === true){
        //         showAlert({type: 'warning', message: "Se excede el número de articulos de compra de este producto", timeOut: 2500});
        //     }
        // }

        if (role === ROLES.PUBLIC) {
            this.addShoppingCartPublic(data);
            return;
        }
        enableSpinner(true);

        let response = await apiClient.updateShoppingCart(data);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 0 });
            return;
        }

        let exist = response.data.value;
        if (!exist) {
            items.push({ ItemCode: data.item.ItemCode, quantity: data.quantity });
            setShoppingCart(items);
        }

        let newItem = undefined;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.quantity = data.quantity;
                newItem = data.item;
                newItem.quantity = data.quantity;
            }
        });

        this.applyFilters(itemsSearch);
    };

    addShoppingCartPublic = async data => {
        const { itemsReducer: { items: itemsSearch }, shoppingCartReducer: { items }, setShoppingCart } = this.props;

        let localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
        localShoppingCart = JSON.parse(localShoppingCart) || [];

        let exist = localShoppingCart.filter((itemFilter) => {
            return (itemFilter.ItemCode === data.item.ItemCode)
        });

        if (!exist.length) {
            localShoppingCart.push({ ItemCode: data.item.ItemCode, quantity: data.quantity });
            items.push({ ItemCode: data.item.ItemCode, quantity: data.quantity });
        } else {
            localShoppingCart.map((itemMap) => {
                if (data.item.ItemCode === itemMap.ItemCode) {
                    itemMap.quantity = data.quantity;
                }
            });
            items.map((itemMap) => {
                if (data.item.ItemCode === itemMap.ItemCode) {
                    itemMap.quantity = data.quantity;
                }
            });
        }


        let newItem = undefined;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.quantity = data.quantity;
                newItem = data.item;
                newItem.quantity = data.quantity;
            }
        });

        /*let newItemShooping = undefined;
        localShoppingCart.map( itemShopping => {
            if (itemShopping.ItemCode === data.item.ItemCode) {
                itemShopping.quantity = data.quantity;
                newItemShooping = data.item;
                newItemShooping.quantity = data.quantity;
            }
        });
        */
        items.map(itemFilter => {
            if (data.item.ItemCode === itemFilter.ItemCode) {
                itemFilter.quantity = data.quantity;
            }
        });

        localStorage.setItem(config.general.localStorageNamed + 'shoppingCart', JSON.stringify(localShoppingCart));
        setShoppingCart(items);

        this.applyFilters(itemsSearch);
        //this.changeInfoDetails(newItem);
    };

    deleteShoppingCart = async data => {
        const { itemsReducer: { items: itemsSearch }, sessionReducer: { role }, shoppingCartReducer: { items }, notificationReducer: { showAlert }, setShoppingCart,setDevolutions } = this.props;

        if (role === ROLES.PUBLIC) {
            this.deleteShoppingCartPublic(data);
            return;
        }
        let response = await apiClient.deleteShoppingCart(data);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 0 });
            return;
        }

        let newItems = items.filter(itemFilter => {
            //if(data.item.BE !== '' && data.item.BL !== ''){
    	    //    return (itemFilter.BE !== data.item.BE && itemFilter.BL !== data.item.BL)
            //}else{
            return (itemFilter.ItemCode !== data.item.ItemCode)
           // }
        });
        
        if (data.deleteAll) {
            newItems = [];
            setDevolutions(false)
        }
        setShoppingCart(newItems);

        let newItem = undefined;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.quantity = '';
                newItem = data.item;
                newItem.quantity = '';
            }
        });
        this.applyFilters(itemsSearch);
        //  this.changeInfoDetails(newItem);
    };

    deleteShoppingCartPublic = data => {
        const { itemsReducer: { items: itemsSearch }, shoppingCartReducer: { items }, setItemsSearch, setShoppingCart } = this.props;

        let localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');

        localShoppingCart = JSON.parse(localShoppingCart) || [];

        let newItems = items.filter(itemFilter => {
            return (itemFilter.ItemCode !== data.item.ItemCode)
        });

        if (data.deleteAll) {
            newItems = [];
        }

        localStorage.setItem(config.general.localStorageNamed + 'shoppingCart', JSON.stringify(newItems));
        setShoppingCart(newItems);

        let newItem = undefined;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.quantity = '';
                newItem = data.item;
                newItem.quantity = '';
            }
        });
        this.applyFilters(itemsSearch);
        //this.changeInfoDetails(newItem);
    };

    addBackOrder = async data => {
        const { itemsReducer: { items }, sessionReducer: { role }, shoppingCartReducer: { backOrder }, notificationReducer: { showAlert }, enableSpinner, setBackOrder } = this.props;

        if (Number(data.quantity) >= 100) {
            items.map(itemFilter => {
                if (itemFilter.ItemCode === data.item.ItemCode) {
                    data.quantity = itemFilter.OnHandPrincipal >= 100 ? "100" : itemFilter.OnHandPrincipal;
                }
            });
            
            showAlert({ type: 'warning', message: 'Se excede el número maximo de articulos ', timeOut: 2500 });
        }

        if (role === ROLES.PUBLIC) {
            this.needToCreateAccount();
            return;
        }

        enableSpinner(true);
        let response = await apiClient.updateBackOrder(data);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 0 });
            return;
        }

        let exist = response.data.value;
        if (!exist) {
            backOrder.push({ ItemCode: data.item.ItemCode, quantity: data.quantity });
            setBackOrder(backOrder);
        }

        let newItem = undefined;
        items.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.backOrder = true;
                newItem = data.item;
                newItem.backOrder = true;
            }
        });
        this.applyFilters(items);
        //this.changeInfoDetails(newItem);
    };

    deleteBackOrder = async data => {
        const { itemsReducer: { items }, sessionReducer: { role }, shoppingCartReducer: { backOrder }, notificationReducer: { showAlert }, enableSpinner, setBackOrder } = this.props;

        if (role === ROLES.PUBLIC) {
            this.needToCreateAccount();
            return;
        }

        enableSpinner(true);
        let response = await apiClient.deleteBackOrder(data);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 0 });
            return;
        }

        let newItems = backOrder.filter(itemFilter => {
            return (itemFilter.ItemCode !== data.item.ItemCode)
        });

        if (data.deleteAll) {
            newItems = [];
        }


        setBackOrder(newItems);

        let newItem = undefined;
        items.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.backOrder = false;
                newItem = data.item;
                newItem.backOrder = false;
            }
        });
        this.applyFilters(items);
        //this.changeInfoDetails(newItem);
    };

    openItemDetails = async item => {
        const { sessionReducer: { role }, itemsReducer: { whsGeneral }, setItemDetailsSearch, notificationReducer: { showAlert }, configReducer: { history }, enableSpinner } = this.props;
        let localShoppingCart = undefined;
        let whs = whsGeneral || '';
        let responseChildrens = [];
        let response = '';
        let haveChild = false;
        const cliente = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')) ?? {};
        // let data ={
        //     ItemCode : item.ItemCode,
        // }
        // enableSpinner(true);

        // let code = await apiClient.openItemDetails(data.ItemCode, localShoppingCart); Descomentar en b2c para permalinks
        enableSpinner(true);
        if(role === ROLES.CLIENT && cliente.CardCode === undefined){
            showAlert({type: 'warning', message: "Debes seleccionar un cliente para ingresar aqui", timeOut: 2000});
        }else{
            if (role === ROLES.PUBLIC) {
                localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
                localShoppingCart = JSON.parse(localShoppingCart) || [];
            }

            if (item.FATHER && (item.FATHER).toString() == '1') {
                responseChildrens = await apiClient.getChildrens(item.ItemCode);
                if (responseChildrens.status === SERVICE_RESPONSE.ERROR) {
                    enableSpinner(false);
                    showAlert({ type: 'error', message: 'GCS' + responseChildrens.message, timeOut: 0 });
                    return;
                } else {
                    if (responseChildrens.data.Childrens.length > 0) {

                        let ItemCode = responseChildrens.data.Childrens[0].ItemCode;
                        if (item.Articulo) {
                            ItemCode = responseChildrens.data.Childrens.filter(child => child.ItemCode == item.Articulo);
                            ItemCode = ItemCode[0].ItemCode;
                        }

                        response = await apiClient.openItemDetails(ItemCode, localShoppingCart, whs);
                        haveChild = true;

                    } else {
                        enableSpinner(false);
                        showAlert({ type: 'error', message: 'No se encontro el articulo, realice de nuevo la busqueda.', timeOut: 0 });
                        return;
                    }
                }
            } else {
                response = await apiClient.openItemDetails(item.ItemCode, localShoppingCart, whs);
            }

            if (response.status === SERVICE_RESPONSE.ERROR) {
                enableSpinner(false);
                showAlert({ type: 'error', message: 'OID' + response.message, timeOut: 0 });
                return;
            }

            let images = [];
            // images.push({ path: (response.data.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + response.data.PicturName) : require('../images/noImage.png')) });

            let imagesArray = response.data.U_Handel_ImagesArray || '';

            if (imagesArray !== '') {

                // Eliminamos el '|' al final si existe para no guardar vacios al splitear
                if(imagesArray.endsWith('|')){
                    imagesArray = imagesArray.slice(0, -1);
                }
                
                imagesArray = imagesArray.split('|');
            }else{
                imagesArray = [];
            }

            imagesArray.map(image => {
                    images.push({ path: (image ? (config.BASE_URL + SERVICE_API.getImage + '/' + image) : require('../images/noImage.png')) });
            });

            if (!images?.length) { //Default Image
                images.push({ path: require('../images/noImage.png') });
            }

            let files = [];
            let fileArray = response.data.U_Handel_attachment || '';
            fileArray = fileArray.split('|');

            fileArray.map(file => {
                if (file) {
                    files.push({ path: (file ? (config.BASE_URL + SERVICE_API.getFile + '/' + file) : ''), name: file });
                }
            });
            if (haveChild) {

                

                if (responseChildrens.data.Childrens.length > 0) {

                    let childrens = responseChildrens.data.Childrens;

                    response.data.nVariables = 0;

                    let convinaciones = [] ;
                    let convinacionesMostrar = [] ;
                    let nVariables = 0;
                    let convinacion ='';
                    
                    for (let index = 0; index < childrens.length; index++) {
                        const element = childrens[index];

                        convinacion ='';
                        nVariables = ( ( Object.keys(element).length -2 ) / 2 ) ;
                        
                        for (let index = 0; index < nVariables; index++) {
                            convinacion += element[`Code_${index}`];
                            let ExisteCampo = convinacionesMostrar.filter( convinacion => ( convinacion.code == element[`Code_${index}`])
                            && ( convinacion.name == element[`Atributo_${index}`] ) && ( convinacion.lvl == index )  
                            && ( convinacion.pather == (element[`Code_${index-1}`] || 'M') ) ) ;

                            if( ExisteCampo.length < 1 ){
                                convinacionesMostrar.push( { code: element[`Code_${index}`], name : element[`Atributo_${index}`] , lvl : index , pather: element[`Code_${index-1}`] ||'M'} );
                            }
                        }

                        convinaciones.push( { convinaciones : convinacion , ItemCode : element["ItemCode"] } );
                    }
                    
                    if(response.data.nVariables < nVariables){
                        response.data.nVariables = nVariables;
                        response.data.convinacionesMostrar = convinacionesMostrar;
                    }

                    response.data.convinaciones = convinaciones;
                    response.data.Childrens = childrens;
                    response.data.variables = Object.keys(responseChildrens.data.Childrens[0]).filter(campo => campo != 'ItemCode');
                } else {
                    enableSpinner(false);
                    showAlert({ type: 'error', message: 'No se encotro el articulo, realice denuevo la busqueda2.', timeOut: 0 });
                    return;
                }
            }

            response.data.images = images;
            response.data.files = files;

            setItemDetailsSearch(response.data);
            setTimeout(() => {
                // modal.itemDetails('show');
                // history.goItemsDetails(item.ItemName, item.ItemCode, code.data.U_Marca);  Descomentar en b2c para permalinks
                history.goItemsDetails(item.ItemCode); //Borrar si se descomenta arriba
            }, 10)
            enableSpinner(false);
        }
    };

    openItemDetailsEdit = async item => {
        const { sessionReducer: { role }, itemsReducer: { whsGeneral }, setItemDetailsSearch, notificationReducer: { showAlert }, configReducer: { history }, enableSpinner } = this.props;

        let localShoppingCart = undefined;
        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }

        enableSpinner(true);

        let whs = whsGeneral || '';
        let response = await apiClient.getItemsData(item);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 0 });
            return;
        }

        let images = [];
        // if (response.data.itemDATA[0].PicturName && response.data.itemDATA[0].PicturName.length > 0) {
        //     images.push({ path: ((config.BASE_URL + SERVICE_API.getImage + '/' + response.data.itemDATA[0].PicturName)) });
        // }

        let imagesArray = response.data.itemDATA[0].U_FMB_Handel_ImagesArray || '';
        imagesArray = imagesArray.split('|');

        imagesArray.map(image => {
            if (image) {
                images.push({ path: (image ? (config.BASE_URL + SERVICE_API.getImage + '/' + image) : require('../images/noImage.png')) });
            }
        });

        let files = [];
        let fileArray = response.data.itemDATA[0].U_Handel_attachment || '';
        fileArray = fileArray.split('|');
        fileArray.map(file => {
            if (file) {
                files.push({ path: (file ? (config.BASE_URL + SERVICE_API.getFile + '/' + file) : ''), name: file });
            }
        });

        response.data.itemDATA[0].images = images;
        response.data.itemDATA[0].files = files;

        // setItemDetailsSearch(response.data);
        // if(item.Edit == undefined && item.Edit != true){
        // setTimeout(() => {
        // modal.itemDetails('show');
        // history.goItemsDetails();
        // }, 10)
        // }

        return response;

    };

    changeInfoDetails = item => {
        const { setItemDetailsSearch } = this.props;
        if (item) {
            setItemDetailsSearch(item);
        }
    };

    getStockDetails = async (ItemCode, location = '') =>{
        const {notificationReducer: { showAlert }, enableSpinner, itemsReducer: {itemDetails, items1, items2, itemsFilter}, setItemsSearch1,setItemsSearch2, setItemsFilterSearch, setItemDetailsSearch} = this.props;
        let items = [];

        enableSpinner(true);
        let response = await apiClient.getStock(ItemCode);

        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 0 });
            enableSpinner(false);
            return;
        }

        // evaluar desde donde se hace la consulta de stock
        switch (location) {
            case 'itemDetails':
                itemDetails.whsCodes = response.data;
            break;
        // Sliders en los detalles del articulo
            case 'sliderReco1':
                items = itemDetails.OtrosClientesCompraron.length > 0 ? itemDetails.OtrosClientesCompraron : [];
            break;
            case 'sliderReco2':
                items = itemDetails.PodriaInteresarte.length > 0 ? itemDetails.PodriaInteresarte : [];
            break;
            case 'sliderReco3':
                items = itemDetails.Recomendaciones.length > 0 ? itemDetails.Recomendaciones : [];           
            break;
            case 'sliderDash1':
                items = items1.length > 0 ? items1 : [];           
            break;
            case 'sliderDash2':
                items = items2.length > 0 ? items2 : [];           
            break;
            case 'itemList':
                items = itemsFilter.length > 0 ? itemsFilter : [];           
            break;
            default:
        }

        if(location !== 'itemDetails' && items.length > 0){
            for(let i =0; i < items.length; i++){
                let item = items[i];
                if(item.ItemCode === ItemCode){
                    item.whsCodes = response.data;
                    item.flagStock = true;
                }
            }

          switch (location) {
                case 'sliderReco1':
                   itemDetails.OtrosClientesCompraron = items;
                   await setItemDetailsSearch(itemDetails);
                break;
                case 'sliderReco2':
                    itemDetails.PodriaInteresarte = items;
                    await setItemDetailsSearch(itemDetails);
               
                break;
                case 'sliderReco3':
                    itemDetails.Recomendaciones = items;
                    await setItemDetailsSearch(itemDetails);
                break;
                case 'sliderDash1':
                    await setItemsSearch1(items);
                break;
                case 'sliderDash2':
                    await setItemsSearch2(items);
                break;
                case 'itemList':
                    await setItemsFilterSearch(items);
                break;
                default:
            }
        }
        enableSpinner(false);
    }

    getInfoDiscount = async (ItemCode, location = '') =>{
        const {notificationReducer: { showAlert }, enableSpinner, itemsReducer: {itemDetails, items1, items2, itemsFilter}, shoppingCartReducer: { items: itemsShopping }, setItemsSearch1,setItemsSearch2, setItemsFilterSearch, setItemDetailsSearch, setShoppingCart} = this.props;
        let items = [];

        enableSpinner(true);
        let response = await apiClient.getInfoDiscount(ItemCode);

        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 0 });
            enableSpinner(false);
            return;
        } else if (response.status === 2) { //El descuento ya no se encuentra disponible (Fecha vencida o no existe)
            showAlert({ type: 'info', message: response.message, timeOut: 0 });
            enableSpinner(false);
            return;
        }

        // evaluar desde donde se hace la consulta de stock
        switch (location) {
            case 'itemDetails':
                itemDetails.infoDiscount = response.data;
            break;
            case 'ShoppinCart':
                items = itemsShopping?.length > 0 ? itemsShopping : [];
                break;
        // Sliders en los detalles del articulo
            case 'sliderReco1':
                items = itemDetails.OtrosClientesCompraron.length > 0 ? itemDetails.OtrosClientesCompraron : [];
            break;
            case 'sliderReco2':
                items = itemDetails.PodriaInteresarte.length > 0 ? itemDetails.PodriaInteresarte : [];
            break;
            case 'sliderReco3':
                items = itemDetails.Recomendaciones.length > 0 ? itemDetails.Recomendaciones : [];           
            break;
            case 'sliderDash1':
                items = items1.length > 0 ? items1 : [];           
            break;
            case 'sliderDash2':
                items = items2.length > 0 ? items2 : [];           
            break;
            case 'itemList':
                items = itemsFilter.length > 0 ? itemsFilter : [];           
            break;
            default:
        }

        if(location !== 'itemDetails' && items.length > 0){
            for(let i =0; i < items.length; i++){
                let item = items[i];
                if(item.ItemCode === ItemCode){
                    item.infoDiscount = response.data;
                    item.flagInfoDiscount = true;
                }
            }

          switch (location) {
                case 'sliderReco1':
                   itemDetails.OtrosClientesCompraron = items;
                   await setItemDetailsSearch(itemDetails);
                break;
                case 'sliderReco2':
                    itemDetails.PodriaInteresarte = items;
                    await setItemDetailsSearch(itemDetails);
               
                break;
                case 'sliderReco3':
                    itemDetails.Recomendaciones = items;
                    await setItemDetailsSearch(itemDetails);
                break;
                case 'sliderDash1':
                    await setItemsSearch1(items);
                break;
                case 'sliderDash2':
                    await setItemsSearch2(items);
                break;
                case 'itemList':
                    await setItemsFilterSearch(items);
                break;
                case 'ShoppinCart':
                    await setShoppingCart(items);
                    break;
                default:
            }
        }
        enableSpinner(false);
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openItemDetailsEdit: value => dispatch({ type: DISPATCH_ID.ITEMS_OPEN_ITEM_DETAILS_EDIT, value }),
        openItemDetailsReference: value => dispatch({ type: DISPATCH_ID.ITEMS_OPEN_ITEM_DETAILS_REFERENCE, value }),
        updateFavoriteReference: value => dispatch({ type: DISPATCH_ID.ITEMS_UPDATE_FAVORITE_REFERENCE, value }),
        addShoppingCartReference: value => dispatch({ type: DISPATCH_ID.ITEMS_ADD_SHOPPING_CART_REFERENCE, value }),
        addBackOrderReference: value => dispatch({ type: DISPATCH_ID.ITEMS_ADD_BACK_ORDER_REFERENCE, value }),
        deleteBackOrderReference: value => dispatch({ type: DISPATCH_ID.ITEMS_DELETE_BACK_ORDER_REFERENCE, value }),
        deleteShoppingCartReference: value => dispatch({ type: DISPATCH_ID.ITEMS_DELETE_SHOPPING_CART_REFERENCE, value }),
        setSearchItemsByKeyReference: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_KEY_REFERENCE, value }),
        setSearchItemsByCategoriesReference: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_CATEGORIES_REFERENCE, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
        setTotalRows: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_TOTALROWS, value }),
        setItemDetailsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value }),
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),
        setBackOrder: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_BACK_ORDER, value }),
        setItemsCategories: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_CATEGORIES, value }),
        setItemsFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value }),
        setItemsAutoComplete: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_AUTO_COMPLETE, value }),
        specialPrice: value => dispatch({ type: DISPATCH_ID.ITEMS_SPECIAL_PRICES, value }),
        setTags: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_TAGS, value }),
        setCurrency: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_CURRENCY, value }),
        setItemsSearch1: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS1, value }),
        setItemsSearch2: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS2, value }),
        setItemsFilterSearch1: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER1, value }),
        setItemsFilterSearch2: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER2, value }),
        setPackage: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_PACKAGE, value }),
        //117
        setIdFamilyFilters: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_ID_FAMILY_FILTERS, value }),
        setIdCategoryFilters: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_ID_CATEGORY_FILTERS, value }),
        setfieldsFilter: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_FIELDSFILTER, value }),

        setSearchLite: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_SEARCH_LITE, value }),
        setWhsGeneral: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_WHSGENERAL, value }),
        setWhsGeneralName: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_WHSGENERAL_NAME, value }),
        setWhsGeneralType: value => dispatch({type: DISPATCH_ID.ITEMS_SET_WHSGENERAL_TYPE, value}),

        // ######### REGION MENU NAVBAR ############
        setIdCategoriesMenu: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_CATEGORIES_MENU, value }),
        setIdMarcasMenu: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_MARCAS_MENU, value }),
        setDevolutions: value => dispatch({ type: DISPATCH_ID.ITEMS_DEVOLUTION, value }),
        // ######### FIN REGION ############

        // Detalles de stock por almacen
        setStockDetailsReference: value => dispatch({ type: DISPATCH_ID.ITEM_STOCK_REFRENCE, value }),
        setItemsCategoryFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_CATEGORY_FILTER, value }),

        //Información de descuento
        setInfoDiscountReference: value => dispatch({ type: DISPATCH_ID.ITEM_INFO_DISCOUNT, value }),
        
        setNewFilters: value => dispatch({ type: DISPATCH_ID.SET_NEW_FILTERS, value }),
        setPrevSearchVal: value => dispatch({ type: DISPATCH_ID.SET_PREV_SEARCH_VAL, value }),

        // Solicitar filtros por una nueva búsqueda
        setFlagGetFilters: value => dispatch({ type: DISPATCH_ID.SET_FLAG_GET_FILTERS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchItemsActions)