import React, { Component } from 'react';
import { SERVICE_RESPONSE, DISPATCH_ID, config } from "../libs/utils/Const";
import $ from 'jquery';
import { ApiClient } from "../libs/apiClient/ApiClient";
import { connect } from "react-redux";
let apiClient = ApiClient.getInstance();

class NewFamilyModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nameFamily: '',
            atributesItems: [], // Aquí cargas tus atributos con Code y Name
            atributeActive: [] // Para manejar los seleccionados
        };
    };

    componentDidMount = async () => {
        const { enableSpinner } = this.props;
        enableSpinner(true);
        const response = await apiClient.getAllAttributes();
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                atributesItems: response.data ?? [],
            });
        }
    }

    closeAction = async () => {
        $('#NewFamilyModal').modal('hide');
        $('#NewFamilyModal').on('hidden.bs.modal', function () {
        });
        await this.removeBackdrop();
    };

    removeBackdrop = () => {
        document.body.classList.remove('modal-open');
        document.body.style.overflow = ''; // Restablece el scroll si está bloqueado
        const backdrop = document.querySelector('.modal-backdrop');// Elimina manualmente cualquier backdrop que haya quedado en el DOM
        if (backdrop) {
            backdrop.remove();
        }
    }

    changeCheck(code) {
        const { atributeActive } = this.state;

        const isChecked = atributeActive?.includes(code); // Determinar si el atributo ya está en la lista activa

        let updatedActive;
        if (isChecked) {
            updatedActive = atributeActive.filter(attr => attr !== code); // Si está activo, lo quitamos de la lista
        } else {
            updatedActive = [...atributeActive, code]; // Si no está activo, lo añadimos
        }

        this.setState({
            atributeActive: updatedActive
        });
    }

    onChangeValue(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        })
    }

    SaveFamily = async () => {
        const { notificationReducer: { showAlert }, recargar, enableSpinner } = this.props;
        const { nameFamily, atributeActive } = this.state;

        if (!nameFamily) return showAlert({ type: 'warning', message: 'Ingrese el nombre de la familia', timeOut: 5000 });

        if (atributeActive?.length === 0) return showAlert({ type: 'warning', message: 'Seleccione al menos un atributo para la familia', timeOut: 5000 });

        if (nameFamily && atributeActive?.length > 0) { // Agregar nueva familia
            enableSpinner(true);
            let dataFamily = { nameFamily, atributeActive, type: 1 }; // 1- Crear, 2-Actualizar, 3-Eliminar, 4-Obtener
            let result = await apiClient.updateFamilies(dataFamily);
            enableSpinner(false);
            if (result.status == SERVICE_RESPONSE.SUCCESS) {
                enableSpinner(true);
                await recargar();
                await this.closeAction();
                enableSpinner(false);
                showAlert({ type: 'success', message: result.message, timeOut: 8000 });
            } else {
                showAlert({ type: 'error', message: result.message, timeOut: 8000 });
            }
        }
    }

    render() {
        const { nameFamily, atributesItems } = this.state;

        return (
            <div className="modal fade bd-example-modal-lg" id="NewFamilyModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{ minWidth: 300, maxWidth: '80vw', margin: '1.75rem auto', borderRadius: 15 }}>
                    <div className="modal-content">

                        <div className="modal-header" style={{ background: config.navBar.iconColor, color: '#FFFFFF' }}>
                            <h5 className="modal-title">Nueva Familia</h5>
                            <button type="button" style={{ color: "#FFFFFF", opacity: 1, margin: 0, padding: 0 }} className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeAction()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>Ingrese la nueva familia</label>
                                </div>
                                {/* Nombre de la familia*/}
                                <div className="form-row" style={{ padding: "0px 50px 0px 50px" }}>
                                    <div className="form-group col-md-12" style={{ display: "flex" }}>
                                        <input type="text"
                                            id='id-nameFamily'
                                            name='nameFamily'
                                            value={nameFamily ?? ''}
                                            placeholder='Nombre de la Familia'
                                            // onKeyDown={event => event.keyCode === 13 && this.SaveFamily()}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(event) => this.onChangeValue(event)}
                                            maxLength={100}
                                        />
                                    </div>
                                </div>

                                {/* Atributos de la familia*/}
                                <div className="form-row" >
                                    <div className="form-group col-12 row body-form-col" >
                                        {atributesItems.length > 0 &&
                                            atributesItems.map((attribute, index) => {
                                                const isChecked = this.state.atributeActive.includes(attribute.Code);
                                                return (
                                                    <div key={index} className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 row">
                                                        <input
                                                            className="col-1 check-atributes"
                                                            type="checkbox"
                                                            id={'Check' + attribute.Code}
                                                            checked={isChecked} // Controlado por el estado
                                                            onChange={() => this.changeCheck(attribute.Code)} // Cambiar estado
                                                            maxLength={30}
                                                        />
                                                        <div className="col-9">
                                                            <label className="text-atribut" htmlFor={'Check' + attribute.Code}>
                                                                {attribute.Name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn "
                                style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF' }}
                                onClick={() => { this.closeAction() }} > Cancelar </button>
                            <button type="button" className="btn"
                                style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF' }}
                                onClick={(event) => this.SaveFamily(event)}> Guardar </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        sessionReducer: store.SessionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewFamilyModal);