import React, { Component } from 'react';
import { SERVICE_RESPONSE, DISPATCH_ID, config, ROLES} from "../libs/utils/Const";
import $, { isNumeric } from 'jquery';
import {ApiClient} from "../libs/apiClient/ApiClient";
import {connect} from "react-redux";
let apiClient = ApiClient.getInstance();

class PreguntasModal extends Component{

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            telephone: '',
            coment: '',
        };
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        await this.SendMessage();
        this.setState({
            name: '',
            email: '',
            telephone: '',
            coment: ''
        });
    };

    async componentDidMount() {
        const {enableSpinner, notificationReducer: {showAlert}, sessionReducer: { user }} = this.props;
        this.setState({
            email: '',
            name: '',
        });
    }

    SendMessage = async () => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        const {coment, email, name, telephone} = this.state;
        
        if (name.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo del Nombre no puede ir vacío', 
            timeOut: 0
        });
        if (email.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo de email no puede ir vacío',
            timeOut: 0
        });
        if (coment.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo de comentarios no puede ir vacío',
            timeOut: 0
        });

        enableSpinner(true);
        let data = {
            coment, 
            email,
            name, 
            telephone,
            typeEmail: 'Ayuda',
        };
        // return;
        let apiResponse = await apiClient.sendMessage(data);

        if (apiResponse.status === SERVICE_RESPONSE.SUCCESS) {
            enableSpinner(false);
            showAlert({type: 'success', message: apiResponse.message });
            $('#ClarificationModal').modal('hide');
            return;
        }

        showAlert({type: 'warning', message: "Aviso: "+apiResponse.message});
        enableSpinner(false)
    };

    handleChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value
        });
    };
    renderClarification = () => {
        const{coment, user, email, name, telephone} = this.state;
        return(
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label style={{fontWeight: "bold",fontSize:'12px'}}>¡Estamos Aquí para Ayudarte!</label>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="name">Nombre</label>
                        <input type="text" className="form-control" name="name" id="name" onChange={this.handleChange} autoComplete="off" value={name} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="email">Correo Electrónico</label>
                        <input type="email" className="form-control" name="email" id="email" onChange={this.handleChange} autoComplete="off" value={email} />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="telephone">Teléfono</label>
                        <input type="tel" className="form-control" name="telephone" id="telephone" onChange={this.handleChange} autoComplete="off" value={telephone} />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="comment">Comentarios</label>
                    <textarea className="form-control" name="coment" id="coment" rows="5" onChange={this.handleChange} autoComplete="off" value={coment}></textarea>
                </div>
                <button type="submit" className="btn" style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}}>Enviar</button>
            </form>
        );
    }

    render(){
        const {title} = this.props;
        return (
            <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" id="ClarificationModal" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header" style={{background: config.navBar.iconColor, color: '#FFFFFF'}}>
                            <span className="modal-title" style={{fontSize:'12px'}}>{title}</span>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.renderClarification()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

}
const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        sessionReducer: store.SessionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PreguntasModal);